import React from 'react';
import styled from 'styled-components';
import { String } from '../String';
import { RangedMeasurement } from './RangedMeasurement';

const RangeContainer = styled.div<Props>`
	padding: ${({ padding }) => padding || "0 20px 0 0"};
	width: ${({width}) => width || "365px"};
`;

const CustomRangeInputWrapper = styled.div`
	width: "365px";
	height: 71px;
	border: 1px solid #b3b5c4;
	border-radius: 4px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: "Open-sans";
`;

const StyledRange = styled.input`
	width: 100%;
	height: 4px;
	position: absolute;
	outlie: none;
	bottom: 0;
	accent-color: ${(props) => props.theme.appColors.commonTheme};
`;

const MinMax = styled.div`
	padding: 0 20px 0 0;
	width: 365px;
	display: flex;
	justify-content: space-between;
`;

type Props = {
	width?: string;
	padding?: string;
	specialSymbol: string;
	minValue: string;
	maxValue: string;
	label?: string;
	valueHandler?: (value: string) => string | undefined;
	currancyHandler?: (value: string) => string;
	callbackValue: (value: string) => void;
	value: string;
	setValue?: (value: number) => void;
};

export const CustomRangeInput: React.FC<Props> = (props) => {
	const {
		specialSymbol,
		minValue,
		maxValue,
		label,
		value,
		setValue,
		valueHandler,
		currancyHandler,
		callbackValue,
	} = props;
	const rangeValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue && setValue(+e.currentTarget.value)
	};
	return (
		<RangeContainer {...props}>
			{label && <String fSize={"14px"}>{label}</String>}
			<CustomRangeInputWrapper>
				<RangedMeasurement>
					{currancyHandler
						? currancyHandler(value)
						: value}
				</RangedMeasurement>
				<RangedMeasurement>{specialSymbol}</RangedMeasurement>
				<StyledRange
					type={"range"}
					min={minValue}
					max={maxValue}
					onChange={rangeValueHandler}
					value={value}
					onClick={e => {
						callbackValue(e.currentTarget.value);
					}}
				/>
			</CustomRangeInputWrapper>
			<MinMax>
				<String fSize={"12px"}>
					{valueHandler ? valueHandler(minValue) : minValue}
				</String>
				<String fSize={"12px"}>
					{valueHandler ? valueHandler(maxValue) : maxValue}
				</String>
			</MinMax>
		</RangeContainer>
	);
};