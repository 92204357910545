import { FormikValues } from 'formik';
import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OCUPATION_TYPES_MAPPER, PRODUCTS_CODES } from '../../../../../config';
import { getDadataAddress, getDadataCompany, onValidateParam, } from '../../../../../redux/Application/app-reducer';
import {
  setCompanyInnDadata,
  setCompanyNameDadata,
  setPhoneValidationErrorMessage,
  setWorkPlaceAddressList,
  setWorkPlaceLegalAddressList,
} from '../../../../../redux/Application/appActions';
import {
	ICompanyDadataList,
	ICurrentRoutesParams,
	IDadataAddressTypes,
} from '../../../../../redux/Application/appTypes';
import { IAdvancedOptions2, IOpfListRef, IProfileConfig2, IProfileState2, } from '../../../../../redux/Credit/creditTypes';
import { AnimatedBlock } from '../../../../../styles/UIKit/AnimatedBlock';
import { ComboBox } from '../../../../../styles/UIKit/ComboBox';
import { CurrancyField } from '../../../../../styles/UIKit/CurrancyField';
import { CheckBoxField } from '../../../../../styles/UIKit/CustomCheckBox/CheckBoxField';
import { CustomField } from '../../../../../styles/UIKit/CustomField/CustomField';
import { CustomField2 } from '../../../../../styles/UIKit/CustomField/CustomField2';
import { TelephoneField } from '../../../../../styles/UIKit/CustomField/TelephoneField';
import { AutoCompleteAddresses } from '../../../../../styles/UIKit/dadata/AutoCompleteAddresses';
import { DadataCompanyAutoComplete } from '../../../../../styles/UIKit/dadata/DadataCompanyAutoComplete';
import { CustomerCalendar } from '../../../../../styles/UIKit/DatePicker/CustomerCalendar/CustomerCalendar';
import { FejoaMappedSelect } from '../../../../../styles/UIKit/FejoaSelect/FejoaMappedSelect';
import { Flex } from '../../../../../styles/UIKit/Flex';
import { String } from '../../../../../styles/UIKit/String';
import { Title } from '../../../../../styles/UIKit/Title';
import { dateIntoString } from '../../../../../utils/commonFunctions';
import { onBubbleSortByParam } from '../../../../../utils/onBubbleSortByParam';
import { onSetFieldBorderColor } from '../../../../../utils/onSetFieldBorderColor';
import { ParagraphWrapper } from '../../../../CommonPagesStyles';
import { PublicServicesBlock } from '../../../../../components/PublicServicesBlock/PublicServicesBlock';
import { EsiaWrapper } from '../../../../PassportDataForm/PassportMainBlock/PassportMainBlock';
import { onAuthEsia } from '../../../../../redux/Auth/auth-reducer';
import { AppRootStateType } from '../../../../../redux/store';

interface IEmployer {
	esiaUsage?: boolean;
	formik: FormikValues;
	fieldsWrapConditions: boolean;
	standartWidthMeasures: string;
	standartHeightMeasures: string;
	standartFontSizeMeasures: string;
	updateFormCallBack: (formItem: string, currentValue: string, event: FormikValues) => void;
	workPlaceLegalAddressList: IDadataAddressTypes[];
	profileConfig: IProfileConfig2;
	workPlaceAddressList: IDadataAddressTypes[];
	setFormDataUpdates: (values: IProfileState2) => void;
	companyNamesDadataList: ICompanyDadataList[];
	companyInnDadataList: ICompanyDadataList[];
	occupationCompanyOpfRef: IOpfListRef[];
	screenWidth: number;
	phoneValidationErrorMessage: string | null;
}

export const Employer: React.FC<IEmployer> = (props) => {
	const {
		esiaUsage,
		formik,
		fieldsWrapConditions,
		standartWidthMeasures,
		standartHeightMeasures,
		standartFontSizeMeasures,
		updateFormCallBack,
		workPlaceLegalAddressList,
		workPlaceAddressList,
		setFormDataUpdates,
		profileConfig,
		companyNamesDadataList,
		companyInnDadataList,
		occupationCompanyOpfRef,
		screenWidth,
		phoneValidationErrorMessage,
	} = props;

	const [companyIsSelected, setCompanyIsSelected] = useState(false);
	const { flowId, product } = useSelector<AppRootStateType, ICurrentRoutesParams>((state) => state.appReducer.currentForm);
	const dispatch = useDispatch();
	const [defaultOccupationDocConfirmationIncomesValue, setdefaultOccupationDocConfirmationIncomesValue] = React.useState("Выберите");


	const [occupationStartWorkDate, setOccupationStartWorkDate] = React.useState<string>(formik.values.occupationStartWorkDate ? dateIntoString(formik.values.occupationStartWorkDate) : "");


	// *** HANDLERS FOR WORK PLACE LEGAL ADDRESS LIST ***
	const workPlaceLegalAddress = formik.values.occupationCompanyLegalAddress ? formik.values.occupationCompanyLegalAddress.title : "";
	const onChangeWorkPlaceLegalAddressCallback = (value: IDadataAddressTypes) => {
		resetCompanyWhenEditing()
		dispatch(getDadataAddress("address", "title", value.title, "legal_work_place"));
	};
	const setWorkPlaceLegalAddressListOptionHandler = (chosenItem: IDadataAddressTypes) => {
		setCompanyIsSelected(true)
		formik.setFieldValue("occupationCompanyLegalAddress", chosenItem);
		const nextState = {...formik.values};
		if (formik.values.occupationCompanyIsActualMutchLegal) {
			formik.setFieldValue("occupationCompanyAddress", chosenItem);
			nextState.occupationCompanyAddress = chosenItem;
		}
		setFormDataUpdates({...nextState, occupationCompanyLegalAddress: chosenItem});
		dispatch(setWorkPlaceLegalAddressList([]));
	};
	const workPlaceLegalAddressListHandler = () => dispatch(setWorkPlaceLegalAddressList([]));

	// *** HANDLERS FOR WORK PLACE MATCH CHECKBOX ***
	const onChangeOccupationCompanyIsActualMutchLegalHandler = ({ target: { checked } }: any) => {
		const nextState = {...formik.values};
		const occupationCompanyLegalAddress = formik?.values?.occupationCompanyLegalAddress;
		formik.setFieldValue("occupationCompanyIsActualMutchLegal", checked);
		if (checked) {
			formik.setFieldValue("occupationCompanyAddress", occupationCompanyLegalAddress);
			nextState.occupationCompanyAddress = occupationCompanyLegalAddress;
		}
		setFormDataUpdates({...nextState, occupationCompanyIsActualMutchLegal: checked});
	};

	// *** HANDLERS FOR WORK PLACE ADDRESS LIST ***
	const workPlaceAddress = formik.values.occupationCompanyAddress ? formik.values.occupationCompanyAddress.title : "";
	const onChangeWorkPlaceAddressCallback = (value: IDadataAddressTypes) => {
		dispatch(getDadataAddress("address", "title", value.title, "work_place"));
	};
	const setWorkPlaceAddressListOptionHandler = (chosenItem: IDadataAddressTypes) => {
		formik.setFieldValue("occupationCompanyAddress", chosenItem);
		setFormDataUpdates({...formik.values, occupationCompanyAddress: chosenItem});
		dispatch(setWorkPlaceAddressList([]));
	};
	const workPlaceActualAddressListHandler = () => {
		dispatch(setWorkPlaceAddressList([]));
	};


	// *** COMPANY NAME HANDLER ***
	const resetCompanyWhenEditing = () => {
		if (companyIsSelected) {
			setCompanyIsSelected(false)
			setCompanyDataInForm({});
		}
	}

	const currentCompanyName = formik.values.occupationCompanyName ? formik.values.occupationCompanyName : "";
	const onChangeCompanyNameHandler = (value: string) => {
		dispatch(getDadataCompany("name", value));
		resetCompanyWhenEditing();
	}
	const setCompanyDataInForm = (companyData: Partial<ICompanyDadataList>) => {
		formik.setFieldValue("occupationCompanyName", companyData?.name || '');
		formik.setFieldValue("occupationCompanyInn", companyData?.inn || '');
		formik.setFieldValue("occupationCompanyLegalAddress", companyData?.address || '');
		formik.setFieldValue("occupationCompanyOgrn", companyData?.ogrn || '');
		formik.setFieldValue("occupationCompanyBranchType", companyData?.branchType || '');
		formik.setFieldValue("occupationCompanyCodeOkved", companyData?.okved || '');
		formik.setFieldValue("occupationCompanyStatus", companyData?.status || '');
		formik.setFieldValue("occupationCompanyCodeOKOPF", companyData?.okopf || '');
		formik.setFieldValue("occupationCompanyOrgType", companyData?.type || '');
		formik.setFieldValue("occupationCompanyKpp", companyData?.kpp || '');
	}
	const setCompanyNameHandler = (chosenItem: ICompanyDadataList) => {
		setCompanyIsSelected(true)
		setCompanyDataInForm(chosenItem);
		dispatch(setCompanyNameDadata([]));
	};
	const resetCompanyNameDataHandler = () => dispatch(setCompanyNameDadata([]));


	// *** COMPANY INN HANDLER ***
	const currentCompanyInn = formik.values.occupationCompanyInn ? formik.values.occupationCompanyInn : "";
	const onChangeCompanyInnHandler = (value: string) => {
		resetCompanyWhenEditing();
		dispatch(getDadataCompany("inn", value));
	}
	const setCompanyInnHandler = (chosenItem: ICompanyDadataList) => {
		setCompanyIsSelected(true)
		formik.setFieldValue("occupationCompanyName", chosenItem.name);
		formik.setFieldValue("occupationCompanyInn", chosenItem.inn);
		formik.setFieldValue("occupationCompanyLegalAddress", chosenItem.address);
		dispatch(setCompanyInnDadata([]));
	};
	const resetCompanyInnDataHandler = () => dispatch(setCompanyInnDadata([]));

	const sortedOpfList = occupationCompanyOpfRef.sort((a, b) => +a.pos > +b.pos ? 1 : -1)

	const onBlurPhoneHandler = (formItem: string, currentValue: string, event: FormikValues) => {
		let promise;
		if (formItem) updateFormCallBack(formItem, currentValue, event);
		promise = dispatch(setPhoneValidationErrorMessage(null));
		// if (formik.values.occupationPhoneNumber) {
		// 	dispatch(setPhoneValidationErrorMessage(null));
		// 	promise = dispatch(onValidateParam({paramValue: formik.values.occupationPhoneNumber, searchParam: "phone"}));
		// } else {
		// 	promise = dispatch(setPhoneValidationErrorMessage(null));
		// }
		return promise;
	}

	// *** Подстановка значений документа подтверждения дохода при нажатии чекбокса ***
	const onOccupationDocConfirmationIncomesUpdateHandler = () => {
			formik.setFieldValue("isReadyConfirmIncomes", !formik.values.isReadyConfirmIncomes);
			{
				const defaultValue = profileConfig.occupationDocConfirmationIncomesRef.find((x) => x.code==='StatementPFR' && x.parentCode==='Employment')
				defaultValue && setdefaultOccupationDocConfirmationIncomesValue(defaultValue.title);
				formik.setFieldValue("occupationDocConfirmationIncomes",defaultValue?.code)
			}
    };

	// @ts-ignore
	window.occupationPhoneNumberHandler = onBlurPhoneHandler;

	const onEsia = () => flowId && product && dispatch(onAuthEsia(flowId, PRODUCTS_CODES[product]));

	return (
		<ParagraphWrapper padding={fieldsWrapConditions ? "0" : "10px"}>
			<FejoaMappedSelect
        formik={formik}
        updateFormCallBack={updateFormCallBack}
				formItem={"occupationCategory"}
        height={standartHeightMeasures}
        width="100%"
        fSize={standartFontSizeMeasures}
        options={profileConfig.occupationCategoryRef}
        defaultValue={"Выберите"}
				label={"Категория должности"}
				borderColor={ onSetFieldBorderColor(formik, "occupationCategory") }
        errorValue={formik.touched.occupationCategory && formik.errors.occupationCategory}
      />
			<Flex
				width={fieldsWrapConditions ? "750px" : "100%"}
				justify="space-between"
				align={fieldsWrapConditions ? "center" : "none"}
				margin="12px 0"
				direction={fieldsWrapConditions ? "row" : "column"}
			>
				<CustomField
					onBlurHandler={updateFormCallBack}
					screenWidth={screenWidth}
					height={standartHeightMeasures}
					width={standartWidthMeasures}
					fSize={standartFontSizeMeasures}
					label={"Должность"}
					formItem={"occupationPost"}
					{...formik.getFieldProps("occupationPost")}
					error={ formik.touched.occupationPost && formik.errors.occupationPost }
					borderColor={ onSetFieldBorderColor(formik, "occupationPost") }
					errorValue={formik.touched.occupationPost && formik.errors.occupationPost}
				/>
				<TelephoneField
					label="Рабочий телефон (Необязательно)"
					width={standartWidthMeasures}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					onBlurHandler={onBlurPhoneHandler}
					formItem={"occupationPhoneNumber"}
					{...formik.getFieldProps("occupationPhoneNumber")}
					error={ formik.touched.occupationPhoneNumber && !!formik.errors.occupationPhoneNumber }
					borderColor={ onSetFieldBorderColor(formik, "occupationPhoneNumber") }
					errorValue={ phoneValidationErrorMessage || (formik.touched.occupationPhoneNumber && formik.errors.occupationPhoneNumber) }
				/>
			</Flex>
			<Title>Работодатель</Title>
			<ParagraphWrapper margin="10px 0" padding="0">
				<DadataCompanyAutoComplete
					formik={formik}
					formName={"occupationCompanyName"}
					onBlurHandler={updateFormCallBack}
					width={"100%"}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					label={"Наименование организации"}
					dadataList={companyNamesDadataList}
					currentValue={currentCompanyName}
					onChangeHandler={onChangeCompanyNameHandler}
					onMouseDownHandler={setCompanyNameHandler}
					ResetListHandler={resetCompanyNameDataHandler}
					paramKey={"name"}
					error={ formik.touched.occupationCompanyName && !!formik.errors.occupationCompanyName }
					borderColor={ onSetFieldBorderColor(formik, "occupationCompanyName") }
					errorValue={formik.touched.occupationCompanyName && formik.errors.occupationCompanyName}
				/>
			</ParagraphWrapper>
			<ComboBox
        formik={formik}
        label={"Организационно-правовая форма"}
        formName={"occupationCompanyCodeOKOPF"}
				currentValue={occupationCompanyOpfRef?.filter(el => el.code === formik.values.occupationCompanyCodeOKOPF)[0]?.title || formik.values.occupationCompanyCodeOKOPF || ""}
				options={sortedOpfList}
				maxOptionsLength={11}
  			optionParam={"code"}
				onChange={(e) => {
					resetCompanyWhenEditing()
					formik.handleChange(e)
				}}
				onMouseDownHandler={(chosenItem: string) => {
					setCompanyIsSelected(true)
					formik.setFieldValue("occupationCompanyCodeOKOPF", chosenItem)
				}}
        width="100%"
				height={standartHeightMeasures}
        fSize={standartFontSizeMeasures}
				borderColor={ onSetFieldBorderColor(formik, "occupationCompanyCodeOKOPF") }
        errorValue={ formik.touched.occupationCompanyCodeOKOPF && formik.errors.occupationCompanyCodeOKOPF }
			/>
			<ParagraphWrapper margin="10px 0" padding="0">
				<AutoCompleteAddresses
					formik={formik}
					formName={"occupationCompanyLegalAddress"}
					width={"100%"}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					label={"Юридический адрес"}
					dadataAddressList={workPlaceLegalAddressList}
					currentValue={workPlaceLegalAddress}
					onChangeHandler={onChangeWorkPlaceLegalAddressCallback}
					onMouseDownHandler={setWorkPlaceLegalAddressListOptionHandler}
					ResetListHandler={workPlaceLegalAddressListHandler}
					error={ formik.touched.occupationCompanyLegalAddress && !!formik.errors.occupationCompanyLegalAddress }
					borderColor={ onSetFieldBorderColor(formik, "occupationCompanyLegalAddress") }
					errorValue={ formik.touched.occupationCompanyLegalAddress && formik.errors.occupationCompanyLegalAddress }
				/>
			</ParagraphWrapper>
			<Flex
				align={"center"}
				margin={"20px 0"}
				// padding={fieldsWrapConditions ? "10px 0" : "10px"}
			>
				<CheckBoxField
					formItem="occupationCompanyIsActualMutchLegal"
					checked={formik.values.occupationCompanyIsActualMutchLegal}
					onChange={onChangeOccupationCompanyIsActualMutchLegalHandler}
				/>
				<String margin="0 20px">
					Фактический адрес совпадает с юридическим
				</String>
			</Flex>
			{!formik.values.occupationCompanyIsActualMutchLegal && (
				<AnimatedBlock>
					<AutoCompleteAddresses
						formik={formik}
						formName={"occupationCompanyAddress"}
						width={"100%"}
						height={standartHeightMeasures}
						fSize={standartFontSizeMeasures}
						label={"Фактический адрес"}
						dadataAddressList={workPlaceAddressList}
						currentValue={workPlaceAddress}
						onChangeHandler={onChangeWorkPlaceAddressCallback}
						onMouseDownHandler={setWorkPlaceAddressListOptionHandler}
						ResetListHandler={workPlaceActualAddressListHandler}
						error={ formik.touched.occupationCompanyAddress && !!formik.errors.occupationCompanyAddress }
						borderColor={ onSetFieldBorderColor(formik, "occupationCompanyAddress") }
						errorValue={ formik.touched.occupationCompanyAddress && formik.errors.occupationCompanyAddress }
					/>
				</AnimatedBlock>
			)}
			<Flex
				direction={fieldsWrapConditions ? "row" : "column"}
				width={fieldsWrapConditions ? "750px" : "100%"}
				justify="space-between"
				align={fieldsWrapConditions ? "center" : "none"}
				margin="12px 0"
			>

				<DadataCompanyAutoComplete
					formik={formik}
					formName={"occupationCompanyInn"}
					onBlurHandler={updateFormCallBack}
					width={standartWidthMeasures}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					label={"ИНН"}
					dadataList={companyInnDadataList}
					currentValue={currentCompanyInn}
					onChangeHandler={onChangeCompanyInnHandler}
					onMouseDownHandler={setCompanyInnHandler}
					ResetListHandler={resetCompanyInnDataHandler}
					paramKey={"inn"}
					error={ formik.touched.occupationCompanyInn && !!formik.errors.occupationCompanyInn }
					borderColor={ onSetFieldBorderColor(formik, "occupationCompanyInn") }
					mask={[ /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/ ]}
					errorValue={ formik.touched.occupationCompanyInn && formik.errors.occupationCompanyInn }
					type={"tel"}
				/>
			</Flex>
			<Flex
				direction={fieldsWrapConditions ? "row" : "column"}
				width={fieldsWrapConditions ? "750px" : "100%"}
				justify="space-between"
				align={fieldsWrapConditions ? "center" : "none"}
			>
				<CustomerCalendar
					width={standartWidthMeasures}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					label={"Дата трудоустройства"}
					maxDate={new Date()}
					updateFormHandler={setFormDataUpdates}
					formik={formik}
					formItem={"occupationStartWorkDate"}
					value={formik.values.occupationStartWorkDate || null}
					dateValue={occupationStartWorkDate}
					setDateValue={setOccupationStartWorkDate}
					error={ formik.touched.occupationStartWorkDate && !!formik.errors.occupationStartWorkDate }
					errorValue={ formik.touched.occupationStartWorkDate && formik.errors.occupationStartWorkDate }
					borderColor={ onSetFieldBorderColor(formik, "occupationStartWorkDate") }
				/>
				<CurrancyField
					formik={formik}
					onBlurHandler={updateFormCallBack}
					fieldName={"occupationMonthlyIncomesValue"}
					width={standartWidthMeasures}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					label={"Размер ежемесячного дохода (руб.)"}
					error={ formik.touched.occupationMonthlyIncomesValue && !!formik.errors.occupationMonthlyIncomesValue }
					borderColor={ onSetFieldBorderColor(formik, "occupationMonthlyIncomesValue") }
					errorValue={ formik.touched.occupationMonthlyIncomesValue && formik.errors.occupationMonthlyIncomesValue }
				/>
			</Flex>
			<Flex align={fieldsWrapConditions ? "center" : "none"} margin={"0 0 20px 0"}>
				<CheckBoxField
					formItem="isGettingSalaryOnCard"
					checked={formik.values.isGettingSalaryOnCard}
					onChange={() => {
						formik.setFieldValue("isGettingSalaryOnCard", !formik.values.isGettingSalaryOnCard)
						formik.setFieldValue("isReadyConfirmIncomes", false)
					}}
				/>
				<String margin="0 20px">
					Получаю заработную плату на карту Банка Зенит
				</String>
			</Flex>
			{!formik.values.isGettingSalaryOnCard && <AnimatedBlock>
				<Flex align={"center"} margin={"0 0 20px 0"}>
					<CheckBoxField
						formItem="isReadyConfirmIncomes"
						checked={formik.values.isReadyConfirmIncomes}
						onChange={() => onOccupationDocConfirmationIncomesUpdateHandler()}
					/>
					<String margin="0 20px">
						Готов подтвердить доход
					</String>
				</Flex>
			</AnimatedBlock>}
			{formik.values.isReadyConfirmIncomes && (
				<AnimatedBlock>
					<FejoaMappedSelect
						formik={formik}
						updateFormCallBack={updateFormCallBack}
						formItem={"occupationDocConfirmationIncomes"}
						height={standartHeightMeasures}
						width={"100%"}
						fSize={standartFontSizeMeasures}
						options={
							profileConfig.occupationDocConfirmationIncomesRef
								.filter((el) => el.parentCode && el.parentCode === OCUPATION_TYPES_MAPPER.Employment)
								.sort((a, b) => a.title < b.title ? -1 : 1)
						}
						defaultValue={defaultOccupationDocConfirmationIncomesValue}
						label={"Документ для подтверждения дохода"}
						borderColor={ onSetFieldBorderColor(formik, "occupationDocConfirmationIncomes") }
						errorValue={ formik.touched.occupationDocConfirmationIncomes && formik.errors.occupationDocConfirmationIncomes }
					/>
					{
						!esiaUsage && product && formik.values.occupationDocConfirmationIncomes === 'StatementPFR' && <AnimatedBlock>
							<Flex>
								<EsiaWrapper product={product}>
									<PublicServicesBlock onEsia={onEsia} isIncomeTexts={true} />
								</EsiaWrapper>
							</Flex>
						</AnimatedBlock>
					}
				</AnimatedBlock>
			)}
		</ParagraphWrapper>
	);
};
