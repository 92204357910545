import { useSelector } from 'react-redux'
import './Disclaimer.css'
import { AppRootStateType } from '../../../redux/store'
import { TestPageNames } from '../../../utils/getQueryParams';

export const Disclaimer = () => {
  const testPageName = useSelector<AppRootStateType, string | undefined>(
		(state) => state.appReducer.testPageName
	);
  const product = useSelector<AppRootStateType, string | undefined>(
		(state) => state.appReducer.currentForm.product
	);
  return (<div className={"disclaimer"}>
    {(!testPageName || testPageName === TestPageNames.TEST1) &&  product === 'cards'? 
  <div className={'container'}>
      Кредитная карта Привилегий (Тарифный план 1). Валюта счета – рубли. Два льготных периода: 120 дней на покупки и 120 дней на 
			снятие наличных и переводы, действуют с 1-го числа месяца, в котором совершена первая соответствующая операция. 
      Льготный период возобновляется на следующий день после полного погашения задолженности. ПСК: 29,573% - 39,491%. 
			Процентная ставка 29,9 – 39,9%. Минимальный платеж – 2% от суммы задолженности, минимум 300 руб. 
			Неустойка при неоплате минимального платежа – 0,1% ежедневно на сумму просроченной задолженности. 
			Кредитный лимит: от 25 000 до 2 млн. руб. Подробные условия – на zenit.ru. ПАО Банк ЗЕНИТ. 
			Ген. лицензия Банка России №3255 от 16.12.2014.
    </div> :
   <div className={'container'}>
      Кредитная карта Привилегий. Валюта счета – рубли. Два льготных периода: 120 дней на покупки и 120 дней на снятие
      наличных и переводы, действуют с 1-го числа месяца, в котором совершена первая соответствующая операция.
      Льготный период возобновляется на следующий день после полного погашения задолженности.
      ПСК: 29,561% - 39,491%. Процентная ставка 29,9 – 69,9%. Минимальный платеж – 2% от суммы задолженности, минимум 300 руб.
      Неустойка при неоплате минимального платежа – 0,1% ежедневно на сумму просроченной задолженности.
      Кредитный лимит: от 25 000 до 2 млн. руб.
      Подробные условия – на <a className={"disclaimer__link"} href={'https://www.zenit.ru'}>zenit.ru</a>.
      <span className={"disclaimer__marked-text"}> ПАО Банк ЗЕНИТ.</span> Ген. лицензия Банка России №3255 от 16.12.2014.
    </div>
  } 
  </div>)
}