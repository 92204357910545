import {ChangeEvent, useEffect, useState} from 'react';
import { FormikValues } from 'formik';
import { useDispatch } from 'react-redux';
import { isMarriedStatus } from '../../../../config';
import { setPhoneBlackListErrorMessage, setPhoneValidationErrorMessage } from '../../../../redux/Application/appActions';
import { IFioDadataList } from '../../../../redux/Application/appTypes';
import { IAdvancedOptions, IProfileConfig2, IProfileState2, } from '../../../../redux/Credit/creditTypes';
import { adaptiveParams } from '../../../../styles/adaptiveParams';
import { AnimatedBlock } from '../../../../styles/UIKit/AnimatedBlock';
import { Button } from '../../../../styles/UIKit/Button';
import { TelephoneField } from '../../../../styles/UIKit/CustomField/TelephoneField';
import { CustomProgressBar } from '../../../../styles/UIKit/CustomProgressBar';
import { CustomQuantityInput } from '../../../../styles/UIKit/CustomQuantityInput';
import { FejoaMappedSelect } from '../../../../styles/UIKit/FejoaSelect/FejoaMappedSelect';
import { Flex } from '../../../../styles/UIKit/Flex';
import { String } from '../../../../styles/UIKit/String';
import { Title } from '../../../../styles/UIKit/Title';
import { onSetFieldsTouched } from '../../../../utils/commonFunctions';
import { onSetFieldBorderColor } from '../../../../utils/onSetFieldBorderColor';
import { ParagraphWrapper } from '../../../CommonPagesStyles';
import { additionalDataFieldsList } from '../../helper/helper';
import { TProfileSubPages } from '../ProfileMainBlock';
import { SpouseData } from '../SpouseData';
import {onValidateParam} from "../../../../redux/Application/app-reducer";

interface IAdditionalData {
	setFormDataUpdates: (values: IProfileState2) => void;
	updateFormCallBack: (
		formItem: string,
		currentValue: string,
		event: FormikValues
	) => void;
	screenWidth: number;
	profileConfig: IProfileConfig2;
	formik: FormikValues;
	creditAmount: number;
	setSubPage: (subPage: TProfileSubPages) => void;
	maritalStatusRef: IAdvancedOptions[];
	contactsRelationRef: IAdvancedOptions[];
	standartFontSizeMeasures: string;
	standartHeightMeasures: string;
	standartWidthMeasures: string;
	employmentTypeRef: IAdvancedOptions[];
	fioList: IFioDadataList[];
	phoneValidationErrorMessage: string | null;
}

export const AdditionalData: React.FC<IAdditionalData> = ({
	formik,
	setFormDataUpdates,
	updateFormCallBack,
	setSubPage,
	creditAmount,
	maritalStatusRef,
	screenWidth,
	profileConfig,
	standartFontSizeMeasures,
	standartHeightMeasures,
	standartWidthMeasures,
	contactsRelationRef,
	employmentTypeRef,
	fioList,
	phoneValidationErrorMessage,
}) => {
	const [isCheckingPhone, setIsCheckingPhone] = useState(false);

	// const nextPageHandler = () => {
  //   window.scroll(0,0)
  //   return setSubPage("finishForm");
  // }

	const backPageHandler = () => {
    window.scroll(0,0);
    setSubPage("workActivity");
  }

	const { mainContentWidth } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;

	const dispatch = useDispatch()

	const onHandleChangeMaritalStatus = () => {
		formik.setFieldValue("additionalInfoSpouseFio", null)
		formik.setFieldValue("additionalInfoSpouseBirthDate", null)
		formik.setFieldValue("additionalInfoSpouseOccupationType", null)
		formik.setFieldValue("additionalInfoSpouseIncomes", null)
	}

	const onChangePhoneHandler = ({value = "", event}: {value: string, event: ChangeEvent<HTMLInputElement>}) => {
		setIsCheckingPhone(true)
		if (value.length === 15) {
			setTimeout(()=>{
				event.target.blur()
			}, 0)
		}
	}

	const onBlurPhoneHandler = (formItem: string, currentValue: string, event: FormikValues) => {
		let promise;
		if (formItem) updateFormCallBack(formItem, currentValue, event);
		dispatch(setPhoneBlackListErrorMessage(null));
		dispatch(setPhoneValidationErrorMessage(null));
		if (formik.values.contactsPhone) {
			promise = dispatch(
				onValidateParam({
					paramValue: formik.values.contactsPhone,
					searchParam: "phone",
					onComplete: (res = {}) => {
						if (!res.error) {
							setIsCheckingPhone(false)
						}
					}
				})
			)
		} else {
			promise = Promise.resolve({ isAllowed: false });
		}
		return promise;
	}

	useEffect(()=> {
		if((formik.values.contactsPhone || "").length === 15 ) {
			setIsCheckingPhone(true);
			// @ts-ignore
			onBlurPhoneHandler().then()
		}
	}, [])

	const isAdditionalFields = creditAmount > profileConfig.thresholdAmount;

	const onSubmitHandler = async () => {
		window.scroll(0, 0)
		// @ts-ignore
		if ((await onBlurPhoneHandler()).isAllowed === false) {
			return;
		}
		const touchedFields = onSetFieldsTouched(additionalDataFieldsList)
		formik.setTouched(touchedFields);
	};

	useEffect(() => {
		if (isAdditionalFields) {
			formik.setFieldValue("additionalInfoMinorChildrenQuantity", 0)
			formik.setFieldValue("additionalInfoOtherDependents", 0)
		}
	}, [isAdditionalFields]);

	return (
		<>
			<Flex justify={"flex-start"} padding={fieldsWrapConditions ? "0" : "0 10px"}>
				<Title fSize={fieldsWrapConditions ? "28px" : "20px"} margin="0 0 15px">
					Дополнительная информация
				</Title>
			</Flex>
			<ParagraphWrapper padding={fieldsWrapConditions ? "0" : "10px"}>
				<String fSize="14px">Шаг 4 из 4. </String>
				<String fSize="14px" color="black">
					Получите +10% к одобрению, заполнив четвертый шаг{" "}
				</String>
			</ParagraphWrapper>
			<Flex padding={fieldsWrapConditions ? "0" : "10px"}>
				<CustomProgressBar
					completed={"100%"}
					width={"100%"}
					margin={"0"}
				/>
			</Flex>
			<ParagraphWrapper
				padding={fieldsWrapConditions ? "50px 0" : "10px"}
			>
				<FejoaMappedSelect
					formik={formik}
					updateFormCallBack={updateFormCallBack}
					formItem={"additionalInfoMaritalStatus"}
					height={standartHeightMeasures}
					width={"100%"}
					fSize={standartFontSizeMeasures}
					defaultValue={"Выберите"}
					label={"Семейное положение"}
					options={maritalStatusRef}
					borderColor={ onSetFieldBorderColor(formik, "additionalInfoMaritalStatus") }
					errorValue={ formik.touched.additionalInfoMaritalStatus && formik.errors.additionalInfoMaritalStatus }
					additionalActions={onHandleChangeMaritalStatus}
				/>
				{isAdditionalFields && (
					<Flex
						width={fieldsWrapConditions ? "750px" : "100%"}
						justify="space-between"
						margin="12px 0"
						direction={fieldsWrapConditions ? "row" : "column"}
					>
						<CustomQuantityInput
							width={fieldsWrapConditions ? "360px" : "100%"}
							formik={formik}
							formItem={"additionalInfoMinorChildrenQuantity"}
							label={"Количество несовершеннолетних детей"}
						/>
						<CustomQuantityInput
							width={fieldsWrapConditions ? "360px" : "100%"}
							formik={formik}
							formItem={"additionalInfoOtherDependents"}
							label={"Количество прочих иждивенцев"}
						/>
					</Flex>
				)}
			</ParagraphWrapper>
			{isAdditionalFields && formik.values.additionalInfoMaritalStatus === isMarriedStatus && (
				<AnimatedBlock>
					<SpouseData
						employmentTypeRef={employmentTypeRef}
						setFormDataUpdates={setFormDataUpdates}
						updateFormCallBack={updateFormCallBack}
						formik={formik}
						fieldsWrapConditions={fieldsWrapConditions}
						standartFontSizeMeasures={standartFontSizeMeasures}
						standartHeightMeasures={standartHeightMeasures}
						standartWidthMeasures={standartWidthMeasures}
						screenWidth={screenWidth}
						gender={profileConfig.gender}
						fioList={fioList}
					/>
				</AnimatedBlock>
			)}
			<Title
				padding={fieldsWrapConditions ? "0" : "10px"}
				fSize={fieldsWrapConditions ? "24px" : "20px"}
			>
		Телефон для экстренной связи
			</Title>
			<ParagraphWrapper padding={fieldsWrapConditions ? "0" : "10px"}>
				<Flex
					width={fieldsWrapConditions ? "750px" : "100%"}
					justify="space-between"
					margin="12px 0"
					direction={fieldsWrapConditions ? "row" : "column"}
				>
					<TelephoneField
						// required
						height={standartHeightMeasures}
						width={standartWidthMeasures}
						fSize={standartFontSizeMeasures}
						label={`Номер телефона не должен совпадать с ${ formik?.values?.phoneNumber ? formik.values?.phoneNumber : 'номером заемщика' }`}
						onBlurHandler={async () => {
							setIsCheckingPhone(true);
							// @ts-ignore
							await onBlurPhoneHandler()
						}}
						onChangeHandle={onChangePhoneHandler}
						formItem={"contactsPhone"}
						{...formik.getFieldProps("contactsPhone")}
						borderColor={ onSetFieldBorderColor(formik, "contactsPhone") }
						error={ formik.touched.contactsPhone && !!formik.errors.contactsPhone }
						errorValue={ phoneValidationErrorMessage || (formik.touched.contactsPhone && formik.errors.contactsPhone) }
					/>
				</Flex>
			</ParagraphWrapper>
			<Flex
				align="flex-start"
        margin="45px 0"
				direction={fieldsWrapConditions ? "row" : "column"}
				padding={fieldsWrapConditions ? "0" : "10px"}
			>
				<Button
					mode="primary"
					margin={fieldsWrapConditions ? "0 15px 0 0" : "0 0 15px 0"}
          width={fieldsWrapConditions ? "auto" : "100%"}
          background="#F0F2F6"
          color="#0A1244"
          padding="18px 41px"
					onClick={backPageHandler}
					type={"button"}
				>
					Вернуться назад
				</Button>
				<Button
					mode="primary"
          padding="18px 41px"
          width={fieldsWrapConditions ? "auto" : "100%"}
					onClick={onSubmitHandler}
					type={"submit"}
					disabled={!!phoneValidationErrorMessage || isCheckingPhone}
				>
					Отправить заявку на рассмотрение
				</Button>
			</Flex>

		</>
	);
};
