import React from 'react';
import styled from 'styled-components';
import useDimensions from '../../hooks/useDimensions';
import { ROUTE_PATH } from '../../routesConfig';
import { SubHeader } from './SubHeader/SubHeader';
import { useSelector } from "react-redux";
import { AppRootStateType } from "../../redux/store";
import { IGenerate } from "../../redux/Auth/authTypes";

const HeaderStyles = styled.div`
	width: 100%;
	position: fixed;
	z-index: 4;
`;

export const Header = () => {
	const { screenWidth } = useDimensions();
	const generate = useSelector<AppRootStateType, IGenerate | undefined>((state) => state.authReducer.generate);

	const currentForm = window.location.pathname.split("/")[2];
	const currentTestForm = window.location.pathname.split("/")[3];

	const isStartForm = currentForm === ROUTE_PATH.START || currentForm === ROUTE_PATH.DRAFT
		|| currentTestForm === ROUTE_PATH.START || currentTestForm === ROUTE_PATH.DRAFT;

	return (
		<HeaderStyles>
			{(!isStartForm || generate?.success) && <SubHeader screenWidth={screenWidth} />}
		</HeaderStyles>
	);
};
