import classnames from 'classnames';
import { String } from '../../styles/UIKit/String';
import gosUslugiImg from '../../assets/guLogo2.svg';
import { Button } from '../../styles/UIKit/Button';
import React, {useEffect} from 'react';
import useDimensions from '../../hooks/useDimensions';
import { useDispatch, useSelector } from "react-redux";
import { AppRootStateType } from "../../redux/store";
import { setAppError } from "../../redux/Application/appActions";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import './PublicServicesBlock.css';
import {CompleteIn2Minutes} from "../CompleteIn2Minutes";
import {IThemes, THEME_NAMES} from "../../styles/themes/themes";
import {PublicServicesProposal} from "./ PublicServicesProposal/ PublicServicesProposal";

type IProps = {
  hiddenImage?: boolean;
  isIncomeTexts?: boolean;
  onEsia?: () => void;
	isCard?: boolean;
  noBackground?: boolean;
  textAlignLeft?: boolean;
  bottomIndent?: boolean;
  hideCompleteIn2Minutes?: boolean;
}

export const PublicServicesBlock : (props: IProps) => JSX.Element = (props: IProps) => {
  const [loading, setLoading] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const { screenWidth } = useDimensions();
  const dispatch = useDispatch();
  const error = useSelector<AppRootStateType | undefined>(
      (state) => state?.appReducer.error
  );
  const theme = useSelector<AppRootStateType, IThemes>(state => state.appReducer.theme)

  const buttonText = props.isIncomeTexts ? 'Подтвердить доход через Госуслуги' : 'Получить одобрение';

  useEffect(() => {
    if (error === 'esiaError') {
      setLoading(false);
      setButtonDisabled(true);
      dispatch(setAppError(null));
    }
  }, [error]);

  const onButtonClick = () => {
    if (props.onEsia) {
      props.onEsia();
      setLoading(true);
    }
  };

  return(
    <div
      id="anketa"
      className={ classnames( 'public-services', {
        'public-services_no-background': !props.isCard || props.noBackground,
        bottomIndent: props.bottomIndent,
      })}
    >
      <div className="public-services__inner">
        <div className="public-services__container container">
          <div className="public-services__content">
            <div className="public-services__logo-wrapper">
              <img
                className="public-services__logo"
                width="200px"
                src={gosUslugiImg}
              />
            </div>
            <PublicServicesProposal/>
            {/*{ !buttonDisabled && !props.hideCompleteIn2Minutes && (*/}
            {/*  <CompleteIn2Minutes*/}
            {/*    screenWidth={screenWidth}*/}
            {/*    isIncomeTexts={props.isIncomeTexts}*/}
            {/*    alignLeft={props.textAlignLeft}*/}
            {/*  />*/}
            {/*)}*/}
            { buttonDisabled && <String fSize={screenWidth < 670 ? '16px' : '18px'}  color="#fff">
              К сожалению, сервис временно не доступен. <br/>
              { props.isIncomeTexts ? 'Подтвердите доход' : 'Заполните заявку' } сами, это просто!
            </String> }
            <Button
              className="public-services__btn"
              onClick={onButtonClick}
              disabled={loading || buttonDisabled}
              width={screenWidth < 670 ? '100%' : 'auto'}
              mode="primary"
              padding="14.5px 64.5px"
              background={theme.name === THEME_NAMES.newYear ? "#FFF" : "#F0F2F6"}
              color={theme.name === THEME_NAMES.newYear ? "#0A1244" :"#137D8B"}
              FSize="16.17px"
              fontWeight="600"
              fontFamily="'Formular'"
              type="button"
            >
              { loading && <AiOutlineLoading3Quarters className="spin" style={{ marginRight: '6px' }} /> }
              { buttonText }
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
