import { FormikValues } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setCalculatedCreditData } from '../../../redux/Credit/creditActions';
import { ICreditInitState, ISubmitCreditConfig, } from '../../../redux/Credit/creditTypes';
import { ROUTE_PATH } from '../../../routesConfig';
import { adaptiveMeasurements, adaptiveParams, } from '../../../styles/adaptiveParams';
import { FejoaInput } from '../../../styles/UIKit/CustomRangeInput/FejoaInput/FejoaInput';
import { Flex } from '../../../styles/UIKit/Flex';
import { bigSumHandler, FormatCurrancy, yaersHandler, } from '../../../utils/commonFunctions';
import { CreditCalculator } from '../../../utils/creditCalculator';
import { ParagraphWrapper } from '../../CommonPagesStyles';

interface IRanges {
  creditFormData: FormikValues;
  config: ISubmitCreditConfig;
  currentRate: number | undefined;
  nextRate: number | undefined;
  preferentialTerm: number | undefined;
  screenWidth: number;
  setFormDataUpdates: (values: ICreditInitState) => void;
  product: string;
}

export const Ranges: React.FC<IRanges> = (props) => {
  const dispatch = useDispatch();
  const {
    creditFormData,
    config,
    currentRate,
    nextRate,
    preferentialTerm,
    screenWidth,
    setFormDataUpdates,
    product,
  } = props;

  const isCredit = product === "credits"
  const isCpza = product === "cpza"

  const FIELD_LABELS: {[key: string]: string} = {
    [ROUTE_PATH.CREDITS]: "Сумма кредита",
    [ROUTE_PATH.CPZA]: "Сумма кредита",
    [ROUTE_PATH.CARDS]: "Желаемый кредитный лимит"
  }

  const { mainContentWidth } = adaptiveParams;

  const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;

  React.useEffect(() => {
    if (currentRate && typeof nextRate === 'number' && preferentialTerm) {
      dispatch(
        setCalculatedCreditData(
          CreditCalculator.calculateCredit(
            creditFormData.values.amount,
            creditFormData.values.term,
            currentRate,
            nextRate,
            preferentialTerm,
          )
        )
      );
    }
  }, [
    dispatch,
    creditFormData.values.amount,
    creditFormData.values.term,
    currentRate,
    nextRate,
    preferentialTerm,
  ]);

  const CREDIT_AMOUNT_MIN_VALUE: {[key: string]: number} = {
    [ROUTE_PATH.CREDITS]: config.minCreditAmount,
    [ROUTE_PATH.CPZA]: config.minCreditAmount,
		[ROUTE_PATH.CARDS]: config.creditcardMinAmount,
  }

  const CREDIT_AMOUNT_MAX_VALUE: {[key: string]: number} = {
    [ROUTE_PATH.CREDITS]: config.maxCreditAmount,
    [ROUTE_PATH.CPZA]: config.maxCreditAmount,
		[ROUTE_PATH.CARDS]: config.creditcardMaxAmount,
  }

  const amountLimitHint = creditFormData.values.amount < CREDIT_AMOUNT_MIN_VALUE[product] || creditFormData.values.amount > CREDIT_AMOUNT_MAX_VALUE[product]
  const termLimitHint = creditFormData.values.term < config.minCreditTerm || creditFormData.values.term > config.maxCreditTerm

  const AMOUNT_LIMIT_HINT: {[key: string]: string | null} = {
    [ROUTE_PATH.CREDITS]: amountLimitHint ? "Некорректное значениие" : null,
    [ROUTE_PATH.CPZA]: amountLimitHint ? "Некорректное значениие" : null,
		[ROUTE_PATH.CARDS]: amountLimitHint ? "Некорректное значениие" : null,
  }

  const TERM_LIMITS_HINT: {[key: string]: string | null} = {
    [ROUTE_PATH.CREDITS]: termLimitHint ? "Некорректное значениие" : null,
    [ROUTE_PATH.CPZA]: termLimitHint ? "Некорректное значениие" : null,
		[ROUTE_PATH.CARDS]: null,
  }

  const onHandleRangeStep = (maxLimit: number, currentValue: number) => {
    const defaultStep = 10000;
    switch (true) {
      case maxLimit - currentValue <= defaultStep: return 1
      default: return defaultStep
    }
  }

  return (
    <>
      <ParagraphWrapper>
        <Flex
          Wrap="wrap"
          direction="row"
          width={"100%"}
          justify={"space-between"}
        >
          <FejoaInput
            onBlurHandler={setFormDataUpdates}
            max={CREDIT_AMOUNT_MAX_VALUE[product]}
            min={CREDIT_AMOUNT_MIN_VALUE[product]}
            fSize={ adaptiveMeasurements(screenWidth, "28px", "26px", "24px", "20px") }
            height={fieldsWrapConditions ? "71px" : "50px"}
            margin={screenWidth > 767 ? '0' : '20px 10px'}
            width={screenWidth > 767 ? '360px' : '100%'}
            value={creditFormData.values.amount}
            valueHandler={bigSumHandler}
            specialTag={"₽"}
            formik={creditFormData}
            formItem={"amount"}
            formatting={FormatCurrancy}
            label={FIELD_LABELS[product]}
            maxWidth={adaptiveMeasurements(screenWidth, "360px", "360px", "100%", "100%")}
            screenWidth={screenWidth}
            hint={AMOUNT_LIMIT_HINT[product]}
            step={onHandleRangeStep(CREDIT_AMOUNT_MAX_VALUE[product], creditFormData.values.amount)}
          />
          {(isCredit || isCpza) && <FejoaInput
            margin={screenWidth > 767 ? '0' : '20px 10px'}
            width={screenWidth > 767 ? '360px' : '100%'}
            fSize={ adaptiveMeasurements(screenWidth, "28px", "26px", "24px", "20px") }
            height={fieldsWrapConditions ? "71px" : "50px"}
            onBlurHandler={setFormDataUpdates}
            max={config.maxCreditTerm / 12}
            min={config.minCreditTerm / 12}
            value={creditFormData.values.term / 12}
            valueHandler={yaersHandler}
            specialTag={"лет"}
            formik={creditFormData}
            formItem={"term"}
            label={"Срок кредита"}
            maxWidth={adaptiveMeasurements(screenWidth, "360px", "360px", "100%", "100%")}
            screenWidth={screenWidth}
            hint={TERM_LIMITS_HINT[product]}
          />}
        </Flex>
      </ParagraphWrapper>
    </>
  );
};
