import React from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentForm } from '../redux/Application/appActions';
import { Flex } from '../styles/UIKit/Flex';
import { Title } from '../styles/UIKit/Title';
import { adaptiveParams } from '../styles/adaptiveParams';
import useDimensions from '../hooks/useDimensions';
import { Figure } from '../styles/UIKit/StyledFigure';

export const TemporaryFinal = () => {
	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(
			setCurrentForm({
				product: "temporaryFinal",
				formId: undefined,
				flowId: undefined,
			})
		);
	}, [dispatch]);
	const { mainContentWidth } = adaptiveParams;
	const { screenWidth } = useDimensions();
	return (
		<Flex
			direction="column"
			align="center"
			margin={screenWidth > mainContentWidth ? "0" : "0 10px"}
		>
			<Title fSize="40px">
				Заявка успешно отправлена на рассмотрение в банк.
			</Title>
			<Flex margin="50px 0">
				<Figure />
			</Flex>
			<Flex fFamily="Formular">
				С Вами свяжется специалист по указанному ранее номеру телефона
				для продолжения процедуры заёма
			</Flex>
			<Flex fFamily="Formular" margin="50px 0">
				Для создания новой заявки нажмите "выход" в верхнем правом углу
			</Flex>
		</Flex>
	);
};