import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useDimensions from '../../hooks/useDimensions';
import { setCurrentForm } from '../../redux/Application/appActions';
import { initialLogin } from '../../redux/Auth/auth-reducer';
import { TUserStatus } from '../../redux/Auth/authTypes';
import { ICommonFormData, TLoadingMode } from '../../redux/commonTypes';
import { getInitialCreditDboData, } from '../../redux/Credit/credit-reducer';
import { ICreditDboState, ISubmitCreditDboConfig, } from '../../redux/Credit/creditTypes';
import { AppRootStateType } from '../../redux/store';
import { SmsAuth } from './components/SmsAuth';

export const AuthDBO = () => {
	const dispatch = useDispatch();

	const { screenWidth } = useDimensions();

	const paramsToken = useParams().token;
	const token = paramsToken || localStorage.getItem("flowId") || undefined;

	React.useEffect(() => {
		dispatch(
			setCurrentForm({
				product: undefined,
				formId: "dbo",
				flowId: token,
			})
		);
		if (token) {
			dispatch(getInitialCreditDboData(token));
		}
	}, []);

	const isLoading = useSelector<AppRootStateType, TLoadingMode>(
		(state) => state.appReducer.appLoadingMode
	);

	const userStatus = useSelector<AppRootStateType, TUserStatus>(
		(state) => state.authReducer.userStatus
	);

	const dboConfigData = useSelector<
		AppRootStateType,
		ISubmitCreditDboConfig | undefined
	>((state) => state.creditReducer.initialCreditsDboData?.config);

	const dboData = useSelector<
		AppRootStateType,
		ICommonFormData<ISubmitCreditDboConfig, ICreditDboState> | undefined
	>((state) => state.creditReducer.initialCreditsDboData);

	// console.log(dboConfigData);

	const [smsCodeValue, setSmsCodeValue] = React.useState<string>("");
	const [smsPopup, setSmsPopup] = React.useState<boolean>(true);

	const onAuthDBOGenerateHandler = () => {};

	const onAuthDBOValidateHandler = () => {
		if (dboData && dboConfigData) {
			const payload = {
				formId: "dboAuth",
				state: {},
			};
			// dispatch(
			// 	initialLogin(
			// 		dboConfigData.phone,
			// 		null,
			// 		smsCodeValue,
			// 		dboConfigData.codeId,
			// 		dboData.flowId,
			// 		"validate",
			// 		payload,
			// 		"credits"
			// 	)
			// );
		}
	};

	// console.log(" => ", dboData);
	// console.log(" => ", dboConfigData);

	return (
		<>
			{dboData && (
				<SmsAuth
					screenWidth={screenWidth}
					customerName={dboData?.config.customerName}
					sendCodeHandler={onAuthDBOValidateHandler}
					smsCodeValue={smsCodeValue}
					setSmsCodeValue={setSmsCodeValue}
					show={smsPopup}
					setShow={setSmsPopup}
				/>
			)}
		</>
	);
};
