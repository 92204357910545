import React from 'react';
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ROUTE_PATH } from '../../../../routesConfig';
import { ISubmitCreditConfig } from '../../../../redux/Credit/creditTypes';
import { AppRootStateType } from "../../../../redux/store";
import { TestPageNames } from '../../../../utils/getQueryParams';
import './ProgressModel.css';

interface IProgressModel {
	product: string;
	config?: ISubmitCreditConfig;
	isReferral?: boolean;
}

const TestingCap = styled.div`
	min-height: 80px;
`

export const ProgressModel: React.FC<IProgressModel> = (props) => {
	const { product, config, isReferral } = props;
	const isTestMode = useSelector<AppRootStateType, boolean>(state => state.appReducer.isTestMode)
	const testPageName = useSelector<AppRootStateType, string | undefined>(state => state.appReducer.testPageName)

	const isTestPage2 = testPageName === TestPageNames.TEST2

	const isCard = product === "cards"
	const isCredit = product === "credits"

	const THIRD_BALL_TITLE = {
		[ROUTE_PATH.CREDITS]: "Получите деньги",
		[ROUTE_PATH.CPZA]: "Получите деньги",
		[ROUTE_PATH.CARDS]: "Получите карту",
	}

	const THIRD_BALL_CONTENT = {
		[ROUTE_PATH.CREDITS]: "в одном из отделений Банка",
		[ROUTE_PATH.CPZA]: "в одном из отделений банка. Мы самостоятельно зарегистрируем залог",
		[ROUTE_PATH.CARDS]: "в одном из отделений банка",
	}

	return (<>
		{(isTestMode && product === "credits" && !isTestPage2) ?
			<TestingCap/> : (
			<div className={`progress-model ${product} ${isReferral ? "referral" : ""}`}>
				<div className={"container"}>
					<div className={"progress-model__list"}>
						<div className={"progress-model__item"}>
							<div className={"progress-model__ball"}>
								1
							</div>
							<div className={"progress-model__info-block"}>
								<span className={"progress-model__header-text"}>Заполните заявку</span>
								<span className={"progress-model__text"}>за 5 минут полностью онлайн</span>
							</div>
						</div>
						<div className={"progress-model__item"}>
							<div className={"progress-model__ball"}>
								2
							</div>
							<div className={"progress-model__info-block"}>
								<span className={"progress-model__header-text"}>5 минут</span>
								<span className={"progress-model__text"}>дождитесь одобрения</span>
							</div>
						</div>
						<div className={"progress-model__item"}>
							<div className={"progress-model__ball"}>
								3
							</div>
							<div className={"progress-model__info-block"}>
								<span className={"progress-model__header-text"}>{THIRD_BALL_TITLE[product as keyof typeof THIRD_BALL_TITLE]}</span>
								<span className={"progress-model__text"}>{THIRD_BALL_CONTENT[product as keyof typeof THIRD_BALL_TITLE]}</span>
							</div>
						</div>
						{isCard && isReferral && (
							<div className={"progress-model__item"}>
								<div className={"progress-model__ball"}>
									4
								</div>
								<div className={"progress-model__info-block"}>
									<span className={"progress-model__header-text"}>+ {config?.utmGift} ₽</span>
									<span className={"progress-model__text"}>
										на бонусный счет, как только ваши траты по карте достигнут {config?.utmSpentRubles} руб.
									</span>
								</div>
							</div>
						)}
						{isCredit && isReferral && (
							<div className={"progress-model__item"}>
								<div className={"progress-model__ball"}>
									4
								</div>
								<div className={"progress-model__info-block"}>
									<span className={"progress-model__header-text"}>1 000 ₽ в подарок</span>
									<span className={"progress-model__text"}>
										начислим вам на бонусный счет карты Банка ЗЕНИТ
									</span>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>)
		}
	</>
	);
};
