import { useEffect, useRef } from 'react';
import { FormikValues } from 'formik';

export const useValidationScroll = (formik: FormikValues, phoneValidationErrorMessage?: string | null) => {
  const invalidFields = useRef<Array<string>>([]);

  useEffect(() => {
    const newInvalidFields = Object.keys(formik.touched).filter(
      (field) =>
        formik.errors[field] ||
        // Частный случай с рабочим телефоном, валидация проходит в обход формика
        field === 'occupationPhoneNumber' && phoneValidationErrorMessage
    );

    const fieldForScroll = newInvalidFields.filter(
      (field) => !invalidFields.current.includes(field)
    )[0];

    if (fieldForScroll) {
      setTimeout(() => {
        if (invalidFields.current.includes(fieldForScroll)) {
          document.querySelector(`[name=${fieldForScroll}]`)?.scrollIntoView({ behavior: 'smooth' , block: 'center'});
        }
      }, 100)
    }

    invalidFields.current = newInvalidFields;
  }, [formik.touched, formik.errors, phoneValidationErrorMessage]);
};
