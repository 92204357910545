export const getTimeRemaining = (antifrodLife: number) => {
    const seconds = Math.floor((antifrodLife / 1000) % 60);
    const minutes = Math.floor((antifrodLife / 1000 / 60) % 60);
    const hours = Math.floor((antifrodLife / (1000 * 60 * 60)) % 24);
    const days = Math.floor(antifrodLife / (1000 * 60 * 60 * 24));
    return {
        total: antifrodLife,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
    };
}