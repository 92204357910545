import { FormikValues } from 'formik';
import React from 'react';
import styled from 'styled-components';

const FieldWrapper = styled.div`
	position: relative;
	width: 365px;
`;

const StyledMonthInput = styled.input`
    width: 365px;
    height: 71px;
    border: 1px solid #B3B5C4;
    border-radius: 4px;
	font-weight: 400;
	outline: none;
	font-size: 28px;
	line-height: 43px;
	font-family: "Formular";
	padding: 0 14px;
`

const Tag = styled.div`
    position: absolute;
    font-size: 30px;
    top: 25%;
    right: 20px;
    color: #7E829B;
`

interface IMonthInput {
    formik: FormikValues;
	fieldName: string;
}
export const MonthInput: React.FC<IMonthInput> = (props) => {
	const {fieldName, formik} = props;
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		let regexp = /[^0-9]/gi;
		let value = e.target.value;
		value = e.target.value.replace(regexp, "");
		formik.setFieldValue(fieldName, value)
	};
    return <FieldWrapper {...props}>
        <Tag>мес</Tag>
        <StyledMonthInput {...props} onChange={onChangeHandler} value={formik.values[fieldName]}/>
    </FieldWrapper>
}