import React from 'react';
import styled from 'styled-components';

type Props = {
	completed?: string;
	background?: string;
	width?: string;
	margin?: string;
};

const ProgreeBarContainer = styled.div<Props>`
	height: 10px;
	width: ${({ width }) => width || "399px"};
	background-color: #f0f2f6;
	border-radius: 5px;
	margin: ${({ margin }) => margin || "16px 0"};
`;

const FillerStyles = styled.div<Props>`
	height: 100%;
	width: ${(props) => props.completed || "0"};
	background: ${(props) =>
		props.background || props.theme.appColors.commonTheme};
	border-radius: inherit;
	text-align: right;
	transition: width 1s ease-in-out;
	position: relative;
`;

export const CustomProgressBar: React.FC<Props> = (props) => {
	return (
		<ProgreeBarContainer {...props} >
			<FillerStyles {...props} />
		</ProgreeBarContainer>
	);
};