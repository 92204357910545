import { IBlackListSearchParam, IParamValidationSearchParam } from '../redux/Application/appTypes';
import { instance } from './interceptors';

export class AppApi {
	getDeclarationsList(limit?: number, onlyDrafts?: boolean, productCode?: number, excludedId: string | null = null) {
		const _limit = `limit=${limit || 100}`
		const _onlyDrafts = `onlyDrafts=${onlyDrafts || false}`
		const _productCode = productCode ? `productCode=${productCode}` : ""
		const _excludedId = excludedId ? `excludedId=${excludedId}` : ""
		const queries = [_limit, _onlyDrafts, _productCode, _excludedId].filter(el => el !== "")
		const queriesString = queries.join("&")
		return instance.get(`/catalog/orders?${queriesString}`);
		// return Promise.resolve().then(() => {
		// 	return { data: mochedSpravochnik };
		// });
	}

	getDadataCatalog(catalogId: string, item: string, param: string, opf?: string) {

		const _item = item ? `${[item]}=${param}` : ""
		const _opf = opf ? `${"opf"}=${opf}` : ""


		const queries = [_opf, _item].filter(el => el !== "")
		const queriesString = queries.join("&")

		return instance.get(`/catalog/${catalogId}?${queriesString}`);
	}

	getDboData(token: string) {
		return instance.post(`flows/credit`, {token})
	}

	onTestErrors(error: string) {
		return instance.get(`errors/${error}`)
	}

	onGetDadataList(dadataParam: string, queries: string) {
		return instance.get(`catalog/${dadataParam}?${queries}`)
	}

	onCheckBlackList({query, searchParam } : {query: string, searchParam: IBlackListSearchParam}) {
		return instance.get(`/blacklist/${searchParam}?query=${query}`);
	}

	onValidateParam({paramValue, searchParam } : {paramValue: string, searchParam: IParamValidationSearchParam}) {
		return instance.post(`/dadata/${searchParam}/${paramValue}`, {})
	}

	onCallTouchAction({flowId} : {flowId: string}) {
		return instance.post(`/flow/${flowId}/invoke/callTouch`, {})
	}

}


// пагинация для списка заявок
// Олег Ролик
// слушай. а по поводу до параметров - можно добавлять offset и limit
// если ты их не указываешь, то offset = 0, limit= 10
// т.е. ты получишь первые 10 записей, если не укажешь offset и limit
