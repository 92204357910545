import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { CreditApi } from '../../api/Credit/creditApi';
import { setAppError, setAppLoadingMode, setAppSuccess, setCurrentForm, } from '../Application/appActions';
import { AppRootStateType } from '../store';
import {
  SET_CALCULATED_CREDIT_DATA,
  SET_CONSIDERATIONS,
  SET_CREDIT_DATA,
  SET_CREDIT_DBO_DATA,
  SET_PASSPORT_DATA,
  SET_PROFILE_DATA,
  SET_UPLOADED_FILES,
  SET_UPLOADED_PASSPORT_STATE,
  SET_UPLOADING_FILE_MODE,
  setCreditData,
  setCreditDboData,
  setProfileData,
  setUploadedFilesList,
  setUploadingFileMode,
} from './creditActions';
import { redefineInitialData } from "./utils/redefineInitialData";
import {CreditActionsTypes, ICreditInitState, ISubmitCreditConfig, ISubmitCreditState} from './creditTypes';
import { getCurrentFormHandler, postCurrentFormHandler, } from './credit-reducer-helper';
import { TCommonGetCurrentForm, TCommonPostCurrentForm, } from '../../api/Credit/creditApiTypes';
import { AppApi } from '../../api/app-api';
import { FileApiPassportService } from '../../api/files-api';
import { ROUTE_PATH } from '../../routesConfig';
import { LoacalData } from '../../api/localStorage';
import { downloadFile } from '../../utils/file-downloader';
import {ICommonFormData, TProductTypes} from '../commonTypes';
import { toIsoString } from '../../utils/commonFunctions';
import { onToasting } from '../../utils/onToasting';
import { ERRORS_HANDLER, PRODUCTS_CODES } from '../../config';
import { getDeclarationsList } from '../Application/app-reducer';
import { ThunkAction } from 'redux-thunk';
import { AppActionsType } from '../Application/appTypes';

export const initialState: ISubmitCreditState = {
	initialCreditsData: undefined,
	initialCreditsDboData: undefined,
	passportFormData: undefined,
	passportDocumentsList: [],
	calculatedCreditValues: undefined,
	profile: undefined,
	passportFileUploadingMode: "idle",
	consideration: undefined,
};

const localData = new LoacalData();

export const creditReducer = (
	state: ISubmitCreditState = initialState,
	action: CreditActionsTypes
): ISubmitCreditState => {
	switch (action.type) {
		case SET_CREDIT_DATA:
			return { ...state, initialCreditsData: action.payload };
		case SET_CREDIT_DBO_DATA:
			return { ...state, initialCreditsDboData: action.payload };
		case SET_PASSPORT_DATA:
			return {
				...state,
				passportFormData: action.data,
			};
		case SET_UPLOADED_FILES:
			return { ...state, passportDocumentsList: action.payload };
		case SET_UPLOADING_FILE_MODE:
			return { ...state, passportFileUploadingMode: action.mode };
		case SET_UPLOADED_PASSPORT_STATE:
			if (state.passportFormData) {
				return {
					...state,
					passportFormData: {
						...state.passportFormData,
						state: action.payload,
					},
				};
			}
			return state;
		case SET_CALCULATED_CREDIT_DATA:
			return {
				...state,
				calculatedCreditValues: action.calculatedCreditValues,
			};
		case SET_CONSIDERATIONS:
			return { ...state, consideration: action.payload };
		case SET_PROFILE_DATA:
			return { ...state, profile: action.payload };
		default:
			return state;
	}
};

const createDate = (str: string | null): Date | null => {
	if (!str) {			// TODO
		return null;
	}
	if (str) {
		return new Date(str.split("T")[0]);
	}
	return null;
};

export const getInitialCreditData =
	(flowType: "credit", product: string, dto?: Object) => async (dispatch: Dispatch, getState: () => AppRootStateType) => {
		if (getState().appReducer.appLoadingMode !== "loading") {
			dispatch(setAppLoadingMode("loading"));

			const client = new CreditApi();
			try {
				const res = await client.getInitFormData(flowType, dto);
				const {flowId, formId} = res.data;
				const superRes = {
					...res.data,
					state: {
						...res.data.state,
						birthDate: createDate(res.data.state.birthDate as any)
					}
				}
				redefineInitialData(superRes, product)
				dispatch(setCreditData(superRes));
				dispatch(
					setCurrentForm({
						product: product as TProductTypes,
						formId,
						flowId,
					})
				);
				dispatch(setAppSuccess("Добро пожаловать!"));
				dispatch(setAppLoadingMode("succeeded"));
			} catch (err) {
				const error = err as AxiosError
				onToasting({type: "error", message: ERRORS_HANDLER[error?.response?.status || 502]})
				dispatch(setAppLoadingMode("failed"));
			}
		}
	};

export const getInitialCreditDboData =
	(token: string) => async (dispatch: Dispatch) => {
		dispatch(setAppLoadingMode("loading"));
		const client = new AppApi();
		try {
			const res = await client.getDboData(token);
			const { data } = res;
			dispatch(setCreditDboData(data));
			localData.setFlowId(res.data.flowId);
			dispatch(setAppLoadingMode("succeeded"));
		} catch (err) {
			dispatch(setAppLoadingMode("idle"));
			const error = err as AxiosError
			error.response && onToasting({type: "error", message: ERRORS_HANDLER[error.response?.status]})
		}
	};

// Download chosen file
export const getLoadedFile =
	(flowId: string, documentId: string, fileName: string, param: "consent" | "content", download: "true" | "false") =>
	async (dispatch: Dispatch) => {
		const client = new FileApiPassportService();
		try {
			const res = await client.getLoadedFile(flowId, documentId, param, download);
			if (download === "true") downloadFile(res.request.responseURL, fileName);
			if (download === "false") {
				const a = document.createElement("a");
				document.body.appendChild(a);
				a.href = res.request.responseURL;
				a.target = '_blank';
				a.click();
				document.body.removeChild(a);
			}
		} catch (err) {
			console.warn("some error has occured.....");
			dispatch(setUploadingFileMode("idle"));
		}
	};

// Get Passport files list
export const getDocumentList =
	(flowId: string) => async (dispatch: Dispatch) => {
		const client = new FileApiPassportService();
		try {
			const { data } = await client.getDocumentList(flowId);
			dispatch(setUploadedFilesList(data));
			// console.log(data)
		} catch (err) {
			console.warn("some error has occured.....");
			dispatch(setUploadingFileMode("idle"));
		}
	};

// Post document file to the list
export const postDocumentFile = (docType: string, flowId: string, file: Blob) => async (dispatch: Dispatch, getState: () => AppRootStateType) => {
		dispatch(setAppLoadingMode("loading"));
		const client = new FileApiPassportService();
		const info = {
			type: docType,
		};
		const payload = new FormData();
		payload.append("info", JSON.stringify(info));
		payload.append("file", file);
		const currentPassportDocumentList =
			getState().creditReducer.passportDocumentsList;
		try {
			const { data } = await client.addPassportFile(flowId, payload);
			dispatch(
				setUploadedFilesList([...currentPassportDocumentList, data])
			);
			dispatch(setAppLoadingMode("succeeded"));
		} catch (err) {
			console.warn("some error has occured.....");
			dispatch(setUploadingFileMode("idle"));
		}
	};

// Remove document from passport list
export const removeFileFromList =
	(flowId: string, documentId: string) =>
	async (dispatch: Dispatch, getState: () => AppRootStateType) => {
		const client = new FileApiPassportService();
		const currentPassportDocumentList =
			getState().creditReducer.passportDocumentsList;
		try {
			const { data } = await client.removeFileFromList(
				flowId,
				documentId
			);
			dispatch(
				setUploadedFilesList(
					currentPassportDocumentList.filter(
						(doc) => doc.documentId !== data.documentId
					)
				)
			);
		} catch (err) {
			console.warn("some error has occured.....");
			dispatch(setUploadingFileMode("idle"));
		}
	};

// get profile form data
export const getProfile = (flowId: string) => async (dispatch: Dispatch) => {
	const client = new CreditApi();
	try {
		const { data } = await client.getProfileForm(flowId);
		dispatch(setProfileData(data));
	} catch (err) {
		console.warn("some error has occured.....", err);
	}
};

export const getCurrentForm =
	(flowId: string, product: string) => async (dispatch: Dispatch) => {
		dispatch(setAppLoadingMode("loading"));
		const client = new CreditApi();
		try {
			const res = await client.getCurrentForm(flowId);
			getCurrentFormHandler<AxiosResponse<TCommonGetCurrentForm>>(
				res,
				dispatch,
				product,
			);
			// dispatch(setCreditData(res.data));
			dispatch(setAppLoadingMode("idle"));
		} catch (err) {
			dispatch(setAppLoadingMode("idle"));
			const error = err as AxiosError
			error.response && onToasting({type: "error", message: ERRORS_HANDLER[error.response?.status]})
		}
	};

// update current form
export const updateCurrentForm =
	(flowId: string, payload: TCommonPostCurrentForm) =>
	async (dispatch: Dispatch) => {
		const obj = payload.state
		let resultObj = {}
		for (let key in obj) {
			if (obj[key] instanceof Date) {
				resultObj = { ...resultObj, [key]: toIsoString(obj[key]) }
			} else {
				resultObj = { ...resultObj, [key]: obj[key] }
			}
		}
		const client = new CreditApi();
		try {
			await client.updateForm(flowId, {...payload, state: resultObj});
		} catch (err) {
			console.warn("some error has occured.....", err);
		}
	};
// 2APdHtGisTjWfRNXPGA5E3Yw7X3VinGotB1JruaMtAXXFue6zg
// post current form
export const postCurrentForm =
	(flowId: string, payload: TCommonPostCurrentForm, product: string): ThunkAction<
		void,
		AppRootStateType,
		unknown,
		AppActionsType
	> =>
	async (dispatch) => {
		dispatch(setAppLoadingMode("loading"));
		const obj = payload.state
		let resultObj = {}
		for (let key in obj) {
			if (obj[key] instanceof Date) {
				resultObj = { ...resultObj, [key]: toIsoString(obj[key]) }
			} else {
				resultObj = { ...resultObj, [key]: obj[key] }
			}
		}
		const client = new CreditApi();
		try {
			const res = await client.postCurrentForm(flowId, {...payload, state: resultObj});
			if (res.data.formId === ROUTE_PATH.START) {
				dispatch(getInitialCreditData("credit", product, res.data.state))
			}
			if (res.data.formId === ROUTE_PATH.PASSPORT) {
				dispatch(getDeclarationsList(1, true, PRODUCTS_CODES[product], res.data.flowId))
			}
			postCurrentFormHandler<AxiosResponse<TCommonGetCurrentForm>>(
				res,
				dispatch,
				product
			);
			dispatch(setAppLoadingMode("idle"));
		} catch (err) {
			dispatch(setAppLoadingMode("idle"));
			const error = err as AxiosError
			error.response && onToasting({type: "error", message: ERRORS_HANDLER[error.response?.status]})
		}
	};

	export const onCancelDeclaration = (flowId: string, canceledFlowId: string, product: string) => async (dispatch: Dispatch) => {
		dispatch(setAppLoadingMode("loading"));
		const client = new CreditApi();
		try {
			const res = await client.onCancelDeclaration(flowId, canceledFlowId)
			getCurrentFormHandler<AxiosResponse<TCommonGetCurrentForm>>(
				res,
				dispatch,
				product,
			);
			dispatch(setAppLoadingMode("approved"));
		} catch(err) {
			dispatch(setAppLoadingMode("failed"));
			const error = err as AxiosError
			error.response && onToasting({type: "error", message: ERRORS_HANDLER[error.response?.status]})
		}
	}


// TEMPORARY MOCH has to be removed
// export const getMochedInProcessApprovedCredit = async (dispatch: Dispatch) => {
// 	const client = new CreditApi();
// 	try {
// 		const { data } = await client.getMochedInProcessApprovedCredit();
// 		dispatch(setConsiderations(data));
// 		console.log(data);
// 	} catch (err) {
// 		dispatch(setAppLoadingMode("idle"));
// 		console.warn("some error has occured.....", err);
// 	}
// };
