// @ts-ignore
import coinImg from '../../assets/orders/coinsStack.svg';
export const OrdersEmpty : React.FC<{excludeCreateNewDeclaration?: boolean, onCreateNewDeclaration?: () => void}> = (props) => {
  return (
    <div className="requests-list-body">
      <div className="requests-list__icon">
        <img src={coinImg} alt=""/>
      </div>
      <p className="requests-list__text">
        На данный момент у вас нет заявок.
      </p>
      {!props.excludeCreateNewDeclaration && <div className="requests-list__link" onClick={props?.onCreateNewDeclaration}>Создать новую заявку</div>}
    </div>
  );
}