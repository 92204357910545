import React from "react";
import classnames from "classnames";
import {String} from "../styles/UIKit/String";

export type CompleteIn2MinutesProps = {
  screenWidth: number
  isIncomeTexts?: boolean
  alignLeft?: boolean
  textAlign?: string
  setShift?: boolean
  noLimitWidth?: boolean
}

export const CompleteIn2Minutes = ({screenWidth, isIncomeTexts, alignLeft, noLimitWidth, setShift = true}: CompleteIn2MinutesProps) => (
  <div className={classnames("public-services__text", { alignLeft, noLimitWidth })}>
    <String fSize={screenWidth < 670 ? '18px' : '20px'} color="#fff" >
      {`${isIncomeTexts ? 'Подтвердите доход' : 'Заполните заявку' } за 2 минуты`} {setShift && <br/>}
      Просто авторизируйтесь на Госуслугах
    </String>
  </div>
)