import styled from 'styled-components';
import { adaptiveParams } from '../adaptiveParams';

const FigureWrapper = styled.div`
	margin: 0 0 40px 0;
	@media (max-width: ${adaptiveParams.mainContentWidth}px) {
		margin: 0;
	}
`;

const StyledFigure = styled.div`
	width: 256px;
	height: 180px;
	position: relative;
	&:after {
		content: "";
		width: 13px;
		height: 13px;
		border-radius: 8px;
		background: #ff6700;
		position: absolute;
		top: 11px;
		left: 11px;
	}
`;

const StyledFigure1 = styled.div`
	content: "";
	width: 159px;
	height: 36px;
	background: #e6e7ec;
	position: absolute;
	left: 59px;
	&:after {
		content: "";
		width: 159px;
		height: 20px;
		background: #10c8d2;
		position: absolute;
		top: 16px;
		left: 20px;
	}
`;

const StyledFigure2 = styled.div`
	content: "";
	width: 79px;
	height: 144px;
	background: #10c8d2;
	position: absolute;
	left: -60px;
	top: 36px;
	&:after {
		content: "";
		width: 177px;
		height: 144px;
		background: #e6e7ec;
		position: absolute;
		left: 79px;
	}
`;

const StyledFigure3 = styled.div`
	content: "";
	width: 64px;
	height: 49px;
	background: #10c8d2;
	border-radius: 24px 0 0 24px;
	position: absolute;
	left: 192px;
	z-index: 1;
	top: 44px;
	&:after {
		content: "";
		width: 23px;
		height: 23px;
		border-radius: 12px;
		background: #e6e7ec;
		position: absolute;
		left: 14px;
		top: 13px;
	}
`;

export const Figure = () => {
	return (
		<FigureWrapper>
			<StyledFigure>
				<StyledFigure1>
					<StyledFigure2>
						<StyledFigure3 />
					</StyledFigure2>
				</StyledFigure1>
			</StyledFigure>
		</FigureWrapper>
	);
};