import { FormikValues } from 'formik';
import { Button } from '../../../styles/UIKit/Button';
import * as uuid from 'uuid';
import { CurrancyField } from '../../../styles/UIKit/CurrancyField';
import { IAdvancedOptions } from '../../../redux/Credit/creditTypes';
import { FejoaSelect } from '../../../styles/UIKit/FejoaSelect/FejoaSelect';
import { FejoaMappedSelect } from '../../../styles/UIKit/FejoaSelect/FejoaMappedSelect';

interface IAdditionalIncomes {
	formik: FormikValues;
	incomesList: IAdvancedOptions[];
	additionalIncomesData: any[];
	standartFontSizeMeasures: string;
	standartHeightMeasures: string;
	updateFormCallBackForAdditionalIncomesData: (formItem: string, currentValue: any[]) => void;
	setAdditionalIncomesData: (additionalIncomesData: any[]) => void;
	setShow: (show: boolean) => void;
}

export const AdditionalIncomes: React.FC<IAdditionalIncomes> = (props) => {
	const {
		formik,
		additionalIncomesData,
		updateFormCallBackForAdditionalIncomesData,
		setAdditionalIncomesData,
		setShow,
		incomesList,
		standartFontSizeMeasures,
		standartHeightMeasures,
	} = props;
	// console.log("=> ", additionalIncomesData);
	const addItemHandler = () => {
		const newId = uuid.v4();
		const addedItem = [
			...additionalIncomesData,
			{
				id: newId,
				occupationAdditionalIncomesKind: formik.values.occupationAdditionalIncomesKind,
				occupationAdditionalIncomeValue: formik.values.occupationAdditionalIncomeValue,
			},
		];
		setAdditionalIncomesData(addedItem);
		// updateFormCallBackForAdditionalIncomesData("additionalIncomes", addedItem);
		setShow(false);
		formik.setFieldValue("occupationAdditionalIncomesKind", null)
		formik.setFieldValue("occupationAdditionalIncomeValue", null)
	};

	const { occupationAdditionalIncomesKind, occupationAdditionalIncomeValue } = formik.values;

	const disablingConditions = !occupationAdditionalIncomesKind || !occupationAdditionalIncomeValue
		|| occupationAdditionalIncomeValue.replace(/\D/g, "") == 0;

	return (
		<>
			<FejoaMappedSelect
				formik={formik}
				formItem={"occupationAdditionalIncomesKind"}
				height={standartHeightMeasures}
				fSize={standartFontSizeMeasures}
				width={"100%"}
				options={incomesList}
				// defaultValue={"Выберите"}
				label={"Вид дополнительного дохода"}
				borderColor={"transparent"}
			/>
			<CurrancyField
				formik={formik}
				fieldName={"occupationAdditionalIncomeValue"}
				label={"Размер ежемесячного дохода (руб.)"}
				width={"100%"}
				height={standartHeightMeasures}
				fSize={standartFontSizeMeasures}
			/>
			<Button
				mode="primary"
				width="100%"
				height="71px"
				type="button"
				margin="30px 0 0 0"
				onClick={addItemHandler}
				disabled={disablingConditions}
			>
				Добавить
			</Button>
		</>
	);
};