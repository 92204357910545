import { instance } from '../interceptors';

export class AuthApi {
	invokeAction(
		payload:
			| IInvokeGenerateBody
			| IInvokeValidateBody
			| IInvokeRefreshTokenBody
			| Object,
		flowId: string | null,
		action: InvokeActionTypes
	) {
		return instance.post(`flow/${flowId}/invoke/${action}`, payload);
		// return Promise.resolve().then(() => {
		// 	return mockSmsData;
		// 	// return errorMockSmsData;
		// });
	}

	onLogaut() {
		return instance.post(`/logout`, {})
	}

	fakeMethodForRefresh(
		payload:
			| IInvokeGenerateBody
			| IInvokeValidateBody
			| IInvokeRefreshTokenBody
			| IEsiaLoginDto,
		flowId: string,
		action: InvokeActionTypes
	) {
		return instance.post(`flow/${flowId}/invoke/${action}`, payload);
	}
}

interface IInvokeValidateBody {
	phone: string;
	codeId: number;
	code: string;
}

interface IInvokeGenerateBody {
	phone: string;
	stamp: Date;
}

interface IInvokeRefreshTokenBody {
	token: string;
	refreshToken: string;
}

interface IEsiaLoginDto {
	productCode: number;
}

export type InvokeActionTypes =
	| "esiaLogin"
	| "generateWithSign"
	| "validateWithSign"
	| "generate"
	| "validate"
	| "refresh";