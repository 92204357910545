import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { AppApi } from '../../api/app-api';
import { LoacalData } from '../../api/localStorage';
import { ERRORS_HANDLER } from '../../config';
import { onToasting } from '../../utils/onToasting';
import { TDadataParamKey } from '../commonTypes';
import {
	APP_SET_ERROR,
	APP_SET_SUCCESS,
	SET_ACTUAL_ADDRESS_LIST,
	SET_APP_ERROR_FORM,
	SET_APP_ESIA_DATA,
	SET_APP_LOADING_MODE,
	SET_APP_START_QUERY,
	SET_BIRTH_PLACES_ADDRESS_LIST,
	SET_CABINET_LIST,
	SET_CITIES_LIST,
	SET_COMPANY_INN_DADATA_LIST,
	SET_COMPANY_NAMES_DADATA_LIST,
	SET_CONTINUATION_FORM,
	SET_CURRENT_FORM,
	// SET_PASSPORT_ISSUED_BY,
	SET_PASSPORT_ISSUED_BY_CODES,
	SET_PREVIOUS_PASSPORT_ISSUED_BY,
	SET_PREVIOUS_PASSPORT_ISSUED_BY_CODES,
	SET_REGIONS_LIST,
	SET_REGISTRATION_ADDRESS_LIST,
	SET_WORK_PLACES_ADDRESS_LIST,
	SET_WORK_PLACES_LEGAL_ADDRESS_LIST,
	setActualAddressList,
	setBirthPlaceAddressList,
	setCitiesList,
	setCompanyInnDadata,
	setCompanyNameDadata,
	setDocumentIssuanceCodesList,
	// setDocumentIssuanceList,
	setDocumentPreviousIssuanceCodesList,
	// setDocumentPreviousIssuanceList,
	setOrederCatalog,
	setRegionsList,
	setRegistrationAddressList,
	setWorkPlaceAddressList,
	setWorkPlaceLegalAddressList,
	setAppLoadingMode,
	SET_APP_FIO_DATA,
	setFioList,
	SET_FIO_BLACK_LIST_MESSAGE,
	setFioBlackListErrorMessage,
	SET_PHONE_BLACK_LIST_MESSAGE,
	setPhoneBlackListErrorMessage,
	SET_PHONE_VALIDATION_MESSAGE,
	SET_EMAIL_VALIDATION_MESSAGE,
	SET_ACTUAL_PASSPORT_VALIDATION_MESSAGE,
	SET_PREVIOUS_PASSPORT_VALIDATION_MESSAGE,
	setPhoneValidationErrorMessage,
	setEmailValidationErrorMessage,
	setCurrentPassportValidationErrorMessage,
	setPreviousPassportValidationErrorMessage,
	SET_ADDITIONAL_CABINET_LIST,
	setAdditionalOrders,
	SET_IS_SHOW_FOOTER_MENU,
	SET_THEME,
	SET_IS_TEST_MODE,
} from './appActions';
import { AppActionsType, IAppReducerState, IBlackListSearchParam, IDadataTypes, IParamValidationSearchParam } from './appTypes';
import {defaultTheme} from "../../styles/themes/themes";

const localData = new LoacalData();

export const initialState: IAppReducerState = {
	esiaData: null,
	startDataQuery: null,
	isContinuationForm: !!localData.getFlowId(),
	appLoadingMode: "idle",
	error: null,
	formError: undefined,
	success: null,
	lkzList: null,
	lkzAdditionalList: null,
	regionsList: [],
	// documentIssuanceList: [],
	// previousDocumentIssuanceList: [],
	documentIssuanceCodesList: [],
	previousDocumentIssuanceCodesList: [],
	registrationAddressList: [],
	actualAddressList: [],
	citiesList: [],
	birthPlacesAddressList: [],
	workPlaceAddressList: [],
	workPlaceLegalAddressList: [],
	companyNamesDadataList: [],
	companyInnDadataList: [],
	fioList: [],
	fioBlackListErrorMessage: null,
	phoneBlackListErrorMessage: null,
	phoneValidationErrorMessage: null,
	emailValidationErrorMessage: null,
	currentPassportValidationErrorMessage: null,
	previousPassportValidationErrorMessage: null,
	currentForm: {
		product: undefined,
		formId: undefined,
		flowId: undefined,
	},
	isShowFooterMenu: true,
	theme: defaultTheme,
	isTestMode: false,
	testPageName: undefined,
};

export const appReducer = (
	state: IAppReducerState = initialState,
	action: AppActionsType
): IAppReducerState => {
	switch (action.type) {
		case SET_APP_LOADING_MODE:
			return { ...state, appLoadingMode: action.appLoadingMode };
		case SET_APP_ERROR_FORM:
			return { ...state, formError: action.payload };
		case APP_SET_ERROR:
			return { ...state, error: action.error };
		case APP_SET_SUCCESS:
			return { ...state, success: action.success };
		case SET_CABINET_LIST:
			return { ...state, lkzList: action.payload };
		case SET_ADDITIONAL_CABINET_LIST:
			return { ...state, lkzAdditionalList: action.payload }
		case SET_REGIONS_LIST:
			return { ...state, regionsList: action.payload };
		// case SET_PASSPORT_ISSUED_BY:
		// 	return { ...state, documentIssuanceList: action.payload };
		// case SET_PREVIOUS_PASSPORT_ISSUED_BY:
		// 	return { ...state, previousDocumentIssuanceList: action.payload };
		case SET_PASSPORT_ISSUED_BY_CODES:
			return { ...state, documentIssuanceCodesList: action.payload };
		case SET_PREVIOUS_PASSPORT_ISSUED_BY_CODES:
			return { ...state, previousDocumentIssuanceCodesList: action.payload };
		case SET_REGISTRATION_ADDRESS_LIST:
			return { ...state, registrationAddressList: action.payload };
		case SET_ACTUAL_ADDRESS_LIST:
			return { ...state, actualAddressList: action.payload };
		case SET_CITIES_LIST:
			return { ...state, citiesList: action.payload };
		case SET_BIRTH_PLACES_ADDRESS_LIST:
			return { ...state, birthPlacesAddressList: action.payload };
		case SET_WORK_PLACES_ADDRESS_LIST:
			return { ...state, workPlaceAddressList: action.payload };
		case SET_WORK_PLACES_LEGAL_ADDRESS_LIST:
			return { ...state, workPlaceLegalAddressList: action.payload };
		case SET_CURRENT_FORM:
			return { ...state, currentForm: action.payload };
		case SET_CONTINUATION_FORM:
			return { ...state, isContinuationForm: action.isContinuationForm };
		case SET_COMPANY_NAMES_DADATA_LIST:
			return { ...state, companyNamesDadataList: action.payload };
		case SET_COMPANY_INN_DADATA_LIST:
			return { ...state, companyInnDadataList: action.payload };
		case SET_APP_START_QUERY:
			return { ...state, startDataQuery: action.payload };
		case SET_APP_ESIA_DATA:
			return { ...state, esiaData: action.payload };
		case SET_APP_FIO_DATA:
			return { ...state, fioList: action.payload };
		case SET_FIO_BLACK_LIST_MESSAGE:
			return { ...state, fioBlackListErrorMessage: action.payload };
		case SET_PHONE_BLACK_LIST_MESSAGE:
			return { ...state, phoneBlackListErrorMessage: action.payload };
		case SET_PHONE_VALIDATION_MESSAGE:
			return { ...state, phoneValidationErrorMessage: action.payload };
		case SET_EMAIL_VALIDATION_MESSAGE:
			return { ...state, emailValidationErrorMessage: action.payload };
		case SET_ACTUAL_PASSPORT_VALIDATION_MESSAGE:
			return { ...state, currentPassportValidationErrorMessage: action.payload };
		case SET_PREVIOUS_PASSPORT_VALIDATION_MESSAGE:
			return { ...state, previousPassportValidationErrorMessage: action.payload };
		case SET_IS_SHOW_FOOTER_MENU:
			return { ...state, isShowFooterMenu: action.payload };
		case SET_THEME:
			return {...state, theme: action.payload};
		case SET_IS_TEST_MODE:
			return {...state, isTestMode: action.payload.isTestMode || false, testPageName: action.payload.testPageName};
		default:
			return state;
	}
};

export const getDeclarationsList = (limit?: number, onlyDrafts?: boolean, productCode?: number, excludedId: string | null = null) => async (dispatch: Dispatch) => {
	dispatch(setAppLoadingMode("loading"));
	const client = new AppApi();
	try {
		const { data } = await client.getDeclarationsList(limit, onlyDrafts, productCode, excludedId);
		dispatch(setOrederCatalog(data));
		dispatch(setAppLoadingMode("approved"));
	} catch (err) {
		console.warn("some error has occured.....");
		dispatch(setAppLoadingMode("failed"));
	}
};

export const onGetAdditionalDeclarations = (productCode: number, excludedId: string) => async (dispatch: Dispatch) => {
	const client = new AppApi();
	try {
		const { data } = await client.getDeclarationsList(100, false, productCode, excludedId);
		dispatch(setAdditionalOrders(data))
	} catch (err) {
		const error = err as AxiosError
		error.response && onToasting({ type: "error", message: ERRORS_HANDLER[error.response?.status] })
	}
}

// dadata region search
export const getDatataRegion =
	(catalogId: string, paramKey: TDadataParamKey, param: string) =>
		async (dispatch: Dispatch) => {
			// dispatch(setAppLoadingMode("loading"));
			const client = new AppApi();
			try {
				const res = await client.getDadataCatalog(
					catalogId,
					paramKey,
					param
				);
				dispatch(setRegionsList(res.data));
				// dispatch(setAppLoadingMode("idle"));
			} catch (err) {
				console.warn("some error has occured..... => ", err);
				dispatch(setRegionsList([]));
				// dispatch(setAppLoadingMode("idle"));
			}
		};

// dadata cities search
export const getDadataCities = (catalogId: string, paramKey: TDadataParamKey, param: string) =>
	async (dispatch: Dispatch) => {
		const client = new AppApi();
		try {
			const { data } = await client.getDadataCatalog(catalogId, paramKey, param)
			const outletList = data?.map((el: IDadataTypes) => ({ ...el, title: `${el.city}, ${el.region}` }))
			dispatch(setCitiesList(outletList))
		} catch (err) {
			console.warn("some error has occured..... => ", err);
			dispatch(setCitiesList([]));
		}
	}

// dadata passport issuer
export const getDadataPassportIssuer = (param: string) => async (dispatch: Dispatch) => {
	const client = new AppApi();
	try {
		const res = await client.getDadataCatalog("fms_unit", "query", param);
		dispatch(setDocumentIssuanceCodesList(res.data));
	} catch (err) {
		console.warn("some error has occured..... => ", err);
		dispatch(setDocumentIssuanceCodesList([]));
	}
};

// dadata previous passport issuer
export const getDadataPreviousPassportIssuer = (param: string) => async (dispatch: Dispatch) => {
	const client = new AppApi();
	try {
		const res = await client.getDadataCatalog("fms_unit", "query", param);
		dispatch(setDocumentPreviousIssuanceCodesList(res.data));
	} catch (err) {
		console.warn("some error has occured..... => ", err);
		dispatch(setDocumentPreviousIssuanceCodesList([]));
	}
};

// dadata address list
export const getDadataAddress =
	(
		catalogId: string,
		paramKey: TDadataParamKey,
		param: string,
		dadataFlag:
			| "registration"
			| "actual"
			| "birth_place"
			| "work_place"
			| "legal_work_place"
			| "company"
	) =>
		async (dispatch: Dispatch) => {
			// dispatch(setAppLoadingMode("loading"));
			const client = new AppApi();
			try {
				const res = await client.getDadataCatalog(
					catalogId,
					paramKey,
					param
				);
				dadataFlag === "registration" &&
					dispatch(setRegistrationAddressList(res.data));
				dadataFlag === "actual" && dispatch(setActualAddressList(res.data));
				dadataFlag === "birth_place" &&
					dispatch(setBirthPlaceAddressList(res.data));
				dadataFlag === "work_place" &&
					dispatch(setWorkPlaceAddressList(res.data));
				dadataFlag === "legal_work_place" &&
					dispatch(setWorkPlaceLegalAddressList(res.data));
				// dispatch(setAppLoadingMode("idle"));
			} catch (err) {
				console.warn("some error has occured..... => ", err);
				// dispatch(setAppLoadingMode("idle"));
			}
		};

export const getDadataCompany =
	(key: "name" | "inn", param: string, opf?: string) => async (dispatch: Dispatch) => {
		// dispatch(setAppLoadingMode("loading"));
		const client = new AppApi();
		try {
			const res = await client.getDadataCatalog(
				"company",
				"title",
				param,
				opf
			);
			key === "name" && dispatch(setCompanyNameDadata(res.data));
			key === "inn" && dispatch(setCompanyInnDadata(res.data));
			// dispatch(setAppLoadingMode("idle"));
		} catch (err) {
			console.warn("some error has occured..... => ", err);
			key === "name" && dispatch(setCompanyNameDadata([]));
			key === "inn" && dispatch(setCompanyInnDadata([]));
			// dispatch(setAppLoadingMode("idle"));
		}
	};

export const getDadataFio = ({ title, onSuccess }: { title: string, onSuccess?: () => void }) => async (dispatch: Dispatch) => {
	const client = new AppApi();
	try {
		const { data } = await client.onGetDadataList("fio", `title=${title}`)
		dispatch(setFioList(data));
		onSuccess && onSuccess()
	} catch (err) {
		dispatch(setFioList([]))
	}
}

export const onCheckBlackList = (_data: { query: string, searchParam: IBlackListSearchParam }) => async (dispatch: Dispatch) => {
	const client = new AppApi();
	try {
		const { data } = await client.onCheckBlackList(_data)
		if (data.error) {
			_data.searchParam === "fio" && dispatch(setFioBlackListErrorMessage(data.error))
			_data.searchParam === "phone" && dispatch(setPhoneBlackListErrorMessage(data.error))
		}
	} catch (err) {
		const error = err as AxiosError
		error.response && onToasting({ type: "error", message: ERRORS_HANDLER[error.response?.status] })
	}
}

export const onValidateParam = (_data: { paramValue: string, searchParam: IParamValidationSearchParam, onComplete?: (res: any) => void }) => async (dispatch: Dispatch) => {
	const client = new AppApi();
	const { onComplete, ...param} = _data;
	let res;
	try {
		const { data } = await client.onValidateParam(param)
		res = data;
		if (data.error) {
			param.searchParam === "email" && dispatch(setEmailValidationErrorMessage(data.error))
			param.searchParam === "phone" && dispatch(setPhoneValidationErrorMessage(data.error))
			// _data.searchParam === "passport" && dispatch(setCurrentPassportValidationErrorMessage(data.error))
			param.searchParam === "previousPassport" && dispatch(setPreviousPassportValidationErrorMessage(data.error))
		}
	} catch (err) {
		res = err;
		const error = err as AxiosError
		error.response && onToasting({ type: "error", message: ERRORS_HANDLER[error.response?.status] })
	}
	if (onComplete) onComplete(res);
	return res;
}

export const onCallTouchAction = (_data: { flowId: string, onSuccess?: () => void }) => async (dispatch: Dispatch) => {
	const client = new AppApi();
	try {
		await client.onCallTouchAction(_data)
	} catch (err) {
		const error = err as AxiosError
		error.response && onToasting({ type: "error", message: ERRORS_HANDLER[error.response?.status] })
	}
}
