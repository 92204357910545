import { ICommonFormData, TLoadingMode } from '../commonTypes';
import {
  ICompanyDadataList,
  ICreditDeclarations,
  ICurrentRoutesParams,
  IDadataAddressTypes,
  IDadataTypes,
  IErrorConfig,
  IErrorState,
	IFioDadataList,
} from './appTypes';
import {IThemes} from "../../styles/themes/themes";
import {TestPageNamesType} from "../../utils/getQueryParams";

export const SET_APP_LOADING_MODE = "app/SET_APP_LOADING_MODER";
export const APP_SET_ERROR = "app/APP_SET_ERROR";
export const APP_SET_SUCCESS = "app/APP_SET_SUCCESS";
export const SET_CABINET_LIST = "app/SET_CABINET_LIST";
export const SET_ADDITIONAL_CABINET_LIST = "app/SET_ADDITIONAL_CABINET_LIST"
export const SET_REGIONS_LIST = "app/SET_REGIONS_LIST";
export const SET_PREVIOUS_PASSPORT_ISSUED_BY = "app/SET_PREVIOUS_PASSPORT_ISSUED_BY";
export const SET_PASSPORT_ISSUED_BY_CODES = "app/SET_PASSPORT_ISSUED_BY_CODES";
export const SET_PREVIOUS_PASSPORT_ISSUED_BY_CODES = "app/SET_PREVIOUS_PASSPORT_ISSUED_BY_CODES";
export const SET_CURRENT_FORM = "app/SET_CURRENT_FORM";
export const SET_APP_ERROR_FORM = "app/SET_APP_ERROR_FORM";
export const SET_REGISTRATION_ADDRESS_LIST = "app/SET_REGISTRATION_ADDRESS_LIST";
export const SET_ACTUAL_ADDRESS_LIST = "app/SET_ACTUAL_ADDRESS_LIST";
export const SET_CITIES_LIST = "app/SET_CITIES_LIST";
export const SET_CONTINUATION_FORM = "app/SET_CONTINUATION_FORM";
export const SET_BIRTH_PLACES_ADDRESS_LIST = "app/SET_BIRTH_PLACES_ADDRESS_LIST";
export const SET_WORK_PLACES_ADDRESS_LIST = "app/SET_WORK_PLACES_ADDRESS_LIST";
export const SET_COMPANY_NAMES_DADATA_LIST = "app/SET_COMPANY_NAMES_DADATA_LIST";
export const SET_COMPANY_INN_DADATA_LIST = "app/SET_COMPANY_INN_DADATA_LIST";
export const SET_WORK_PLACES_LEGAL_ADDRESS_LIST = "app/SET_WORK_PLACES_LEGAL_ADDRESS_LIST";
export const SET_APP_START_QUERY = "app/SET_APP_START_QUERY";
export const SET_APP_ESIA_DATA = "app/SET_APP_ESIA_DATA";
export const SET_APP_FIO_DATA = "app/SET_APP_FIO_DATA";
export const SET_FIO_BLACK_LIST_MESSAGE = "app/SET_FIO_BLACK_LIST_MESSAGE";
export const SET_PHONE_BLACK_LIST_MESSAGE = "app/SET_PHONE_BLACK_LIST_MESSAGE";
export const SET_PHONE_VALIDATION_MESSAGE = "app/SET_PHONE_VALIDATION_MESSAGE";
export const SET_EMAIL_VALIDATION_MESSAGE = "app/SET_EMAIL_VALIDATION_MESSAGE";
export const SET_ACTUAL_PASSPORT_VALIDATION_MESSAGE = "app/SET_ACTUAL_PASSPORT_VALIDATION_MESSAGE";
export const SET_PREVIOUS_PASSPORT_VALIDATION_MESSAGE = "app/SET_PREVIOUS_PASSPORT_VALIDATION_MESSAGE";
export const SET_IS_SHOW_FOOTER_MENU = "app/SET_IS_SHOW_FOOTER_MENU";
export const SET_THEME = "app/SET_THEME";
export const SET_IS_TEST_MODE = "app/SET_IS_TEST_MODE";

export const setContinuationForm = (isContinuationForm: boolean) => {
	return { type: SET_CONTINUATION_FORM, isContinuationForm } as const;
};

export const setCurrentForm = (payload: ICurrentRoutesParams) => {
	return { type: SET_CURRENT_FORM, payload } as const;
};

export const setAppLoadingMode = (appLoadingMode: TLoadingMode) => {
	return { type: SET_APP_LOADING_MODE, appLoadingMode } as const;
};

export const setAppError = (error: string | null) => {
	return { type: APP_SET_ERROR, error } as const;
};

export const setAppSuccess = (success: string | null) => {
	return { type: APP_SET_SUCCESS, success } as const;
};

export const setOrederCatalog = (payload: ICreditDeclarations[] | null) => {
	return { type: SET_CABINET_LIST, payload } as const;
};

export const setAdditionalOrders = (payload: ICreditDeclarations[] | null) => {
	return { type: SET_ADDITIONAL_CABINET_LIST, payload } as const
}

export const setRegionsList = (payload: IDadataTypes[]) => {
	return { type: SET_REGIONS_LIST, payload } as const;
};

// export const setDocumentIssuanceList = (payload: IDadataTypes[]) => {
// 	return { type: SET_PASSPORT_ISSUED_BY, payload } as const;
// };

export const setDocumentPreviousIssuanceList = (payload: IDadataTypes[]) => {
	return { type: SET_PREVIOUS_PASSPORT_ISSUED_BY, payload } as const;
};

export const setDocumentIssuanceCodesList = (payload: IDadataTypes[]) => {
	return { type: SET_PASSPORT_ISSUED_BY_CODES, payload } as const;
};

export const setDocumentPreviousIssuanceCodesList = (payload: IDadataTypes[]) => {
	return { type: SET_PREVIOUS_PASSPORT_ISSUED_BY_CODES, payload } as const;
};

export const setRegistrationAddressList = (payload: IDadataAddressTypes[]) => {
	return { type: SET_REGISTRATION_ADDRESS_LIST, payload } as const;
};

export const setActualAddressList = (payload: IDadataAddressTypes[]) => {
	return { type: SET_ACTUAL_ADDRESS_LIST, payload } as const;
};

export const setCitiesList = (payload: IDadataAddressTypes[]) => {
	return { type: SET_CITIES_LIST, payload } as const;
};

export const setBirthPlaceAddressList = (payload: IDadataAddressTypes[]) => {
	return { type: SET_BIRTH_PLACES_ADDRESS_LIST, payload } as const;
};

export const setWorkPlaceAddressList = (payload: IDadataAddressTypes[]) => {
	return { type: SET_WORK_PLACES_ADDRESS_LIST, payload } as const;
};

export const setWorkPlaceLegalAddressList = (payload: IDadataAddressTypes[]) => {
	return { type: SET_WORK_PLACES_LEGAL_ADDRESS_LIST, payload } as const;
};

export const setCompanyNameDadata = (payload: ICompanyDadataList[]) => {
	return { type: SET_COMPANY_NAMES_DADATA_LIST, payload } as const;
};

export const setCompanyInnDadata = (payload: ICompanyDadataList[]) => {
	return { type: SET_COMPANY_INN_DADATA_LIST, payload } as const;
};

export const setErrorForm = (payload: ICommonFormData<IErrorConfig, IErrorState> | undefined) => {
	return { type: SET_APP_ERROR_FORM, payload } as const;
};

export const setStartDataQuery = (payload: string | null) => {
	return {type: SET_APP_START_QUERY, payload} as const
}

export const setEsiaData = (payload: Object | null) => {
	return {type: SET_APP_ESIA_DATA, payload} as const
}

export const setFioList = (payload: IFioDadataList[]) => {
	return {type: SET_APP_FIO_DATA, payload} as const
}

export const setFioBlackListErrorMessage = (payload: string | null) => {
	return {type: SET_FIO_BLACK_LIST_MESSAGE, payload} as const
}

export const setPhoneBlackListErrorMessage = (payload: string | null) => {
	return {type: SET_PHONE_BLACK_LIST_MESSAGE, payload} as const 
}

export const setPhoneValidationErrorMessage = (payload: string | null) => {
	return {type: SET_PHONE_VALIDATION_MESSAGE, payload} as const 
}

export const setEmailValidationErrorMessage = (payload: string | null) => {
	return {type: SET_EMAIL_VALIDATION_MESSAGE, payload} as const 
}

export const setCurrentPassportValidationErrorMessage = (payload: string | null) => {
	return {type: SET_ACTUAL_PASSPORT_VALIDATION_MESSAGE, payload} as const 
}

export const setPreviousPassportValidationErrorMessage = (payload: string | null) => {
	return {type: SET_PREVIOUS_PASSPORT_VALIDATION_MESSAGE, payload} as const 
}

export const setIsShowFooterMenu = (payload: boolean) => {
	return {type: SET_IS_SHOW_FOOTER_MENU, payload} as const
}

export const setTheme = (payload: IThemes) => {
	return { type: SET_THEME, payload} as const
}

export const setIsTestMode = (payload: {isTestMode?: boolean, testPageName?: TestPageNamesType}) => {
	return { type: SET_IS_TEST_MODE, payload} as const
}