import { Button } from '../../../styles/UIKit/Button';
import { Flex } from '../../../styles/UIKit/Flex';
import { AiOutlineLoading } from "react-icons/ai";
import useDimensions from "../../../hooks/useDimensions";

interface IContinuationButton {
	fieldsWrapConditions: boolean;
	disabledModeForConfirmationPhone: boolean;
	getSmsCode: () => void;
	loading?: boolean;
  labelText?: string;
}

export const ContinuationButton = (props: IContinuationButton) => {
	const {
		fieldsWrapConditions,
		disabledModeForConfirmationPhone,
		loading,
		getSmsCode,
    labelText = 'Далее'
	} = props;

	const { screenWidth } = useDimensions();
	const isMobile = screenWidth <= 767;

	return (
		<Flex width={ isMobile ? "unset" : "100%"} margin={fieldsWrapConditions ? "45px 0 0" : "45px 10px"}>
      <Button
        mode="primary"
        onClick={getSmsCode}
        disabled={disabledModeForConfirmationPhone}
        type="button"
        padding="18px 41px"
        FSize="16px"
        fontWeight="800"
				width={isMobile ? "100%" : "unset"}
      >
	    { !!loading && <AiOutlineLoading /> }
        { labelText }
      </Button>
		</Flex>
	);
};