// export interface ICreditDeclarations {
// 	flowId: string;
// 	creditType: string;
// 	amount: number;
// 	startDate: Date | null;
// 	status: string;
// }

// export const declarationsData: ICreditDeclarations[] = [
//     {
//         flowId: "v9C8MxDTULMntNK6ULUHJSwxoQNmGbY9SxzFVThaBZEdd7Fug",
//         creditType: "потребительский кредит",
//         amount: 200000,
//         startDate: new Date("2022-05-12T17:32:00.830279"),
//         status: "одобрен"
//     },
//     {
//         flowId: "24MuXjAnL4Mj8HVnxBboBjQqg99nehS9crZggPMvteJN69ncEu",
//         creditType: "потребительский кредит",
//         amount: 0,
//         startDate: null,
//         status: "в процессе..."
//     },
//     {
//         flowId: "jA671tXuzW7w7YGjZPuujHFoAAeDGg6hK6sDbiGjq5NF1aUUS",
//         creditType: "потребительский кредит",
//         amount: 200000,
//         startDate: new Date("2022-05-12T16:28:08.425632"),
//         status: "одобрен"
//     }
// ]

export const insurnceCompanies = ["alpha", "betta", "gamma", "delta"]

export const insuranceProductTypes = ["Другие типы страхования"]