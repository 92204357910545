import { FormikValues } from 'formik';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import useDimensions from '../../../hooks/useDimensions';
import { IDadataTypes } from '../../../redux/Application/appTypes';
import { TDadataParamKey } from '../../../redux/commonTypes';
import { adaptiveParams } from '../../adaptiveParams';
import { FieldStyles, MaskedFieldStyles, StyledFieldsProps, } from '../CustomField/CustomFieldStyles';
import { Flex } from '../Flex';
import { String } from '../String';
import { StyledErrorNotificator } from '../StyledErrorNotificator';
import { HintedFieldWrapper } from './HintedFieldWrapper';
import { calcErrorOffset } from '../../../utils/calcErrorOffset';

interface ICustomAutoComplete extends StyledFieldsProps {
	mask?: Array<RegExp | string>;
	formik: FormikValues;
	formName: string;
	dadataList: IDadataTypes[];
	currentValue: string;
	onChangeHandler: (value: string) => void;
	onMouseDownHandler: (chosenItem: IDadataTypes) => void;
	ResetListHandler: () => void;
	paramKey: TDadataParamKey;
	onBlurHandler?: (
		formItem: string,
		currentValue: string,
		event: FormikValues
	) => void;
	customPattern?: RegExp;
	errorValue?: string;
	backgroundColor?: string;
	type?: string;
}

// select options window animation
const optionsWindowAnimation = keyframes`
    0% {opacity: 0;}
    100% {opacity: 1;}
`;

// modal with options to select
const OptionsWindow = styled.ul<{ maxHeight?: string }>`
	border-radius: 4px;
	position: absolute;
	width: 100%;
	margin-top: 3px;
	background: #ffffff;
	overflow-y: scroll;
	max-height: ${({ maxHeight }) => maxHeight || "400px"};
	opacity: 0;
	animation: ${optionsWindowAnimation} 0.4s forwards;
	box-shadow: 0px 0px 5px black;
	z-index: 2;
`;

// Each chosen option
const EachOption = styled.div`
	width: 100%;
	padding: 5px 10px;
	font-size: 20px;
	cursor: pointer;
	transition: background-color 0.1s;
	font-family: Formular;
	&:hover {
		background: #00cad6;
		color: white;
	}
`;

export const CustomAutoComplete: React.FC<ICustomAutoComplete> = (props) => {
	const {
		formik,
		mask,
		formName,
		label,
		dadataList,
		currentValue,
		onChangeHandler,
		onMouseDownHandler,
		ResetListHandler,
		paramKey,
		customPattern,
		errorValue,
		backgroundColor,
		type,
		// borderColor,
	} = props;

	const {screenWidth} = useDimensions()

	const { mainContentWidth } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;
	const Top = calcErrorOffset(screenWidth, fieldsWrapConditions, undefined);

	const onChangeRegularValueHandler = (e: FormikValues) => {
		if (e.target.value.charAt(0) !== " ") {
			formik.handleChange(e);
			onChangeHandler(e.target.value);
		}
	};
	const onChangePatternedValueHandler = (e: FormikValues) => {
		if ((customPattern && customPattern.test(e.nativeEvent.data) && e.target.value.charAt(0) !== " ") || !e.nativeEvent.data) {
			formik.handleChange(e);
			onChangeHandler(e.target.value);
		}
	};
	const onBlurValueHndler = (e: React.FocusEvent<HTMLInputElement>) => {
		ResetListHandler();
	};

	return (
		<Flex direction="column" Position="relative" padding={fieldsWrapConditions ? "0" : "10px 0"}>
			{label && <String fSize={"14px"} margin="0 0 8px">{label}</String>}
			<HintedFieldWrapper>
				{!customPattern && !mask && (
					<FieldStyles
						{...props}
						type={type || "text"}
						backgroundColor={backgroundColor}
						name={formName}
						value={currentValue}
						onChange={onChangeRegularValueHandler}
						onBlur={onBlurValueHndler}
						onBlurCapture={() => formik.setFieldTouched(formName)}
						onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
							event.target.setAttribute("autocomplete", "new-password");
						}}
					/>
				)}

				{mask && (
					<MaskedFieldStyles
						{...props}
						showMask={false}
						guide={false}
						name={formName}
						value={currentValue}
						onChange={onChangeRegularValueHandler}
						onBlur={onBlurValueHndler}
						onBlurCapture={() => formik.setFieldTouched(formName)}
						backgroundColor={backgroundColor}
						onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
							event.target.setAttribute("autocomplete", "off");
						}}
						mask={mask}
					/>
				)}

				{customPattern && (
					<FieldStyles
						{...props}
						value={currentValue}
						name={formName}
						onChange={onChangePatternedValueHandler}
						onBlur={onBlurValueHndler}
						onBlurCapture={() => formik.setFieldTouched(formName)}
						onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
							event.target.setAttribute("autocomplete", "new-password");
						}}
					/>
				)}

				{/* {mask ? (
					<MaskedFieldStyles
						{...props}
						showMask={false}
						guide={false}
						name={formName}
						value={currentValue}
						onChange={onChangeRegularValueHandler}
						onBlur={onBlurValueHndler}
						onBlurCapture={() => formik.setFieldTouched(formName)}
						onFocus={(event) => {
							event.target.setAttribute("autocomplete", "off");
						}}
						mask={mask}
					/>
				) : (
					<FieldStyles
						{...props}
						name={formName}
						value={currentValue}
						onChange={onChangeRegularValueHandler}
						onBlur={onBlurValueHndler}
						onBlurCapture={() => formik.setFieldTouched(formName)}
						onFocus={(event) => {
							event.target.setAttribute("autocomplete", "off");
						}}
					/>
				)} */}

				{dadataList.length > 0 && (
					<OptionsWindow>
						{dadataList.map((option, index) => {
							const choseOptionHandler = () => {
								onMouseDownHandler(option);
							};
							return (
								<EachOption
									key={index}
									onMouseDown={choseOptionHandler}
								>
									{option[paramKey]}
								</EachOption>
							);
						})}
					</OptionsWindow>
				)}
			</HintedFieldWrapper>
			{errorValue && dadataList?.length < 1 && (
				<StyledErrorNotificator Top={Top}>{errorValue}</StyledErrorNotificator>
			)}
		</Flex>
	);
};
