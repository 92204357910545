import styled from "styled-components";
import {defaultTheme} from "../../themes/themes";


const CheckBoxWrapper = styled.div`
position: relative;
`
const CheckBoxLabel = styled.label`
 position: absolute;
 top: 0;
 left: 0;
 width: 40px;
 height: 24px;
 border-radius: 15px;
 background: white;
 border: 1px solid ${defaultTheme.FEI_GREY_LIGHT_2};
 cursor: pointer;
 &::after {
     content: "";
     display: block;
     border-radius: 50%;
     width: 17px;
     height: 17px;
     margin: 3px;
     background: ${defaultTheme.FEI_GREY_LIGHT_2};
     transition: .2s;
 }
`;
const CheckBox = styled.input`
 opacity: 0;
 z-index:1;
 border-radius: 15px;
 width: 40px;
 height: 24px;
&:checked + ${CheckBoxLabel} {
 border: 2px solid ${defaultTheme.appColors.commonTheme};
 &::after {
     content: "";
     display: block;
     border-radius: 50%;
     width: 17x;
     height: 17x;
     margin: 2px 2px 2px 17px;
     background: ${defaultTheme.appColors.commonTheme};
     transition: .2s;
 }
}
`;


export const ToggleField: React.FC<{
  formItem: string;
  margin?: string;
  checked: boolean;
  onChange: () => void;
}> = (props) => {
  const {formItem, checked, onChange, margin} = props
  return (
    <div>
      <CheckBoxWrapper>
        <CheckBox id={formItem} checked={checked} onChange={onChange} type={"checkbox"} />
        <CheckBoxLabel htmlFor={formItem}/>
      </CheckBoxWrapper>
    </div>
  )
}