import { FormikValues } from 'formik';
import React from 'react';
import { OCUPATION_TYPES_MAPPER } from '../../../../../config';
import { ICompanyDadataList, IDadataAddressTypes } from '../../../../../redux/Application/appTypes';
import {
  IAdvancedOptions,
  IAdvancedOptions2,
  IOpfListRef,
  IProfileConfig2,
  IProfileState2
} from '../../../../../redux/Credit/creditTypes';
import { Employer } from './Employer';
import { IndividualHolder } from './IndividualHolder';
import { OtherIncomes } from './OtherIncomes';
import { OwnBusiness } from './OwnBusiness';

interface IMainContent {
	esiaUsage?: boolean;
	formik: FormikValues;
	fieldsWrapConditions: boolean;
	standartWidthMeasures: string;
	standartHeightMeasures: string;
	standartFontSizeMeasures: string;
	otherIncomesRef: IAdvancedOptions[];
	updateFormCallBack: (
		formItem: string,
		currentValue: string,
		event: FormikValues
	) => void;
	workPlaceLegalAddressList: IDadataAddressTypes[];
	profileConfig: IProfileConfig2;
	workPlaceAddressList: IDadataAddressTypes[];
	setFormDataUpdates: (values: IProfileState2) => void;
	companyNamesDadataList: ICompanyDadataList[];
	companyInnDadataList: ICompanyDadataList[];
	occupationCompanyOpfRef: IOpfListRef[];
	screenWidth: number;
	phoneValidationErrorMessage: string | null;
}
export const MainContent: React.FC<IMainContent> = (props) => {
	const {
		formik,
		workPlaceLegalAddressList,
		workPlaceAddressList,
		setFormDataUpdates,
	} = props;
	return (
		<>
			{
				(
					formik.values.occupationType === OCUPATION_TYPES_MAPPER.Employment ||
					formik.values.occupationType === OCUPATION_TYPES_MAPPER.Military ||
					formik.values.occupationType === OCUPATION_TYPES_MAPPER.Judge ||
					formik.values.occupationType === OCUPATION_TYPES_MAPPER.Valuer ||
					formik.values.occupationType === OCUPATION_TYPES_MAPPER.Farmer ||
					formik.values.occupationType === OCUPATION_TYPES_MAPPER.Bankruptcy ||
					formik.values.occupationType === OCUPATION_TYPES_MAPPER.MainGPH
				) && (
				<Employer
					{...props}
					workPlaceLegalAddressList={workPlaceLegalAddressList}
					workPlaceAddressList={workPlaceAddressList}
					setFormDataUpdates={setFormDataUpdates}
				/>
			)}
			{formik.values.occupationType === OCUPATION_TYPES_MAPPER.Other && <OtherIncomes {...props} />}
			{formik.values.occupationType === OCUPATION_TYPES_MAPPER.Business && <OwnBusiness {...props} />}
			{formik.values.occupationType === OCUPATION_TYPES_MAPPER.PrivateEntrepreneur && <IndividualHolder {...props} />}
		</>
	);
};