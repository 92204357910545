import { FormikValues } from 'formik';
import React from 'react';
import { CustomMaskedField } from '../../../../styles/UIKit/CustomField/CustomMaskedField';
import { Flex } from '../../../../styles/UIKit/Flex';
import { ParagraphWrapper } from '../../../CommonPagesStyles';
import { getDadataPassportIssuer, onValidateParam  } from '../../../../redux/Application/app-reducer';
import { setDocumentIssuanceCodesList, setCurrentPassportValidationErrorMessage } from '../../../../redux/Application/appActions';
import { IDadataTypes } from '../../../../redux/Application/appTypes';
import { useDispatch } from 'react-redux';
import { Title } from '../../../../styles/UIKit/Title';
import { CustomerCalendar } from '../../../../styles/UIKit/DatePicker/CustomerCalendar/CustomerCalendar';
import { dateIntoString } from '../../../../utils/commonFunctions';
import { adaptiveParams } from '../../../../styles/adaptiveParams';
import { IPassportState } from '../../../../redux/Credit/creditTypes';
import { onSetFieldBorderColor } from '../../../../utils/onSetFieldBorderColor';
import { SuperPassport } from '../../../../styles/UIKit/dadata/SuperPassport';
import {STAGES, usePixel} from "../../../../hooks/usePixel";
import {useSearchParams} from "react-router-dom";

interface ICurrentPassportData {
	standartFontSizeMeasures: string;
	standartHeightMeasures: string;
	standartWidthMeasures: string;
	updateFormCallBack: (formItem: string, currentValue: string, event: FormikValues) => void;
	setFormDataUpdates: (values: IPassportState) => void;
	userPassportData: FormikValues;
	documentIssuanceCodesList: IDadataTypes[];
	screenWidth: number;
	currentPassportValidationErrorMessage: string | null;
	product: string;
}

export const CurrentPassportData: React.FC<ICurrentPassportData> = (props) => {
	const [searchParams] = useSearchParams();
	const {
		standartFontSizeMeasures,
		standartHeightMeasures,
		standartWidthMeasures,
		updateFormCallBack,
		documentIssuanceCodesList,
		userPassportData,
		screenWidth,
		setFormDataUpdates,
		currentPassportValidationErrorMessage,
		product,
	} = props;

	const dispatch = useDispatch();

	const { mainContentWidth } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;

	// *** HANDLERS FOR CURRENT PASSPORT FIELD ***
	const onHandleCurrentPassportChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let value = e.currentTarget.value
    userPassportData.setFieldValue("currentPassportDepartmentCode", value)
    userPassportData.setFieldValue("issuedBy", null)
		dispatch(getDadataPassportIssuer(value));
	}
	const onHandleCurrentPassportBlur = () => {
		dispatch(setDocumentIssuanceCodesList([]));
    userPassportData.setFieldTouched("currentPassportDepartmentCode")
    userPassportData.setFieldTouched("issuedBy")
	}
	const onHandleCurrentPassportFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		event.target.setAttribute("autocomplete", "off");
		dispatch(getDadataPassportIssuer(userPassportData.values.currentPassportDepartmentCode));
	}
	const onChoseCurrentPassportPosition = (option: IDadataTypes) => {
		userPassportData.setFieldValue("currentPassportDepartmentCode", option.code);
		userPassportData.setFieldValue("issuedBy", option.title);
		dispatch(setDocumentIssuanceCodesList([]));
	}

	const onHandleCurrentPassportSeriaAndNumberBlur = (formItem: string, currentValue: string, event: FormikValues) => {
		updateFormCallBack(formItem, currentValue, event);
		if (!!userPassportData.values.currentPassportSeria && !!userPassportData.values.currentPassportNumber) {
			dispatch(setCurrentPassportValidationErrorMessage(null));
			dispatch(onValidateParam({ paramValue: userPassportData.values.currentPassportSeria + " " + userPassportData.values.currentPassportNumber, searchParam: "passport" }))
		}
	}

	const onFocusCurrentPassportSeriaAndNumberHandler = (formItem: string, currentValue: string, event: FormikValues) => {
		if (!!currentPassportValidationErrorMessage) {
			dispatch(setCurrentPassportValidationErrorMessage(null));
		}
	}

	const [receiptDateValue, setReceiptDateValue] = React.useState<string>(
		userPassportData.values.receiptDate
			? dateIntoString(userPassportData.values.receiptDate as Date)
			: ""
	);

	usePixel({stage: STAGES.purchased, product, searchParams})

	return (
		<>
			<ParagraphWrapper padding={fieldsWrapConditions ? "20px 0 0 0" : "10px"}>
				<Flex justify="flex-start">
					<Title color={"#0A1244"} fSize={"24px"} margin="40px 0 0">
						Заполните паспортные данные
					</Title>
				</Flex>
			</ParagraphWrapper>
			<ParagraphWrapper padding={fieldsWrapConditions ? "0" : "10px"}>
				<Flex
					direction={fieldsWrapConditions ? "row" : "column"}
					width={"100%"}
					justify="space-between"
					margin="12px 0"
				>
					<CustomMaskedField
						width={standartWidthMeasures}
						height={standartHeightMeasures}
						fSize={standartFontSizeMeasures}
						onBlurHandler={onHandleCurrentPassportSeriaAndNumberBlur}
						onFocusHandler={onFocusCurrentPassportSeriaAndNumberHandler}
						formItem={"currentPassportSeria"}
						{...userPassportData.getFieldProps("currentPassportSeria")}
						label={"Серия"}
						borderColor={currentPassportValidationErrorMessage && !userPassportData.errors.currentPassportNumber && !userPassportData.errors.currentPassportSeria ? "red" : onSetFieldBorderColor(userPassportData, "currentPassportSeria")}
						mask={[/[0-9\-\*]$/, /[0-9\-\*]$/, " ", /[0-9\-\*]$/, /[0-9\-\*]$/]}
						errorValue={userPassportData.touched.currentPassportSeria && (currentPassportValidationErrorMessage && !userPassportData.errors.currentPassportSeria && !userPassportData.errors.currentPassportNumber
							? currentPassportValidationErrorMessage
							: userPassportData.errors.currentPassportSeria || null)}
					/>
					<CustomMaskedField
						width={standartWidthMeasures}
						height={standartHeightMeasures}
						fSize={standartFontSizeMeasures}
						onBlurHandler={onHandleCurrentPassportSeriaAndNumberBlur}
						onFocusHandler={onFocusCurrentPassportSeriaAndNumberHandler}
						formItem={"currentPassportNumber"}
						{...userPassportData.getFieldProps("currentPassportNumber")}
						label={"Номер"}
						borderColor={currentPassportValidationErrorMessage && !userPassportData.errors.currentPassportNumber && !userPassportData.errors.currentPassportSeria ? "red" : onSetFieldBorderColor(userPassportData, "currentPassportNumber")}
						mask={[ /[0-9\-\*]$/, /[0-9\-\*]$/, /[0-9\-\*]$/, /[0-9\-\*]$/, /[0-9\-\*]$/, /[0-9\-\*]$/ ]}
						errorValue={userPassportData.touched.currentPassportNumber && (currentPassportValidationErrorMessage && !userPassportData.errors.currentPassportNumber && !userPassportData.errors.currentPassportSeria
							? currentPassportValidationErrorMessage
							: userPassportData.errors.currentPassportNumber || null)}
					/>
				</Flex>
				<Flex
					direction={fieldsWrapConditions ? "row" : "column"}
					width={"100%"}
					justify="space-between"
					margin="22px 0"
				>
					<CustomerCalendar
						updateFormHandler={setFormDataUpdates}
						width={standartWidthMeasures}
						label={"Дата выдачи"}
						formik={userPassportData}
						formItem={"receiptDate"}
						value={userPassportData.values.receiptDate}
						minDate={userPassportData.values.birthDate || undefined}
						maxDate={new Date()}
						dateValue={receiptDateValue}
						fSize={standartFontSizeMeasures}
						height={standartHeightMeasures}
						setDateValue={setReceiptDateValue}
						error={ userPassportData.touched.receiptDate && !!userPassportData.errors.receiptDate }
						borderColor={ onSetFieldBorderColor(userPassportData, "receiptDate") }
						errorValue={ userPassportData.touched.receiptDate && userPassportData.errors.receiptDate }
					/>
				</Flex>
				<div style={{marginBottom: "20px"}}>
				<SuperPassport
					formik={userPassportData}
					dadataList={documentIssuanceCodesList}
					width={"100%"}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					codeProp={"currentPassportDepartmentCode"}
					issuedProp={"issuedBy"}
					onChange={onHandleCurrentPassportChange}
					onBlur={onHandleCurrentPassportBlur}
					onFocus={onHandleCurrentPassportFocus}
					onChoise={onChoseCurrentPassportPosition}
					borderColor={ onSetFieldBorderColor(userPassportData, "issuedBy") }
					errorValue={userPassportData.touched.issuedBy && userPassportData.errors.issuedBy}
				/>
				</div>
			</ParagraphWrapper>
		</>
	);
};