import { ROUTE_PATH } from './routesConfig';
import { getYearsByDate } from './utils/datesHandler';

export const GET_IP_DATA_URL = "https://extreme-ip-lookup.com/json";

export const PRODUCTS_CODES: {[key: string]: number} = {
  [ROUTE_PATH.CREDITS]: 9,
  [ROUTE_PATH.CPZA]: 9,
  [ROUTE_PATH.CARDS]: 7,
};

export const REG_EXPS = {
  isOnlyCyrillicWithDigits: (value: string) => value.split("").some(el => !/^[а-яёА-ЯЁ0-9-*.№]/.test(el)),
  isOnlyCyrillic: (value: string) => value.split("").some(el => !/^[ёа-яЁА-Я-*.№]/.test(el)),
  forFIO:(value: string) => value.split("").some(el => !/^[ёа-яЁА-Я-]/.test(el)),
  isNoLatinic: (value: string) => !value,
  isValidAge: (value: Date, minAge: number, maxAge: number) => getYearsByDate(value) < minAge || getYearsByDate(value) > maxAge,
  isValid_OGRN_IP: (value: string) => value[0] === "3",
  isValid_OGRN: (value: string) => value[0] === "1" || value[0] === "5",
}

export const ERRORS_HANDLER: {[key: number]: string} = {
  400: 'Неверный запрос (400)',
  401: 'Требуется авторизация (401)',
  403: 'Недостаточно прав выполнить операцию (403)',
  404: 'Запрос не найден (404)',
  500: 'Серверная проблема (500)',
  502: 'Что-то пошло не так (502)',
  504: 'Вышло время ожидания запроса (504)',
  1014: 'Ошибка сервиса "Госуслуги"'
};

export const OCUPATION_TYPES_MAPPER = {
  Employment: "Employment",
  Military: "Military",
  Judge: "Judge",
  Valuer: "Valuer",
  Farmer: "Farmer",
  Bankruptcy: "Bankruptcy",
  Other: "Other",
  Business: "Business",
  PrivateEntrepreneur: "PrivateEntrepreneur",
  MainGPH: "Main_GPH"
}

export const isMarriedStatus = "Married"

export const HREF_START_PAGE = [
    {id: "0", href:"#anketa", title: "Рассчитайте свой кредит"},
    {id: "1", href:"#useful_info", title: "Полезная информация"},
    {id: "2", href:"#contacts_info", title: "Контактная информация"},
]