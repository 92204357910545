import React, {FC} from "react";
import {IoMdClose} from "react-icons/io";
import {Title} from "../../styles/UIKit/Title";
import styled from "styled-components";
import {BackDrop} from "../../styles/UIKit/BackDrop/BackDrop";
import {topFallAnimation} from "../../styles/UIKit/Animations/Animations";
import presentBox from '../../assets/presentBox.svg'
import useDimensions from "../../hooks/useDimensions";


const ModalStyles = styled.div`
  position: absolute;
  background: linear-gradient(98deg, #007D8C 0%, #10C8D2 100%);
  width: 676px;
  height: 377px;
  max-height: 95%;
  max-width: 95%;
  padding: 50px 30px;
  top: 50%;
  left: 50%;
  display: flex;
  gap: 15px;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  color: #FFFFFF;

  animation: ${topFallAnimation};
  animation-duration: 2s;

  @media (max-width: 676px) {
    width: 100%;
    padding: 20px;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const CloseFeature = styled.div`
  position: absolute;
  right: 9px;
  top: 9px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  background: transparent;
  border: solid 1px #fff;
  padding: 4px;
  
  @media(max-width: 750px) {
    width: 40px;
    height: 40px;
  }
`;

const TextContentWrapper = styled.div`
  flex: 1 0 50%;
  @media (max-width: 676px) {
    width: unset;
    padding: 20px;
    flex-direction: column-reverse;
  }
`

const ImageWrapper = styled.div`
  padding: 10px 0 0;
  flex: 1 0 50%;

  @media (max-width: 676px) {
    width: 60%;
  }
`

const Image = styled.img`
  width: 100%;
  height: auto;
  margin-top: -40px;
  margin-left: -20px;
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: flex-start;
  padding: 30px 0 0;

  @media (max-width: 676px) {
    align-items: center;
  }
`
const Proposal = styled.p`
  font-family: 'Exo2-Regular';
  font-size: 21px;
  line-height: 25.2px;
  width: 100%;
  color: #FFF;

  @media (max-width: 676px) {
    text-align: center;
  }
`
const GetSolutionBtn = styled.button`
  padding: 8px 24px;
  height: 49px;
  min-width: 202px;
  border: none;
  border-radius: 6px;
  font-family: Formular;
  font-weight: 900;
  font-size: 18px;
  cursor: pointer;
  color: #007D8D;
`
  
export interface LeavingModalProps {
  onClose: () => void;
  onClickGetSolution: () => void;
}

export const LeavingModal: FC<LeavingModalProps> = ({onClose, onClickGetSolution}) => {
  const { screenWidth } = useDimensions()

  return (
    <BackDrop show onClick={onClose}>
      <ModalStyles onClick={(e) => {e.stopPropagation()}}>
        <CloseFeature>
          <IoMdClose
            size={30}
            onClick={onClose}
          />
        </CloseFeature>
        <TextContentWrapper>
          <Title
            fontName={"Exo2-Bold"}
            padding={"10px 0 0 0"}
            fSize={"25px"}
            lineHeight={"30px"}
            textTransform={"uppercase"}
            color={"FFFFFF"}
            align={screenWidth <= 676 ? "center" : ""}
          >
            Уже уходите,<br/>без подарка ?
          </Title>
          <ModalContent>
            <Proposal>
              Продолжите заполнение<br/>анкеты и получите<br/>гарантированный подарок !
            </Proposal>
            <GetSolutionBtn onClick={onClickGetSolution}>Продолжить заполнение</GetSolutionBtn>
          </ModalContent>
        </TextContentWrapper>
        <ImageWrapper>
          <Image src={presentBox}/>
        </ImageWrapper>
      </ModalStyles>
    </BackDrop>
  )
}

