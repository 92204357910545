import React from 'react';
import { FormikValues } from 'formik';
import { AdditionalData } from './SubPages/AdditionalData';
import {
  IAdditionalIncomes,
  IAdvancedOptions,
  IAdvancedOptions2,
  IOpfListRef,
  IProfileConfig2,
  IProfileState2,
} from '../../../redux/Credit/creditTypes';
import { ICompanyDadataList, IDadataAddressTypes, IFioDadataList } from '../../../redux/Application/appTypes';
import { WorkActivityFixd } from './SubPages/WorkActivityFixed';

export type TProfileSubPages =
	| "workActivity"
	| "additionalData"
	| "additionalInfo"
	| "finishForm";

interface IProfileMainBlock {
	standartFontSizeMeasures: string;
	standartHeightMeasures: string;
	standartWidthMeasures: string;
	setFormDataUpdates: (values: IProfileState2) => void;
	updateFormCallBack: (
		formItem: string,
		currentValue: string,
		event: FormikValues
	) => void;
	additionalIncomesRef: IAdvancedOptions[];
	otherIncomesRef: IAdvancedOptions[];
	screenWidth: number;
	formik: FormikValues;
	state: IProfileState2;
	profileConfig: IProfileConfig2;
	creditAmount: number;
	realEstateTypeOptions: string[];
	realEstateGettingWayOptions: string[];
	realEstatePledgedDocTypeOptions: string[];
	vehicleTypeOptions: string[];
	vehicleGettingWayOptions: string[];
	vehicleLabelOptions: string[];
	vehiclePledgedDocTypeOptions: string[];
	maritalStatusRef: IAdvancedOptions[];
	scanItLink: string;
	workPlaceAddressList: IDadataAddressTypes[];
	workPlaceLegalAddressList: IDadataAddressTypes[];
	additionalIncomesData: IAdditionalIncomes[];
	setAdditionalIncomesData: (
		additionalIncomesData: IAdditionalIncomes[]
	) => void;
	companyNamesDadataList: ICompanyDadataList[];
	companyInnDadataList: ICompanyDadataList[];
	occupationCompanyOpfRef: IOpfListRef[];
	contactsRelationRef: IAdvancedOptions[];
	employmentTypeRef: IAdvancedOptions[];
	fioList: IFioDadataList[];
	phoneValidationErrorMessage: string | null;
	product: string;
}

export const ProfileMainBlock: React.FC<IProfileMainBlock> = (props) => {

	const [subPage, setSubPage] = React.useState<TProfileSubPages>("workActivity");

	return (
		<>
			{subPage === "workActivity" && (
				<WorkActivityFixd {...props} setSubPage={setSubPage} />
			)}
			{subPage === "additionalData" && (
				<AdditionalData {...props} setSubPage={setSubPage} />
			)}
		</>
	);
};
