import { FormikValues } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { String } from '../String';

const FieldWrapper = styled.div`
	position: relative;
	width: 365px;
`;

const StyledPercentInput = styled.input`
	width: 365px;
	height: 71px;
	border: 1px solid #b3b5c4;
	border-radius: 4px;
	font-weight: 400;
	outline: none;
	font-size: 28px;
	line-height: 43px;
	font-family: "Formular";
	padding: 0 14px;
`;

const Tag = styled.div`
	position: absolute;
	font-size: 30px;
	top: 25%;
	right: 20px;
	color: #7e829b;
`;

interface IPercentInput {
	formik: FormikValues;
	fieldName: string;
    label?: string;
}

export const PercentInput: React.FC<IPercentInput> = (props) => {
	const { fieldName, formik, label } = props;
	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let regexp = /[^\d.]/ig;
		let value = e.target.value;
		value = e.target.value.replace(regexp, "");
		formik.setFieldValue(fieldName, value);
	};
	return (
		<>
            {label && <String fSize={"14px"}>{label}</String>}
			<FieldWrapper {...props}>
				<Tag>%</Tag>
				<StyledPercentInput
					onChange={onChangeHandler}
					value={formik.values[fieldName]}
				/>
			</FieldWrapper>
		</>
	);
};