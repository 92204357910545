import { Formik, FormikValues } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { getDadataPreviousPassportIssuer, onValidateParam } from "../../../../redux/Application/app-reducer";
import { setDocumentPreviousIssuanceCodesList, setPreviousPassportValidationErrorMessage } from "../../../../redux/Application/appActions";
import { IDadataTypes } from "../../../../redux/Application/appTypes";
import { IPassportState } from "../../../../redux/Credit/creditTypes";
import { adaptiveParams } from "../../../../styles/adaptiveParams";
import { AnimatedBlock } from "../../../../styles/UIKit/AnimatedBlock";
import { CustomMaskedField } from "../../../../styles/UIKit/CustomField/CustomMaskedField";
import { SuperPassport } from "../../../../styles/UIKit/dadata/SuperPassport";
import { CustomerCalendar } from "../../../../styles/UIKit/DatePicker/CustomerCalendar/CustomerCalendar";
import { Flex } from "../../../../styles/UIKit/Flex";
import { String } from "../../../../styles/UIKit/String";
import { dateIntoString } from "../../../../utils/commonFunctions";
import { onSetFieldBorderColor } from "../../../../utils/onSetFieldBorderColor";

interface IPreviousPassportData {
	userPassportData: FormikValues;
	screenWidth: number;
	updateFormCallBack: (formItem: string, currentValue: string, event: FormikValues) => void;
	setFormDataUpdates: (values: IPassportState) => void;
	standartFontSizeMeasures: string;
	standartHeightMeasures: string;
	previousDocumentIssuanceCodesList: IDadataTypes[];
	standartWidthMeasures: string;
	previousPassportValidationErrorMessage: string | null;
}

export const PreviousPassportData: React.FC<IPreviousPassportData> = (
	props
) => {
	const {
		userPassportData,
		screenWidth,
		updateFormCallBack,
		standartFontSizeMeasures,
		standartHeightMeasures,
		previousDocumentIssuanceCodesList,
		setFormDataUpdates,
		previousPassportValidationErrorMessage
	} = props;

	const dispatch = useDispatch();

	const { mainContentWidth } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;

	// *** HANDLERS FOR PREVIOUS PASSPORT DEPARTMENT CODE FIELD ***
	const onHandlePreviousPassportChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let value = e.currentTarget.value
		userPassportData.setFieldValue("previousPassportDepartmentCode", value)
		userPassportData.setFieldValue("previousPassportIssuedBy", null)
		dispatch(getDadataPreviousPassportIssuer(value));
	}
	const onHandlePreviousPassportBlur = () => {
		dispatch(setDocumentPreviousIssuanceCodesList([]));
		userPassportData.setFieldTouched("previousPassportDepartmentCode")
		userPassportData.setFieldTouched("previousPassportIssuedBy")
	}
	const onHandlePreviousPassportFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		event.target.setAttribute("autocomplete", "off");
		dispatch(getDadataPreviousPassportIssuer(userPassportData.values.previousPassportDepartmentCode));
	}
	const onChosePreviousPassportPosition = (option: IDadataTypes) => {
		userPassportData.setFieldValue("previousPassportDepartmentCode", option.code);
		userPassportData.setFieldValue("previousPassportIssuedBy", option.title);
		dispatch(setDocumentPreviousIssuanceCodesList([]));
	}

	const onHandlePreviousPassportSeriaAndNumberBlur = (formItem: string, currentValue: string, event: FormikValues) => {
		updateFormCallBack(formItem, currentValue, event);
		if (!!userPassportData.values.previousPassportSeria && !!userPassportData.values.previousPassportNumber) {
			dispatch(setPreviousPassportValidationErrorMessage(null));
			dispatch(onValidateParam({ paramValue: userPassportData.values.previousPassportSeria + " " + userPassportData.values.previousPassportNumber, searchParam: "previousPassport" }))
		}
	}

	const onFocusPreviousPassportSeriaAndNumberHandler = (formItem: string, currentValue: string, event: FormikValues) => {
		if (!!previousPassportValidationErrorMessage) {
			dispatch(setPreviousPassportValidationErrorMessage(null));
		}
	}

	const [previousPassportReceiptDate, setpreviousPassportReceiptDate] = React.useState<string>(
		userPassportData.values.previousPassportReceiptDate
			? dateIntoString(userPassportData.values.previousPassportReceiptDate as Date)
			: ""
	);

	return (
		<>
			<AnimatedBlock
				background={"#F0F2F6"}
				borderRadius={"6px"}
				padding={"20px"}
			>
				<String color={"#0A1244"} fSize={"24px"}>
					Предыдущие данные паспорта
				</String>{" "}
				<String color={"red"} fSize={"24px"}>
					*
				</String>
				<Flex margin="6px 0">
					<String fSize="14px">
						* обязательно к заполнению. если текущему паспорту менее
						2-х лет
					</String>
				</Flex>
				<Flex
					direction={fieldsWrapConditions ? "row" : "column"}
					width={"100%"}
					justify="space-between"
					margin="22px 0"
				>
					<CustomMaskedField
						width={fieldsWrapConditions ? "340px" : "100%"}
						height={standartHeightMeasures}
						fSize={standartFontSizeMeasures}
						backgroundColor="white"
						onBlurHandler={onHandlePreviousPassportSeriaAndNumberBlur}
						onFocusHandler={onFocusPreviousPassportSeriaAndNumberHandler}
						formItem={"previousPassportSeria"}
						{...userPassportData.getFieldProps("previousPassportSeria")}
						label={"Серия"}
						borderColor={previousPassportValidationErrorMessage && !userPassportData.errors.previousPassportNumber && !userPassportData.errors.previousPassportSeria ? "red" : onSetFieldBorderColor(userPassportData, "previousPassportSeria")}
						mask={[/[0-9\-\*]$/, /[0-9\-\*]$/, " ", /[0-9\-\*]$/, /[0-9\-\*]$/]}
						errorValue={userPassportData.touched.previousPassportSeria && (previousPassportValidationErrorMessage && !userPassportData.errors.previousPassportSeria && !userPassportData.errors.previousPassportNumber
							? previousPassportValidationErrorMessage
							: userPassportData.errors.previousPassportSeria || null)}
					/>
					<CustomMaskedField
						width={fieldsWrapConditions ? "340px" : "100%"}
						height={standartHeightMeasures}
						fSize={standartFontSizeMeasures}
						onBlurHandler={onHandlePreviousPassportSeriaAndNumberBlur}
						onFocusHandler={onFocusPreviousPassportSeriaAndNumberHandler}
						backgroundColor="white"
						formItem={"previousPassportNumber"}
						{...userPassportData.getFieldProps("previousPassportNumber")}
						label={"Номер"}
						borderColor={previousPassportValidationErrorMessage && !userPassportData.errors.previousPassportNumber && !userPassportData.errors.previousPassportSeria ? "red" : onSetFieldBorderColor(userPassportData, "previousPassportNumber")}
						mask={[/[0-9\-\*]$/, /[0-9\-\*]$/, /[0-9\-\*]$/, /[0-9\-\*]$/, /[0-9\-\*]$/, /[0-9\-\*]$/]}
						errorValue={userPassportData.touched.previousPassportNumber && (previousPassportValidationErrorMessage && !userPassportData.errors.previousPassportNumber && !userPassportData.errors.previousPassportSeria
							? previousPassportValidationErrorMessage
							: userPassportData.errors.previousPassportNumber || null)}
					/>
				</Flex>
				<Flex
					direction={fieldsWrapConditions ? "row" : "column"}
					width={"100%"}
					justify="space-between"
					margin="22px 0"
				>
					<CustomerCalendar
						updateFormHandler={setFormDataUpdates}
						width={fieldsWrapConditions ? "340px" : "100%"}
						height={standartHeightMeasures}
						fSize={standartFontSizeMeasures}
						label={"Дата выдачи"}
						formik={userPassportData}
						backgroundColor="white"
						formItem={"previousPassportReceiptDate"}
						value={userPassportData.values.previousPassportReceiptDate}
						maxDate={new Date()}
						minDate={userPassportData.values.birthDate || undefined}
						dateValue={previousPassportReceiptDate}
						setDateValue={setpreviousPassportReceiptDate}
						error={userPassportData.touched.previousPassportReceiptDate && !!userPassportData.errors.previousPassportReceiptDate}
						borderColor={onSetFieldBorderColor(userPassportData, "previousPassportReceiptDate")}
						errorValue={userPassportData.touched.previousPassportReceiptDate && userPassportData.errors.previousPassportReceiptDate}
					/>
				</Flex>
				<SuperPassport key={'previous'}
					formik={userPassportData}
					dadataList={previousDocumentIssuanceCodesList}
					width={"100%"}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					backgroundColor="white"
					codeProp={"previousPassportDepartmentCode"}
					issuedProp={"previousPassportIssuedBy"}
					onChange={onHandlePreviousPassportChange}
					onBlur={onHandlePreviousPassportBlur}
					onFocus={onHandlePreviousPassportFocus}
					onChoise={onChosePreviousPassportPosition}
					borderColor={onSetFieldBorderColor(userPassportData, "previousPassportIssuedBy")}
					errorValue={userPassportData.touched.previousPassportIssuedBy && userPassportData.errors.previousPassportIssuedBy}
				/>
			</AnimatedBlock>
		</>
	);
};