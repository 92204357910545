import { FormikValues, useFormik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import useFormSaver from '../../hooks/useFormSaver';
import { useProductSetter } from '../../hooks/useProductSetter';
import { ICompanyDadataList, IDadataAddressTypes, IFioDadataList, } from '../../redux/Application/appTypes';
import { postCurrentForm } from '../../redux/Credit/credit-reducer';
import { setProfileData } from '../../redux/Credit/creditActions';
import {
  IAdditionalIncomes,
  IAdvancedOptions,
  IOpfListRef,
  IProfileConfig2,
  IProfileState2,
} from '../../redux/Credit/creditTypes';
import { ROUTE_PATH } from '../../routesConfig';
import { MainBlockWrapper, SecondaryBlockWrapper, StyledPageContainer, } from '../CommonPagesStyles';
import { ProfileMainBlock } from './components/ProfileMainBlock';
import { ProfileSecondaryBlock } from './components/ProfileSecondaryBlock';
import { RkkNotificator } from './components/RkkNotificator';
import { profileValidatorNew } from './profileValidatorNew';
import { setFioList } from '../../redux/Application/appActions';
import {useSearchParams} from "react-router-dom";
import {buildFieldsObject} from "../../utils/getQueryParams";
import { useValidationScroll } from '../../hooks/useValidationScroll';

interface IProfile {
	standartFontSizeMeasures: string;
	standartHeightMeasures: string;
	standartWidthMeasures: string;
	screenWidth: number;
	flowId: string;
	state: IProfileState2;
	profileConfig: IProfileConfig2;
	creditAmount: number;
	realEstateTypeOptions: string[];
	realEstateGettingWayOptions: string[];
	realEstatePledgedDocTypeOptions: string[];
	vehicleTypeOptions: string[];
	vehicleGettingWayOptions: string[];
	vehicleLabelOptions: string[];
	vehiclePledgedDocTypeOptions: string[];
	maritalStatusRef: IAdvancedOptions[];
	scanItLink: string;
	workPlaceAddressList: IDadataAddressTypes[];
	workPlaceLegalAddressList: IDadataAddressTypes[];
	companyNamesDadataList: ICompanyDadataList[];
	companyInnDadataList: ICompanyDadataList[];
	product: string;
	occupationCompanyOpfRef: IOpfListRef[];
	contactsRelationRef: IAdvancedOptions[];
	employmentTypeRef: IAdvancedOptions[];
	fioList: IFioDadataList[];
	phoneValidationErrorMessage: string | null;
}

export const Profile: React.FC<IProfile> = (props) => {
	const { state, flowId, profileConfig, screenWidth, product, creditAmount } = props;
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	React.useEffect(() => {
		if (profileConfig.errorMessage) setShowRkkNotificator(true)
	}, [profileConfig.errorMessage])

	const [additionalIncomesData, setAdditionalIncomesData] = React.useState<IAdditionalIncomes[]>(state.additionalIncomes);
	const [showRkkNotificator, setShowRkkNotificator] = React.useState(true)

	const isAdditionalFields = creditAmount > profileConfig.thresholdAmount;

	const profileForm = useFormik<
		IProfileState2 & {
			phoneNumber: string;
		}
	>({
		initialValues: {
			...state,
			phoneNumber: profileConfig.phoneNumber,
			occupationCompanyIsActualMutchLegal: ('occupationCompanyIsActualMutchLegal' in state) ? state.occupationCompanyIsActualMutchLegal : false,
		},
		validate: (values) => profileValidatorNew(values, profileConfig.occupationCompanyOpfRef.map(el => el.code), isAdditionalFields),
		onSubmit: (values) => {
			window.scrollTo(0, 0);
			if (profileConfig.errorMessage) dispatch(setProfileData(undefined))
			const payload = {
				formId: ROUTE_PATH.PROFILE,
				state: {
					...values,
					additionalIncomes: additionalIncomesData.map((items) => {
						return {
							...items,
							occupationAdditionalIncomeValue: +items.occupationAdditionalIncomeValue.replace(/\s/g, ""),
						};
					}),
				},
			};
			flowId && dispatch(postCurrentForm(flowId, payload, product));
			dispatch(setFioList([]));
		},
		enableReinitialize: true,
	});
	useValidationScroll(profileForm, props.phoneValidationErrorMessage);

	useProductSetter(profileForm, product)

	const setFormDataUpdates = useFormSaver<IProfileState2>(
		state,
		"profile",
		flowId
	);
	const updateFormCallBack = (
		formItem: string,
		currentValue: string,
		event: FormikValues
	) => {
		setFormDataUpdates({
			...profileForm.values,
			[formItem]: currentValue,
		});
		profileForm.handleBlur(event);
	};

	React.useEffect(() => {
		setSearchParams(buildFieldsObject(profileForm.values));
	}, [profileForm.values]);

	return (
		<StyledPageContainer>
			{profileConfig.errorMessage && <RkkNotificator screenWidth={screenWidth} setShowRkkNotificator={setShowRkkNotificator} showRkkNotificator={showRkkNotificator} errorMessage={profileConfig.errorMessage}/>}
			<form onSubmit={profileForm.handleSubmit}>
				<MainBlockWrapper>
					<ProfileMainBlock
						{...props}
						otherIncomesRef={profileConfig.otherIncomesRef}
						additionalIncomesRef={profileConfig.additionalIncomesRef}
						setFormDataUpdates={setFormDataUpdates}
						updateFormCallBack={updateFormCallBack}
						formik={profileForm}
						additionalIncomesData={additionalIncomesData}
						setAdditionalIncomesData={setAdditionalIncomesData}
					/>
				</MainBlockWrapper>
			</form>
			<div style={{ padding: (!profileConfig.esiaUsage && profileForm.values.occupationDocConfirmationIncomes === 'StatementPFR') ? '0 0 342px 0' : 0, width: '440px', minHeight: '100%' }}>
				<SecondaryBlockWrapper>
					<ProfileSecondaryBlock screenWidth={screenWidth} product={product} flowId={flowId} userPhone={profileConfig.phoneNumber}/>
				</SecondaryBlockWrapper>
			</div>
		</StyledPageContainer>
	);
};
