import { instance } from './interceptors';

export class FileApiPassportService {
	addPassportFile(flowId: string, payload: any) {
		return instance.post(`document/${flowId}`, payload);
	}
	getDocumentList(flowId: string) {
		return instance.get(`document/${flowId}/`);
	}
	removeFileFromList(flowId: string, documentId: string) {
		return instance.delete(`document/${flowId}/${documentId}`);
	}
	getLoadedFile(flowId: string, documentId: string, param: "consent" | "content", download: string) {
		return instance.get(`document/${flowId}/${documentId}/${param}?download=${download}`);
	}
}