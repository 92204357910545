import { AxiosResponse } from 'axios';
import { ICommonFormData } from '../../redux/commonTypes';
import { ICreditInitState, ISubmitCreditConfig, } from '../../redux/Credit/creditTypes';
import { instance } from '../interceptors';
import { TCommonGetCurrentForm, TCommonPostCurrentForm } from './creditApiTypes';
import { mochedProfileData2 } from './mocks/mochProfile2';

export class CreditApi {
	getInitFormData(flowType: string, dto?: Object) {
		return instance.post<
			ICommonFormData<ISubmitCreditConfig, ICreditInitState>
		>(`/flows/${flowType}`, dto || {});
	}

	updateForm(flowId: string, payload: any) {
		return instance.put(`flow/${flowId}`, payload);
	}

	// universat method
	postFormData(flowId: string, payload: any) {
		return instance.post(`flow/${flowId}`, payload);
	}

	getPollingApi(flowId: string, at: number) {
		return instance.get(`flow/${flowId}?at=${at}`);
	}

	getProfileForm(flowId: string) {
		// return instance.post(`flow/${flowId}`, {})
		return Promise.resolve().then(() => {
			return mochedProfileData2;
		});
	}

	getCurrentForm(flowId: string) {
		return instance.get<TCommonGetCurrentForm>(`flow/${flowId}?at=111`);
	}

	// конечная отправка форм
	postCurrentForm(flowId: string, payload: TCommonPostCurrentForm) {
		return instance.post<
			TCommonPostCurrentForm,
			AxiosResponse<TCommonGetCurrentForm>
		>(`flow/${flowId}`, payload);
	}

	onCancelDeclaration(flowId: string, canceledFlowId: string) {
		return instance.get(`flow/${flowId}?at=111&cancel=${canceledFlowId}`)
	}
}