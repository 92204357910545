import React from 'react';
import { useDispatch } from 'react-redux';
import useDimensions from '../hooks/useDimensions';
import { PassportDataForm } from '../pages/PassportDataForm/PassportDataForm';
import { setCurrentForm } from '../redux/Application/appActions';
import { setCalculatedCreditData } from '../redux/Credit/creditActions';
import { IPassportConfig, IPassportState } from '../redux/Credit/creditTypes';
import { CreditCalculator } from '../utils/creditCalculator';

export const TestPassport = () => {

	const { screenWidth } = useDimensions();

  const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(
			setCurrentForm({
				product: "test_passport",
				formId: undefined,
				flowId: undefined,
			})
		);
		// flowId && dispatch(getCurrentForm(flowId));
	}, []);

  const order = {
    amount: 500000,
    term: 24,
    rate: 15,
    preferentialRate: 4,
    preferentialTerm: 1,
  }

  const flowId = "test"

  React.useEffect(() => {
		if (order && flowId) {
			const { amount, rate, term, preferentialRate, preferentialTerm } = order;
			const calculator = CreditCalculator.calculateCredit(amount, term / 12, preferentialRate, rate, preferentialTerm);
			dispatch(setCalculatedCreditData(calculator));
		}
	}, [order]);

  const tempState: IPassportState = {
    isRegistrationAddressEqualWithActual: true,
    previousPassportIssuedBy: null,
    previousPassportReceiptDate: null,
    actualAddress: null,
    // additionalIncome: null,
    agreements: [],
    birthDate: null,
    birthPlace: null,
    currentPassportDepartmentCode: null,
    currentPassportNumber: null,
    currentPassportSeria: null,
    gender: null,
    // isChangedFIO: false,
    // isForeignCitizenship: false,
    // isRegistrationAddressEqualWithActual: false,
    issuedBy: null,
    previousFio: null,
    previousPassportDepartmentCode: null,
    previousPassportNumber: null,
    previousPassportSeria: null,
    purpose: null,
    receiptDate: null,
    registrationAddress: null,
  }

  const tempConfig: IPassportConfig = {
    esiaenable: true,
    esiaUsage: true,
    passportFormFiles: [],
    agreements: [],
    loanPurposeRef: [],
    maxAllowedAge: 68,
    minAllowedAge: 21,
    mobileScanner: {scan: ""},
    order: {
      amount: 500000,
      term: 24,
      rate: 15,
      preferentialRate: 4,
      preferentialTerm: 1,
      // phoneNumber: "+7 (999) 777-7777",
      // fio: "Иванов Иван Иванович",
    },
    phoneNumber: "+7 (999) 777-7777",
    userFio: "Иванов Иван Иванович",
  }

  return <>
        <PassportDataForm
          fioList={[]}
          previousDocumentIssuanceCodesList={[]}
					product={"credits"}
					passportFiles={[]}
					passportFormDataState={tempState}
					config={tempConfig}
					flowId={"test"}
					screenWidth={screenWidth}
					registrationAddressList={[]}
					actualAddressList={[]}
					regionsList={[]}
					documentIssuanceCodesList={[]}
          currentPassportValidationErrorMessage={null}
          previousPassportValidationErrorMessage={null}
					citiesList={[]}
					mobileScanner={tempConfig.mobileScanner.scan}
					phoneNumber={"+7 (999) 777-7777"}
				/>

  </>
}
