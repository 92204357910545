import { FormikValues } from "formik";
import React from "react"
import styled, { keyframes } from "styled-components";
import useDimensions from "../../hooks/useDimensions";
import { adaptiveParams } from "../adaptiveParams";
import { FieldStyles, StyledFieldsProps } from "./CustomField/CustomFieldStyles";
import { HintedFieldWrapper } from "./dadata/HintedFieldWrapper";
import { Flex } from "./Flex"
import { String } from "./String";
import { StyledErrorNotificator } from "./StyledErrorNotificator";
import { calcErrorOffset } from '../../utils/calcErrorOffset';

interface IComboBox extends StyledFieldsProps {
	formik: FormikValues;
	errorValue?: string;
  options: any[];
  maxOptionsLength: number;
  formName: string;
	currentValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onMouseDownHandler: (chosenItem: string) => void;
  optionParam: string;
}

// select options window animation
const optionsWindowAnimation = keyframes`
    0% {opacity: 0;}
    100% {opacity: 1;}
`;

// modal with options to select
const OptionsWindow = styled.ul<{ maxHeight?: string }>`
	border-radius: 4px;
	position: absolute;
	width: 100%;
	margin-top: 3px;
	background: #ffffff;
	overflow-y: scroll;
	max-height: ${({ maxHeight }) => maxHeight || "400px"};
	opacity: 0;
	animation: ${optionsWindowAnimation} 0.4s forwards;
	box-shadow: 0px 0px 5px black;
	z-index: 2;
`;

// Each chosen option
const EachOption = styled.div`
	width: 100%;
	padding: 5px 10px;
	font-size: 20px;
	cursor: pointer;
	transition: background-color 0.1s;
	font-family: Formular;
	&:hover {
		background: #00cad6;
		color: white;
	}
`;

export const ComboBox: React.FC<IComboBox> = (props) => {
  const {
		formik,
    label,
		errorValue,
    options,
    maxOptionsLength,
    currentValue,
    formName,
    onChange,
    onMouseDownHandler,
    optionParam,
  } = props;

  const {screenWidth} = useDimensions()

	const { mainContentWidth } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;
  const Top = calcErrorOffset(screenWidth, fieldsWrapConditions);

  const [currentOptions, setCurrentOptions] = React.useState<any[]>(options);
  const [showOptions, setShowOptions] = React.useState(false)

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCurrentOptions(options.filter(el => el.abbr.toLowerCase().includes(value.toLowerCase()) || el.title.toLowerCase().includes(value.toLowerCase())))
    onChange(e)
  }

	React.useEffect(() => {
		if (currentOptions.length <= 0) {
			formik.setTouched({...formik.touched, [formName]: true});
		}
	}, [currentOptions]);

  return(
    <Flex direction="column" Position={"relative"} padding={fieldsWrapConditions ? "0" : "10px 0"}>
			{label && <String fSize={"14px"} margin="0 0 8px">{label}</String>}
			<HintedFieldWrapper>
        <FieldStyles
					{...props}
					name={formName}
					value={currentValue}
					onChange={onChangeHandler}
					onFocus={(event) => {
						event.target.setAttribute("autocomplete", "off");
            setShowOptions(true)
					}}
          onBlur={() => {
            setShowOptions(false)
						formik.setTouched({...formik.touched, [formName]: true})
          }}
				/>
        {showOptions && (
					<OptionsWindow>
						{currentOptions?.slice(0, maxOptionsLength)?.map((option, index) => {
							const choseOptionHandler = () => {
								onMouseDownHandler(option[optionParam]);
							};
							return (
								<EachOption key={index} onMouseDown={choseOptionHandler} >
									{option.title}
								</EachOption>
							);
						})}
					</OptionsWindow>
				)}
      </HintedFieldWrapper>
      {errorValue && (
				<StyledErrorNotificator Top={Top}>{errorValue}</StyledErrorNotificator>
			)}
    </Flex>
  )
}
