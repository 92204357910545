import { FormikValues } from 'formik';
import useDimensions from '../../../hooks/useDimensions';
import { adaptiveParams } from '../../adaptiveParams';
import { Flex } from '../Flex';
import { String } from '../String';
import { StyledErrorNotificator } from '../StyledErrorNotificator';
import { StyledFieldsProps } from './CustomFieldStyles';
import { StyledMaskedInput } from './TelephoneField';
import { calcErrorOffset } from '../../../utils/calcErrorOffset';
import { FormEvent } from "react";

interface ICustomMaskedField extends StyledFieldsProps {
	mask: string[];
	placeholder: string;
	onBlurHandler?: (formItem: string, currentValue: string, e: FormikValues) => void;
	formItem?: string;
	errorValue?: string;
	backgroundColor?: string;
	onFocusHandler?: () => void;
	onInput?: (e?: FormEvent<HTMLInputElement>) => void
}

export const CustomMaskedField: React.FC<ICustomMaskedField> = (props) => {
	const { label, subLabel, mask, onBlurHandler, formItem, errorValue, onFocusHandler, onInput } = props;
	const { screenWidth } = useDimensions();

	const { mainContentWidth } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;
	const Top = calcErrorOffset(screenWidth, fieldsWrapConditions);

	return (
		<Flex direction="column" Position="relative" padding={fieldsWrapConditions ? "0" : "10px 0"}>
			{label && <String fSize={"14px"} margin="0 0 8px">{label}</String>}
			<StyledMaskedInput
				screenWidth={screenWidth}
				type={"tel"}
				{...props}
				showMask={false}
				guide={false}
				mask={mask}
				onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
					event.target.setAttribute("autocomplete", "off");
					onFocusHandler && onFocusHandler()
				}}
				onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
					if (formItem && onBlurHandler) onBlurHandler(formItem, e.currentTarget.value, e);
				}}
				onInput={onInput}
			/>
			{subLabel && <String fSize={"12px"}>{subLabel}</String>}
			{errorValue && (
				<StyledErrorNotificator Top={Top}>{errorValue}</StyledErrorNotificator>
			)}
		</Flex>
	);
};