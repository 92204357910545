import axios from 'axios';
import { LoacalData } from './localStorage';

const loacalData = new LoacalData();

export const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

// Request interceptor
instance.interceptors.request.use((config) => {
	if (config.headers) {
		const accessToken = loacalData.getToken("accessToken");
		if (accessToken && accessToken !== "undefined") {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}
		return config;
	}
});


instance.interceptors.response.use(
	(config) => {
		return config;
	},
	async (error) => {
		if (error.response) {
			if (error.response.status === 401) {
				loacalData.removeToken("accessToken");
				loacalData.removeToken("refreshToken");
				window.location.replace(window.location.origin)
			}
		}
	}
);