import React, {FC, useEffect} from "react";
import { AiOutlineLoading } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useFingerPrint } from "../../hooks/useFingerPrint";
import { IFingerPrintData } from "../../redux/commonTypes";
import { getLoadedFile } from "../../redux/Credit/credit-reducer";
import { IConsents, ISignData, IValidationDto } from "../../redux/SignDocs/segn-docs-types";
import { setCallSignData, setConsents, setVaidationResult } from "../../redux/SignDocs/sign-docs-actions";
import {
	generateForm,
	validateSignature,
} from "../../redux/SignDocs/sign-docs-reducer";
import { AppRootStateType } from "../../redux/store";
import { Button } from "./Button";
import { Flex } from "./Flex";
import { AntifrodTimer } from "./Modal/components/AntifrodTimer";
import { CloseFeature, Modal } from "./Modal/Modal";
import { String } from "./String";
import { Title } from "./Title";
import { InputCode } from '../../components/InputCode/InputCode';
import { onDetectSafari } from "../../utils/onDetectSafari";
import {defaultTheme} from "../themes/themes";

export const StyledFileLoader = styled.a`
	color: ${defaultTheme.appColors.commonTheme};
	cursor: pointer;
  font-family: Formular;
`;

interface ISignDocumentModal {
	show: boolean;
	setShow: (show: boolean) => void;
	screenWidth: number;
	phoneNumber: string;
	smsCodeValue: string;
	setSmsCodeValue: (smsCodeValue: string) => void;
	flowId: string;
	fields: any;
	product: string;
}

const SignSocumentFormWrapper = styled.div`
	padding: 80px 10px;
`

const SignDocumentForm: FC<ISignDocumentModal> = (props) => {
	const {
		show,
		setShow,
		phoneNumber,
		screenWidth,
		smsCodeValue,
		setSmsCodeValue,
		flowId,
		fields,
		product,
	} = props;

	const [render, setRender] = React.useState(0);

	const dispatch = useDispatch();

	const reduxConsents = useSelector<AppRootStateType, IConsents | undefined>((state) => state.signDocsReducer.consents);
	const attempts = useSelector<AppRootStateType, number | undefined>((state) => state.signDocsReducer.validatedSignature?.result.attempts);
	const signed = useSelector<AppRootStateType, boolean | undefined>((state) => state.signDocsReducer.validatedSignature?.result.signed);
	const [consents, setInternalConsents] = React.useState<IConsents | undefined>(reduxConsents);

	React.useEffect(() => {
		if (reduxConsents) setInternalConsents(reduxConsents);
	}, [reduxConsents]);

	React.useEffect(() => {
		if (!show && consents) {
			dispatch(setConsents(undefined));
			setInternalConsents(undefined);
			dispatch(setCallSignData(undefined));
		}
	}, [show])

	const signData = useSelector<AppRootStateType, ISignData | undefined>(state => state.signDocsReducer.signData);

	const fingerprint: IFingerPrintData | null = useFingerPrint()

	const eol = signData?.result.eol

	const getFinishCallback = () => {
		dispatch(setCallSignData(undefined))
	}

	const validateDocs = () => {
		if (reduxConsents && consents) {
			const dto: IValidationDto = {
				fingerprint: fingerprint ? fingerprint.fingerprint : null,
				deviceModel: fingerprint ? fingerprint.deviceModel : null,
				code: smsCodeValue,
				docIds: [consents.documentId],
			};
			dispatch(validateSignature(flowId, dto, "passport", fields, product));
		}
	};

	const generateDocs = (first: boolean) => () => {
		const forceRender = (prev: number) => prev + 1;
		dispatch(generateForm(flowId));
		if (!first) setRender(forceRender);
	}

	const onAttemptsHandler = (attempts?: number) => {
		switch (true) {
			case attempts && attempts >= 0: return `Неверный смс-код, осталось ${attempts} попытки`
			case attempts && attempts === 0: return "Попыток не осталось"
			default: return "Попыток не осталось"
		}
	}

	const downloadFile = () => {
		if (consents) {
			dispatch(getLoadedFile(
				flowId,
				consents.documentId,
				consents.name,
				"content",
				onDetectSafari()
			));
		}
	};

	const codeLength = 6;

	useEffect(() => {
		if (smsCodeValue?.length < codeLength || attempts === 0) {
			return;
		}
		validateDocs();
	}, [smsCodeValue]);

	return <>
		<Title fSize="28px" margin="0 0 20px">Подтвердите оформление заявки</Title>
		<Flex >
			<String>
				Код подтверждения отправлен на номер смс
				<br />
				<String fontWeight color="black">{`${phoneNumber}`}</String>
			</String>
		</Flex>
		{consents &&
			<Flex justify="flex-start" margin="20px 0 20px" width="100%">
				<InputCode key={`${+show}${render}`}
									 onComplete={setSmsCodeValue} length={codeLength}
				/>
			</Flex>
		}
		{signed === false && <Flex justify="center" height="40px">
			<String color="red">{onAttemptsHandler(attempts || 0)}</String>
		</Flex>}
		{consents
			? <Flex
				direction="column"
				justify="space-between"
			>
				<Button
					type="button"
					mode="primary"
					onClick={generateDocs(false)}
					color={"#0A1244"}
					background="#F0F2F6"
					margin="0 0 10px"
					disabled={!!eol}
				>
					Получить код еще раз{" "}
					{eol && (
						<AntifrodTimer
							antifrodLife={Date.parse(eol)}
							getFinishCallback={getFinishCallback}
						/>
					)}
				</Button>
			</Flex>
			: <Flex>
				<Title fSize="14px">
					Идет загрузка...
				</Title>
			</Flex>}
		{consents && <Flex>
			<String>
				Соглашаюсь с{" "}
				<StyledFileLoader onClick={downloadFile}>
					условиями предоставления услуги
				</StyledFileLoader>
			</String>
		</Flex>}
	</>
}


export const SignDocumentModal: React.FC<ISignDocumentModal> = (props) => {
	const {
		show,
		setShow,
	} = props;

	return (
			<SignSocumentFormWrapper>
				<SignDocumentForm {...props}/>
			</SignSocumentFormWrapper>
	);
};
