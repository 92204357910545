import React from 'react';
import styled from 'styled-components';
import {adaptiveParams} from '../../adaptiveParams';
import {BackDrop} from "../BackDrop/BackDrop";
import {topFallAnimation} from "../Animations/Animations";

const ModalStyles = styled.div<ModalProps>`
	position: absolute;
	background: #ffffff;
	width: ${({ width }) => width || "auto"};
	max-height: ${({ height }) => height || "95%"};
	max-width: ${({ maxWidth }) => maxWidth || "95%"};
	padding: ${({ padding }) => padding || "40px"};
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 4px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 10px 40px rgba(0, 0, 0, 0.2);
	color: rgba(0, 0, 139, 0.7);
	
	animation: ${topFallAnimation};
  animation-duration: 2s;

	@media (max-width: ${adaptiveParams.mainContentWidth}px) {
		width: 100%;
	  padding: 20px;
	}
`;

export const CloseFeature = styled.div<ModalProps>`
	position: absolute;
	left: calc(100% + 15px);
	top: 0;
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 50%;
	background: #fff;
	color: #0A1244;
	@media(max-width: 750px) {
	  left: unset;
	  right: 15px;
	  top: -50px;
	  width: 40px;
	  height: 40px;
	}
`;

interface ModalProps {
	show: boolean;
	padding?: string;
	width?: string;
	maxWidth?: string;
	height?: string;
  closeButton?: any;
	onClickBackDrop?: () => void
}

export const Modal: React.FC<ModalProps> = (props) => {
	const { show, onClickBackDrop } = props;
	return (
		<BackDrop show={show} onClick={() => onClickBackDrop && onClickBackDrop()}>
      <ModalStyles {...props} />
    </BackDrop>
	);
};