import React from 'react';
import { useDispatch } from 'react-redux';
import { updateCurrentForm } from '../redux/Credit/credit-reducer';

const useFormSaver = <T>(formState: T, formId: string, flowId: string) => {
	const dispatch = useDispatch();
	const [state, setFormDataUpdates] = React.useState<T>(formState);
	React.useEffect(() => {
		dispatch(
			updateCurrentForm(flowId, {
				formId,
				state,
			})
		);
	}, [state]);
	return setFormDataUpdates;
};

export default useFormSaver;