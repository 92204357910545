import { Button } from '../../../styles/UIKit/Button';
import { Flex } from '../../../styles/UIKit/Flex';
import { Modal } from '../../../styles/UIKit/Modal/Modal';
import { Title } from '../../../styles/UIKit/Title';
import { InputCode } from '../../../components/InputCode/InputCode';
import React from 'react';
import { String } from '../../../styles/UIKit/String';

interface ISmsAuth {
	customerName: string;
	smsCodeValue: string;
	setSmsCodeValue: (smsCodeValue: string) => void;
	sendCodeHandler: () => void;
	show: boolean;
	setShow: (show: boolean) => void;
	screenWidth: number;
}

export const SmsAuth: React.FC<ISmsAuth> = ({
	customerName,
	smsCodeValue,
	setSmsCodeValue,
	sendCodeHandler,
	show,
	setShow,
	screenWidth,
}) => {
	return (
		<Modal show={show}>
			{<Title>Добро пожаловать {customerName}</Title>}
      <String>
        Код из смс
      </String>
      <InputCode key={+show}
        onComplete={setSmsCodeValue} length={6}
      />
			{/* <Flex justify="center" height="40px">
        {smsAuthError && <Title color="red">{smsAuthError}</Title>}
    </Flex> */}
			<Flex direction="row" margin="50px 0 0 0" justify="center">
				<Button
					type="submit"
          padding="18px 41px"
					mode={"primary"}
					onClick={sendCodeHandler}
					disabled={smsCodeValue.length < 6}
				>
					Отправить
				</Button>
			</Flex>
		</Modal>
	);
};