import styled from 'styled-components';
import { adaptiveParams } from '../styles/adaptiveParams';

export const StyledPageContainer = styled.div<{ background?: string, padding?: string }>`
	padding: ${({padding}) => padding || "150px 0 0 0"};
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	background: ${({ background }) => background || "#FFFFFF"};
`;

export const MainBlockWrapper = styled.div`
	max-width: ${adaptiveParams.mainContentWidth}px;
	margin: 0 25px 50px 0;
	@media (max-width: ${adaptiveParams.standartFormWidthFirstValue}px) {
		margin: 0 0 50px 0;
	}
	@media (min-width: ${adaptiveParams.mainContentWidth}px) {
		width: 750px;
	}
`;

export const SecondaryBlockWrapper = styled.div`
	width: 440px;
  padding: 80px 0 50px;
	height: 100%;
	// border: 1px solid red;
	margin: 0 0 0 25px;
	@media (max-width: ${adaptiveParams.standartFormWidthFirstValue}px) {
		max-width: ${adaptiveParams.mainContentWidth}px;
		margin: 25px 0 0 0;
	}
`;

type TParagraphWrapper = {
	padding?: string;
	width?: string;
	margin?: string;
	background?: string;
	borderRadius?: string;
};

export const ParagraphWrapper = styled.div<TParagraphWrapper>`
	padding: ${({ padding }) => padding || "16px 0"};
	width: ${({ width }) => width || "auto"};
	margin: ${({ margin }) => margin || "auto"};
	background: ${({ background }) => background || "transparent"};
	border-radius: ${({ borderRadius }) => borderRadius || "0"};
`;