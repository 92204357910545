import styled from 'styled-components';

interface IWrapper {
    Padding?: string
    Margin?: string
    Background?: string
    Border?: string
    BorderRadius?: string
    HoveredBackground?: string
}
export const Wrapper = styled.div<IWrapper>`
    padding: ${({Padding}) => Padding || "0"};
    margin: ${({Margin}) => Margin || "0"};
    border: ${({Border}) => Border || "none"};
    border-radius: ${({BorderRadius}) => BorderRadius || "0"};
    background: ${({Background}) => Background || "none"};
    width: 750px;
    max-width: 100%;
    &:hover {
        transition: .5s background;
        -webkit-transition: .5s background;
        -moz-transition: .5s background;
        background: ${({HoveredBackground}) => HoveredBackground};
    }
`;