import React from 'react';
import {Flex} from '../../styles/UIKit/Flex';
import {useDispatch} from 'react-redux';
import {setCurrentForm} from '../../redux/Application/appActions';
import {useParams} from 'react-router-dom';
import {setConsiderations} from '../../redux/Credit/creditActions';
import {SuccessBlock} from '../Cabinet/components/SuccessBlock/SuccessBlock';
import {TProductTypes} from '../../redux/commonTypes';
import {ROUTE_PATH} from '../../routesConfig';
import {getCurrentForm} from '../../redux/Credit/credit-reducer';
import {useRefreshToken} from '../../hooks/useRefreshToken';
import {STAGES, usePixel} from "../../hooks/usePixel";
import { PresentBlock } from '../PromoPresent/PresentBlock/PresentBlock';

export const Consideration = ({ product } : { product: string }) => {
	const dispatch = useDispatch();
	const paramsFlowId = useParams().flowId;
	const flowId = paramsFlowId || localStorage.getItem("flowId") || undefined;


	React.useEffect(() => {
		if (flowId) {
			dispatch(setCurrentForm({ flowId, formId: ROUTE_PATH.IN_PROGRESS_FULL, product: product as TProductTypes }))
			dispatch(getCurrentForm(flowId, product));
		}
	}, [dispatch, flowId])

	React.useEffect(() => {
		return () => {
			dispatch(setConsiderations(undefined));
		}
	}, [dispatch]);

	const onGetOrders = () => {
		dispatch(
			setCurrentForm({
				product: undefined,
				formId: ROUTE_PATH.ORDERS,
				flowId: undefined,
			})
		);
	}

	useRefreshToken(flowId, product);

	return (
			<Flex direction="column" justify="center" height="100vh">
			<SuccessBlock onGetOrders={onGetOrders}/>
			<PresentBlock/>
		</Flex>
	);
};