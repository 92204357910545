import React from 'react';
import styled from 'styled-components';

type Props = {
	width: string;
	src: string;
	margin?: string;
	padding?: string;
	cursor?: string;
	onClick?: () => void;
};

const ImageStyled = styled.img<Props>`
	width: ${(props) => props.width};
	// height: ${(props) => props.width};
	margin: ${(props) => props.margin};
	padding: ${(props) => props.padding};
	cursor: ${(props) => props.cursor || "default"};
`;

export const Image: React.FC<Props> = (props) => {
	return <ImageStyled {...props} />;
};