import { BsFillCalendarWeekFill } from 'react-icons/bs';
import styled from 'styled-components';

interface ICalendar {
	size: number;
	color?: string;
	onClick?: () => void;
	position?: string;
}

const StyledCalendar = styled(BsFillCalendarWeekFill)<ICalendar>`
	position: ${({ position }) => position || "absolute"};
	right: 5px;
	top: 50%;
	// transform: translate(-50%, -50%);
	transform: ${({position}) => position ? "none" : "translate(-50%, -50%)"};
	color: ${({ color, theme }) => color || theme.appColors.commonTheme};
	// pointer-events: none;
	cursor: pointer;
`;

export const CalendarIcon: React.FC<ICalendar> = (props) => {
	return <StyledCalendar {...props} />;
};