import { adaptiveMeasurements } from "../../../styles/adaptiveParams";
import { Button } from "../../../styles/UIKit/Button";
import { Flex } from "../../../styles/UIKit/Flex";
import { Modal } from "../../../styles/UIKit/Modal/Modal"
import { Title } from "../../../styles/UIKit/Title"

export const RkkNotificator: React.FC<{
  errorMessage: string;
  screenWidth: number;
  showRkkNotificator: boolean;
  setShowRkkNotificator: (showRkkNotificator: boolean) => void;
}> = ({
  errorMessage,
  screenWidth,
  showRkkNotificator,
  setShowRkkNotificator,

}) => {

  const fSize = adaptiveMeasurements(screenWidth, "25px", "20px", "18px", "16px");

  const standartHeightMeasures = adaptiveMeasurements(screenWidth, "50px", "46px", "42px", "40px");
	const standartWidthMeasures = adaptiveMeasurements(screenWidth, "360px", "360px", "100%", "100%");

  const onHandleCloseModal = () => setShowRkkNotificator(false)

  return <Modal show={showRkkNotificator}>
    <Title fSize={fSize}>{errorMessage}</Title>
    <Flex justify="center" margin="40px 0 0 0">
      <Button mode="outlined" 
        width={standartWidthMeasures} 
        height={standartHeightMeasures} 
        onClick={onHandleCloseModal}>
          Понятно
      </Button>
    </Flex>
  </Modal>
}