import { FormikValues } from 'formik';
import { TCurrentInfo } from '../components/SubPages/AdditionalInfo';

export const translator = (text: TCurrentInfo): string => {
    switch(text) {
        // case "idle":
        //     return ""
        case "realEstate":
            return "Объект недвижимости"
        case "vehicle":
            return "Транспортное средство"
        default: return "Выберите"
    }
}

export const setCorrectHeaderInAccordanceWithCosenPosition = (
    chosenPosition: string
) => {
    switch (chosenPosition) {
        case "Работа по найму":
            return "Работодатель";
        case "Индивидуальный предприниматель":
            return "Предприниматель";
        case "Собственный бизнес":
            return "Бизнесмен";
        case "Прочий доход":
            return "Заполните поля";
        default:
            return "Укажите тип деятельности";
    }
};

export const workActivityFieldsList = [
    "occupationStartWorkDate",
	"occupationMonthlyIncomesValue",
	// "occupationCompanyLegalForm",
	"occupationCompanyCodeOKOPF",
	"occupationCompanyInn",
	"occupationCompanyAddress",
	"occupationCompanyLegalAddress",
	"occupationCompanyName",
	"occupationPost",
	"occupationPhoneNumber",
	"occupationCategory",
	"occupationCompanyName",
	"occupationCompanyOgrn",
	"occupationCompanyKpp",
	"occupationOwnBusinessShare",
	// "occupationCompanyOpf",
	"occupationIncomesKind",
	"occupationDocConfirmationIncomes",
    "isReadyConfirmIncomes",
    "isGettingSalaryOnCard",
]

export const additionalDataFieldsList = [
    "additionalInfoMaritalStatus",
    // "additionalInfoSnils",
    "contactsPhone"
]

export const onCheckPartOfFields = (formik: FormikValues, fields: string[]) => {
    let result: string[] = []
    fields.forEach((el: string) => {
        if (formik.errors[el]) {
            result = [...result, el]
        }
    })
    return result
}