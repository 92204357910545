import {useEffect, useState} from "react";
import {pushDataLayerEvent} from "../utils/dataLayer";
import {goToTheQuestionnaire} from "../utils/goToTheQuestionnaire";

export interface useLeavingThePageProps {
  leavingZoneSize?: number;
  isMobile: boolean;
}

export type LeavingModalState = {
  show: boolean;
  already: boolean;
}

let prevPoint: number | null = null;

export const useLeavingModal = ({leavingZoneSize = 10, isMobile}: useLeavingThePageProps) => {
  const [leavingModalState, setLeavingModalState] = useState<LeavingModalState>({
    show: false,
    already: false,
  })

  const [_, setSecondDataLayerAction] = useState<string>("")
  const addDataLayerAction = (action: string) => {
    setSecondDataLayerAction((prevState) => {
      if (action !== prevState && !(action === "notshow" && prevState === "show")) {
        pushDataLayerEvent("widgets", {
          category: "marketing",
          action,
          label: "backform",
        })
      }
      return action
    })
  }

  const onLeavingHandler = () => {
    setLeavingModalState(prevState => {
      if (!prevState.show && !prevState.already) {
        addDataLayerAction("show")
        return {show: true, already: true};
      } else {
        addDataLayerAction("notshow")
        return prevState;
      }
    });
  }

  const closeLeavingModal = () => {
    setLeavingModalState({show: false, already: true});
    addDataLayerAction("close")
  }

  const onClickGetSolution = () => {
    setLeavingModalState({show: false, already: true});
    goToTheQuestionnaire();
    addDataLayerAction("click_button")
  }

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      const {clientY} = event;
      if ( !isMobile && leavingZoneSize && clientY <= leavingZoneSize && prevPoint !== null && prevPoint > clientY ) {
        onLeavingHandler()
      }
      prevPoint = clientY
    };
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [leavingZoneSize]);

  return {
    leavingModalState,
    closeLeavingModal,
    onClickGetSolution
  }
}