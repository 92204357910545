import { IGenerate, IValidate, TUserStatus } from './authTypes';

export const SET_USER_STATUS = "auth/SET_USER_STATUS";
export const SET_GENERATED_SMS_DATA = "auth/SET_GENERATED_SMS_DATA";
export const SET_VALIDATED_SMS_DATA = "auth/SET_VALIDATED_SMS_DATA";
export const SET_ANTIFRD_LIFE = "auth/SET_ANTIFRD_LIFE";

export const setUserStatus = (status: TUserStatus) => {
	return { type: SET_USER_STATUS, status } as const;
};

export const setGeneratedSmsData = (data: IGenerate | undefined) => {
	return { type: SET_GENERATED_SMS_DATA, data } as const;
};

export const setValidateSmsData = (data: IValidate | undefined) => {
	return { type: SET_VALIDATED_SMS_DATA, data } as const;
};

export const setAntifrodTimer = (value: number | undefined) => {
	return { type: SET_ANTIFRD_LIFE, value } as const;
};