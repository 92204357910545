import {useEffect} from "react";

export enum PIXEL_VENDOR_NAMES {
  bankiRu = "Banki_ru",
  cpahub = "cpahub",
  guruleads = "guruleads",
  cityads = "cityads",
  leads = "leads",
  mastertarget = "mastertarget",
  leadgid = 'leadgid',
  rafinad = 'rafinad',
  xpartners = 'xpartners',
  click2money = 'click2money',
  vbr = 'vbr',
  bankiros = 'bankiros',
  unicom24 = 'unicom24',
  SravniRu = 'Sravni_ru',
}

export enum STAGES {
  start = 'start',
  purchased = 'purchased',
}

enum PRODUCTS {
  credits = "credits",
  cards = "cards",
}

export enum PIXEL_TYPES {
  bankiRuCardsPurchased = "bankiRuCardsPurchased",
  bankiRuCreditsPurchased = "bankiRuCreditsPurchased",

  leadsCardsPurchased = 'leadsCardsPurchased',
  leadsCreditsPurchased = 'leadsCreditsPurchased',

  mastertargetCardsPurchased = 'mastertargetCardsPurchased',
  mastertargetCreditsPurchased = 'mastertargetCreditsPurchased',

  cpahubCardsPurchased = 'cpahubCardsPurchased',
  cpahubCreditsPurchased = 'cpahubCreditsPurchased',

  guruleadsCardsPurchased = 'guruleadsCardsPurchased',
  guruleadsCreditsPurchased = 'guruleadsCreditsPurchased',

  leadgidCardsPurchased = 'leadgidCardsPurchased',
  leadgidCreditsPurchased = 'leadgidCreditsPurchased',

  cityadsCardsPurchased = 'сityadsCardsPurchased',
  cityadsCreditsPurchased = 'сityadsCreditsPurchased',

  rafinadCardsPurchased = 'rafinadCardsPurchased',
  rafinadCreditsPurchased = 'rafinadCreditsPurchased',

  xpartnersCardsPurchased = 'xpartnersCardsPurchased',
  xpartnersCreditsPurchased = 'xpartnersCreditsPurchased',

  click2moneyCardsPurchased = 'click2moneyCardsPurchased',
  click2moneyCreditsPurchased = 'click2moneyCreditsPurchased',

  vbrCardsPurchased = 'vbrCardsPurchased',
  vbrCreditsPurchased = 'vbrCreditsPurchased',

  bankirosCardsPurchased = 'bankirosCardsPurchased',
  bankirosCreditsPurchased = 'bankirosCreditsPurchased',

  unicom24CardsPurchased = 'unicom24CardsPurchased',
  unicom24CreditsPurchased = 'unicom24CreditsPurchased',

  sravniRuCardsPurchased = 'sravniRuCardsPurchased',
}

const createPixelTag = (teg: string, attributes: Record<string, string> = {}) => {
  const pixel = document.createElement(teg)
  Object.entries(attributes).forEach(([name, value]) => {
    pixel.setAttribute(name, value)
  })
  return pixel
}

const getPixelTags = (pixelType: PIXEL_TYPES, flowId: string, transactionId: string, clickId: string): HTMLElement[] => {
  switch (pixelType) {
    // Банки.ру
    case PIXEL_TYPES.bankiRuCardsPurchased:
      return [createPixelTag('img', {
        width: '1',
        height: '1',
        src: ` https://tracking.banki.ru/SL5k?adv_sub=${flowId}`,
      })];
    case PIXEL_TYPES.bankiRuCreditsPurchased:
      return [createPixelTag('img', {
        width: '1',
        height: '1',
        src: ` https://tracking.banki.ru/SL5k?adv_sub=${flowId}`,
      })];

    //Leads
    case PIXEL_TYPES.leadsCreditsPurchased:
      return [
        createPixelTag('iframe', {
          width: '1',
          height: '1',
          border: '0',
          src: `https://pxl.leads.su/pixel/57f09c7806fec790e486011963897c4e?adv_sub=${flowId}&transaction_id=${clickId}`
        }),
        createPixelTag('iframe', {
          width: '1',
          height: '1',
          border: '0',
          src: `https://trkleads.ru/pixel/57f09c7806fec790e486011963897c4e?adv_sub=${flowId}&transaction_id=${clickId}`
        })
      ];
    case PIXEL_TYPES.leadsCardsPurchased:
      return [
        createPixelTag('iframe', {
          width: '1',
          height: '1',
          border: '0',
          src: `https://pxl.leads.su/pixel/739da9063705376ef5f83ad046d0d38f?adv_sub=${flowId}&transaction_id=${clickId}`
        }),
        createPixelTag('iframe', {
          width: '1',
          height: '1',
          border: '0',
          src: `https://trkleads.ru/pixel/739da9063705376ef5f83ad046d0d38f?adv_sub=${flowId}&transaction_id=${clickId}`
        })
      ]

    // Mastertarget
    case PIXEL_TYPES.mastertargetCardsPurchased:
      return [createPixelTag('img', {
        width: '1',
        height: '1',
        src: `https://mastertarget.ru/scripts/sale.php?AccountId=d6611774&TotalCost=0&OrderID=${flowId}&ProductID=sale&CampaignID=b4a6e748`,
      })];
    case PIXEL_TYPES.mastertargetCreditsPurchased:
      return [createPixelTag('img', {
        width: '1',
        height: '1',
        src: `https://mastertarget.ru/scripts/sale.php?AccountId=d6611774&TotalCost=0&OrderID=${flowId}&ProductID=sale&CampaignID=881f4ea4`,
      })];

    // Cpahub
    case PIXEL_TYPES.cpahubCardsPurchased:
      return [createPixelTag('iframe', {
        width: '1',
        height: '1',
        scrolling: "no",
        frameborder: "0",
        src: `https://zenit.cpahub.ru/SL3M?adv_sub=${flowId}&transaction_id=${clickId}`,
      })];
    case PIXEL_TYPES.cpahubCreditsPurchased:
      return [createPixelTag('iframe', {
        width: '1',
        height: '1',
        scrolling: "no",
        frameborder: "0",
        src: `https://zenit.cpahub.ru/SL1hmT?adv_sub=${flowId}&transaction_id=${clickId}`,
      })];

    // Guruleads
    case PIXEL_TYPES.guruleadsCardsPurchased:
      return [createPixelTag('img', {
        width: '1',
        height: '1',
        alt: "",
        src: `https://offers.guruleads.ru/conversion/444.pxl?action_id=${flowId}&goal=loan`,
      })];
    case PIXEL_TYPES.guruleadsCreditsPurchased:
      return [createPixelTag('img', {
        width: '1',
        height: '1',
        alt: "",
        src: `https://offers.guruleads.ru/conversion/268.pxl?action_id=${flowId}&goal=loan`,
      })];

    // Leadgid
    case PIXEL_TYPES.leadgidCardsPurchased:
      return [createPixelTag('img', {
        width: '1',
        height: '1',
        src: `https://go.leadgid.ru/aff_l?offer_id=6440&adv_sub=${flowId}`,
      })];
    case PIXEL_TYPES.leadgidCreditsPurchased:
      return [createPixelTag('img', {
        width: '1',
        height: '1',
        src: `https://go.leadgid.ru/aff_l?offer_id=6439&adv_sub=${flowId}`,
      })];

    // CityAds
    case PIXEL_TYPES.cityadsCardsPurchased:
      return [createPixelTag('iframe',{
        width: '1',
        height: '1',
        src:`https://cityadstrack.com/tr/xframe/${flowId}/ct/q1/c/37540?click_id=${clickId}`,
      })]
    case PIXEL_TYPES.cityadsCreditsPurchased:
      return [createPixelTag('iframe',{
        width: '1',
        height: '1',
        src:`https://cityadstrack.com/tr/xframe/${flowId}/ct/q1/c/37532?click_id=${clickId}`,
      })]

    // Rafinad
    case PIXEL_TYPES.rafinadCreditsPurchased:
      return [createPixelTag('img',{
        src:`https://rfndtrk.com/p/?target=mmmmm9myht&clickid=${clickId}&order_id=${flowId}`,
      })]
    case PIXEL_TYPES.rafinadCardsPurchased:
      return [createPixelTag('img',{
        src:`https://rfndtrk.com/g/?type=lead&clickid=${clickId}&order_id=${flowId}`,
      })]

    // XPartners
    case PIXEL_TYPES.xpartnersCreditsPurchased:
      return [createPixelTag('iframe',{
        width: '1',
        height: '1',
        src:`https://trk.xpgoal.io/success.php?offer_id=1060&afsecure=940e8581c96e97138a77918728e97619&afid=${flowId}&afstatus=2&`,
      })]
    case PIXEL_TYPES.xpartnersCardsPurchased:
      return [createPixelTag('iframe',{
        width: '1',
        height: '1',
        src:`https://trk.xpgoal.io/success.php?offer_id=1061&afsecure=940e8581c96e97138a77918728e97619&afid=${flowId}&afstatus=2`,
      })]

    // Click2Money
    case PIXEL_TYPES.click2moneyCreditsPurchased:
      return [createPixelTag('img',{
        src:`https://c2mpbtrck.com/cpaCallback?cid=${clickId}&partner=zenit_cashcredit&action=hold&lead_id=${flowId}`,
        style:"height:1px;width:1px;opacity: 0",
        title:"img examle",
      })]
    case PIXEL_TYPES.click2moneyCardsPurchased:
      return [createPixelTag('img',{
        src:`https://c2mpbtrck.com/cpaCallback?cid=${clickId}&partner=zenit_creditcard&action=hold&lead_id=${flowId}`,
        style:"height:1px;width:1px;opacity: 0",
        title:"img examle",
      })]

    // Выбери.ру
    case PIXEL_TYPES.vbrCreditsPurchased:
      return [createPixelTag('iframe',{
        src:`https://adv.vbr.ru/api/v2/zeropixels/657acd3cd1489cc96c900e92/frame?coid=${flowId}`,
        scrolling:"no",
        frameborder:"0",
        width:"1",
        height:"1",
      })]
    case PIXEL_TYPES.vbrCardsPurchased:
      return [createPixelTag('iframe',{
        src:`https://adv.vbr.ru/api/v2/zeropixels/657acdc1a31ba43b6cbc7923/frame?coid=${flowId}`,
        scrolling:"no",
        frameborder:"0",
        width:"1",
        height:"1",
      })]

    // Bankiros
    case PIXEL_TYPES.bankirosCreditsPurchased:
      return [createPixelTag('img',{
        src:`https://tracker.myfin.group/api/orders/zenit_1078_cps?aff=${clickId}&type=img&conversion=${flowId}&status=0`,
        width:"1",
        height:"1",
      })]
    case PIXEL_TYPES.bankirosCardsPurchased:
      return [createPixelTag('img',{
        src:`https://tracker.myfin.group/api/orders/zenit_1079_cps?aff=${clickId}&type=img&conversion=${flowId}&status=0`,
        width:"1",
        height:"1",
      })]

    // Unicom 24
    case PIXEL_TYPES.unicom24CreditsPurchased:
      return [createPixelTag('img',{
        src:`https://unicom24.ru/api/offer/pixel/?hash=${clickId}&status=receive&external_id=${flowId}`,
        height:"1",
        width:"1",
        alt:"",
      })]
    case PIXEL_TYPES.unicom24CardsPurchased:
      return [createPixelTag('img',{
        src:`https://unicom24.ru/api/offer/pixel/?hash=${clickId}&status=receive&external_id=${flowId}`,
        height:"1",
        width:"1",
        alt:"",
      })]

    // Сравни.ру
    case PIXEL_TYPES.sravniRuCardsPurchased:
      return [createPixelTag('img',{
        src: `https://sravni.go2cloud.org/aff_l?offer_id=2230&adv_sub=${flowId}`,
        height:"1",
        width:"1",
      })]

    default:
      return []
  }
}

const appendPixelTags = ({pixelType, flowId, transactionId, clickId}: {
  pixelType: PIXEL_TYPES
  flowId: string
  transactionId: string
  clickId: string
}) => {
  const head = document.head;
  const pixelTags = getPixelTags(pixelType, flowId, transactionId, clickId);
  // console.log('! append pixel ' + pixelType)
  pixelTags.forEach(tag => {
    head.append(tag)
  })
}

const getCurrenPixelType = (
  stage: STAGES,
  product: string,
  pixelVendor?: string,
) => {
  // if (stage === STAGES.start) {}

  if (stage === STAGES.purchased) {
    if (product === PRODUCTS.credits) {
      if (pixelVendor === PIXEL_VENDOR_NAMES.bankiRu) return PIXEL_TYPES.bankiRuCreditsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.cpahub) return PIXEL_TYPES.cpahubCreditsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.guruleads) return PIXEL_TYPES.guruleadsCreditsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.cityads) return PIXEL_TYPES.cityadsCreditsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.leads) return PIXEL_TYPES.leadsCreditsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.mastertarget) return PIXEL_TYPES.mastertargetCreditsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.leadgid) return PIXEL_TYPES.leadgidCreditsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.rafinad) return PIXEL_TYPES.rafinadCreditsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.xpartners) return PIXEL_TYPES.xpartnersCreditsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.click2money) return PIXEL_TYPES.click2moneyCreditsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.vbr) return PIXEL_TYPES.vbrCreditsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.bankiros) return PIXEL_TYPES.bankirosCreditsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.unicom24) return PIXEL_TYPES.unicom24CreditsPurchased;
    }
    if (product === PRODUCTS.cards) {
      if (pixelVendor === PIXEL_VENDOR_NAMES.bankiRu) return PIXEL_TYPES.bankiRuCardsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.cpahub) return PIXEL_TYPES.cpahubCardsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.guruleads) return PIXEL_TYPES.guruleadsCardsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.cityads) return PIXEL_TYPES.cityadsCardsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.leads) return PIXEL_TYPES.leadsCardsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.mastertarget) return PIXEL_TYPES.mastertargetCardsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.leadgid) return PIXEL_TYPES.leadgidCardsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.rafinad) return PIXEL_TYPES.rafinadCardsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.xpartners) return PIXEL_TYPES.xpartnersCardsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.click2money) return PIXEL_TYPES.click2moneyCardsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.vbr) return PIXEL_TYPES.vbrCardsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.bankiros) return PIXEL_TYPES.bankirosCardsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.unicom24) return PIXEL_TYPES.unicom24CardsPurchased;
      if (pixelVendor === PIXEL_VENDOR_NAMES.SravniRu) return PIXEL_TYPES.sravniRuCardsPurchased;
    }
  }
  return undefined
}

const getDecisionAddingPixel = ({product, stage, flowId, pixelVendor}:
  {
    product: string,
    stage: STAGES,
    flowId: string,
    pixelVendor: string,
  }) => {
  if(!product || !stage || !flowId || !pixelVendor) return false;

  const completedPixelStage = localStorage.getItem('pixelStage') || '';

  if(stage === STAGES.start && stage !== completedPixelStage) {
    localStorage.setItem('pixelStage', stage);
    return true;
  }

  if(stage === STAGES.purchased && stage !== completedPixelStage) {
    localStorage.removeItem('pixelStage');
    clearPixelData();
    return true;
  }

  return false
}

const savePixelData = ({
   searchParamsPixelVendor = '',
   searchParamsTransactionId = '',
   searchParamsClickId = '',
 }) => {
  localStorage.setItem('pixelVendor', searchParamsPixelVendor);
  localStorage.setItem('transactionId', searchParamsTransactionId);
  localStorage.setItem('clickId', searchParamsClickId);

  const currentDate = new Date();
  const expiredPixelDate = currentDate.setDate(currentDate.getDate() + 30)
  localStorage.setItem('expiredPixelDate', expiredPixelDate.toString())
}

const clearPixelData = () => {
  localStorage.removeItem('pixelVendor' );
  localStorage.removeItem('transactionId' );
  localStorage.removeItem('clickId' );
  localStorage.removeItem('expiredPixelDate' )
  localStorage.removeItem('pixelStage' )
}

const checkActualOfDataInLocalStorage = () => {
  const expiredPixelDateString = localStorage.getItem('expiredPixelDate');

  if(!expiredPixelDateString) return false;

  const currentDate = new Date();
  const expiredPixelDate = new Date(Number(expiredPixelDateString));
  return currentDate < expiredPixelDate;
}

const getPixelParams = (searchParams: URLSearchParams): {
  pixelVendor: string,
  transactionId: string,
  clickId: string,
  flowId: string
} => {
  const searchParamsPixelVendor = searchParams.get('utm_source') || '';
  const searchParamsTransactionId = searchParams.get('transaction_id') || '';
  const searchParamsClickId = searchParams.get('click_id') || '';

  if (searchParamsPixelVendor || searchParamsTransactionId || searchParamsClickId) {
    savePixelData({
      searchParamsPixelVendor,
      searchParamsTransactionId,
      searchParamsClickId
    })
  }

  const localStorageDataIsActual = checkActualOfDataInLocalStorage()
  if(!localStorageDataIsActual) clearPixelData()

  const pixelVendor = searchParamsPixelVendor || localStorage.getItem('pixelVendor') || '';
  const transactionId = searchParamsTransactionId || localStorage.getItem('transactionId') || '';
  const clickId = searchParamsClickId || localStorage.getItem('clickId') || '';
  const flowId = localStorage.getItem('flowId') || '';

  return {pixelVendor, transactionId, clickId, flowId}
}


interface usePixelProps {
  product: string
  stage: STAGES
  searchParams: URLSearchParams
}

export const usePixel = ({product, stage, searchParams}: usePixelProps) => {
  const {flowId, pixelVendor, transactionId, clickId} = getPixelParams(searchParams)
  if(!flowId || !pixelVendor) return;

  const isAdded = getDecisionAddingPixel({product, stage, flowId, pixelVendor})
  if(!isAdded) return;

  const pixelType = getCurrenPixelType(stage, product, pixelVendor);
  if(!pixelType) return;

  appendPixelTags({pixelType, flowId, transactionId, clickId})
}