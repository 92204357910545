import "./Reviews.css"
import quotationMarkIMG from "../../assets/quotation-mark.webp";

export const Reviews = () => {

  const reviews = [
    {
      clientName: "Алексей:",
      message: "Мне понравилось, что карта бесплатная👍, льготный период большой, " +
        "и есть кешбэк на основные для меня категории – супермаркеты, " +
        "кафе и общественный транспорт. Оплачиваю все кредитной картой, " +
        "потом с зарплаты пополняю ее. В прошлом месяце мне пришел кешбэк – 2 800 руб.😃😃😃"
    },
    {
      clientName: "Владимир:",
      message: "Взял карту на нужды по ремонту. Привлекло, что по наличным есть свой льготный период🔥🔥🔥, " +
        "и он не привязан к обычному льготному периоду по покупкам. То есть, когда понадобилось снять деньги, " +
        "тогда и открылся на них беспроцентный период в 120 дней.🤩"
    },
    {
      clientName: "Марина:",
      message: "Я оформила карту, когда планировала новогодний отпуск🎄💥. Мои деньги лежали на вкладе, " +
        "не хотелось снимать их и терять проценты, поэтому кредитка мне очень помогла. " +
        "Деньги буду возвращать с зарплаты – у меня есть время до середины апреля, чтобы не платить %🖐️🖐️🖐️. " +
        "Получилась выгода со всех сторон: проценты по вкладу копятся, по кредитке ничего не переплачиваю."
    },
  ]


  return <div className={"reviews"}>
    <div className={"container"}>
      <h2 className={"reviews__title"}>
        Как пользуются картой наши клиенты
      </h2>
      <div className={"reviews__list"}>
        {reviews.map((review) => <>
          <div className="reviews__card">
            <h4 className="reviews__card-title">{review.clientName}</h4>
            <div className="reviews__card-message">{review.message}</div>
            <img className="reviews__card-quotation reviews__card-quotation_left" src={quotationMarkIMG} alt="quotation" />
            <img className="reviews__card-quotation reviews__card-quotation_right" src={quotationMarkIMG} alt="quotation" />
          </div>
        </>)}
      </div>
    </div>
  </div>
}