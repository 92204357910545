import { FormikValues } from "formik";
import { isMarriedStatus, OCUPATION_TYPES_MAPPER, REG_EXPS } from "../../config";
import { checkPatronymic } from "../../utils/commonFunctions";
import { checkPhones, phoneNumbersMatchRules } from "../../utils/comparePhoneNumbers";
import { getYearsByDate } from "../../utils/datesHandler";
import { onGetPhoneDigits } from "../../utils/onGetPhoneDigits";

const stringifyPhoneNumbers = (arr: any[]) => {
	return `Поля:${arr.map((el) => ` ${el}`).join(" и")}, не должны совпадать`;
};

export const profileValidatorNew = (values: FormikValues, opfCodesList: string[], isAdditionalFields: boolean) => {
	interface IProfileFieldsError {
		phoneNumber?: string;
		occupationType?: string;
		occupationCategory?: string;
		occupationPost?: string;
		occupationPhoneNumber?: string;
		occupationCompanyName?: string;
		occupationCompanyAddress?: string;
		occupationCompanyLegalAddress?: string;
		occupationCompanyCodeOKOPF?: string;
		occupationOwnBusinessShare?: string;
		occupationCompanyKpp?: string;
		occupationCompanyInn?: string;
		occupationCompanyOgrn?: string;
		occupationStartWorkDate?: string;
		occupationMonthlyIncomesValue?: string;
		isReadyConfirmIncomes?: boolean;
		isAdditionalIncomes?: boolean;
		occupationDocConfirmationIncomes?: string;
		occupationIncomesKind?: string;

		ipSeria?: string;
		ipNumber?: string;
		ipIssueDate?: string;

		additionalInfoEducation?: string;
		additionalInfoMaritalStatus?: string;

		additionalInfoSpouseFio?: string;
		additionalInfoSpouseBirthDate?: string;
		additionalInfoSpouseOccupationType?: string;
		additionalInfoSpouseIncomes?: string;

		contactsPhone?: string;
	}

	const chosenOcupation = values.occupationType;

	const machedPhoneNumbers = checkPhones(phoneNumbersMatchRules, values);
	const isEmployer: boolean =  chosenOcupation === OCUPATION_TYPES_MAPPER.Employment
														|| chosenOcupation === OCUPATION_TYPES_MAPPER.Military
														|| chosenOcupation === OCUPATION_TYPES_MAPPER.Judge
														|| chosenOcupation === OCUPATION_TYPES_MAPPER.Valuer
														|| chosenOcupation === OCUPATION_TYPES_MAPPER.Farmer
														|| chosenOcupation === OCUPATION_TYPES_MAPPER.Bankruptcy;
	const isOwnBusiness: boolean = chosenOcupation === OCUPATION_TYPES_MAPPER.Business;
	const isIndividualHolder: boolean = chosenOcupation === OCUPATION_TYPES_MAPPER.PrivateEntrepreneur;
	const isOtherIncomes: boolean = chosenOcupation === OCUPATION_TYPES_MAPPER.Other;

	const isMarried: boolean = values.additionalInfoMaritalStatus === isMarriedStatus;
	// console.log("==> ", values.occupationCompanyInn?.length)
	const errors: IProfileFieldsError = {};
	if (!chosenOcupation) {
		errors.occupationType = "Выберите значение из списка";
	}
	if (isEmployer && !values.occupationCategory) {
		errors.occupationCategory = "Выберите значение из списка";
	}
	if (isEmployer && !values.occupationPost?.length) {
		errors.occupationPost = "Укажите должность";
	} else if (values.occupationPost && REG_EXPS.isOnlyCyrillicWithDigits(values.occupationPost.replace(/\s+/g, ""))) {
		errors.occupationPost = "Допускается для ввода только кириллица";
	}
	// if (!isEmployer && !isOwnBusiness && !isIndividualHolder) {
	// 	if (values.occupationPhoneNumber?.length < 17) {
	// 		errors.occupationPhoneNumber = "Номер телефона должен состоять из 10 цифр";
	// 	} else if (!values.occupationPhoneNumber) {
	// 		errors.occupationPhoneNumber = "Номер телефона должен состоять из 10 цифр";
	if ( isEmployer && !!values.occupationPhoneNumber
			&& machedPhoneNumbers && machedPhoneNumbers.includes("Телефон работодателя")) {
		errors.occupationPhoneNumber = stringifyPhoneNumbers(machedPhoneNumbers);
	}
	// }
	if ((isEmployer || isOwnBusiness || isIndividualHolder) && !values.occupationCompanyName) {
		errors.occupationCompanyName = "Укажите наименование организации";
	}
	// else if ((isEmployer || isOwnBusiness || isIndividualHolder) && values.occupationCompanyName && REG_EXPS.isNoLatinic(values.occupationCompanyName)) {
	// 	errors.occupationCompanyName = "Допускается для ввода только Кириллица";
	// }

	if ((isEmployer || isIndividualHolder || isOwnBusiness) && values.occupationCompanyLegalAddress && REG_EXPS.isNoLatinic(values.occupationCompanyLegalAddress?.title.replace(/\s+/g, ""))) {
		errors.occupationCompanyLegalAddress = "Допускается для ввода только кириллица";
	}
	if ((isEmployer || isIndividualHolder || isOwnBusiness) && values.occupationCompanyAddress && REG_EXPS.isNoLatinic(values.occupationCompanyAddress?.title.replace(/\s+/g, ""))) {
		errors.occupationCompanyAddress = "Допускается для ввода только кириллица";
	}
	if ((isEmployer || isOwnBusiness) && values.occupationCompanyCodeOKOPF && REG_EXPS.isNoLatinic(values.occupationCompanyCodeOKOPF.replace(/\s+/g, ""))) {
		errors.occupationCompanyCodeOKOPF = "Допускается для ввода только кириллица";
	} else if ((isEmployer || isOwnBusiness) && !values.occupationCompanyCodeOKOPF?.length) {
		errors.occupationCompanyCodeOKOPF = "ОПФ не найден. Выберите вариант из списка";
	} else if ((isEmployer || isOwnBusiness) && values.occupationCompanyCodeOKOPF.length > 0 && !opfCodesList.includes(values.occupationCompanyCodeOKOPF)) {
		errors.occupationCompanyCodeOKOPF = "ОПФ не найден. Выберите вариант из списка";
	}
	if (isEmployer && (!values.occupationCompanyInn || (values.occupationCompanyInn?.length !== 12 && values.occupationCompanyInn?.length !== 10))) {
		errors.occupationCompanyInn = "Номер ИНН должен состоять из 10 или 12 символов";
	} else if (isOwnBusiness && (!values.occupationCompanyInn || values.occupationCompanyInn?.length !== 10)) {
		errors.occupationCompanyInn = "Номер ИНН должен состоять из 10 символов";
	} else if (isIndividualHolder && (!values.occupationCompanyInn || values.occupationCompanyInn?.length !== 12)) {
		errors.occupationCompanyInn = "Номер ИНН должен состоять из 12 символов";
	}
	if (isOwnBusiness && !values.occupationOwnBusinessShare) {
		errors.occupationOwnBusinessShare = "Введите число от 0 до 100";
	} else if (isOwnBusiness && values.occupationOwnBusinessShare && +values.occupationOwnBusinessShare > 100) {
		errors.occupationOwnBusinessShare = "Укажите долю владения";
	}
	if (isEmployer && !values.occupationStartWorkDate) {
		errors.occupationStartWorkDate = "Дата должна быть в формате ДД.ММ.ГГГГ. Например, 25.06.1995";
	}
	// } else if (isEmployer && values.occupationStartWorkDate && getYearsByDate(values.occupationStartWorkDate) < 0.2546200915151976 ) {
	// 	errors.occupationStartWorkDate = "Трудовой стаж должен быть не менее 3 месяцев";
	// }
	if (!values.occupationMonthlyIncomesValue) {
		errors.occupationMonthlyIncomesValue = "Укажите размер ежемесячного дохода (руб.)";
	} else if (values.occupationMonthlyIncomesValue && values.occupationMonthlyIncomesValue?.length < 1) {
		errors.occupationMonthlyIncomesValue = "Укажите размер ежемесячного дохода (руб.)";
	}
	if (values.isReadyConfirmIncomes && !values.occupationDocConfirmationIncomes) {
		errors.occupationDocConfirmationIncomes = "Укажите документ для подтверждения дохода";
	}
	if (!values.additionalInfoMaritalStatus) {
		errors.additionalInfoMaritalStatus = "Выберите вариант из списка";
	}
	// if (!values.additionalInfoSnils) {
	// 	errors.additionalInfoSnils = "Некорректное значение";
	// } else if (values.additionalInfoSnils?.length < 14) {
	// 	errors.additionalInfoSnils = "Значение должно состоять из 11 цифр";
	// }
	if (isAdditionalFields && isMarried && !values.additionalInfoSpouseFio) {
		errors.additionalInfoSpouseFio = "Укажите Фамилию Имя Отчество";
	} else if (isAdditionalFields && values.additionalInfoSpouseFio && REG_EXPS.forFIO(values.additionalInfoSpouseFio.replace(/\s+/g, ""))) {
		errors.additionalInfoSpouseFio = "Допускается для ввода только кириллица";
	} else if (isAdditionalFields && isMarried && (checkPatronymic(values.additionalInfoSpouseFio) < 2 || checkPatronymic(values.additionalInfoSpouseFio) > 4)) {
		errors.additionalInfoSpouseFio = "Укажите Фамилию Имя Отчество";
	}
	if (isAdditionalFields && isMarried && !values.additionalInfoSpouseBirthDate) {
		errors.additionalInfoSpouseBirthDate = "Дата должна быть в формате ДД.ММ.ГГГГ. Например, 25.06.1995";
	} else if (REG_EXPS.isValidAge(values.additionalInfoSpouseBirthDate, 16, 100 + 0.07671232876)) {
		errors.additionalInfoSpouseBirthDate = `Укажите возраст от ${16} до ${100} лет`;
	}
	if (isAdditionalFields && isMarried && !values.additionalInfoSpouseOccupationType) {
		errors.additionalInfoSpouseOccupationType = "Выберите вариант из списка";
	}
	if (isAdditionalFields && isMarried && !values.additionalInfoSpouseIncomes) {
		errors.additionalInfoSpouseIncomes = "Укажите размер ежемесячного дохода (руб.)";
	}
	if (!values.contactsPhone) {
		errors.contactsPhone = "Укажите телефон для экстренной связи";
	} else if (onGetPhoneDigits(values.contactsPhone)?.length < 11) {
		errors.contactsPhone = "Номер телефона должен состоять из 10 цифр";
	} else if (
		machedPhoneNumbers &&
		machedPhoneNumbers.includes("Телефон для экстренной связи")
	) {
		errors.contactsPhone = stringifyPhoneNumbers(machedPhoneNumbers);
	}
	if (isIndividualHolder && !values.occupationCompanyOgrn) {
		errors.occupationCompanyOgrn = "Укажите ОГРНИП";
	} else if (isIndividualHolder && !REG_EXPS.isValid_OGRN_IP(values.occupationCompanyOgrn)) {
		errors.occupationCompanyOgrn = "ОГРНИП должен начинаться с цифры 3";
	} else if (isIndividualHolder && values.occupationCompanyOgrn?.length < 15) {
		errors.occupationCompanyOgrn = "ОГРНИП должен состоять из 15 цифр"
	}
	if (isOwnBusiness && !values.occupationCompanyOgrn) {
		errors.occupationCompanyOgrn = "Укажите ОГРН";
	} else if (isOwnBusiness && !REG_EXPS.isValid_OGRN(values.occupationCompanyOgrn)) {
		errors.occupationCompanyOgrn = "ОГРН должен начинаться с цифры 1 или 5";
	} else if (isOwnBusiness && values.occupationCompanyOgrn?.length < 13) {
		errors.occupationCompanyOgrn = "ОГРН должен состоять из 13 цифр"
	}
	if (isOtherIncomes && !values.occupationIncomesKind) {
		errors.occupationIncomesKind = "Выберите из списка";
	}
	if (isOwnBusiness && !values.occupationCompanyKpp) {
		errors.occupationCompanyKpp = "Укажите КПП";
	} else if (isOwnBusiness && values.occupationCompanyKpp?.length < 9) {
		errors.occupationCompanyKpp = "КПП должен состоять из 9 цифр";
	}

	return errors;
};
