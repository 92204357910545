export const calculateAmounts = <
	K extends { amount: number; isChecked: boolean }
>(
	array: K[]
): number => {
	let outletAmount = 0;

	array.forEach((item) => {
        if(item.isChecked) {
            outletAmount += item.amount;
        }
	});

	return outletAmount;
};
