import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { onCallTouchAction } from '../redux/Application/app-reducer';

export const useCallTouch = () => {

  const paramsFlowId = useParams().flowId;
  const flowId = paramsFlowId || localStorage.getItem("flowId") || undefined;

  const dispatch = useDispatch()

  const _onCallTouchAction = () => {
    flowId && dispatch(onCallTouchAction({flowId: flowId}))
  }

  React.useEffect(() => {
    (function(w: any,d: any,n: any,c: any){
      w.CalltouchDataObject=n;
      w[n]=function(){w[n]["callbacks"].push(arguments)};
      if(!w[n]["callbacks"]){
        w[n]["callbacks"]=[]
      }
      w[n]["loaded"]=false;
      if(typeof c!=="object"){
        c=[c]
      }
      w[n]["counters"]=c;
      for(var i=0;i<c.length;i+=1){
        p(c[i])
      }
      function p(cId: any) {
        var a=d.getElementsByTagName("script")[0],s=d.createElement("script"),i=function() {
          a.parentNode.insertBefore(s,a)
        },
        m=typeof Array.prototype.find === 'function',
        n=m?"init-min.js":"init.js";
        s.type="text/javascript";
        s.async=true;
        s.src="https://mod.calltouch.ru/"+n+"?id="+cId;
        if (w.opera=="[object Opera]") {
          d.addEventListener("DOMContentLoaded",i,false)
        } else {
          i()
        }
      }
      
    })(window,document,"ct","dtn4256z");
	}, [])
  const frame: any = document.getElementById("CalltouchWidgetFrame") 
  React.useEffect(() => {
    frame?.contentDocument?.body?.addEventListener("mouseup", _onCallTouchAction )
    return () => frame?.contentDocument?.body?.addEventListener("mouseup", _onCallTouchAction )
  }, [frame])

  //  React.useEffect(() => {
  //       const frame: any = document.getElementById("CalltouchWidgetFrame") 
  //       frame?.contentDocument?.body?.addEventListener("mouseup",(event: any)=>console.log("calltach click:",event))
  //   }, [])
}