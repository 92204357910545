import { String } from '../styles/UIKit/String';
import React from 'react';
import { ROUTE_PATH } from '../routesConfig';

export const CalculatorFormNote: React.FC<{product: string, fullWidth: boolean, margin?: string, display?: string}> = (props) => {
  const { product, fullWidth, margin, display } = props
  const NOTE_MAPPER: {[key: string]: string} = {
    [ROUTE_PATH.CREDITS]: `${ !fullWidth ? '* ' : '' } Расчет приблизительный и носит информационный характер. Точные параметры кредита определяются индивидуально для каждого клиента по результатам рассмотрения заявки. Не является публичной офертой.`,
    [ROUTE_PATH.CPZA]: `${ !fullWidth ? '* ' : '' } Расчет приблизительный и носит информационный характер. Точные параметры кредита определяются индивидуально для каждого клиента по результатам рассмотрения заявки. Не является публичной офертой.`,
    [ROUTE_PATH.CARDS]: `Условия носят информационный характер. Точные параметры определяются по результатам рассмотрения заявки. Не является публичной офертой`,
  }

  return (
    <String margin={margin} display={display} color="#7E829B" fontFamily={'Open-sans'} lineHeight="1.5" fSize="11px">{NOTE_MAPPER[product]}</String>
  );
};