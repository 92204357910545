import React, {ReactElement} from "react";
import {IThemeNames, THEME_NAMES} from "../../styles/themes/themes";
import {PromoCreditDefault} from "./PromoCreditDefault/PromoCreditDefault";
import {PromoCpzaDefault} from "./PromoCpzaDefault/PromoCpzaDefault";
import {PromoCreditReferral} from "./PromoCreditReferral/PromoCreditReferral";

type PromoProps = {
  type?: IThemeNames
  product?: string
  children?: ReactElement
  isReferral?: boolean
  isMobile?: boolean
}

export const Promo = ({type = THEME_NAMES.default, product, isReferral, isMobile, children }: PromoProps) => {

  if(product === 'cpza'){
    return <PromoCpzaDefault children={children} />
  }

  if(isReferral) {
    return <PromoCreditReferral isMobile={isMobile} />
  }

  return <PromoCreditDefault children={children} />
}