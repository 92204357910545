import React, {ReactNode} from 'react';
import './Marathon.css';
import SupermarketsImg from '../../../assets/cashback-on-categories/supermarkets.svg'
import CoffeeImg from '../../../assets/cashback-on-categories/coffee.svg'
import TransportAndTaxisImg from '../../../assets/cashback-on-categories/transport-and-taxis.svg'

export const MarathonComponent : React.FC<{screenWidth: number, isReferral?: boolean}> = ({screenWidth, isReferral}) => {
  const onToStartFill = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    window.history.pushState(undefined, '', '#anketa');
    document.querySelector("#anketa")?.scrollIntoView({ behavior: 'smooth' , block: 'start'});
  };

  return (
    <div className={`main__container${isReferral ? " main__container_white" : ""}`}>
      <div className="marathon-container">
        <div className="marathon-container__title">
          Получайте <span>кешбэк до 7%</span> на самые популярные категории:
        </div>
        <div className="marathon-container__categories">
          <div className="marathon-container__item">
            <img className="marathon-container__item-img" src={SupermarketsImg} alt="иконка корзинка продуктов"/>
            <span className="marathon-container__item-text">
              Супермаркеты
            </span>
          </div>
          <div className="marathon-container__separator"></div>
          <div className="marathon-container__item">
            <img className="marathon-container__item-img" src={CoffeeImg} alt="иконка чашка кофе"/>
            <span className="marathon-container__item-text">
              Кафе и рестораны
            </span>
          </div>
          <div className="marathon-container__separator"></div>
          <div className="marathon-container__item">
            <img className="marathon-container__item-img" src={TransportAndTaxisImg} alt="иконка автомобиля такси"/>
            <span className="marathon-container__item-text">
              Общественный транспорт и такси
            </span>
          </div>
        </div>
        {isReferral && <>
            <div className={"marathon-container__additionally-mir"}>
              <div className="marathon-container__title">
                И <span>до 50% кешбэк</span> у партнеров платежной системы «Мир»!
              </div>
              <span className={"marathon-container__additionally-text"}>
                Список актуальных акций и предложений доступен на сайте программы лояльности платежной системы «Мир»
                и в приложении «Привет!)»
              </span>
            </div>
         </>}
        <div className="arrange-btn">
          <a href="#anketa" onClick={onToStartFill}>Оформить карту</a>
        </div>
        {/*<div className="marathon-container__description marathon-container__terms-link text_center-mobile">*/}
        {/*  <a href="https://www.zenit.ru/personal/cards/credit-cards-privilege/pravila.pdf" target="_blank" className="link">Условия акции&gt;&gt;</a>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}
