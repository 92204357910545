import React from 'react';
import { useFormik } from 'formik';
import { MainBlockWrapper, SecondaryBlockWrapper, StyledPageContainer, } from '../CommonPagesStyles';
import { PassportSecondaryBlock } from './PassportSecondaryBlock/PassportSecondaryBlock';
import { PassportMainBlock } from './PassportMainBlock/PassportMainBlock';
import { ICreditDeclarations, IDadataAddressTypes, IDadataTypes, IFioDadataList, } from '../../redux/Application/appTypes';
import { IPassportConfig, IPassportState, IUploadedFile, } from '../../redux/Credit/creditTypes';
import { passportValidator } from './passportValidator';
import {useDispatch, useSelector} from 'react-redux';
import useFormSaver from '../../hooks/useFormSaver';
import { SignDocumentModal } from '../../styles/UIKit/SignDocumentModal';
import { generateForm } from '../../redux/SignDocs/sign-docs-reducer';
import { updateCurrentForm } from '../../redux/Credit/credit-reducer';
import { useProductSetter } from '../../hooks/useProductSetter';
import { checkPatronymic } from '../../utils/commonFunctions';
import { ROUTE_PATH } from '../../routesConfig';
import { onAuthEsia } from '../../redux/Auth/auth-reducer';
import { PRODUCTS_CODES } from '../../config';
import { setFioList } from '../../redux/Application/appActions';
import {useSearchParams} from "react-router-dom";
import {buildFieldsObject} from "../../utils/getQueryParams";
import { useValidationScroll } from '../../hooks/useValidationScroll';
import {AppRootStateType} from "../../redux/store";

interface IPassportDataForm {
	passportFiles: IUploadedFile[];
	passportFormDataState: IPassportState;
	flowId: string;
	config: IPassportConfig;
	mobileScanner: string | undefined;
	regionsList: IDadataTypes[];
	documentIssuanceCodesList: IDadataTypes[];
	previousDocumentIssuanceCodesList: IDadataTypes[];
	currentPassportValidationErrorMessage: string | null;
	previousPassportValidationErrorMessage: string | null;
	registrationAddressList: IDadataAddressTypes[];
	actualAddressList: IDadataAddressTypes[];
	citiesList: IDadataAddressTypes[];
	screenWidth: number;
	phoneNumber: string;
	product: string;
	fioList: IFioDadataList[];
}

export const PassportDataForm: React.FC<IPassportDataForm> = React.memo(
	(props) => {
		const { passportFormDataState, flowId, phoneNumber, screenWidth, product, config } = props;
		const dispatch = useDispatch();
		const [searchParams, setSearchParams] = useSearchParams();

		const [smsCodeValue, setSmsCodeValue] = React.useState<string>("");
		const [isSignDocs, setIsSignDocs] = React.useState<boolean>(false);
		const [agreements, setAgreements] = React.useState<string[]>(passportFormDataState.agreements);

		const userPassportData = useFormik<IPassportState>({
			initialValues: passportFormDataState,
			validate: (values) => passportValidator(values, config.minAllowedAge, config.maxAllowedAge, checkPatronymic(config.userFio), product),
			onSubmit: (values) => {
				window.scrollTo(0, 0);
				setIsSignDocs(true);
				dispatch(
					updateCurrentForm(flowId, {
						formId: ROUTE_PATH.PASSPORT,
						state: values,
					})
				);
				dispatch(setFioList([]));
				if (!isSignDocs) {
					dispatch(generateForm(flowId));
				}
			},
			enableReinitialize: true,
		});
		useValidationScroll(userPassportData);

		useProductSetter(userPassportData, product)

		const setFormDataUpdates = useFormSaver<IPassportState>({ ...userPassportData.values, agreements }, ROUTE_PATH.PASSPORT, flowId);

		React.useEffect(() => {
			dispatch(updateCurrentForm(flowId, { formId: ROUTE_PATH.PASSPORT, state: { ...userPassportData.values, agreements } }));
		}, [agreements]);

	const onEsia = () => flowId && dispatch(onAuthEsia(flowId, PRODUCTS_CODES[product]))

		const finalFieldsToBeSent = {
			...userPassportData.values,
			agreements,
			// purpose: isCredit ? extractValue(props.config.loanPurposeRef, userPassportData.values.purpose) : null
		}

		React.useEffect(() => {
			setSearchParams(buildFieldsObject(userPassportData.values));
		}, [userPassportData.values]);
		console.log("!",{
			smsCodeValue,
			isSignDocs,
			agreements
		})

		return (
			<StyledPageContainer>
				{/* <StartPAssportFormModal /> */}
				<form onSubmit={userPassportData.handleSubmit}>
					{!isSignDocs && <MainBlockWrapper>
						<PassportMainBlock
						{...props}
						agreements={agreements}
						setAgreements={setAgreements}
						setFormDataUpdates={setFormDataUpdates}
						userPassportData={userPassportData}
						onEsia={onEsia}						/>
					</MainBlockWrapper>}
					{isSignDocs && <SignDocumentModal
						product={product}
						show={isSignDocs}
						setShow={setIsSignDocs}
						phoneNumber={phoneNumber}
						screenWidth={screenWidth}
						smsCodeValue={smsCodeValue}
						setSmsCodeValue={setSmsCodeValue}
						flowId={flowId}
						fields={finalFieldsToBeSent}
					/>}
				</form>
				<SecondaryBlockWrapper>
					<PassportSecondaryBlock screenWidth={screenWidth} product={product} userPhone={phoneNumber} flowId={flowId}/>
				</SecondaryBlockWrapper>
			</StyledPageContainer>
		);
	}
);
