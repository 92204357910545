import React from 'react';
import { adaptiveParams } from '../../../styles/adaptiveParams';
import { MonthlyPayment } from '../../../styles/UIKit/MonthlyPayment/MonthlyPayment';

export interface ILinks {
	id: number;
	anchor: string;
	linkName: string;
}

export const PassportSecondaryBlock: React.FC<{ screenWidth: number, product: string, userPhone: string, flowId: string}> = ({
	screenWidth, product, userPhone, flowId
}) => {
	const fieldsWrapConditions: boolean = screenWidth > adaptiveParams.mainContentWidth;
	return (
		<>
			{fieldsWrapConditions && (
				<MonthlyPayment
					product={product}
					screenWidth={screenWidth}
					userPhone={userPhone}
					flowId={flowId}
				/>
			)}
		</>
	);
};