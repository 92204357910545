import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDimensions from '../hooks/useDimensions';
import { Profile } from '../pages/Profile/Profile';
import { setCurrentForm } from '../redux/Application/appActions';
import { setCalculatedCreditData } from '../redux/Credit/creditActions';
import { AppRootStateType } from '../redux/store';
import { adaptiveMeasurements } from '../styles/adaptiveParams';
import { CreditCalculator } from '../utils/creditCalculator';
import { Iorder, IProfileConfig2, IProfileState2 } from '../redux/Credit/creditTypes';
import { ICompanyDadataList, IDadataAddressTypes } from '../redux/Application/appTypes';

export const TestFillFull = () => {
	const { screenWidth } = useDimensions();

	const dispatch = useDispatch();
	// const { flowId } = useParams();

	// useRefreshToken(flowId);

	React.useEffect(() => {
		dispatch(
			setCurrentForm({
				product: "test_fillFull",
				formId: undefined,
				flowId: undefined,
			})
		);
		// flowId && dispatch(getCurrentForm(flowId));
	}, []);

	const profileState: IProfileState2 = {
		occupationAdditionalIncomesKind: "",
    occupationType: "Работа по найму",
		occupationCompanyKpp: "",
		occupationCompanyOgrn: "",
    occupationOtherIncomes: "occupationOtherIncomes",
    occupationCategory: "",
    occupationPost: "",
    occupationPhoneNumber: "",
    occupationCompanyName: "",
    occupationCompanyAddress: null,
    occupationCompanyLegalAddress: null,
    occupationCompanyIsActualMutchLegal: false,
    // occupationCompanyLegalForm: "",
    occupationCompanyInn: "",
    occupationStartWorkDate: null,
    occupationMonthlyIncomesValue: "",
    isGettingSalaryOnCard: true,
    isAdditionalIncomes: true,
		isReadyToProveIncomes: false,
    occupationDocConfirmationIncomes: "Налоговая декларация по форме 3-НДФЛ",
    occupationIncomesKind: "Алименты и денежные пособия",
    occupationAdditionalIncomeValue: "2 323",
    occupationOwnBusinessShare: "occupationOwnBusinessShare",
    ipSeria: "ipSeria",
    ipNumber: "ipNumber",
    ipIssueDate: new Date(),

    additionalInfoEducation: "",
    additionalInfoMaritalStatus: "",
    additionalInfoMinorChildrenQuantity: 0,
    additionalInfoOtherDependents: 0,

    additionalInfoSpouseFio: "",
    additionalInfoSpouseBirthDate: null,
    additionalInfoSpouseOccupationType: "Работа по найму",
    additionalInfoSpouseIncomes: "",

    contactsPhone: "",

    additionalIncomes: [],

    questionnaire: [],
  }

	// const profileConfig = useSelector<
	// 	AppRootStateType,
	// 	IProfileConfig2 | undefined
	// >((state) => state.creditReducer.profile?.config);

  const profileConfig: IProfileConfig2 = {
		esiaUsage: false,
		otherIncomesRef: [
			{
					"code": "SelfEmployment",
					"title": "Самозанятый"
			},
			{
					"code": "GPH",
					"title": "Работа по договору ГПХ"
			},
			{
					"code": "Pension",
					"title": "Пенсия"
			}
	],
		errorMessage: null,
		gender: "M",
		employmentTypeRef: [],
		contactsRelationRef: [],
		occupationCompanyOpfRef: [],
		thresholdAmount: 1000000,
    realEstateTypeOptions: ['Частный дом', 'Квартира', 'Другое'],
    realEstateGettingWayOptions: ['Покупка', 'Наследство', 'Другое'],
    realEstatePledgedDocTypeOptions: ['Договор', 'Квитанция'],
		maritalStatusOptions: [],
    maritalStatusRef: [],

    vehicleTypeOptions: ['Мотоцикл', 'Автомобиль', 'Коммерческий транспорт', 'Иное'],
    vehicleGettingWayOptions: ['Покупка', 'Наследство', 'Иное'],
    vehicleLabelOptions: ['Toyota', 'TAZ', 'Иное'],
    vehiclePledgedDocTypeOptions: ['Договор', 'Квитанция', 'Иное'],

    additionalIncomesRef: [
			{
      	title: "Доход по вкладам и иным финансовым активам",
	    	code: "Investment",
    	},
			{
      	title: "Ежемесячные денежные выплаты для специальных категорий граждан",
	    	code: "Special",
    	},
			{
      	title: "Алименты и денежные пособия",
	    	code: "Allowance",
    	},
			{
      	title: "Самозанятый",
	    	code: "SelfEmployment",
    	},
			{
      	title: "Работа по договору ГПХ",
	    	code: "GPH",
    	},
			{
      	title: "Неофициально (Фриланс)",
	    	code: "Freelance",
    	},
			{
      	title: "Доход от сдачи недвижимого имущества внаем/аренду",
	    	code: "Rent",
    	},
			{
      	title: "Пенсия",
	    	code: "Pension",
    	},
		],
    occupationTypeRef: [
			{code: 'Employment', title: 'Работа по найму'},
			{code: 'PrivateEntrepreneur', title: 'Индивидуальный предприниматель'},
			{code: 'Business', title: 'Собственный бизнес'},
			{code: 'Other', title: 'Прочий доход'},
    ],
    occupationDocConfirmationIncomesRef: [
			{
					"code": "IncomeBook",
					"title": "КУД/КУДиР",
					"parentCode": "PrivateEntrepreneur"
			},
			{
					"code": "Patent",
					"title": "Патент",
					"parentCode": "PrivateEntrepreneur"
			},
			{
					"code": "StatementPFR",
					"title": "Выписка ПФР",
					"parentCode": "PrivateEntrepreneur"
			},
			{
					"code": "2NDFL",
					"title": "Справка 2-НДФЛ (Справка о доходах и суммах налога физического лица)",
					"parentCode": "Business"
			},
			{
					"code": "FreeIncomeForm",
					"title": "Справка о доходах в свободной форме",
					"parentCode": "Employment"
			},
			{
					"code": "TenantReceipts",
					"title": "Расписки арендатора",
					"parentCode": "Other",
					"otherIncomes": [
							"Rent"
					]
			},
			{
					"code": "StatementFNS",
					"title": "Выписка о доходах самозанятого",
					"parentCode": "Other",
					"otherIncomes": [
							"SelfEmployment"
					]
			},
			{
					"code": "StatementTurnover",
					"title": "Выписка об оборотах по основному р\\с",
					"parentCode": "Business"
			},
			{
					"code": "StatementTurnover",
					"title": "Выписка об оборотах по основному р\\с",
					"parentCode": "PrivateEntrepreneur"
			},
			{
					"code": "3NDFL",
					"title": "Справка 3-НДФЛ",
					"parentCode": "Employment"
			},
			{
					"code": "IncomeChange",
					"title": "Документ, подтверждающий изменение заработной платы",
					"parentCode": "Employment"
			},
			{
					"code": "PaymentsToAccount",
					"title": "Выписка по счету о зачислении платежей",
					"parentCode": "Other",
					"otherIncomes": [
							"Rent",
							"SelfEmployment",
							"Allowance",
							"Special",
							"GPH"
					]
			},
			{
					"code": "3NDFL",
					"title": "Справка 3-НДФЛ",
					"parentCode": "Other",
					"otherIncomes": [
							"Rent"
					]
			},
			{
					"code": "DividendStatement",
					"title": "Выписка по счету с зачислениями по дивидендам",
					"parentCode": "Business"
			},
			{
					"code": "StatementPFR",
					"title": "Выписка ПФР",
					"parentCode": "Employment"
			},
			{
					"code": "TaxDeclaration",
					"title": "Налоговая декларация",
					"parentCode": "PrivateEntrepreneur"
			},
			{
					"code": "StatementAccount",
					"title": "Выписка по зарплатному/текущему (дебетовому) счету",
					"parentCode": "Employment"
			},
			{
					"code": "2NDFL",
					"title": "Справка 2-НДФЛ (Справка о доходах и суммах налога физического лица)",
					"parentCode": "Employment"
			},
			{
					"code": "PensionStatement",
					"title": "Справка/Выписка о начислении пенсии/пожизненного содержания/пособия",
					"parentCode": "Other",
					"otherIncomes": [
							"Pension"
					]
			},
			{
					"code": "PensionCertificate",
					"title": "Пенсионное удостоверение",
					"parentCode": "Other",
					"otherIncomes": [
							"Pension"
					]
			}
	],
    occupationCategoryRef: [
			{code: 'Special', title: 'Неруководящая должность'},
			{code: 'Head', title: 'Руководитель среднего звена'},
			{code: 'Top', title: 'Топ-менеджер'},
		],
    additionalInfoEducation: [
			{code: 'Degree', title: 'Ученая степень'},
			{code: 'MBA', title: 'MBA'},
			{code: 'Middle', title: 'Среднее'},
			{code: 'HalfHigher', title: 'Незаконченное высшее'},
			{code: 'Higher', title: 'Высшее'},
			{code: 'SecondHigher', title: 'Второе высшее'},
			{code: 'HalfMiddle', title: 'Неполное среднее'},
			{code: 'SpecialMiddle', title: 'Среднее специальное'}
		],

    phoneNumber: "+7 (999) 777-7777",

    order: {
      amount: 2000000,
      term: 29.9,
      rate: 12,
			preferentialRate: 4,
			preferentialTerm: 1,
    },

    mobileScanner: {
      scan: "https://spa.feijoa-develop.boos.solutions/scan-it/2JfuoCjS8Sas91bSDXfr8H7aqkct8k4ahBRTVEG2kkyLos4TAXzLyCBip7h4p13vLFg6WwQb9ZQbooE4agufFPLvQjk2y4MYc1iHfhF2CFDRXupJQtmzKiF7ixfSBXRt7MaR",
    },
  }

	// const order = useSelector<AppRootStateType, Iorder | undefined>(
	// 	(state) => state.creditReducer.profile?.config.order
	// );

  const order: Iorder = {
      amount: 2000000,
      term: 12,
      rate: 7,
			preferentialRate: 4,
			preferentialTerm: 1,
    }

	const workPlaceAddressList = useSelector<
		AppRootStateType,
		IDadataAddressTypes[]
	>((state) => state.appReducer.workPlaceAddressList);

	const companyNamesDadataList = useSelector<
		AppRootStateType,
		ICompanyDadataList[]
	>((state) => state.appReducer.companyNamesDadataList);

	const companyInnDadataList = useSelector<
		AppRootStateType,
		ICompanyDadataList[]
	>((state) => state.appReducer.companyInnDadataList);

	const workPlaceLegalAddressList = useSelector<
		AppRootStateType,
		IDadataAddressTypes[]
	>((state) => state.appReducer.workPlaceLegalAddressList);

	const standartFontSizeMeasures = adaptiveMeasurements(
		screenWidth,
		"28px",
		"26px",
		"24px",
		"20px"
	);

	const standartHeightMeasures = adaptiveMeasurements(
		screenWidth,
		"71px",
		"68px",
		"56px",
		"50px"
	);

	const standartWidthMeasures = adaptiveMeasurements(
		screenWidth,
		"360px",
		"360px",
		"100%",
		"100%"
	);

	React.useEffect(() => {
    if (order) {
			const { amount, rate, term, preferentialRate, preferentialTerm } = order;
			const calculator = CreditCalculator.calculateCredit(
				amount,
				term,
				preferentialRate,
				rate,
				preferentialTerm,
			);
			dispatch(setCalculatedCreditData(calculator));
    }
	}, [order]);

	return (
		<>
			<h1>TEST</h1>
      {profileState && profileConfig && order &&
				<Profile
					fioList={[]}
					employmentTypeRef={[]}
					contactsRelationRef={[]}
					occupationCompanyOpfRef={[]}
					product="credits"
					companyNamesDadataList={companyNamesDadataList}
					companyInnDadataList={companyInnDadataList}
					standartFontSizeMeasures={standartFontSizeMeasures}
					standartHeightMeasures={standartHeightMeasures}
					standartWidthMeasures={standartWidthMeasures}
					screenWidth={screenWidth}
					profileConfig={profileConfig}
					flowId={"flowId"}
					creditAmount={profileConfig.order.amount}
					realEstateTypeOptions={profileConfig.realEstateTypeOptions}
					realEstateGettingWayOptions={
						profileConfig.realEstateGettingWayOptions
					}
					realEstatePledgedDocTypeOptions={
						profileConfig.realEstatePledgedDocTypeOptions
					}
					vehicleTypeOptions={profileConfig.vehicleTypeOptions}
					vehicleGettingWayOptions={
						profileConfig.vehicleGettingWayOptions
					}
					vehicleLabelOptions={profileConfig.vehicleLabelOptions}
					vehiclePledgedDocTypeOptions={
						profileConfig.vehiclePledgedDocTypeOptions
					}
					maritalStatusRef={[]}
					scanItLink={profileConfig.mobileScanner.scan}
					state={profileState}
					workPlaceAddressList={workPlaceAddressList}
					workPlaceLegalAddressList={workPlaceLegalAddressList}
					phoneValidationErrorMessage={null}
				/>
      }
		</>
	);
}
