import Calendar, { CalendarProps } from 'react-calendar';
import styled from 'styled-components';
import 'react-calendar/dist/Calendar.css';
import { StyledFieldsProps } from '../../CustomField/CustomFieldStyles';
import { Flex } from '../../Flex';
import MaskedInput from 'react-text-mask';
import { CalendarIcon } from '../../Icons/Calendar';
import React, { ChangeEvent } from 'react';
import { String } from '../../String';
import { FormikValues } from 'formik';
import { checkDate, dateIntoString, stringIntoDate } from '../../../../utils/commonFunctions';
import { StyledErrorNotificator } from '../../StyledErrorNotificator';
import { adaptiveParams } from '../../../adaptiveParams';
import useDimensions from '../../../../hooks/useDimensions';
import { calcErrorOffset } from '../../../../utils/calcErrorOffset';
import {defaultTheme} from "../../../themes/themes";

interface IInput extends StyledFieldsProps {
	padding?: string;
	dateValue?: string;
	setDateValue?: (dateValue: string) => void;
	error?: boolean;
	backgroundColor?: string;
}

const Input = styled(MaskedInput)<IInput>`
	width: ${({ width }) => width || "auto"};
	height: ${({ height }) => height || "auto"};
	padding: ${({ padding }) => padding || "0"};
	background: ${({ backgroundColor }) => backgroundColor || "#F2F4F7"};
	border: 2px solid ${({borderColor}) => borderColor || "transparent"};
	border-radius: 4px;
	font-family: Formular,serif;
	font-size: ${({ fSize }) => fSize || "0"};
	outline: none;
	// &:focus {
	// 	border-color: #00CAD6;
	// }
`;

const StyledCalendar = styled(Calendar)<ICustomerCalendar>`
	position: absolute;
  font-family: 'Formular'!important;
	width: ${({ width }) => width || "auto"};
	height: ${({ height }) => height || "auto"};
	/* font-size: ${({ fSize }) => fSize || "auto"}; */
	top: 71px;
	z-index: 1000;
  
  & * {
    font-family: 'Formular'!important;
		font-size: 16px;
  }

  & .react-calendar__tile {
    border-radius: 4px;
    border: 1px solid transparent;
  }
  
  & .react-calendar__tile:hover {
    background: transparent;
    border: 1px solid #00CAD6;
  }
  
  & .react-calendar__tile--active, & .react-calendar__tile--active:hover {
    background: #00CAD6;
  }
  
  & .react-calendar__tile--now, & .react-calendar__tile--now:hover{
    background: #F2F4F7;
  }
`;

interface ICustomerCalendar extends CalendarProps {
	updateFormHandler?: (values: any) => void;
	borderColor?: string;
	fSize?: string;
	width?: string;
	height?: string;
	label?: string;
	formik: FormikValues;
	// fields: any;
	formItem: string;
	dateValue?: string;
	setDateValue?: (dateValue: string) => void;
	error?: boolean;
	errorValue?: string;
	backgroundColor?: string;
}
export const CustomerCalendar: React.FC<ICustomerCalendar> = (props) => {
	const {
		updateFormHandler,
		borderColor,
		value,
		dateValue,
		setDateValue,
		width,
		label,
		formik,
		formItem,
		maxDate,
		minDate,
		error,
		errorValue,
		backgroundColor,
	} = props;

	React.useEffect(() => {
		if (setDateValue) {
			setDateValue(dateIntoString(value as Date))
		}
	}, [value])

	const {screenWidth} = useDimensions()

	const { mainContentWidth } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;
	const Top = calcErrorOffset(screenWidth, fieldsWrapConditions);

	const [showCalendar, setShowCalendar] = React.useState<boolean>(false);
	const onChangeInputDateHandler = (e: ChangeEvent<HTMLInputElement>) => {
		const regExpTest = checkDate(e.currentTarget.value);
		if (!regExpTest) {
			formik.setFieldValue(formItem, null);
		}

		if (regExpTest) {
			const array = e.currentTarget.value.split(".");
			const insertDate = new Date(+array[2], +array[1] - 1, +array[0]);
			formik.setFieldValue(formItem, insertDate);
		}
		setDateValue && setDateValue(e.currentTarget.value);
	};
	const calendarChangeHandler = (e: Date) => {
		formik.setFieldValue(formItem, e);
		setDateValue && setDateValue(dateIntoString(e));
		updateFormHandler &&
			updateFormHandler({
				...formik.values,
				[formItem]: e,
			});
		setShowCalendar(false);
	};
	return (
		<Flex direction="column" Position="relative" padding={fieldsWrapConditions ? "0" : "10px 0"}>
			{label && <String fSize="14px" margin="0 0 8px">{label}</String>}
			<Flex direction="column" Position="relative" width={width}>
				<Input
					mask={[ /[0-3]/, /[0-9]/, ".", /[0-1]/, /[0-9]/, ".", /[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/ ]}
					backgroundColor={backgroundColor}
					padding={"0 10px"}
					borderColor={borderColor}
					dateValue={dateValue}
					setDateValue={setDateValue}
					name={formItem}
					width="100%"
					height={props.height}
					fSize={props.fSize}
					value={dateValue}
					onChange={onChangeInputDateHandler}
					onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
						setShowCalendar(false);
						updateFormHandler && updateFormHandler({ ...formik.values, [formItem]: stringIntoDate(e.currentTarget.value) });
					}}
					onFocus={(event) => {
						event.target.setAttribute("autocomplete", "off");
					}}
					type={"tel"}
					showMask={false}
					guide={false}
					onBlurCapture={() => formik.setFieldTouched(formItem)}
					error={error}
				/>
				<CalendarIcon
					size={30}
					onClick={() => setShowCalendar(!showCalendar)}
					color={
						showCalendar
							? defaultTheme.appColors.commonTheme
							: defaultTheme.appColors.greyText
					}
				/>
				{showCalendar && (
					<StyledCalendar
						locale="RU"
						width={width}
						formik={formik}
						formItem={formItem}
						value={value}
						onChange={calendarChangeHandler}
						maxDate={maxDate}
						minDate={minDate}
					/>
				)}
			</Flex>
			{errorValue && !showCalendar && (
				<StyledErrorNotificator Top={Top}>{errorValue}</StyledErrorNotificator>
			)}
		</Flex>
	);
};