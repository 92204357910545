import { FormikValues } from 'formik';

export const yaersHandler = (value: string): string => {
	switch (true) {
		case +value === 1:
			return `${value} год`;
		case +value > 1 && +value < 5:
			return `${value} годa`;
		case +value >= 5:
			return `${value} лет`;
		default:
			return `${value}`;
	}
};

export const bigSumHandler = (value: string): string => {
	switch (true) {
		case value.length < 7 && value.length >= 4:
			return `${value.slice(0, -3)} тыс`;
		case value.length >= 7:
			return `${value.slice(0, -6)} млн`;
		default:
			return `${value}`;
	}
};

export const FormatCurrancy = (value: string | number): string => {
	return value
		.toString()
		.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + " ");
};

export const checkPatronymic = (fio: string): number => {
	return fio?.split(" ")?.filter((el) => el !== "")?.length || 0;
};

export const reformatPhoneNumber = (val: string): string => {
	const valArr = val?.split("");
	const outletArr: string[] = [];
	valArr?.forEach((element) => {
		if (/[0-9]/.test(element)) {
			outletArr.push(element);
		}
	});
	if (outletArr[0] === "7") {
		outletArr[0] = "8";
	}
	const phone = outletArr;
	return phone.join("");
};

export const getFlowIdFromParams = (url: string) => {
	const urlParts = url.split("/");
	return urlParts[urlParts.length - 1];
};

export const rangeStepsHandler = (value: number): number => {
	// Разбиваем диапазон суммы кредита на шаги
	// от 100 000 до 300 000 - шаги по 5 000,
	// от 300 000 до 1 000 000 - по 10 000,
	// от 1 000 000 до 5 000 000 - по 100 000
	if (value < 100000) {
		return 1000;
	} else if (value >= 100000 && value < 300000) {
		return 5000;
	} else if (value >= 300000 && value < 1000000) {
		return 10000;
	} else if (value >= 1000000) {
		return 100000;
	}
	return 1;
};

export const addZero = (num: number): string => {
	if (num < 10) {
		return `0${num}`;
	} else {
		return num.toString();
	}
};

export const dateIntoString = (date: Date): string => {
	return date?.toLocaleDateString("ru-RU");
};

export const toIsoString = (date: Date | null) => {
	if (date) {
		const tzo = -date.getTimezoneOffset(),
		dif = tzo >= 0 ? '+' : '-',
		pad = (num: number) => (num < 10 ? '0' : '') + num;
		return date.getFullYear() +
				'-' + pad(date.getMonth() + 1) +
				'-' + pad(date.getDate()) +
				'T' + pad(date.getHours()) +
				':' + pad(date.getMinutes()) +
				':' + pad(date.getSeconds()) + '.000Z'
	}
}

export const stringIntoDate = (str: string): Date => {
	const array = str.split(/(\D)/);
	const date = new Date(`${addZero(+array[4])}/${addZero(+array[2])}/${addZero(+array[0])}`);
	return date
};

export const checkDate = (value: string): boolean => {
	const regExp = new RegExp(/^(0?[1-9]|[12][0-9]|3[01])[\.\-](0?[1-9]|1[012])[\.\-]\d{4}$/);
	return regExp.test(value);
};

export const extractValue = <T extends { title: string; code: string }>(arr: T[], fieldCurrentValue: string | null): string | null => {
	const foundItem: T | undefined = arr.find((item) => item.title === fieldCurrentValue);
	if (foundItem) {
		return foundItem.code;
	}
	return null;
};

// FORMIK CONTROL
export const setAllFormikFieldsTouched = (formik: FormikValues) => {
	for (const key in formik.values) {
		formik.setFieldTouched(key, true);
	}
};

export const isFormikValid = (formik: FormikValues) => {
	if (Object.keys(formik.errors).length) {
		return true;
	}
	return false;
};

export const onSetFieldsTouched = (fieldNames: string[]) => {
	let result = {}
	fieldNames.forEach(field => {
		result = { ...result, [field]: true }
	})
	return result
}