import { IConsents, ISignData, IValidatedSignature } from './segn-docs-types';

export const SET_CONSENTS = "signDocs/SET_CONSENTS";
export const SET_VALIDATION = "signDocs/SET_VALIDATION";
export const SET_CALL_SIGN_DATA = "signDocs/SET_CALL_SIGN_DATA";

export const setConsents = (payload: IConsents | undefined) => {
	return { type: SET_CONSENTS, payload } as const;
};

export const setCallSignData = (payload: ISignData | undefined) => {
	return { type: SET_CALL_SIGN_DATA, payload } as const
}

export const setVaidationResult = (payload: IValidatedSignature | undefined) => {
	return { type: SET_VALIDATION, payload } as const;
};