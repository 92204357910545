import { FormikValues } from 'formik';
import styled from 'styled-components';
import { adaptiveParams } from '../../../adaptiveParams';
import { FieldStyles, StyledFieldsProps } from '../../CustomField/CustomFieldStyles';
import { Flex } from '../../Flex';
import { String } from '../../String';
import FejoaCustomRange from '../IFejoaRanges/FejoaRanges';

const FejoaInputContainer = styled.div<IFejoaInput>`
	// border: 1px solid red;
	// width: 100%;
	display: flex;
	max-width: ${({ maxWidth }) => maxWidth || "auto"};
	flex-direction: column;
	align-items: center;
	position: relative;
`;

const Limits = styled.div`
	position: absolute;
	bottom: -25px;
	width: 100%;
`;

const Tag = styled.div`
	position: absolute;
  font-family: 'Formular';
	font-size: 18px;
	right: 5px;
	top: 50%;
	transform: translate(-50%, -50%);
	color: #7e829b;
`;

const Hint = styled.span`
	color: red;
	font-family: Formular;
	font-size: 14px;
`;

interface IFejoaInput  extends StyledFieldsProps {
	min: number;
	max: number;
  padding?: string;
  margin?: string;
	value: number;
	valueHandler?: (value: string) => string;
	specialTag: string;
	formik: FormikValues;
	formItem: string;
	formatting?: (value: string) => string;
	maxWidth?: string;
	width?: string;
	label?: string;
	disabled?: boolean;
	step?: number;
	screenWidth: number;
	onBlurHandler: (values: any) => void;
	hint?: string | null;
}

export const FejoaInput: React.FC<IFejoaInput> = (props) => {
	const {
		min,
		max,
		value,
		valueHandler,
		specialTag,
		formik,
		formItem,
		formatting,
		maxWidth,
		width,
    height,
    fSize,
		label,
		disabled,
		step,
		screenWidth,
		onBlurHandler,
		hint,
	} = props;

	const onHandleStateByFormName = (value: number) => {
		switch(formItem) {
			case "term": return formik.setFieldValue(formItem, value * 12);
			default: return formik.setFieldValue(formItem, value)
		}
	}

	const onHandleValueByFormName = (value: string): number => {
		switch(formItem) {
			case "term": return +value.replaceAll(/\s/g, "") * 12
			default: return +value.replaceAll(/\s/g, "")
		}
	}

	const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const maxLength = 10;
		const value = e.target.value;
		if (+value.replace(/\s/g, "").length <= maxLength) {
			const repl = value.replace(/\s/g, "");
			if (!isNaN(+repl)) {
				onHandleStateByFormName(+repl)
			}
		}
	};

	const { mainContentWidth } = adaptiveParams;

	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;
	return (
		<Flex
			direction="column"
      width={width}
      padding={props.padding}
			margin={props.margin ? props.margin : (fieldsWrapConditions ? "20px 0" : "20px 10px")}
		>
			{label && <String fSize={"14px"} lineHeight="19px" margin="0 0 8px 0">{label}</String>}
			<FejoaInputContainer {...props}>
				<FieldStyles
					type={"tel"}
					name={formItem}
					maxWidth={maxWidth || "360px"}
					fSize={fSize}
					width={"100%"}
					height={height}
					value={formatting ? formatting(`${value}`) : `${value}`}
					onChange={onInputChangeHandler}
					onBlur={(e: any) => {
						if (+e.target.value.replace(/\s/g, "") > max) {
							onHandleStateByFormName(max)
						}
						if (+e.target.value.replace(/\s/g, "") < min) {
							onHandleStateByFormName(min)
						}
						onBlurHandler && onBlurHandler({
							...formik.values,
							[formItem]: onHandleValueByFormName(e.currentTarget.value)
						});
					}}

					disabled={disabled}
				/>
				<FejoaCustomRange
					onHandleValueByFormName={onHandleValueByFormName}
					onBlurHandler={onBlurHandler}
					min={min < 0 ? 0 : min}
					max={max}
					value={value}
					formik={formik}
					formItem={formItem}
					step={step || 1}
				/>
				<Limits>
					<Flex justify="space-between">
						<String fSize={"12px"}>
							{valueHandler && valueHandler(`${min}`)}
						</String>
						{hint && <Hint>{hint}</Hint>}
						<String fSize={"12px"}>
							{valueHandler && valueHandler(`${max}`)}
						</String>
					</Flex>
				</Limits>
				<Tag>{specialTag}</Tag>
			</FejoaInputContainer>
		</Flex>
	);
};