import { FormikValues } from 'formik';
import React, { ChangeEvent, FC, useCallback, useEffect, useRef } from 'react';
import './IFejoaRanges.css';

interface MultiRangeSliderProps {
	min: number;
	max: number;
	value: number;
	formik: FormikValues;
  formItem: string;
	step: number;
	onBlurHandler: (values: any) => void;
	onHandleValueByFormName: (value: string) => number;
}

const FejoaCustomRange: FC<MultiRangeSliderProps> = ({
	min,
	max,
	value,
	formik,
	formItem,
	onBlurHandler,
	onHandleValueByFormName,
	step,
}) => {
	const minValRef = useRef(min);
	const maxValRef = useRef(max);
	const range = useRef<HTMLDivElement>(null);

	const getPercent = useCallback((val: number) => {
		return Math.round(((val - min) / (max - min)) * 100)
	}, [min, max]);

	useEffect(() => {
		const minPercent = getPercent(value) > 100 ? 100 : getPercent(value);
		const maxPercent = getPercent(maxValRef.current)

    const calculatedPercent = maxPercent - minPercent;

		if (range.current) {
			range.current.style.left = `${minPercent < 0 ? 0 : minPercent}%`;
			range.current.style.width = `${calculatedPercent > 100 ? 100 : calculatedPercent}%`;
		}
	}, [value, getPercent]);
	return (
		<div className="common_wrapper">
			<input
				type="range"
				min={min}
				max={max}
				value={value}
				onChange={(event: ChangeEvent<HTMLInputElement>) => {
					const value = formItem === "term"
						? Math.min(Number(event.target.value)) * 12
						: Math.min(Number(event.target.value));
					formik.setFieldValue(formItem, value)
					minValRef.current = value;
				}}
				className="thumb thumb--left"
				onClick={(e) => {
					onBlurHandler && onBlurHandler({
						...formik.values,
						[formItem]: onHandleValueByFormName(e.currentTarget.value)
					});
				}}
				step={step}
			/>
			<div className="slider">
				<div className="slider__track"></div>
				<div ref={range} className="slider__range"></div>
			</div>
		</div>
	);
};

export default FejoaCustomRange;
