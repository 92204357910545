import { FormikValues } from 'formik';
import { AiOutlineEdit } from 'react-icons/ai';
import { BsTrash } from 'react-icons/bs';
import styled from 'styled-components';
import { IAdvancedOptions } from '../../../redux/Credit/creditTypes';
import { Flex } from '../../../styles/UIKit/Flex';
import { String } from '../../../styles/UIKit/String';

const Item = styled.div<IItemsList>`
	/* border: 1px solid ${({ theme }) => theme.appColors.greyText}; */
	color: ${({ theme }) => theme.appColors.greyText};
	width: 100%;
	padding: 10px;
	font-size: 20px;
	font-family: Formular;
	border-radius: 10px;
	background: ${({ theme }) => theme.FEI_GREY_LIGHT};
	margin: 10px 0;
	&:hover {
		/* background: ${({ theme }) => theme.FEI_GREY_LIGHT}; */
		/* color: #ffffff; */
	}
`;

const RemoveItemIcon = styled(BsTrash)`
	cursor: pointer;
	color: #b3b3b3;
	&:hover {
		transition: .7s color;
		color: ${({ theme }) => theme.FEI_ERROR};
	}
`;

const EditItemIcon = styled(AiOutlineEdit)`
	cursor: pointer;
	color: #b3b3b3;
	margin: 0 10px 0 0;
	&:hover {
		transition: .7s color;
		color: ${({ theme }) => theme.appColors.commonTheme};
	}
`;

const ItemControlBlock = styled.div`
	display: flex;
	align-items: center;
	position: absolute;
	top: -7px;
  right: -7px;

`;

interface IItemsList {
	mappingList: IAdvancedOptions[];
	list: any[];
	setList: (list: any[]) => void;
	updateFormCallBackForAdditionalIncomesData: (
		formItem: string,
		currentValue: any[]
	) => void;
	formik: FormikValues;
	renderBy: string;
	editItemById: (item: any | null) => void;
}

export const ItemsList: React.FC<IItemsList> = (props: IItemsList) => {
	const {
		mappingList,
		list,
		setList,
		renderBy,
		editItemById,
		updateFormCallBackForAdditionalIncomesData,
	} = props;
	return (
		<>
			{list.map((el) => {
				const removeItemHandler = () => {
					const removedItemList = list.filter(
						(item) => item.id !== el.id
					);
					setList(removedItemList);
					updateFormCallBackForAdditionalIncomesData(
						"additionalIncomes",
						removedItemList
					);
				};
				const editItemHandler = () => {
					editItemById(el);
				};
				const itemName = mappingList.filter(item => item.code === el[renderBy])[0]?.title
				return (
					<Item {...props} key={el.id}>
						<Flex padding='25px 0 0 0' justify={"space-between"} fFamily="Formular" Position="relative">
							<Flex justify='space-between' align='center' width='100%'>
								<String fSize='19px'>
									{itemName}
								</String>
								<String whiteSpace='nowrap'>
									{el.occupationAdditionalIncomeValue}{" "}₽
								</String>
							</Flex>
							{/* {itemName}, {el.occupationAdditionalIncomeValue}{" "}₽ */}
							<ItemControlBlock>
								<EditItemIcon
									size={25}
									onClick={editItemHandler}
								/>
								<RemoveItemIcon
									size={20}
									// color={"red"}
									onClick={removeItemHandler}
								/>
							</ItemControlBlock>
						</Flex>
					</Item>
				);
			})}
		</>
	);
};