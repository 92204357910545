import { ROUTE_PATH } from "../../../routesConfig"
import { Flex } from "../../../styles/UIKit/Flex";
import { CustomNavLink } from "../SubHeader/CustomNavLink"
import { Navigations } from "../SubHeader/Navigations"
import { useSearchParams } from "react-router-dom";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../../redux/store";

export const BurgerNavButtons: React.FC<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  currentProduct: string;
}> = (props) => {
  const isTestMode = useSelector<AppRootStateType, boolean>(state => state.appReducer.isTestMode)
  const {isOpen, setIsOpen} = props;
  const [searchParams] = useSearchParams();
  const params = searchParams.toString();
  const linkSuffix = params ? ('?' + params) : '';
  return (
    <Flex margin="100px 0 0 0" width="100%" justify="center" align="center">
    <Navigations buttonsDirection={"column"}>
			{(!isTestMode) &&
				<CustomNavLink
					to={`/${ROUTE_PATH.CREDITS}/${ROUTE_PATH.START}${linkSuffix}`}
					closeBurgerMenu={() => setIsOpen(false)}
				>
					КРЕДИТЫ
				</CustomNavLink>
			}
			{(props?.currentProduct === "cpza" || !isTestMode) &&
				<CustomNavLink
					to={`/${ROUTE_PATH.CPZA}/${ROUTE_PATH.START}${linkSuffix}`}
					closeBurgerMenu={() => setIsOpen(false)}
				>
					КРЕДИТ ПОД ЗАЛОГ АВТО
				</CustomNavLink>
			}
			{(props?.currentProduct === "cards" || !isTestMode) &&
				<CustomNavLink
					to={`/${ROUTE_PATH.CARDS}/${ROUTE_PATH.START}${linkSuffix}`}
					closeBurgerMenu={() => setIsOpen(false)}
				>
					КАРТЫ
				</CustomNavLink>
			}
		</Navigations>
    </Flex>
  )
}