import React from 'react';
import { Button } from '../Button';
import styled from 'styled-components';
import {defaultTheme} from "../../themes/themes";

interface IFileButton {
	handleFile: (fileUploaded: File) => void;
	buttonTitle: string;
}

const StyledInputLoader = styled.input`
    display: none;
`;

export const FileButton: React.FC<IFileButton> = (props) => {
	const { handleFile, buttonTitle } = props;

	const hiddenFileInput = React.useRef<HTMLInputElement | null>(null);

	const handleClick = () => {
		hiddenFileInput.current && hiddenFileInput.current.click();
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			const fileUploaded = event.target.files[0];
			handleFile(fileUploaded);
		}
	};
	return (
		<>
			<Button
				mode="primary"
				background="transparent"
				color={defaultTheme.appColors.commonTheme}
				padding="0"
				onClick={handleClick}
				type={"button"}
			>
				{buttonTitle}
			</Button>
			<StyledInputLoader
				type="file"
				ref={hiddenFileInput}
				onChange={handleChange}
			/>
		</>
	);
};