import {ICommonFormData} from "../../commonTypes";
import {ICreditInitState, ISubmitCreditConfig} from "../creditTypes";

export const redefineInitialData = (superRes: ICommonFormData<ISubmitCreditConfig, ICreditInitState> | undefined, product: string) => {
  
  if(product === "cpza" && superRes  ) {
    superRes.config.preferentialRate = 17;
    superRes.config.minCreditAmount = 300000;
    superRes.config.maxCreditAmount = 5000000;
    superRes.config.minCreditTerm = 36;
    superRes.config.maxCreditTerm = 84;
  }

  if(product === "credits" && superRes  ) {
    superRes.config.preferentialRate = (superRes.state.utmCampaign === "MGM1" || !superRes.state.utmCampaign) ? 15.5 : 12.8;
    superRes.config.minCreditTerm = 36;
  }

  if(product === "cards") {
    if(superRes?.config.maxAllowedAge) superRes.config.maxAllowedAge = 69
  }

  return superRes
}