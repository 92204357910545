import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
	width?: string;
	minWidth?: string;
	maxWidth?: string;
	height?: string;
	minHeight?: string;
	maxHeight?: string;
	padding?: string;
	margin?: string;
	borderRadius?: string;
	FSize?: string;
	color?: string;
	background?: string;
	mode: "primary" | "outlined";
	type?: "submit" | "button";
	fontFamily?: string;
	fontWeight?: string;
	disabled?: boolean;
	onClick?: () => void;
  className?: string;
};

const StyledButten = styled.button<Props>`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${(props) => props.width || "auto"};
	min-width: ${(props) => props.minWidth || "auto"};
	max-width: ${(props) => props.maxWidth || "auto"};
	height: ${(props) => props.height || "auto"};
	min-height: ${(props) => props.minHeight || "auto"};
	max-height: ${(props) => props.maxHeight || "auto"};
	padding: ${(props) => props.padding || "18px 41px"};
	margin: ${(props) => props.margin || "0"};
	border-radius: ${(props) => props.borderRadius || "4px"};
	font-family: ${(props) => props.fontFamily || "open-sans"};
	font-weight: ${(props) => props.fontWeight || "bold"};
	font-size: ${(props) => props.FSize || "18px"};
	${(props) =>
		props.mode === "primary" &&
		css<Props>`
			color: ${(props) => props.color || props.theme.mainColor1};
			background: ${(props) =>
				props.background || props.theme.appColors.commonTheme};
			border: none;
		`}
	${(props) =>
		props.mode === "outlined" &&
		css<Props>`
			color: ${(props) =>
				props.color || props.theme.appColors.commonTheme};
			border: 1px solid
				${(props) => props.color || props.theme.appColors.commonTheme};
			background: transparent;
		`}
	&:disabled {
		opacity: 0.5;
	}
	&:hover {
		// opacity: 0.5;
	}
`;
export const Button: React.FC<Props> = (props) => {
	// const loadingMode = useSelector<AppRootStateType, TLoadingMode>(
	// 	(state) => state.appReducer.appLoadingMode
	// );
	return <StyledButten {...props} />;
};