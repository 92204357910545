import { FormikValues } from 'formik';
import React from 'react';
import styled from 'styled-components';
import useDimensions from '../../hooks/useDimensions';
import { adaptiveParams } from '../adaptiveParams';
import { String } from './String';
import { StyledErrorNotificator } from './StyledErrorNotificator';
import {defaultTheme} from "../themes/themes";

const GenderWrapper = styled.div<{ width: string, padding?: string, name: string }>`
	// border: 1px solid green;
	// max-width: 360px;
	position: relative;
	padding: ${({ padding }) => padding};
	display: flex;
	flex-direction: column;
	width: ${({ width }) => width};
`;

const ChosingBlock = styled.div`
	display: flex;
	justify-content: space-between;
`;
interface IOption {
	// margin: string;
	background?: string;
	height: string;
	border?: string;
	borderRadius: string;
}
const Option = styled.div<IOption>`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 2px solid ${({ border }) => border || "transparent"};
	background: ${({ theme, background }) => background || theme.appColors.secondaryGrey};
	border-radius: ${({ borderRadius }) => borderRadius};
	width: 100%;
	height: ${({ height }) => height};
	&:hover {
		opacity: 0.5;
	}
`;

interface IGender {
	formik: FormikValues;
	width: string;
	height: string;
	fSize: string;
	updateFormHandler: (values: any) => void;
	borderColor: string;
}

export const Gender: React.FC<IGender> = React.memo((props) => {
	const { formik, width, height, fSize, updateFormHandler, borderColor } = props;
	const { gender } = formik.values;
	const { commonTheme, secondaryGrey } = defaultTheme.appColors;

	const {screenWidth} = useDimensions()

	const { mainContentWidth, minimalWrap } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;


	const onMaleChose = () => {
		formik.setFieldTouched("gender", true, true);
		formik.setFieldValue("gender", "M");
		updateFormHandler({
			...formik.values,
			gender: "M",
		});
	};
	const onFemaleChose = () => {
		formik.setFieldTouched("gender", true, true);
		formik.setFieldValue("gender", "F");
		updateFormHandler({
			...formik.values,
			gender: "F",
		});
	};
	const errorValue = formik.touched.gender && formik.errors.gender;
	return (
		<GenderWrapper width={width} padding={fieldsWrapConditions ? "0" : "10px 0"} name="gender">
			<String fSize="14px" margin="0 0 8px">Укажите пол</String>
			<ChosingBlock>
				<Option
					borderRadius={"4px 0 0 4px"}
					onClick={onMaleChose}
					background={gender === "M" ? commonTheme : secondaryGrey}
					height={height}
					border={borderColor}
				>
					<String
						onClickHandler={() => {}} // TODO -> Была плохая идея -> ИСПРАВИТЬ
						fSize={fSize}
						color={gender === "M" ? "#FFFFFF" : "#0A1244"}
					>
						Муж
					</String>
				</Option>
				<Option
					borderRadius={"0 4px 4px 0"}
					onClick={onFemaleChose}
					background={
						gender === "F" ? commonTheme : secondaryGrey
					}
					height={height}
					border={borderColor}
				>
					<String
						onClickHandler={() => {}}
						fSize={fSize}
						color={gender === "F" ? "#FFFFFF" : "#0A1244"}
					>
						Жен
					</String>
				</Option>
			</ChosingBlock>
			{errorValue && (
				<StyledErrorNotificator Top={fieldsWrapConditions ? "100px" : "95px"}>{errorValue}</StyledErrorNotificator>
			)}
		</GenderWrapper>
	);
});
