import React from 'react';
import styled from 'styled-components';

interface INavigations {
	buttonsDirection: "row" | "column";
}

const StyledNavigations = styled.div<INavigations>`
	display: flex;
    align-items: center;
	flex-direction: ${({buttonsDirection}) => buttonsDirection};
`;

export const Navigations: React.FC<INavigations> = (props) => {
	return <StyledNavigations {...props} />;
};