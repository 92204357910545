import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ICalculatedCreditValues, ISubmitCreditConfig } from '../../../redux/Credit/creditTypes';
import { AppRootStateType } from '../../../redux/store';
import { FormatCurrancy } from '../../../utils/commonFunctions';
import { String } from '../String';
import { Title } from '../Title';
import { adaptiveParams } from '../../adaptiveParams';
import { Devider } from '../Devider';
import { Flex } from '../Flex';
import { CalculatorFormNote } from '../../../components/CalculatorFormNote';
import { ROUTE_PATH } from '../../../routesConfig';
import { useSearchParams } from 'react-router-dom';
import {defaultTheme} from "../../themes/themes";

const StickyWrapper = styled.div<{ position: string }>`
	position: ${({ position }) => position};
	top: 100px;
`;

const StyledMonthlyPayment = styled.div<{hideBoxShadow?: boolean, noPadding?: boolean}>`
	box-shadow: ${(props) => props.hideBoxShadow ? 'unset' : '1px -5px 29px 6px rgba(34, 60, 80, 0.2)'};
	background: #ffffff;
  border-radius: 4px;
	padding: ${ props => props.noPadding ? 'unset' : "25px 30px"};
	display: flex;
	justify-content: center;
	align-items: center;
`;

const MonthlyPaymentContent = styled.div`
	width: 100%;
	margin-top: 10px;
`;


const InformativeWrapper = styled.div<{textAlign?: string}>`
	text-align: ${props => props.textAlign || "justify"};
	padding: 15px 0;
	width: 100%;
`;

const Button = styled.a`
	display: flex;
	padding: 18px 32px;
	justify-content: center;
	background: #10C8D2;
	color: #FFF;
	font-family: Formular;
	font-weight: 700;
	border-radius: 6px;
	margin: 20px 25px;
	cursor: pointer;
	text-decoration: none;
`

type Props = {
	describer?: string;
	screenWidth: number;
	product: string;
	userPhone?: string;
	flowId: string;
};

export const MonthlyPayment: React.FC<Props> = (props) => {
	const { screenWidth, product, describer, userPhone, flowId } = props;

	const [searchParams] = useSearchParams();
	const isReferral = searchParams.get('referral');
	const config = useSelector<AppRootStateType, ISubmitCreditConfig | undefined>(state => state.creditReducer.initialCreditsData?.config);

	const formId = useSelector<AppRootStateType, string | undefined>(state => state.appReducer.currentForm.formId)

	const isCredit = product === "credits"
	const isCpza = product === "cpza"
	const isCard = product === "cards"

	const calculatedCreditValues = useSelector<AppRootStateType, ICalculatedCreditValues | undefined>((state) => state.creditReducer.calculatedCreditValues);
	const isMobileVersionWidth: boolean = screenWidth < adaptiveParams.mainContentWidth;
	const accentFontSize =  isMobileVersionWidth ? "16px" : "17.64px";

	const onToStartFill = () => {
		window.history.pushState(undefined, '', '#anketa');
		document.querySelector("#anketa")?.scrollIntoView({ behavior: 'smooth' , block: 'center'});
	};

	return (
		<>
			<StickyWrapper position={formId === ROUTE_PATH.PASSPORT || screenWidth < 1200 ? "static" : "sticky"}>
				<StyledMonthlyPayment noPadding={isMobileVersionWidth} hideBoxShadow={isMobileVersionWidth}>
					{calculatedCreditValues ? (
						<MonthlyPaymentContent>
							<Title
								color="#000001"
								fSize="24px"
								lineHeight="32px"
								margin={
									isMobileVersionWidth ? "10px" : "0 0 24px"
								}
							>
								Наше предложение
							</Title>
							<Flex
								direction="column"
								margin={
									isMobileVersionWidth ? "10px" : "0"
								}
								gap={isMobileVersionWidth && (isCredit || isCpza) ? '10px' : undefined}
							>
								{(isCredit || isCpza) && <>
									<String fSize={'12.03px'} color={'#9DA0B3'}>Сумма кредита</String>
									<Title color={"#10C8D2"} fSize={'23px'} margin={'0 0 15px'}>
										{FormatCurrancy(
											calculatedCreditValues.creditAmount
										)}{" "}
										₽
									</Title>
								</>}
								{isCard && <>
									<String fSize={'12.03px'} color={'#9DA0B3'}>Кредитный лимит</String>
									<Title color={"#10C8D2"} fSize={'23px'} margin={'0 0 15px'}>
										{FormatCurrancy(calculatedCreditValues.creditAmount)} ₽
									</Title>
								</>}
								{!(isCredit || isCpza) && <Flex margin="10px 0">
									<Devider background="#10C8D2" />
								</Flex>}
								{(isCredit || isCpza) && <>
									<String fSize={'12.03px'} color={'#9DA0B3'}>Ежемесячный платеж</String>
									<Title color={"#10C8D2"} fSize={'23px'} margin={'0 0 15px'}>
										{FormatCurrancy(
											calculatedCreditValues.monthlyPayment
										)}{" "}
										₽
									</Title>
								</>}
								{/*<>*/}
								{/*	<String fSize={'12.03px'} color={'#9DA0B3'}>!!! percentRate !!!</String>*/}
								{/*	<Title color={"#10C8D2"} fSize={'23px'} margin={'0 0 15px'}>*/}
								{/*		{FormatCurrancy(*/}
								{/*			calculatedCreditValues.percentRate*/}
								{/*		)} %*/}
								{/*	</Title>*/}
								{/*</>*/}
								{isCard && <>
									<String fSize={'12.03px'} color={'#9DA0B3'}>Без процентов</String>
									<Title color={"#10C8D2"} fontWeight="400" fSize={'23px'} margin={'0 0 15px'}>до 4-х месяцев</Title>
								</>}
							</Flex>
							<Flex
								direction="column"
								margin={
									isMobileVersionWidth ? "10px" : "0"
								}
							>
							{isCard && <>
								<String fSize={'12.03px'} color={'#9DA0B3'}>Выпуск и обслуживание</String>
								<Title color={"#10C8D2"} fontWeight="400" fSize={'23px'} margin={'0 0 15px'}>
									Бесплатно
								</Title>
								{isReferral &&
									<>
										<String fSize={'12.03px'} color={'#9DA0B3'}>Подарок от банка</String>
										<Title color={"#10C8D2"} fontWeight="400" fSize={'23px'} margin={'0 0 15px'}>
											{(isReferral && isCard) ? `+ 500 ₽ на бонусный счет` : `+ ${config?.utmGift} ₽`}
										</Title>
									</>
								}
							</>}
							{isCredit && isReferral &&
								<>
									<String fSize={'12.03px'} color={'#9DA0B3'}>Подарок от банка</String>
									<Title color={"#10C8D2"} fontWeight="900" fSize={'23px'} margin={'0 0 15px'}>
										+ 1000 ₽ на бонусный счет
									</Title>
								</>
							}
							</Flex>
							{isMobileVersionWidth && (isCredit || isCpza) && <Flex padding={"0 10px"}>
								<String color={"#9DA0B3"} fSize={"12px"} lineHeight={"normal"}>
									Расчет калькулятора приблизительный и носит информационный характер. Произведён с учетом оформления
									услуги финансовой защиты к кредиту. Точные параметры определяются по результатам рассмотрения заявки.
									Не является публичной офертой.
								</String>
							</Flex>}
						</MonthlyPaymentContent>
					) : (
						<Flex direction="column">
							<Title
								color={defaultTheme.appColors.commonTheme}
								margin="0 0 20px 0"
							>
								Кредитный калькулятор
							</Title>
							<String>
								Более подробную информацию о нашем сервисе вы
								можете получить перейдя по ссылке в шапке
								страницы
							</String>
						</Flex>
					)}
				</StyledMonthlyPayment>
				{
					(screenWidth > 1190) && <InformativeWrapper>
						<CalculatorFormNote
							margin={isMobileVersionWidth && isCard ? "0 24px" : "unset" }
							fullWidth={true} product={product}
							display={isMobileVersionWidth && isCard ? "inline-block" : "inline"}
						/>
					</InformativeWrapper>
				}
			</StickyWrapper>
		</>
	);
};
