import React from 'react';
import { LinkProps } from 'react-router-dom';
import styled from 'styled-components';

const StyledStringLink = styled.a`
	color: ${(props) => props.color || props.theme.appColors.commonTheme};
    text-decoration: none;
	font-family: Formular;
`;

interface Props extends LinkProps {
	color?: string;
}

export const StringLink: React.FC<Props> = (props) => {
	return <StyledStringLink {...props} />;
};