import { ROUTE_PATH } from '../../../routesConfig';
import { adaptiveParams } from '../../../styles/adaptiveParams';
import { CustomProgressBar } from '../../../styles/UIKit/CustomProgressBar';
import { Flex } from '../../../styles/UIKit/Flex';
import { String } from '../../../styles/UIKit/String';
import { Title } from '../../../styles/UIKit/Title';
import { ParagraphWrapper } from '../../CommonPagesStyles';

interface IFirstInformativeBlock {
	screenWidth: number;
	product: string;
	isCard: boolean;
}

export const FirstInformativeBlock: React.FC<IFirstInformativeBlock> = (props) => {
	const { screenWidth, product, isCard } = props;
	const { mainContentWidth } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;

	const START_PRODUCT_TITLE: {[key: string]: string} = {
		[ROUTE_PATH.CREDITS]: "Рассчитайте свой кредит",
		[ROUTE_PATH.CPZA]: "Рассчитайте свой кредит",
		[ROUTE_PATH.CARDS]: "Рассчитайте свою карту",
	}

	return (
		<div id="anketa">
			<Flex direction="column" align="flex-start" margin={fieldsWrapConditions ? "0px" : "0px 10px"}>
				<Title
						color="#000001"
						fSize="24px"
	          lineHeight="32px"
					>
						{START_PRODUCT_TITLE[product]}
					</Title>
				<ParagraphWrapper margin="20px 0 0 0">
					<String
						fSize={screenWidth > 991 ? "12.03px" : "14px"}
						color={screenWidth > 991 ? "#9DA0B3" : undefined}
					>
						Шаг 1 из 4.{" "}
					</String>
					<String fSize="12.03px" color="#9DA0B3" fontWeight={screenWidth > 991}>
						Получите +40% к одобрению, заполнив первый шаг{" "}
					</String>
				</ParagraphWrapper>
				<CustomProgressBar
					completed={"40%"}
					width={"100%"}
					margin={"10px 0"}
				/>
			</Flex>
		</div>
	);
};
