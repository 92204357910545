import React from 'react';
import { FormikValues } from 'formik';
import { PRODUCTS_CODES } from '../config';

export const useProductSetter = (formik: FormikValues, product: string) => {
  React.useEffect(() => {
    if (!formik.values.productCode) {
      formik.setFieldValue('productCode', PRODUCTS_CODES[product])
    }
  }, [product, formik.values.productCode])
}