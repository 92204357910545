import { FormikValues } from 'formik';
import { OCUPATION_TYPES_MAPPER } from '../../../../../config';
import { IAdvancedOptions, IProfileConfig2 } from '../../../../../redux/Credit/creditTypes';
import { AnimatedBlock } from '../../../../../styles/UIKit/AnimatedBlock';
import { CurrancyField } from '../../../../../styles/UIKit/CurrancyField';
import { CheckBoxField } from '../../../../../styles/UIKit/CustomCheckBox/CheckBoxField';
import { FejoaMappedSelect } from '../../../../../styles/UIKit/FejoaSelect/FejoaMappedSelect';
import { Flex } from '../../../../../styles/UIKit/Flex';
import { String } from '../../../../../styles/UIKit/String';
import { onSetFieldBorderColor } from '../../../../../utils/onSetFieldBorderColor';
import { ParagraphWrapper } from '../../../../CommonPagesStyles';

interface IOtherIncomes {
	formik: FormikValues;
	fieldsWrapConditions: boolean;
	standartWidthMeasures: string;
	standartHeightMeasures: string;
	standartFontSizeMeasures: string;
	otherIncomesRef: IAdvancedOptions[];
	profileConfig: IProfileConfig2;
	updateFormCallBack: (
		formItem: string,
		currentValue: string,
		event: FormikValues
	) => void;
}

export const OtherIncomes: React.FC<IOtherIncomes> = (props) => {
	const {
		formik,
		fieldsWrapConditions,
		standartHeightMeasures,
		standartFontSizeMeasures,
		updateFormCallBack,
		profileConfig,
		otherIncomesRef,
	} = props;

	const onFilterDocConfirmationIncomes = (item: IAdvancedOptions) => {
		if (item.parentCode && item.otherIncomes) {
			const isMatchParentCode: boolean = item.parentCode === OCUPATION_TYPES_MAPPER.Other;
			const isMatchIncomesKind: boolean = item.otherIncomes.includes(formik.values.occupationIncomesKind);
			return isMatchParentCode && isMatchIncomesKind;
		}
	}

	return (
		<ParagraphWrapper padding={fieldsWrapConditions ? "0" : "10px"}>
			<FejoaMappedSelect
        formik={formik}
        updateFormCallBack={updateFormCallBack}
				formItem={"occupationIncomesKind"}
        height={standartHeightMeasures}
				fSize={standartFontSizeMeasures} // occupationAdditionalIncomeValue
				width={"100%"}
        options={otherIncomesRef}
        defaultValue={"Выберите"}
				label={"Источник дохода"}
				borderColor={ onSetFieldBorderColor(formik, "occupationIncomesKind") }
				errorValue={ formik.touched.occupationIncomesKind && formik.errors.occupationIncomesKind }
      />
			<CurrancyField
				formik={formik}
				onBlurHandler={updateFormCallBack}
				fieldName={"occupationMonthlyIncomesValue"}
				width={"100%"}
				height={standartHeightMeasures}
				fSize={standartFontSizeMeasures}
				label={"Размер ежемесячного дохода (руб.)"}
				error={ formik.touched.occupationMonthlyIncomesValue && !!formik.errors.occupationMonthlyIncomesValue }
				borderColor={ onSetFieldBorderColor(formik, "occupationMonthlyIncomesValue") }
				errorValue={ formik.touched.occupationMonthlyIncomesValue && formik.errors.occupationMonthlyIncomesValue }
			/>
			<Flex align={"center"} margin={"20px 0"}>
				<CheckBoxField 
					formItem="isReadyConfirmIncomes" 
					checked={formik.values.isReadyConfirmIncomes} 
					onChange={() => formik.setFieldValue("isReadyConfirmIncomes", !formik.values.isReadyConfirmIncomes)} 
				/>
				<String margin="0 20px">
					Готов подтвердить доход
				</String>
			</Flex>
			{formik.values.isReadyConfirmIncomes && (
				<AnimatedBlock>
					<FejoaMappedSelect
						formik={formik}
						updateFormCallBack={updateFormCallBack}
						formItem={"occupationDocConfirmationIncomes"}
						height={standartHeightMeasures}
						width={"100%"}
						fSize={standartFontSizeMeasures}
						options={
							profileConfig.occupationDocConfirmationIncomesRef
							.filter(onFilterDocConfirmationIncomes)
							.sort((a, b) => a.title < b.title ? -1 : 1)
						}
						defaultValue={"Выберите"}
						label={"Документ для подтверждения дохода"}
						borderColor={ onSetFieldBorderColor(formik, "occupationDocConfirmationIncomes") }
						errorValue={ formik.touched.occupationDocConfirmationIncomes && formik.errors.occupationDocConfirmationIncomes }
					/>
				</AnimatedBlock>
			)}
		</ParagraphWrapper>
	);
};