import { FormikValues } from "formik";
import useDimensions from "../../../hooks/useDimensions";
import { IFioDadataList } from "../../../redux/Application/appTypes";
import { adaptiveParams } from "../../adaptiveParams";
import { FieldStyles, StyledFieldsProps } from "../CustomField/CustomFieldStyles";
import { Flex } from "../Flex";
import { String } from "../String";
import { StyledErrorNotificator } from "../StyledErrorNotificator";
import { EachOption, OptionsWindow } from "./DadataOptions";
import { HintedFieldWrapper } from "./HintedFieldWrapper";
import {onSetFieldBorderColor} from "../../../utils/onSetFieldBorderColor";
import { calcErrorOffset } from '../../../utils/calcErrorOffset';

interface IFioDadataField extends StyledFieldsProps {
  formik: FormikValues;
	formName: string;
	dadataList: IFioDadataList[];
  label: string;
	errorValue?: string;
	backgroundColor?: string;
	onMouseDownHandler: (chosenItem: IFioDadataList) => void;
	ResetListHandler: () => void;
	onFocusAdditionalActions?: () => void;
	showPlaceholderOnClicked?: boolean;
}

export const FioDadataField: React.FC<IFioDadataField> = (props) => {
  const {
    label,
    errorValue,
    dadataList,
    formName,
    formik,
    onMouseDownHandler,
    ResetListHandler,
		onFocusAdditionalActions,
  } = props;

  const {screenWidth} = useDimensions()

	const { mainContentWidth } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;
	const Top = calcErrorOffset(screenWidth, fieldsWrapConditions);

  const onChangeRegularValueHandler = (e: FormikValues) => {
		if (e.target.value.charAt(0) !== " ") {
			formik.handleChange(e);
		}
	};

  const onBlurValueHndler = (e: React.FocusEvent<HTMLInputElement>) => {
		ResetListHandler();
	};
  const getColor = () => errorValue ? 'red' : onSetFieldBorderColor(formik, formName);

	return <Flex direction="column" Position="relative" padding={fieldsWrapConditions ? "0" : "10px 0"}>
    <String fSize={"14px"} margin="0 0 8px">{label}</String>
    <HintedFieldWrapper>
      <FieldStyles
				{...props}
				borderColor={getColor()}
				type={"text"}
				name={formName}
				value={formik.values[formName] || ""}
				onChange={onChangeRegularValueHandler}
				onBlur={onBlurValueHndler}
				onBlurCapture={() => formik.setFieldTouched(formName)}
				onFocus={(event) => {
					onFocusAdditionalActions && onFocusAdditionalActions();
					event.target.setAttribute("autocomplete", "off");
				}}
			/>
      {dadataList.length > 0 && dadataList[0]?.value.toLowerCase() !== formik.values[formName]?.toLowerCase() && (
				<OptionsWindow>
					{dadataList.map((option, index) => {
						const choseOptionHandler = () => {
							onMouseDownHandler(option);
						};
						return (
							<EachOption
								key={index}
								onMouseDown={choseOptionHandler}
							>
								{option.value}
							</EachOption>
						);
					})}
				</OptionsWindow>
			)}
    </HintedFieldWrapper>
    {errorValue && (
			<StyledErrorNotificator Top={Top}>{errorValue}</StyledErrorNotificator>
		)}
  </Flex>
}