import styled from "styled-components";
import { Flex } from "../../styles/UIKit/Flex";
import { Image } from "../../styles/UIKit/Image";
import { Title } from "../../styles/UIKit/Title";
import ErrorIcon from "../../assets/errorIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppRootStateType } from "../../redux/store";
import { IErrorConfig, IErrorState } from "../../redux/Application/appTypes";
import { Button } from "../../styles/UIKit/Button";
import { setCurrentForm } from "../../redux/Application/appActions";
import { ROUTE_PATH } from "../../routesConfig";
import { useParams } from "react-router-dom";
import useDimensions from "../../hooks/useDimensions";
import { adaptiveParams } from "../../styles/adaptiveParams";
import { getCurrentProductName } from "../../utils/getCurrentProductName";
import { TProductTypes } from "../../redux/commonTypes";
import React from "react";

const ErrorContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: 50px;
`;

const ErrorWindowWrapper = styled.div`
	padding: 50px;
	box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
		0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
	width: 1000px;
	height: 500px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
`;

export const Error = ({ product } : { product: string }) => {
	const dispatch = useDispatch();

    const paramsFlowId = useParams().flowId;
    const flowId = paramsFlowId || localStorage.getItem("flowId") || undefined;

  React.useLayoutEffect(() => {
			dispatch(setCurrentForm({ product: product as TProductTypes, formId: ROUTE_PATH.ERROR, flowId }));
	}, [dispatch, flowId]);


	const { screenWidth } = useDimensions();
	const { mainContentWidth } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;

	const errorState = useSelector<AppRootStateType, IErrorState | undefined>(
		(state) => state.appReducer.formError?.state
	);
	const errorConfig = useSelector<AppRootStateType, IErrorConfig | undefined>(
		(state) => state.appReducer.formError?.config
	);
	const getBackToMainPage = () => {
		dispatch(
			setCurrentForm({
				product: product as TProductTypes,
				formId: undefined,
				flowId: undefined,
			})
		);
	};

  const onContinueCurrentFlow = () => {
    alert("test")
  }

	// const getCurrentFlowFormHandler = () => {
	// 	flowId && dispatch(getCurrentForm(flowId, undefined));
	// };

	return (
     <Flex align="center" justify="center" height="100vh">
       <ErrorContainer>
         <ErrorWindowWrapper>
           <Flex
              direction={fieldsWrapConditions ? "row" : "column"}
              justify={"space-around"}
              align="center"
           >
             <Image
                src={ErrorIcon}
                width="200px"
                margin={fieldsWrapConditions ? "0" : "0 0 20px 0"}
             />
             <Title fSize="52px" color="#00CAD6">
               Ошибка!
             </Title>
           </Flex>
           <Flex direction="column" align="center">
             <Title color="#00CAD6" fSize="32px">
               {errorState?.type}
             </Title>
             <Title color="#00CAD6" fSize="32px">
               {errorState?.message}
             </Title>
           </Flex>
           <Flex
              direction="column"
              margin="50px 0 0 0"
              justify="center"
              padding={fieldsWrapConditions ? "0 120px" : "0"}
           >
             {/* <Button mode="primary" onClick={() => navigate(-1)}>Назад</Button> */}
             <Button
                mode="primary"
                onClick={getBackToMainPage}
                margin={"10px 0"}
                padding="18px 41px"
                FSize="16px"
                fontFamily="Formular"
             >
               Вернуться на главную
             </Button>
             {/* <Button                                                                          TODO
                mode="outlined"
                fontFamily="Formular"
                margin={"10px 0"}
                padding="18px 41px"
                onClick={onContinueCurrentFlow}
             >
              Продолжить заявку
             </Button> */}
             {/* {flowId && (
						<Button
							mode="primary"
							margin={"10px 0"}
							onClick={getCurrentFlowFormHandler}
						>
							Продолжить
						</Button>
					)} */}
           </Flex>
         </ErrorWindowWrapper>
       </ErrorContainer>
     </Flex>
	);
};