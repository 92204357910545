import { Flex } from '../../../styles/UIKit/Flex';
import { String } from '../../../styles/UIKit/String';
import { Title } from '../../../styles/UIKit/Title';
import { FormatCurrancy } from '../../../utils/commonFunctions';

interface IConfiguratorConditions {
    amount: number;
    rate: number;
	term: number;
}

export const ConfiguratorConditions: React.FC<IConfiguratorConditions> = (props) => {
	const { amount, rate, term } = props;
	return (
		<Flex direction="column" margin="20px 0 0 0">
			<Title>Условия</Title>
			<Flex
				backGround="#f0f0f0"
				Border="1px solid grey"
				padding="30px"
                direction="column"
				align="center"
                BorderRadius={"20px"}
			>
				<String>На {term.toString()} лет с финансовой защитой</String>
				<Title>{FormatCurrancy(amount.toString())} ₽/мес</Title>
                <String>Ставка {rate}%</String>
			</Flex>
		</Flex>
	);
};