import React from 'react';
import odnoklassniki from '../../assets/icons/odnoklassniki.svg';
import telegram from '../../assets/icons/telegram.svg';
import vk from '../../assets/icons/vk.svg';

import './Footer.css';
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../redux/store";
import {TProductTypes} from "../../redux/commonTypes";
import { TestPageNames } from '../../utils/getQueryParams';

interface FooterProps {
  product?: TProductTypes
}

export default function Footer({product}: FooterProps) {
  const isShowFooterMenu = useSelector<AppRootStateType, boolean>((state) => state.appReducer.isShowFooterMenu);
  const isTestMode = useSelector<AppRootStateType, boolean>(state => state.appReducer.isTestMode)
  const testPageName = useSelector<AppRootStateType, string | undefined>(state => state.appReducer.testPageName)
  const isCredits = product === "credits";
  const isTestPage2 = testPageName === TestPageNames.TEST2
  return <footer>
    {(isTestPage2 || !(isCredits && isTestMode)) && <>
      <div className="footer-subheader">
        <div className="footer-container">
          <div className="footer-subheader-wrapper">
            <div className="footer-subheader-menus">
              <a
                href="https://www.zenit.ru/bank/general/"
                target="_blank"
                className="footer-subheader__menu" rel="noreferrer"
              >О банке</a>
              <a
                href="https://www.zenit.ru/currency/"
                target="_blank"
                className="footer-subheader__menu" rel="noreferrer"
              >Курсы валют</a>
              <a
                href="https://www.zenit.ru/en/"
                target="_blank"
                className="footer-subheader__menu" rel="noreferrer"
              >English</a>
            </div>
            <div className="footer-subheader-menus">
              <span className="footer-subheader-menu__title">Бесплатный звонок</span>
              <a
                href="tel:+8 (800) 500-66-77" className="footer-subheader-menu__tel"
              >8 (800) 500-66-77</a>
              <p className="footer-subheader-menu__text">
                Круглосуточно <br />
                Ведение счетов - пн-пт 8:00-20:00 (Мск)
              </p>
              <span
                className="footer-subheader-menu__title"
              >Если вы за границей</span>
              <a
                href="tel:+7(495)967-11-11" className="footer-subheader-menu__tel"
              >+7 (495) 967-11-11</a>
              <a
                href="mailto:info@zenit.ru"
                className="footer-subheader-menu__tel"
              >info@zenit.ru</a>
            </div>
            <div className="footer-subheader-apps">
              <a
                href="https://www.zenit.ru/personal/services/internet-bank/?utm_source=mainpage&utm_medium=footer&utm_campaign=siteactivity&utm_content=mob-bank"
                className="footer-subheader-apps__title"
              >Мобильный банк «Зенит Онлайн»</a>
              <div className="apps-wrapper">
                <a
                  href="https://www.zenit.ru/personal/services/internet-bank/#android"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-subheader-apps__button"
                >
                  Для Android
                </a>
                <a
                  href="https://www.zenit.ru/personal/services/internet-bank/#ios "
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-subheader-apps__button"
                >
                  Для ios
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowFooterMenu && (
        <div className="footer-menu">
          <div className="footer-container">
            <div className="footer-menu-wrapper">
              <div className="footer-menu-block">
                <a
                  href="https://www.zenit.ru/personal/mortgage/"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Ипотека
                </a>
                <a
                  href="https://www.zenit.ru/personal/credits/"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Кредиты
                </a>
                <a
                  href="https://www.zenit.ru/personal/autocredit/"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Автокредит
                </a>
                <a
                  href="https://www.zenit.ru/personal/cards/"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Карты
                </a>
                <a
                  href="https://www.zenit.ru/personal/deposits/"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Вклады
                </a>
                <a
                  href="https://www.zenit.ru/personal/services/"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Онлайн-сервисы
                </a>
                <a
                  href="https://www.zenit.ru/media/"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Акции и спецпредложения
                </a>
                <a
                  href="https://www.zenit.ru/personal/faq/"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Вопросы и ответы
                </a>
                <a
                  href="https://www.zenit.ru/personal/tariffs/"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Тарифы и документы
                </a>
              </div>
              <div className="footer-menu-block">
                <a
                  href="https://www.zenit.ru/zakupki/"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Закупки
                </a>
                <a
                  href="https://www.zenit.ru/privacy/"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Политика в отношении обработки персональных данных
                </a>
                <a
                  href="https://www.zenit.ru/personal/tariffs/?tab=others&filter[]=431"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Рекомендации по безопасности ⚠
                ️</a>
                <a
                  href="https://www.zenit.ru/bank/disclosure/disclosure-information-professional/#"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Написать о продуктах и обслуживании
                </a>
                <a
                  href="https://fincult.info/"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Финансовая культура
                </a>
                <a
                  href="https://www.zenit.ru/personal/biometrics/"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Прием биометрии
                </a>
                <a
                  href="https://www.zenit.ru/bank/general/contacts/"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Контакты
                </a>
              </div>
              <div className="footer-menu-block__right">
                <a
                  href="https://www.zenit.ru/bank/disclosure/"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Раскрытие информации
                </a>
                <a
                  href="https://www.zenit.ru/bank/disclosure/disclosure-information-professional/"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Раскрытие информации профессиональным участником рынка ценных бумаг
                </a>
                <a
                  href="https://www.zenit.ru/For_CBRF/"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Информация о процентных ставках по договорам банковского вклада с
                  физическими лицами
                </a>
                <a
                  href="https://e-disclosure.ru/portal/company.aspx?id=538&attempt=1"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Раскрытие информации на сайте ООО «Интерфакс — ЦРКИ»
                </a>
                <a
                  href="https://www.zenit.ru/bank/general/trust-line/"
                  target="_blank"
                  className="footer-menu__link" rel="noreferrer"
                >
                  Противодействие коррупции, внутренние злоупотребления, конфликты
                  интересов
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>}
    <div className="footer-info">
      <div className="footer-container">
        <div className={`footer-info-wrapper${ !isShowFooterMenu ? ' footer-info-wrapper_separated-from-top' : '' }`}>
          <p className="footer-info__text">
            © 2024 ПАО Банк ЗЕНИТ. Лицензия № 3255 от 16.12.2014
          </p>
          <p className="footer-info__text">
            Россия, 117638, г. Москва, Одесская ул., д.2
          </p>
          <div className="footer-info-links">
            <a
              href="https://vk.com/bank_zenit"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-info__link"
            >
              <img src={vk} alt="vk" />
            </a>
            <a
              href="https://ok.ru/bankzenit"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-info__link"
            >
              <img src={odnoklassniki} alt="odnoklassniki" />
            </a>
            <a
              href="https://t.me/bankzenit"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-info__link"
            >
              <img src={telegram} alt="telegram" />
            </a>
          </div>
        </div>
        <div className="footer-info-license">
          <p className="footer-info__license">
            В соответствии с <a href="https://www.zenit.ru/privacy/" target="_blank" rel="noopener noreferrer">политикой обработки персональных данных</a> Банк ЗЕНИТ использует Cookies для персонализации сервисов и
            повышения удобства пользования сайтом. Вы можете запретить обработку
            Cookies в настройках своего браузера.
          </p>
          <p className="footer-info__license">
            Наш сайт защищен с помощью reCAPTCHA и соответствует
            <a rel="nofollow noreferrer" href="https://policies.google.com/privacy" target="_blank" style={{ marginInline: '4px' }} >политике конфиденциальности</a>
            и <a rel="nofollow noreferrer" href="https://policies.google.com/terms" target="_blank">условиям использования</a> Google.
          </p>
        </div>
      </div>
    </div>
  </footer>;
}
