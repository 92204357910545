import React from 'react';
import styled from "styled-components";
import useDimensions from "../../../hooks/useDimensions";
import {adaptiveParams} from "../../../styles/adaptiveParams";

export type CreditRateRangeProps = {
  message: string
  min: string
  max: string
}

const Wrapper = styled.div<{ isMobile?: boolean }>`
  height: 12px;
  position: relative;
  top: ${(props) => props.isMobile ? '-10px' : '-27px'};
  flex-basis: 100%;
  color: #FFF;
  font-family: Formular;
  font-size: ${(props) => props.isMobile ? '8.597px' : '16.12px'};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const PercentValue = styled.span<{ isMobile?: boolean }>`
  font-size: ${(props) => props.isMobile ? '16px' : '29.33px'};
  font-weight: 700;
`

export const CreditRateRange = ({
    message,
    min,
    max
  }: CreditRateRangeProps) => {
  const {screenWidth} = useDimensions();
  const isMobile: boolean = screenWidth <= adaptiveParams.mainContentWidth;

  return (
    <Wrapper isMobile={isMobile}>
      {message}
      от <PercentValue isMobile={isMobile}>{min}</PercentValue> до <PercentValue isMobile={isMobile}>{max}</PercentValue>
    </Wrapper>
  )
}