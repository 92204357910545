
export enum THEME_NAMES {
  default="default",
  newYear="newYear",
}
export type IThemeNames = keyof typeof THEME_NAMES
export type IThemes = IDefaultTheme | INewYearTheme


export const defaultTheme = {
  name: THEME_NAMES.default,
  appColors: {
    darkAqua: "#127F8C",
    commonTheme: "#10C8D2",
    greyText: "#7E829B",
    secondaryGrey: "#f0f2f6",
  },
  mainColor1: "#FFFFFF",
  mainColor1Hovered: "#E6E7EC",
  errorColor: "#FF1617",
  FEI_PURPLE: "#0A1244",

  FEI_BLUE_LIGHT: "#b3e0ff",
  FEI_BLUE_LIGHT_2: "#1b5299",
  FEI_BLUE_LIGHT_3: "#e6fafc",

  FEI_GREY_LIGHT: "#F2F4F7",
  FEI_GREY_LIGHT_2: "#E1E2E3",

  FEI_BLUE_DARK: "#238697",
  FEI_ERROR: "red",
} as const;

export type IDefaultTheme = typeof defaultTheme


export const newYearTheme = {
  ...defaultTheme,
  name: THEME_NAMES.newYear,
}
export type INewYearTheme = typeof newYearTheme;