import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoacalData } from "../../api/localStorage";
import useDimensions from "../../hooks/useDimensions";
import { getDeclarationsList } from "../../redux/Application/app-reducer";
import { setCurrentForm } from "../../redux/Application/appActions";
import { getSmsData, validateSmsCode } from "../../redux/Auth/auth-reducer";
import {
	setAntifrodTimer,
	setGeneratedSmsData,
	setValidateSmsData,
} from "../../redux/Auth/authActions";
import { IGenerate, IValidate } from "../../redux/Auth/authTypes";
import { AppRootStateType } from "../../redux/store";
import { Button } from "./Button";
import { TelephoneField } from "./CustomField/TelephoneField";
import { Flex } from "./Flex";
import { AntifrodTimer } from "./Modal/components/AntifrodTimer";
import { CloseFeature, Modal } from "./Modal/Modal";
import { String } from "./String";
import { Title } from "./Title";
import { InputCode } from '../../components/InputCode/InputCode';
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";

interface ICheckIsAuth {
	// isUnauth: boolean;
	header: string;
}

export const CheckIsAuth: React.FC<ICheckIsAuth> = (props) => {
	const { header } = props;
	const [render, setRender] = React.useState(0);
	const { screenWidth } = useDimensions();
	const ls = new LoacalData();
	const navigate = useNavigate();
	const isAuth = ls.getToken("accessToken");

	const authFormik = useFormik({
		initialValues: {
			phoneNumber: "",
		},
		validate: (values) => {
			const errors: any = {};
			if (!values.phoneNumber) {
				errors.phoneNumber = "Обязательное поле";
			} else if (values.phoneNumber.length < 15) {
				errors.phoneNumber = "Необходимо полностью ввести номер";
			}
			return errors;
		},
		onSubmit: (values) => { },
	});

	// const onBlurHandler = (
	// 	formItem: string,
	// 	currentValue: string,
	// 	event: FormikValues
	// ) => {
	// 	authFormik.handleBlur(event);
	// };

	const dispatch = useDispatch();

	const generate = useSelector<AppRootStateType, IGenerate | undefined>((state) => state.authReducer.generate);
	const validate = useSelector<AppRootStateType, IValidate | undefined>((state) => state.authReducer.validate);
	const antifrodLife = useSelector<AppRootStateType, number | undefined>((state) => state.authReducer.antifrodLife);

	const attempts: number | null | undefined = validate?.result?.attempts;

	const [smsCodeValue, setSmsCodeValue] = React.useState<string>("");

	const [isFinish, setIsFinish] = React.useState<boolean>(true);

	const getFinishCallback = () => {
		setIsFinish(false);
		// dispatch(setGeneratedSmsData(undefined));
		dispatch(setAntifrodTimer(undefined));
		dispatch(setValidateSmsData(undefined));
	};

	const onCancelHandler = () => {
		dispatch(setGeneratedSmsData(undefined));
		dispatch(
			setCurrentForm({
				product: undefined,
				formId: undefined,
				flowId: undefined,
			})
		);
		navigate('/credits/start');
	};
	const onGeneratelHandler = (first: boolean) => () => {
		const forceRender = (prev: number) => prev + 1;
		dispatch(getSmsData(authFormik.values.phoneNumber, null, "generate", null));
		if (!first) setRender(forceRender);
	};

	const onSuccess = () => dispatch(getDeclarationsList());

	const onValidatelHandler = () => {
		if (generate && generate.result) {
			dispatch(
				validateSmsCode(
					authFormik.values.phoneNumber,
					generate.result.codeId,
					smsCodeValue,
					generate.result.flowId,
					"validate",
					onSuccess
				)
			);
		}
	};

	const codeLength = 6;

	useEffect(() => {
		if (smsCodeValue?.length < codeLength || attempts === 0) {
			return;
		}
		onValidatelHandler();
	}, [smsCodeValue]);

	const attempsMessage = attempts
		? attempts !== 0 &&
		`Неверный смс-код, осталось ${attempts} попытки`
		: attempts === 0
			? "Попыток больше не осталось"
			: null;

	// console.log(validate);
	return (
		<Modal show={!isAuth} maxWidth={"466px"}>
			<CloseFeature show={!isAuth}>
				<IoMdClose
					size={30}
					onClick={onCancelHandler}
				/>
			</CloseFeature>
			<Title margin="0 0 20px" color="#0A1244" fSize="28px" fontWeight="900">{generate ? "Введите код из СМС" : header}</Title>
			{!generate && (
				<>
					<Flex>
						<TelephoneField
							screenWidth={screenWidth}
							label="Введите номер телефона"
							width="100%"
							height="70px"
							formItem={"phoneNumber"}
							// onBlurHandler={onBlurHandler}
							{...authFormik.getFieldProps("phoneNumber")}
							error={authFormik.touched.phoneNumber && !!authFormik.errors.phoneNumber
							}
						/>
					</Flex>
					<Flex padding="50px 0 0 0" direction={screenWidth > 575 ? 'row' : 'column'} justify="space-between">
						<Button type="button" mode="outlined" onClick={onCancelHandler} margin={"10px 0 0 0"}>
							Отменить
						</Button>
						<Button
							mode="primary"
							type="submit"
							onClick={onGeneratelHandler(true)}
							disabled={authFormik.values.phoneNumber.length < 15}
							margin={"10px 0 0 0"}
						>
							Получить код
						</Button>
					</Flex>
				</>
			)}
			{generate && (
				<>
					<String fSize="16px" color="#0A1244" lineHeight="22px">
						Мы отправили код на номер <br />
						<String fontFamily="Formular" color="#0A1244" fontWeight>{authFormik.values.phoneNumber.slice(0, 11)}****</String>
					</String>
					<Flex justify="flex-start" margin="20px 0 20px" width="100%">
						<InputCode key={`${+isAuth}${render}`}
							onComplete={setSmsCodeValue} length={codeLength}
						/>
					</Flex>
					{attempsMessage &&
						<Flex height="40px" align="center">
							<String color="#EF275E">
								{attempsMessage}
							</String>
						</Flex>
					}
					<Flex
						direction="column"
						justify="space-between"
						Border="1px soild green"
					>
						<Button
							mode="primary"
							color={"#0A1244"}
							background="#F0F2F6"
							padding="18px 41px"
							onClick={onGeneratelHandler(false)}
							disabled={isFinish || !!antifrodLife}
							type="button"
						>
							Получить код еще раз{" "}
							{antifrodLife && (
								<AntifrodTimer
									antifrodLife={antifrodLife}
									getFinishCallback={getFinishCallback}
								/>
							)}
						</Button>
					</Flex>
				</>
			)}
		</Modal>

	);
};
