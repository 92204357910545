import React from 'react';
import "./HowToGetBonus.css";
import twoCardsMirIMG from "../../assets/two-cards-mir.webp"
import spend5000IMG from "../../assets/spend-5000.webp"
import plus500rIMG from "../../assets/plus500r.webp"
import cardExampleGettingBonusHorizontallyIMG from "../../assets/example-getting-bonus-horizontally.webp"
import creditsExampleGettingBonusHorizontallyIMG from "../../assets/credits-example-getting-bonus-horizontally.webp"
import cardExampleGettingBonusVerticallyIMG from "../../assets/example-getting-bonus-vertically.webp"
import creditsExampleGettingBonusVerticallyIMG from "../../assets/credits-example-getting-bonus-vertically.webp"
import plus1000IMG from "../../assets/+1000.webp"
import okIMG from "../../assets/ok.webp"
import pileCoinsIMG from "../../assets/pile-coins.webp"
import {ISubmitCreditConfig} from '../../redux/Credit/creditTypes';

interface IHowToGetBonus {
  config?: ISubmitCreditConfig;
  isMobile: boolean;
  product: "credits" | "cards";
}

type CardInfo = {
  step: string,
  title: string,
  img: string,
  description?: string,
}

const dictionary: { cards: CardInfo[], credits: CardInfo[] } = {
  cards: [
    {
      step: "1",
      title: "Оформите кредитную карту на этой странице",
      img: twoCardsMirIMG,
    },
    {
      step: "2",
      title: "Потратьте не менее 5000 ₽ в течение месяца после получения карты",
      img: spend5000IMG,
    },
    {
      step: "3",
      title: "Получите 500 ₽ на бонусный счет в следующем месяце!",
      description: "1 бонусный рубль = 1 рубль. Вы можете компенсировать бонусами покупки по карте и конвертировать их в рубли на свой счет",
      img: plus500rIMG,
    },
  ],
  credits: [
    {
      step: "1",
      title: "Оформите кредит на этой странице",
      img: okIMG,
    },
    {
      step: "2",
      title: "При получении кредита в отделении оформите и получите карту Банка ЗЕНИТ, если ее у вас еще нет",
      img: twoCardsMirIMG,
    },
    {
      step: "3",
      title: "Внесите первый платеж по кредиту согласно графику платежей",
      img: pileCoinsIMG,
    },
    {
      step: "4",
      title: "Получите 1 000 ₽ на бонусный счет в следующем месяце!*",
      img: plus1000IMG,
    },
  ]
}

export const HowToGetBonus: React.FC<IHowToGetBonus> = ({config, isMobile, product = "card"}) => {

  const items: CardInfo[] = product === "cards" && dictionary.cards || product === "credits" && dictionary.credits || []

  return (
    <div className={"how-to-get-bonus"}>
      <div className={"container"}>
        <h2 className={"how-to-get-bonus__title"}>
          Как получить
          {product === "cards" ? " 500 " : ""}
          {product === "credits" ? " 1000 " : ""}
          ₽:</h2>
        <div className={"how-to-get-bonus__cards-list"}>
          {(items).map((cardInfo) =>
            <div className={"how-to-get-bonus__card"}>
              <div className={"how-to-get-bonus__card-step"}>
                {cardInfo.step}
                <div className={"how-to-get-bonus__card-line"}></div>
              </div>
              <div className={"how-to-get-bonus__card-content"}>
                <div className={"how-to-get-bonus__card-header"}>
                  <h3 className="how-to-get-bonus__card-title">
                    {cardInfo.title}
                  </h3>
                  <span className="how-to-get-bonus__description">{cardInfo.description}</span>
                </div>
                <img className={"how-to-get-bonus__card-img"} src={cardInfo.img} alt={"две карты мир банка зенит"}/>
              </div>
            </div>
          )}
        </div>
        <div className={"how-to-get-bonus__for-example-block"}>
          <h2 className={"how-to-get-bonus__title"}>Например:</h2>
          <div className={"how-to-get-bonus__for-example-content"}>
            {product === "cards" && <>
              <img
                className={"how-to-get-bonus__for-example-img-horizontally"}
                src={cardExampleGettingBonusHorizontallyIMG}
                alt={""}
              />
              <img
                className={"how-to-get-bonus__for-example-img-vertically"}
                src={cardExampleGettingBonusVerticallyIMG}
                alt={""}
              />
            </>}
            {product === "credits" && <>
              <img
                className={"how-to-get-bonus__for-example-img-horizontally"}
                src={creditsExampleGettingBonusHorizontallyIMG}
                alt={""}
              />
              <img
                className={"how-to-get-bonus__for-example-img-vertically"}
                src={creditsExampleGettingBonusVerticallyIMG}
                alt={""}
              />
            </>}
          </div>
        </div>
      </div>
    </div>
  );
};
