import React, {ReactNode, useState} from "react";
import { Collapse } from "react-collapse";
import './CollabsableBlock.css';
import ArrowImg from '../../../assets/arrow-up.svg';

export interface CollapsableListProps {
  title: string
  isOpened?: boolean
  children?: ReactNode
}

export const CollapsableBlock = ({title, children, isOpened}: CollapsableListProps) => {
  const [isOpen, setIsOpen] = useState(!!isOpened);

  return <div className={`collapsable-block${isOpen ? ' collapsable-block_opened' : ''}`}>
    <div className={"collapsable-block__header"} onClick={()=> setIsOpen(!isOpen)}>
      <h2 className={"collapsable-block__title"}>{title}</h2>
      <img className={'collapsable-block__arrow'} src={ArrowImg}/>
    </div>
    <Collapse isOpened={isOpen}>{children}</Collapse>
  </div>
}