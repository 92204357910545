import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { AuthDBO } from "./pages/AuthDBO/AuthDBO";
import { CabinetContainer } from "./components/Cabinet/CabinetContainer";
import { ConfirmCreditParams } from "./components/Cabinet/ConfirmCreditParams";
import { CreditRoutes } from "./pages/CreditRoutes";
import { ROUTE_PATH } from "./routesConfig";
import { ConfiguratorContainer } from "./components/Configurator/ConfiguratorContainer";
import { AutoScrollUp } from "./hooks/AutoScrollUp";
import { Regection } from "./components/Consideration/Regection";
import { TemporaryFinal } from "./components/TemporaryFinal";
import { TestFillFull } from "./_components/TestFillFull";
import { CardRoutes } from "./pages/CardRoutes";
import { TestPassport } from "./_components/TestPassport";
import { TestCabinetContainer } from "./_components/TestCabinetContainer";
import useDimensions from "./hooks/useDimensions"
import { getCurrentProductName } from "./utils/getCurrentProductName";
import {CPZARoutes} from "./pages/CpzaRoutes";
import {TestPageNames} from "./utils/getQueryParams";

const StyledWrapper = styled.div<{horisontalOverflow: string}>`
	// padding-top: 220px;
  overflow-x: ${({horisontalOverflow}) => horisontalOverflow};
`;
// ProfileContainer
const currentProductName: string = getCurrentProductName()

const onGetProduct = () => {
	switch(currentProductName) {
		case ROUTE_PATH.CREDITS: return ROUTE_PATH.CREDITS
		case ROUTE_PATH.CARDS: return ROUTE_PATH.CARDS
		default: return ROUTE_PATH.CREDITS
	}
}

export const AppRoutes = () => {
	const { screenWidth } = useDimensions()
	const isHorisontalOverflow: boolean =  screenWidth > 1200

	return (
		<StyledWrapper horisontalOverflow={isHorisontalOverflow ? "visible" : "hidden"}>
			<AutoScrollUp />
			<Routes>
				<Route path={"/test_fillFull"} element={<TestFillFull />} />
				<Route path={"/test_passport"} element={<TestPassport />} />
				<Route path={"/test_orders"} element={<TestCabinetContainer />} />
				<Route path={"reject"} element={<Regection />} />

				<Route path={"configurator"} element={<ConfiguratorContainer />} />
				<Route path={"configurator/:flowId"} element={<ConfiguratorContainer />} />

				<Route path={`${ROUTE_PATH.CREDITS}/*`} element={<CreditRoutes />} />
				<Route path={`${ROUTE_PATH.CREDITS}/${TestPageNames.TEST}/*`} element={<CreditRoutes />} />
				<Route path={`${ROUTE_PATH.CREDITS}/${TestPageNames.TEST1}/*`} element={<CreditRoutes />} />
				<Route path={`${ROUTE_PATH.CREDITS}/${TestPageNames.TEST2}/*`} element={<CreditRoutes />} />
				<Route path={`${ROUTE_PATH.CREDITS}/${TestPageNames.TEST3}/*`} element={<CreditRoutes />} />
				<Route path={`${ROUTE_PATH.CREDITS}/${TestPageNames.TEST4}/*`} element={<CreditRoutes />} />
				<Route path={`${ROUTE_PATH.CREDITS}/${TestPageNames.TEST5}/*`} element={<CreditRoutes />} />
				<Route path={`${ROUTE_PATH.CREDITS}/${TestPageNames.TEST6}/*`} element={<CreditRoutes />} />
				<Route path={`${ROUTE_PATH.CARDS}/*`} element={<CardRoutes />} />
				<Route path={`${ROUTE_PATH.CARDS}/${TestPageNames.TEST}/*`} element={<CardRoutes />} />
				<Route path={`${ROUTE_PATH.CARDS}/${TestPageNames.TEST1}/*`} element={<CardRoutes />} />
				<Route path={`${ROUTE_PATH.CPZA}/*`} element={<CPZARoutes />} />

				<Route path={`${ROUTE_PATH.ORDERS}`} element={<CabinetContainer />} />
				<Route path={`${ROUTE_PATH.DBO}`} element={<AuthDBO />} />
				<Route path={`${ROUTE_PATH.DBO}/:token`} element={<AuthDBO />} />
				<Route path={`${ROUTE_PATH.TEMPORARY_FINAL}`} element={<TemporaryFinal />} />
				<Route path={`${ROUTE_PATH.ORDERS}/:flowId`} element={<ConfirmCreditParams />} />
				<Route path="/" element={<Navigate to={`${onGetProduct()}/${ROUTE_PATH.START}`} replace />} />
			</Routes>
		</StyledWrapper>
	);
};