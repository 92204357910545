import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { LoacalData } from '../../../api/localStorage';
import { setUserStatus } from '../../../redux/Auth/authActions';

interface Props extends NavLinkProps {
	closeBurgerMenu?: () => void;
  as?: any
}

interface IStyledCustomNavLink {}

const StyledCustomNavLink = styled(NavLink)<IStyledCustomNavLink>`
	margin: 5px 10px;
	text-decoration: none;
	color: ${(props) => props.theme.FEI_BLUE_DARK};
	display: flex;
	align-items: center;
	font-family: Formular;
	font-weight: normal;
	font-size: 14px;
	-webkit-transition: .5s all ease-in-out;
	-moz-transition: .5s all ease-in-out;
	-o-transition: .5s all ease-in-out;
	transition: .5s all ease-in-out;
	&.active {
		color: ${(props) => props.theme.FEI_BLUE_DARK};
		font-weight: bold;
		font-size: 20px;
	}
	&:hover {
		opacity: 0.4;
		/* font-weight: bold; */
	}
`;

export const CustomNavLink: React.FC<Props> = (props) => {
	const dispatch = useDispatch();
	const onClickHandler = () => {
		const localData = new LoacalData();
		localData.removeToken("accessToken");
		localData.removeToken("refreshToken");
		dispatch(setUserStatus("guest"));
		props.closeBurgerMenu && props.closeBurgerMenu();
	};

  let Component = StyledCustomNavLink

  if (props.as) {
    Component = props.as
  }

	return <Component {...props} onClick={onClickHandler} />;
};