import React from "react";
import classnames from 'classnames';
import creditCardReferralIMG from "../../../../assets/card_500₽.webp";
import creditCardIMG from "../../../../assets/newBanner.png";
import {goToTheQuestionnaire} from "../../../../utils/goToTheQuestionnaire";

type PromoCardDefault = {
  isReferral: boolean
}

export const PromoCardDefault = ({ isReferral}: PromoCardDefault) => {
  const creditCardClasses = classnames({
    'credit-card': true,
    'credit-card_referral': isReferral
  });

  const wrapperClasses = classnames({
    'credit-card-wrapper': true,
    'credit-card-wrapper_referral': isReferral
  });

  const cardItemsClasses = classnames({
    'credit-card-items': true,
    'credit-card-items_referral': isReferral
  });

  const titleClasses = classnames({
    'credit-card__title': true,
    'credit-card__title_referral': isReferral
  });

  const textClasses = classnames({
    'credit-card__text': true,
    'credit-card__text_referral': isReferral
  });

  const imgClasses = classnames({
    'credit-card__img': true,
    'credit-card__img_referral': isReferral
  });

  const linkClasses = classnames({
    'credit-card__link': true,
    'credit-card__link_referral': isReferral
  });

  return (
    <section className={creditCardClasses}>
    <div className="container">
      <div className={wrapperClasses}>
        <div className={cardItemsClasses}>
          <h2 className={titleClasses}>{isReferral ? 'Друг рекомендует вам кредитную карту' : `Онлайн-заявка на участие\nв марафоне вау-кешбэка\nпо кредитной карте!`}</h2>
          <p className={textClasses}>{isReferral ?
              'Оформите кредитную карту на этой странице и получите 500 ₽\n+ бесплатное обслуживание навсегда!' : ``}</p>
          <div className={imgClasses}>
            <img className="full" src={isReferral ? creditCardReferralIMG : creditCardIMG} alt=""/>
          </div>
          <a href="#anketa" onClick={goToTheQuestionnaire} className={linkClasses}>
            Оформить карту
          </a>
        </div>
      </div>
    </div>
  </section>
  )
}