import React, {useEffect} from 'react';
import './SuccessBlock.css';
import successIMG from '../../../../assets/icons/success.svg';
import {Button} from '../../../../styles/UIKit/Button';
import {pushDataLayerEvent} from "../../../../utils/dataLayer";


export const SuccessBlock: React.FC<{onGetOrders: () => void}> = ({ onGetOrders }) => {
  useEffect(() => {
    pushDataLayerEvent(`order_submit`);
  }, []);

return (
    <div className="container">
      <div className="credit-success-wrapper">
        <div className="credit-success-texts">
          <div className="credit-success__icon">
            <img src={successIMG} alt=""/>
          </div>
            <span className="credit-success__title">
              Заявка отправлена на рассмотрение.
            </span>
            <span className='credit-success__test-content'>
              В ближайшее время с вами свяжется сотрудник банка для уточнения ваших данных.
            </span>
          <p className="credit-success__text">
            Вы можете проверить статус заявки в личном кабинете.
          </p>
          <Button mode='primary' type='button' onClick={onGetOrders}>
            Личный кабинет
          </Button>
        </div>
        {/* <div className="credit-success__img">
          <img src={walletIMG}/>
        </div> */}
      </div>
    </div>
);
}
