import { FormikValues } from 'formik';
import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { OCUPATION_TYPES_MAPPER } from '../../../../../config';
import { getDadataAddress, getDadataCompany, onValidateParam } from '../../../../../redux/Application/app-reducer';
import {
  setCompanyNameDadata,
  setPhoneValidationErrorMessage,
  setWorkPlaceAddressList,
  setWorkPlaceLegalAddressList
} from '../../../../../redux/Application/appActions';
import { ICompanyDadataList, IDadataAddressTypes } from '../../../../../redux/Application/appTypes';
import { IOpfListRef, IProfileConfig2 } from '../../../../../redux/Credit/creditTypes';
import { AnimatedBlock } from '../../../../../styles/UIKit/AnimatedBlock';
import { ComboBox } from '../../../../../styles/UIKit/ComboBox';
import { CurrancyField } from '../../../../../styles/UIKit/CurrancyField';
import { CheckBoxField } from '../../../../../styles/UIKit/CustomCheckBox/CheckBoxField';
import { CustomMaskedField } from '../../../../../styles/UIKit/CustomField/CustomMaskedField';
import { TelephoneField } from '../../../../../styles/UIKit/CustomField/TelephoneField';
import { AutoCompleteAddresses } from '../../../../../styles/UIKit/dadata/AutoCompleteAddresses';
import { DadataCompanyAutoComplete } from '../../../../../styles/UIKit/dadata/DadataCompanyAutoComplete';
import { FejoaMappedSelect } from '../../../../../styles/UIKit/FejoaSelect/FejoaMappedSelect';
import { Flex } from '../../../../../styles/UIKit/Flex';
import { String } from '../../../../../styles/UIKit/String';
import { onSetFieldBorderColor } from '../../../../../utils/onSetFieldBorderColor';
import { ParagraphWrapper } from '../../../../CommonPagesStyles';

interface IOwnBusiness {
	formik: FormikValues;
	fieldsWrapConditions: boolean;
	standartWidthMeasures: string;
	standartHeightMeasures: string;
	standartFontSizeMeasures: string;
	profileConfig: IProfileConfig2;
	companyNamesDadataList: ICompanyDadataList[];
	companyInnDadataList: ICompanyDadataList[];
	workPlaceLegalAddressList: IDadataAddressTypes[];
	workPlaceAddressList: IDadataAddressTypes[];
	occupationCompanyOpfRef: IOpfListRef[]
	updateFormCallBack: (
		formItem: string,
		currentValue: string,
		event: FormikValues
	) => void;
	phoneValidationErrorMessage: string | null;
}

export const OwnBusiness: React.FC<IOwnBusiness> = (props) => {
	const {
		formik,
		fieldsWrapConditions,
		standartWidthMeasures,
		standartHeightMeasures,
		standartFontSizeMeasures,
		updateFormCallBack,
		profileConfig,
		companyNamesDadataList,
		companyInnDadataList,
		workPlaceLegalAddressList,
		workPlaceAddressList,
		occupationCompanyOpfRef,
		phoneValidationErrorMessage,
	} = props;

	const dispatch = useDispatch()
	const [companyIsSelected, setCompanyIsSelected] = useState(false);

	// *** COMPANY OWNER NAME ***
	const resetCompanyWhenEditing = () => {
		if (companyIsSelected) {
			setCompanyIsSelected(false)
			setCompanyDataInForm({});
		}
	}

	const currentCompanyName = formik.values.occupationCompanyName ? formik.values.occupationCompanyName : "";
	const onChangeCompanyNameHandler = (value: string) => {
		dispatch(getDadataCompany("name", value));
		resetCompanyWhenEditing();
	}

	const setCompanyDataInForm = (chosenItem: Partial<ICompanyDadataList>) => {
		formik.setFieldValue("occupationCompanyName", chosenItem.name || '');
		formik.setFieldValue("occupationCompanyInn", chosenItem.inn || '');
		formik.setFieldValue("occupationCompanyLegalAddress", chosenItem.address || '');
		formik.setFieldValue("occupationCompanyOgrn", chosenItem.ogrn || '');
		formik.setFieldValue("occupationCompanyBranchType", chosenItem.branchType || '');
		formik.setFieldValue("occupationCompanyCodeOkved", chosenItem.okved || '');
		formik.setFieldValue("occupationCompanyStatus", chosenItem.status || '');
		formik.setFieldValue("occupationCompanyCodeOKOPF", chosenItem.okopf || '');
		formik.setFieldValue("occupationCompanyOrgType", chosenItem.type || '');
		formik.setFieldValue("occupationCompanyKpp", chosenItem.kpp || '');
	};

	const setCompanyNameHandler = (chosenItem: ICompanyDadataList) => {
		setCompanyIsSelected(true)
		setCompanyDataInForm(chosenItem);
		dispatch(setCompanyNameDadata([]));
	};
	const resetCompanyNameDataHandler = () => dispatch(setCompanyNameDadata([]));


	// *** HANDLERS FOR OWN BUSINESS LEGAL ADDRESS LIST ***
	const workPlaceLegalAddress = formik.values.occupationCompanyLegalAddress ? formik.values.occupationCompanyLegalAddress.title : "";
	const onChangeWorkPlaceLegalAddressCallback = (value: IDadataAddressTypes) => {
		resetCompanyWhenEditing()
		dispatch(getDadataAddress("address", "title", value.title, "legal_work_place"));
	};
	const setWorkPlaceLegalAddressListOptionHandler = (chosenItem: IDadataAddressTypes) => {
		formik.setFieldValue("occupationCompanyLegalAddress", chosenItem);
		// setFormDataUpdates({...formik.values, occupationCompanyLegalAddress: chosenItem});
		dispatch(setWorkPlaceLegalAddressList([]));
	};
	const workPlaceLegalAddressListHandler = () => dispatch(setWorkPlaceLegalAddressList([]));


	// *** HANDLERS FOR OWN BUSINESS ACTUAL ADDRESS LIST ***
	const workPlaceAddress = formik.values.occupationCompanyAddress ? formik.values.occupationCompanyAddress.title : "";
	const onChangeWorkPlaceAddressCallback = (value: IDadataAddressTypes) => {
		dispatch(getDadataAddress("address", "title", value.title, "work_place"));
	};
	const setWorkPlaceAddressListOptionHandler = (chosenItem: IDadataAddressTypes) => {
		formik.setFieldValue("occupationCompanyAddress", chosenItem);
		// setFormDataUpdates({...formik.values, occupationCompanyAddress: chosenItem});
		dispatch(setWorkPlaceAddressList([]));
	};
	const workPlaceActualAddressListHandler = () => {
		dispatch(setWorkPlaceAddressList([]));
	};

	const sortedOpfList = occupationCompanyOpfRef.sort((a, b) => +a.pos > +b.pos ? 1 : -1)

	const onBlurPhoneHandler = (formItem: string, currentValue: string, event: FormikValues) => {
		let promise;
		if (formItem) updateFormCallBack(formItem, currentValue, event);
		if (formik.values.occupationPhoneNumber) {
			dispatch(setPhoneValidationErrorMessage(null));
			promise = dispatch(onValidateParam({paramValue: formik.values.occupationPhoneNumber, searchParam: "phone"}));
		} else {
			promise = dispatch(setPhoneValidationErrorMessage(null));
		}
		return promise;
	}
	// @ts-ignore
	window.occupationPhoneNumberHandler = onBlurPhoneHandler;

	return (
		<ParagraphWrapper padding={fieldsWrapConditions ? "0" : "10px"}>
			<DadataCompanyAutoComplete
				formik={formik}
				formName={"occupationCompanyName"}
				onBlurHandler={updateFormCallBack}
				width={"100%"}
				height={standartHeightMeasures}
				fSize={standartFontSizeMeasures}
				label={"Наименование организации"}
				dadataList={companyNamesDadataList}
				currentValue={currentCompanyName}
				onChangeHandler={onChangeCompanyNameHandler}
				onMouseDownHandler={setCompanyNameHandler}
				ResetListHandler={resetCompanyNameDataHandler}
				paramKey={"name"}
				error={ formik.touched.occupationCompanyName && !!formik.errors.occupationCompanyName }
				borderColor={ onSetFieldBorderColor(formik, "occupationCompanyName") }
				errorValue={ formik.touched.occupationCompanyName && formik.errors.occupationCompanyName }
			/>
			<ComboBox
        formik={formik}
        label={"Организационно-правовая форма"}
        formName={"occupationCompanyCodeOKOPF"}
				currentValue={occupationCompanyOpfRef?.filter(el => el.code === formik.values.occupationCompanyCodeOKOPF)[0]?.title || formik.values.occupationCompanyCodeOKOPF || ""}
				options={sortedOpfList}
				maxOptionsLength={11}
  			optionParam={"code"}
				onChange={(e) => {
					resetCompanyWhenEditing()
					formik.handleChange(e)
				}}
				onMouseDownHandler={(chosenItem: string) => formik.setFieldValue("occupationCompanyCodeOKOPF", chosenItem)}
        width="100%"
				height={standartHeightMeasures}
        fSize={standartFontSizeMeasures}
				borderColor={ onSetFieldBorderColor(formik, "occupationCompanyCodeOKOPF") }
        errorValue={ formik.touched.occupationCompanyCodeOKOPF && formik.errors.occupationCompanyCodeOKOPF }
			/>
			<Flex
				width={fieldsWrapConditions ? "750px" : "100%"}
				justify="space-between"
				margin="12px 0"
				direction={fieldsWrapConditions ? "row" : "column"}
			>
				<CustomMaskedField
					width={standartWidthMeasures}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					onBlurHandler={updateFormCallBack}
					formItem={"occupationCompanyInn"}
					{...formik.getFieldProps("occupationCompanyInn")}
					label={"ИНН"}
					error={ formik.touched.occupationCompanyInn && !!formik.errors.occupationCompanyInn }
					borderColor={ onSetFieldBorderColor(formik, "occupationCompanyInn") }
					mask={[ /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/ ]}
					errorValue={ formik.touched.occupationCompanyInn && formik.errors.occupationCompanyInn }
					type={"tel"}
					onInput={resetCompanyWhenEditing}
				/>
				<CustomMaskedField
					width={standartWidthMeasures}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					onBlurHandler={updateFormCallBack}
					formItem={"occupationCompanyKpp"}
					{...formik.getFieldProps("occupationCompanyKpp")}
					label={"КПП"}
					error={ formik.touched.occupationCompanyKpp && !!formik.errors.occupationCompanyKpp }
					borderColor={ onSetFieldBorderColor(formik, "occupationCompanyKpp") }
					mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
					errorValue={ formik.touched.occupationCompanyKpp && formik.errors.occupationCompanyKpp }
					onInput={resetCompanyWhenEditing}
				/>
			</Flex>
			<Flex
				width={fieldsWrapConditions ? "750px" : "100%"}
				justify="space-between"
				margin="12px 0"
				direction={fieldsWrapConditions ? "row" : "column"}
			>
			<CustomMaskedField
					width={standartWidthMeasures}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					onBlurHandler={updateFormCallBack}
					formItem={"occupationCompanyOgrn"}
					{...formik.getFieldProps("occupationCompanyOgrn")}
					label={"ОГРН"}
					error={formik.touched.occupationCompanyOgrn && !!formik.errors.occupationCompanyOgrn}
					borderColor={ onSetFieldBorderColor(formik, "occupationCompanyOgrn") }
					mask={[ /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/ ]}
					errorValue={ formik.touched.occupationCompanyOgrn && formik.errors.occupationCompanyOgrn }
					onInput={resetCompanyWhenEditing}
				/>
				<CustomMaskedField
					required
					width={fieldsWrapConditions ? "360px" : "100%"}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					onBlurHandler={updateFormCallBack}
					formItem={"occupationOwnBusinessShare"}
					{...formik.getFieldProps("occupationOwnBusinessShare")}
					label={"Доля владения бизнесом %"}
					error={ formik.touched.occupationOwnBusinessShare && !!formik.errors.occupationOwnBusinessShare }
					borderColor={ onSetFieldBorderColor(formik, "occupationOwnBusinessShare") }
					mask={[ /\d/, /\d/, formik.values.occupationOwnBusinessShare && +formik.values.occupationOwnBusinessShare[0] === 1 ? /\d/ : "" ]}
					errorValue={ formik.touched.occupationOwnBusinessShare && formik.errors.occupationOwnBusinessShare }
				/>
			</Flex>
			<AutoCompleteAddresses
				formik={formik}
				onBlurHandler={updateFormCallBack}
				formName={"occupationCompanyLegalAddress"}
				width={"100%"}
				height={standartHeightMeasures}
				fSize={standartFontSizeMeasures}
				label={"Юридический адрес"}
				dadataAddressList={workPlaceLegalAddressList}
				currentValue={workPlaceLegalAddress}
				onChangeHandler={onChangeWorkPlaceLegalAddressCallback}
				onMouseDownHandler={setWorkPlaceLegalAddressListOptionHandler}
				ResetListHandler={workPlaceLegalAddressListHandler}
				error={ formik.touched.occupationCompanyLegalAddress && !!formik.errors.occupationCompanyLegalAddress }
				borderColor={ onSetFieldBorderColor(formik, "occupationCompanyLegalAddress") }
				errorValue={ formik.touched.occupationCompanyLegalAddress && formik.errors.occupationCompanyLegalAddress }
			/>
			<Flex
				align={"center"}
				margin={"20px 0"}
				padding={fieldsWrapConditions ? "0" : "10px"}
			>
				<CheckBoxField
					formItem="occupationCompanyIsActualMutchLegal"
					checked={formik.values.occupationCompanyIsActualMutchLegal}
					onChange={() => formik.setFieldValue("occupationCompanyIsActualMutchLegal", !formik.values.occupationCompanyIsActualMutchLegal)}
				/>
				<String margin="0 20px">
					Фактический адрес совпадает с юридическим
				</String>
			</Flex>
			{!formik.values.occupationCompanyIsActualMutchLegal && (
				<AutoCompleteAddresses
					formik={formik}
					onBlurHandler={updateFormCallBack}
					formName={"occupationCompanyAddress"}
					width={"100%"}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					label={"Фактический адрес"}
					dadataAddressList={workPlaceAddressList}
					currentValue={workPlaceAddress}
					onChangeHandler={onChangeWorkPlaceAddressCallback}
					onMouseDownHandler={setWorkPlaceAddressListOptionHandler}
					ResetListHandler={workPlaceActualAddressListHandler}
					error={ formik.touched.occupationCompanyAddress && !!formik.errors.occupationCompanyAddress }
					borderColor={ onSetFieldBorderColor(formik, "occupationCompanyAddress") }
					errorValue={ formik.touched.occupationCompanyAddress && formik.errors.occupationCompanyAddress }
				/>
			)}
			<Flex
				direction={fieldsWrapConditions ? "row" : "column"}
				width={fieldsWrapConditions ? "750px" : "100%"}
				justify="space-between"
				align={fieldsWrapConditions ? "center" : "none"}
				margin="12px 0"
			>
				<CurrancyField
					formik={formik}
					onBlurHandler={updateFormCallBack}
					fieldName={"occupationMonthlyIncomesValue"}
					width={standartWidthMeasures}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					label={"Размер ежемесячного дохода (руб.)"}
					error={ formik.touched.occupationMonthlyIncomesValue && !!formik.errors.occupationMonthlyIncomesValue }
					borderColor={ onSetFieldBorderColor(formik, "occupationMonthlyIncomesValue") }
					errorValue={ formik.touched.occupationMonthlyIncomesValue && formik.errors.occupationMonthlyIncomesValue }
				/>
				<TelephoneField
					label="Рабочий телефон (Необязательно)"
					width={standartWidthMeasures}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					onBlurHandler={onBlurPhoneHandler}
					formItem={"occupationPhoneNumber"}
					{...formik.getFieldProps("occupationPhoneNumber")}
					error={ formik.touched.occupationPhoneNumber && !!formik.errors.occupationPhoneNumber }
					borderColor={ onSetFieldBorderColor(formik, "occupationPhoneNumber") }
					errorValue={ phoneValidationErrorMessage || (formik.touched.occupationPhoneNumber && formik.errors.occupationPhoneNumber) }
				/>
			</Flex>
			<Flex align={"center"} margin={"0 0 20px 0"}>
				<CheckBoxField
					formItem="isGettingSalaryOnCard"
					checked={formik.values.isGettingSalaryOnCard}
					onChange={() => {
						formik.setFieldValue("isGettingSalaryOnCard", !formik.values.isGettingSalaryOnCard)
						formik.setFieldValue("isReadyConfirmIncomes", false)
					}}
				/>
				<String margin="0 20px">
					Получаю заработную плату на карту Банка Зенит
				</String>
			</Flex>
			{!formik.values.isGettingSalaryOnCard && <AnimatedBlock>
				<Flex align={"center"} margin={"0 0 20px 0"}>
					<CheckBoxField
						formItem="isReadyConfirmIncomes"
						checked={formik.values.isReadyConfirmIncomes}
						onChange={() => formik.setFieldValue("isReadyConfirmIncomes", !formik.values.isReadyConfirmIncomes)}
					/>
					<String margin="0 20px">
						Готов подтвердить доход
					</String>
				</Flex>
			</AnimatedBlock>}
			{formik.values.isReadyConfirmIncomes && (
				<AnimatedBlock>
					<FejoaMappedSelect
						formik={formik}
						updateFormCallBack={updateFormCallBack}
						formItem={"occupationDocConfirmationIncomes"}
						height={standartHeightMeasures}
						width={"100%"}
						fSize={standartFontSizeMeasures}
						options={
							profileConfig.occupationDocConfirmationIncomesRef
								.filter((el) => el.parentCode && el.parentCode === OCUPATION_TYPES_MAPPER.Business)
								.sort((a, b) => a.title < b.title ? -1 : 1)
						}
						defaultValue={"Выберите"}
						label={"Документ для подтверждения дохода"}
						borderColor={ onSetFieldBorderColor(formik, "occupationDocConfirmationIncomes") }
						errorValue={ formik.touched.occupationDocConfirmationIncomes && formik.errors.occupationDocConfirmationIncomes }
					/>
				</AnimatedBlock>
			)}
		</ParagraphWrapper>
	);
};
