import React from 'react';
import { addZero } from '../../../../utils/commonFunctions';
import { getTimeRemaining } from '../../../../utils/milimsToTimer';

interface IAntifrodTimer {
	antifrodLife: number;
	getFinishCallback: () => void;
}

export const AntifrodTimer: React.FC<IAntifrodTimer> = (props) => {
	const getDateDifferent = (dateMs: number) => dateMs - Date.now();
	const { antifrodLife, getFinishCallback } = props;
	const { minutes, seconds } = getTimeRemaining(
		getDateDifferent(antifrodLife)
	);

	const [min, setMin] = React.useState<number>(minutes);
	const [sec, setSec] = React.useState<number>(seconds);
	React.useEffect(() => {
		let timerId = setTimeout(() => {
			const { minutes, seconds } = getTimeRemaining(getDateDifferent(antifrodLife));
			setMin(minutes);
			setSec(seconds);
		}, 1000);
		if (getDateDifferent(antifrodLife) < 0) {
			setSec(0);
			setMin(0);
			getFinishCallback();
			clearTimeout(timerId);
		}
	}, [min, sec]);
	return (
		<>
			{addZero(+minutes)}:{addZero(+seconds)}
		</>
	);
};