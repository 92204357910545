import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { SignDocsApi } from '../../api/sign-docs-api';
import { ERRORS_HANDLER } from '../../config';
import { onToasting } from '../../utils/onToasting';
import { setAppError, setAppLoadingMode } from '../Application/appActions';
import { AppActionsType } from '../Application/appTypes';
import { postCurrentForm } from '../Credit/credit-reducer';
import { CreditActionsTypes, IPassportState } from '../Credit/creditTypes';
import { AppRootStateType } from '../store';
import { IConsents, ISignDocsState, IValidationDto, SignDocsActionsType } from './segn-docs-types';
import { SET_CONSENTS, SET_VALIDATION, setConsents, setVaidationResult, SET_CALL_SIGN_DATA, setCallSignData, } from './sign-docs-actions';
import { pushDataLayerEvent } from "../../utils/dataLayer";

const initialState: ISignDocsState = {
	consents: undefined,
	signData: undefined,
	validatedSignature: undefined,
};

export const signDocsReducer = (
	state: ISignDocsState = initialState,
	action: SignDocsActionsType
): ISignDocsState => {
	switch (action.type) {
		case SET_CONSENTS:
			return { ...state, consents: action.payload };
		case SET_VALIDATION:
			return { ...state, validatedSignature: action.payload };
		case SET_CALL_SIGN_DATA:
			return { ...state, signData: action.payload };
		default:
			return state;
	}
};

// thunks
export const generateForm = (flowId: string) => async (dispatch: Dispatch) => {
	const client = new SignDocsApi();
	try {
		const consentsData = await client.generateAgreementForm(flowId);
		const callSignData = await client.callSign(flowId, [consentsData.data.documentId]);
		dispatch(setConsents(consentsData.data));
		dispatch(setCallSignData(callSignData.data));
	} catch (err) {
		dispatch(setAppError("Что-то пошло не так"));
		dispatch(setAppLoadingMode("failed"));
	}
};

export const validateSignature =
	(
		flowId: string,
		dto: IValidationDto,
		formId: string,
		state: IPassportState,
		product: string,
	): ThunkAction<
		void,
		AppRootStateType,
		unknown,
		CreditActionsTypes | SignDocsActionsType | AppActionsType
	> =>
	async (dispatch, getState) => {
		const client = new SignDocsApi();
		const currentConsents: IConsents | undefined = getState().signDocsReducer.consents;
		try {
			dispatch(setConsents(undefined));
			const { data } = await client.validateSignature(flowId, dto);
			dispatch(setVaidationResult(data));
			if (data.result.signed) {
				const dto = {
					formId,
					state,
				};
				pushDataLayerEvent(`${product}_sms2_verification_success`);
				dispatch(postCurrentForm(flowId, dto, product));
			}
			if (!data.result.signed) {
				// console.log("==> ", data)
			}
		} catch (err) {
			dispatch(setAppLoadingMode("failed"));
			const error = err as AxiosError
			error.response && onToasting({type: "error", message: ERRORS_HANDLER[error.response?.status]})
		} finally {
			dispatch(setConsents(currentConsents));
		}
	};
