import { FormikValues } from "formik";
import { REG_EXPS } from "../../config";
import { checkPatronymic } from "../../utils/commonFunctions";
import { onGetPhoneDigits } from "../../utils/onGetPhoneDigits";

export const creditValidator = (values: FormikValues, minAllowedAge: number, maxAllowedAge: number) => {
	type FormikErrorType = {
		fio?: string;
		phoneNumber?: string;
		email?: string;
		signConsent?: string;
		issuingCity?: string;
		region?: string;
		birthDate?: string;
	};
	const errors: FormikErrorType = {};	
	// if (!values.fio && values.isFatherName) {
	// 	errors.fio = "Укажите Фамилию Имя";
	// } else 
	if (!values.fio && !values.isFatherName) {
		errors.fio = "Укажите Фамилию Имя Отчество";
	} else if (values.fio && REG_EXPS.forFIO(values.fio.replace(/\s+/g, ""))) {
		errors.fio = "Допускается для ввода только кириллица";
	} else if (values.isFatherName && (checkPatronymic(values.fio) < 2 || checkPatronymic(values.fio) > 4)) {
		errors.fio = "Укажите Фамилию Имя";
	} else if (!values.isFatherName && (checkPatronymic(values.fio) < 3 || checkPatronymic(values.fio) > 4)) {
		errors.fio = "Укажите Фамилию Имя Отчество";
	}
	if (!values.signConsent) {
		errors.signConsent = "Необходимо подтвердить соглашения";
	}
	if (!values.issuingCity) {
		errors.issuingCity = "Мы не нашли такого города. Выберите город из списка"
	} else if ((values.issuingCity && REG_EXPS.isOnlyCyrillic(values.issuingCity.replace(/\s+/g, "")))) {
	// } else if ((values.issuingCity && REG_EXPS.isOnlyCyrillic(values.issuingCity.replace(/\s+/g, "").replace("-", "")))) {
		errors.issuingCity = "Допускается для ввода только Кириллица";
	} else if (!values.region) {
		errors.issuingCity = "Некорректное значение. выберите значение из списка"
	}
	if (!values.birthDate) {
		errors.birthDate = "Дата должна быть в формате ДД.ММ.ГГГГ. Например, 25.06.1995";
	} else if (REG_EXPS.isValidAge(values.birthDate, minAllowedAge, maxAllowedAge + 0.07671232876)) {
		errors.birthDate = `По условиям кредитования возраст заёмщика от ${minAllowedAge} до ${maxAllowedAge} лет`;
	}
	if (!values.phoneNumber) {
		errors.phoneNumber = "Номер телефона должен состоять из 10 цифр";
	} else if (onGetPhoneDigits(values.phoneNumber)?.length < 11) {
		errors.phoneNumber = "Номер телефона должен состоять из 10 цифр";
	}
	if (!values.email) {
		errors.email = "Адрес почты указан некорректно ";
	} else if (!/^[A-Z0-9._%+-.*]+@[A-Z0-9.-.*]+\.[A-Z]{2,4}$/i.test(values.email?.trim())) {
		errors.email = "Адрес почты указан некорректно ";
	}
	return errors;
};
