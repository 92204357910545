import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IThemes, newYearTheme} from "./styles/themes/themes";
import {ThemeProvider} from "styled-components";
import {AppPreloader} from "./assets/preloaders/AppPreloader";
import Footer from "./components/Footer/Footer";
import {Header} from "./components/Header/Header";
import {Toaster} from "./components/Toaster/Toaster";
import {useSwitchForm} from "./hooks/useSwitchForm";
import {IAppReducerState, ICurrentRoutesParams} from "./redux/Application/appTypes";
import {AppRootStateType} from "./redux/store";
import {AppStyledWrapper} from "./styles/GlobalStyles/AppStyledWrapper";
import {GlobalStyles} from "./styles/GlobalStyles/GlobalStyles";
import {AppRoutes} from "./Routes";
import {useSetThemeOnStart} from "./hooks/useSetThemeOnStart";
import {setIsTestMode} from "./redux/Application/appActions";
import _package from "../package.json";
import {useLeavingModal} from "./hooks/useLeavingModal";
import {LeavingModal} from "./components/LeavingModal/LeavingModal";
import useDimensions from "./hooks/useDimensions";

function App() {
  const {appLoadingMode} = useSelector<AppRootStateType, IAppReducerState>((state) => state.appReducer);
  const {
    formId,
    flowId,
    product
  } = useSelector<AppRootStateType, ICurrentRoutesParams>((state) => state.appReducer.currentForm);
  const theme = useSelector<AppRootStateType, IThemes>((state) => state.appReducer.theme);
  const leavingZoneSize = useSelector<AppRootStateType, number | undefined>((state) => state.creditReducer.initialCreditsData?.leavingZoneSize);
  const dispatch = useDispatch()
  const { screenWidth } = useDimensions();
  const {leavingModalState, closeLeavingModal, onClickGetSolution} = useLeavingModal({ leavingZoneSize, isMobile: screenWidth <= 991 })

  useSetThemeOnStart(newYearTheme);
  useSwitchForm((value) => {
    dispatch(setIsTestMode(value));
  }, product, formId, flowId);

  useEffect(() => {
    console.info("LKZ version ", _package.version)
  }, [])

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles/>
        <AppStyledWrapper className={theme.name}>
          <Toaster/>
          {appLoadingMode === "loading" && <AppPreloader/>}
          <Header/>
          <AppRoutes/>
          <Footer product={product}/>
          {leavingModalState.show && (
            <LeavingModal
              onClose={closeLeavingModal}
              onClickGetSolution={onClickGetSolution}/>
          )}
        </AppStyledWrapper>
      </ThemeProvider>
    </>
  );
}

export default App;