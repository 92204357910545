import React from 'react';
import { useDispatch } from 'react-redux';
import { setStartDataQuery } from '../../redux/Application/appActions';
import { getInitialCreditData } from '../../redux/Credit/credit-reducer';
import {getQueryParams, onParseQuery} from '../../utils/getQueryParams';

export const ReferalLanding = ({ product } : { product: string }) => {
  const dispatch = useDispatch()
  const query = getQueryParams()

  localStorage.removeItem('flowId');

  React.useEffect(() => {
    dispatch(getInitialCreditData("credit", product, onParseQuery(query)))
    query && dispatch(setStartDataQuery(query))
  }, [])
  return <h4>LOADING...</h4>
}
