import { FormikValues } from 'formik';
import React from 'react';
import { ParagraphWrapper } from '../../../pages/CommonPagesStyles';
import { adaptiveParams } from '../../../styles/adaptiveParams';
import { FejoaInput } from '../../../styles/UIKit/CustomRangeInput/FejoaInput/FejoaInput';
import { Flex } from '../../../styles/UIKit/Flex';
import { OnlyWatchField } from '../../../styles/UIKit/TotlalAmount';
import { bigSumHandler, FormatCurrancy, rangeStepsHandler, yaersHandler, } from '../../../utils/commonFunctions';

interface IRanges {
	screenWidth: number;
	formik: FormikValues;
	minCreditAmount: number;
	maxCreditAmount: number;
	minCreditTerm: number;
	maxCreditTerm: number;
	isRepayment: boolean;
	chosenCreditsTotalAmount: number;
	onBlurHandler: () => void;
}

export const Ranges: React.FC<IRanges> = (props) => {
	const {
		screenWidth,
		formik,
		minCreditAmount,
		maxCreditAmount,
		minCreditTerm,
		maxCreditTerm,
		isRepayment,
		chosenCreditsTotalAmount,
		onBlurHandler,
	} = props;

	const curremtAmont = formik.values.requestedAmaunt;
	const curremtTerm = formik.values.requestedTerm;

	const { minimalWrap } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > minimalWrap;

	// console.log(curremtAmont);

	return (
		<Flex direction="column" height="350px" justify="space-around">
			{!isRepayment ? (
				<FejoaInput
					onBlurHandler={onBlurHandler}
					screenWidth={screenWidth}
					formik={formik}
					formItem={"requestedAmaunt"}
					min={minCreditAmount}
					max={maxCreditAmount}
					value={curremtAmont}
					specialTag={"₽"}
					valueHandler={bigSumHandler}
					formatting={FormatCurrancy}
					width={"100%"}
					label={"Запрошенная сумма кредита"}
					disabled
					step={rangeStepsHandler(formik.values.requestedAmaunt)}
				/>
			) : (
				<>
					<FejoaInput
						onBlurHandler={onBlurHandler}
						screenWidth={screenWidth}
						formik={formik}
						formItem={"requestedAmaunt"}
						min={0}
						max={maxCreditAmount}
						value={curremtAmont}
						specialTag={"₽"}
						valueHandler={bigSumHandler}
						formatting={FormatCurrancy}
						width={"100%"}
						label={"Сумма денежных средств на руки"}
						step={rangeStepsHandler(formik.values.requestedAmaunt)}
					/>
					<ParagraphWrapper
						margin={fieldsWrapConditions ? "0" : "10px"}
					>
						<OnlyWatchField
							data={curremtAmont + chosenCreditsTotalAmount}
							width={"100%"}
							height={"71px"}
							label={"Общая сумма"}
						/>
						{/* <String fSize="14px">Общая сумма</String>
						<Title margin="0" padding="0">
							{FormatCurrency(
								(currentOnHandAmound + chosenCreditsTotalAmount).toString()
							)}
						</Title> */}
					</ParagraphWrapper>
				</>
			)}
			<FejoaInput
				onBlurHandler={onBlurHandler}
				screenWidth={screenWidth}
				formik={formik}
				formItem={"requestedTerm"}
				min={minCreditTerm / 12}
				max={maxCreditTerm / 12}
				value={curremtTerm}
				specialTag={"ЛЕТ"}
				valueHandler={yaersHandler}
				width={"100%"}
				label={"Срок кредита"}
			/>
		</Flex>
	);
};