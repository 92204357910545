import { IValidationDto } from '../redux/SignDocs/segn-docs-types';
import { instance } from './interceptors';

export class SignDocsApi {
	generateAgreementForm(flowId: string) {
		return instance.post(`/document/${flowId}/consents`);
	}

	callSign(flowId: string, documentIds: string[]) {
		return instance.post(`/document/sign/${flowId}/`, documentIds);
	}

	validateSignature(flowId: string, dto: IValidationDto) {
		return instance.post(`flow/${flowId}/invoke/validateSignDocs`, dto);
	}
}