import { IoMdClose } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ParagraphWrapper } from '../../../pages/CommonPagesStyles';
import { ICheckClientsData } from '../../../redux/commonTypes';
import { getLoadedFile } from '../../../redux/Credit/credit-reducer';
import { IConsentFilesData } from '../../../redux/Credit/creditTypes';
import { onDetectSafari } from '../../../utils/onDetectSafari';
import { Button } from '../Button';
import { ToggleField } from '../CustomCheckBox/ToggleField';
import { Flex } from '../Flex';
import { ErrorNotificator } from '../Icons/ErrorNotificator';
import { ScrollWrapper } from '../ScrollWrapper';
import { String } from '../String';
import { Title } from '../Title';
import { CloseFeature, Modal } from './Modal';

interface IAgreementsModal {
	show: boolean;
	setShow: (show: boolean) => void;
	clientsConfirmations: ICheckClientsData[];
	clarificationClientsDataHandler: (id: string, isChecked: boolean) => void;
	flowId: string;
	passportFormFiles: IConsentFilesData[];
}

const Hint = styled.div`
	// display: flex;
	// justify-content: flex-end;
`;

export const AgreementsModal: React.FC<IAgreementsModal> = (props) => {
	const {
		clientsConfirmations,
		show,
		setShow,
		clarificationClientsDataHandler,
		flowId,
		passportFormFiles, 
	} = props;

	const dispatch = useDispatch()

	const getAgreementFile = () => dispatch(getLoadedFile(flowId, passportFormFiles[0].id, passportFormFiles[0].name, "consent", onDetectSafari()));

	return (
		<Modal show={show}>
			<CloseFeature show={show}>
				<IoMdClose
					size={36}
					onClick={() => {
						setShow(false);
					}}
				/>
			</CloseFeature>
			<ParagraphWrapper>
				<Title>
          <a
			  href="https://lkz.zenit.ru/files/interactionsConditions.pdf"
			  target="_blank"
			  style={{
				  color: "rgb(16, 200, 210)",
				  textDecoration: "underline"
			  }}
          >Условия предоставления услуги</a>
        </Title>
				<ScrollWrapper maxHeight={"350px"} margin='20px 0 0 0'>
				{clientsConfirmations.map((el: ICheckClientsData, idx) => {
					return (
						<Flex direction="column">
							<Flex padding='8px 0' key={el.code}>
								<Flex margin="0 20px 0 0">
									{el.isChecked !== undefined && <ToggleField 
										checked={el.isChecked}
										formItem={`${el.code}_${idx}`}
										onChange={() => el.isChecked !== undefined && clarificationClientsDataHandler(el.code, !el.isChecked)}
									/>}
								</Flex>
								<Flex direction="column" width="100%">
									<String
										margin="0 0 5px 0"
									>
										{el.description}
									</String>
									<Hint>
										{el.hint && !el.isChecked && el.required && (
											<String
												margin="0"
												color="red"
												fSize="14px"
											>
												<ErrorNotificator
													size={10}
													margin={"0 5px"}
													color="red"
												/>
												{el.hint}
											</String>
										)}
									</Hint>
								</Flex>
							</Flex>
						</Flex>
					);
				})}
				</ScrollWrapper>
				<Flex margin="20px 0 0 0" width="100%" justify="center">
					<Button
						type={"button"}
						mode="primary"
						onClick={() => setShow(false)}
						disabled={clientsConfirmations.some(
							(data) => data.required && !data.isChecked
						)}
					>
						Понятно
					</Button>
				</Flex>
			</ParagraphWrapper>
		</Modal>
	);
};