import { FormikValues } from 'formik';
import { CurrancyField } from '../../../../styles/UIKit/CurrancyField';
import { Flex } from '../../../../styles/UIKit/Flex';
import { String } from '../../../../styles/UIKit/String';
import { ParagraphWrapper } from '../../../CommonPagesStyles';
import { getDadataAddress, getDadataCities, } from '../../../../redux/Application/app-reducer';
import { setActualAddressList, setCitiesList, setRegistrationAddressList } from '../../../../redux/Application/appActions';
import { useDispatch } from 'react-redux';
import { IDadataAddressTypes, IDadataTypes, } from '../../../../redux/Application/appTypes';
import { adaptiveParams } from '../../../../styles/adaptiveParams';
import { AutoCompleteAddresses } from '../../../../styles/UIKit/dadata/AutoCompleteAddresses';
import { IPassportState } from '../../../../redux/Credit/creditTypes';
import { onSetFieldBorderColor } from '../../../../utils/onSetFieldBorderColor';
import { CheckBoxField } from '../../../../styles/UIKit/CustomCheckBox/CheckBoxField';
import { AnimatedBlock } from '../../../../styles/UIKit/AnimatedBlock';

interface ISecondaryPassportFields {
	updateFormCallBack: (formItem: string, currentValue: string, event: FormikValues) => void;
	screenWidth: number;
	userPassportData: FormikValues;
	regionsList: IDadataTypes[];
	registrationAddressList: IDadataAddressTypes[];
	actualAddressList: IDadataAddressTypes[];
	citiesList: IDadataAddressTypes[];
	standartFontSizeMeasures: string;
	standartHeightMeasures: string;
	standartWidthMeasures: string;
	setFormDataUpdates: (values: IPassportState) => void;
}
export const SecondaryPassportFields: React.FC<ISecondaryPassportFields> = (
	props
) => {
	const {
		userPassportData,
		registrationAddressList,
		actualAddressList,
		citiesList,
		screenWidth,
		updateFormCallBack,
		standartFontSizeMeasures,
		standartHeightMeasures,
		standartWidthMeasures,
		setFormDataUpdates,
	} = props;

	const dispatch = useDispatch();

	const { mainContentWidth } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;

	// *** HANDLERS FOR REGISTRATED ADDRESS LIST ***
	const currentRegistrationAddress = userPassportData.values.registrationAddress ? userPassportData.values.registrationAddress.title : "";
	const onChangeRegistratedAddressCallback = (value: IDadataAddressTypes) => dispatch(getDadataAddress("address", "title", value.title, "registration"));
	const setRegistratedAddressListOptionHandler = (chosenItem: IDadataAddressTypes) => {
		userPassportData.setFieldValue("registrationAddress", chosenItem);
		if (userPassportData.values.isRegistrationAddressEqualWithActual) userPassportData.setFieldValue("actualAddress", chosenItem);
		userPassportData.setFieldValue("registrationAddress", chosenItem);
		setFormDataUpdates({ ...userPassportData.values, registrationAddress: chosenItem });
		dispatch(setRegistrationAddressList([]));
	};
	const resetRegistratedAddressListHandler = () => dispatch(setRegistrationAddressList([]));


	// *** HANDLERS FOR ACTUAL ADDRESS LIST ***
	const actualAddress = userPassportData.values.actualAddress ? userPassportData.values.actualAddress.title : "";
	const onChangeActualAddressCallback = (value: IDadataAddressTypes) => dispatch(getDadataAddress("address", "title", value.title, "actual"));
	const setActualAddressListOptionHandler = (chosenItem: IDadataAddressTypes) => {
		userPassportData.setFieldValue("actualAddress", chosenItem);
		setFormDataUpdates({ ...userPassportData.values, actualAddress: chosenItem });
		dispatch(setActualAddressList([]));
	};
	const resetActualAddressListHandler = () => dispatch(setActualAddressList([]));

	// *** HANDLERS FOR BIRTH PLACE ADDRESS LIST ***
	const currentBirthPlace = userPassportData.values.birthPlace ? userPassportData.values.birthPlace.title : "";
	const onChangeBirthPlaceCallback = (value: IDadataAddressTypes) => dispatch(getDadataCities("city", "title", value.title));
	const setBirthPlaceListOptionHandler = (chosenItem: IDadataAddressTypes) => {
		userPassportData.setFieldValue("birthPlace", chosenItem);
		setFormDataUpdates({ ...userPassportData.values, birthPlace: chosenItem});
		dispatch(setCitiesList([]));
	};
	const resetBirthPlaceList = () => {
		dispatch(setCitiesList([]))
	};

	return (
		<>
			<ParagraphWrapper padding={fieldsWrapConditions ? "10px 0" : "10px"}>
				<AutoCompleteAddresses
					formik={userPassportData}
					// onBlurHandler={updateFormCallBack}
					formName={"birthPlace"}
					width={"100%"}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					label={"Место рождения"}
					dadataAddressList={citiesList}
					currentValue={currentBirthPlace}
					onChangeHandler={onChangeBirthPlaceCallback}
					onMouseDownHandler={setBirthPlaceListOptionHandler}
					ResetListHandler={resetBirthPlaceList}
					error={ userPassportData.touched.birthPlace && !!userPassportData.errors.birthPlace }
					borderColor={ onSetFieldBorderColor(userPassportData, "birthPlace") }
					errorValue={userPassportData.touched.birthPlace && userPassportData.errors.birthPlace}
				/>
			</ParagraphWrapper>
			<ParagraphWrapper padding={fieldsWrapConditions ? "0" : "10px"}>
				<AutoCompleteAddresses
					formik={userPassportData}
					formName={"registrationAddress"}
					width={"100%"}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					label={"Адрес регистрации"}
					dadataAddressList={registrationAddressList}
					currentValue={currentRegistrationAddress}
					onChangeHandler={onChangeRegistratedAddressCallback}
					onMouseDownHandler={setRegistratedAddressListOptionHandler}
					ResetListHandler={resetRegistratedAddressListHandler}
					error={ userPassportData.touched.registrationAddress && !!userPassportData.errors.registrationAddress }
					borderColor={ onSetFieldBorderColor(userPassportData, "registrationAddress") }
					errorValue={ userPassportData.touched.registrationAddress && userPassportData.errors.registrationAddress }
				/>
			</ParagraphWrapper>
			<ParagraphWrapper padding={fieldsWrapConditions ? "0" : "10px"} margin="16px 0">
				<Flex padding='8px 0'>
					<CheckBoxField 
						formItem="signConsent" 
						checked={userPassportData.values.isRegistrationAddressEqualWithActual}
						margin={"0 20px 0 0"}
						onChange={() => {
							setFormDataUpdates({ ...userPassportData.values, isRegistrationAddressEqualWithActual: !userPassportData.values.isRegistrationAddressEqualWithActual });
							userPassportData.setFieldValue("isRegistrationAddressEqualWithActual", !userPassportData.values.isRegistrationAddressEqualWithActual)
							if (userPassportData.values.isRegistrationAddressEqualWithActual) userPassportData.setFieldValue("actualAddress", null);
							if (!userPassportData.values.isRegistrationAddressEqualWithActual) userPassportData.setFieldValue("actualAddress", userPassportData.values.registrationAddress);
						}} 
					/>
					<String margin="5px 0">Адрес регистрации совпадает с адресом проживания</String>
				</Flex>
			</ParagraphWrapper>
			{!userPassportData.values.isRegistrationAddressEqualWithActual && (
				<ParagraphWrapper padding={fieldsWrapConditions ? "0" : "10px"}>
					<AnimatedBlock>
						<AutoCompleteAddresses
							formik={userPassportData}
							formName={"actualAddress"}
							width={"100%"}
							height={standartHeightMeasures}
							fSize={standartFontSizeMeasures}
							label={"Адрес проживания"}
							dadataAddressList={actualAddressList}
							currentValue={actualAddress}
							onChangeHandler={onChangeActualAddressCallback}
							onMouseDownHandler={setActualAddressListOptionHandler}
							ResetListHandler={resetActualAddressListHandler}
							error={ userPassportData.touched.actualAddress && !!userPassportData.errors.actualAddress }
							borderColor={ onSetFieldBorderColor(userPassportData, "actualAddress") }
							errorValue={ userPassportData.touched.actualAddress && userPassportData.errors.actualAddress }
						/>
					</AnimatedBlock>
				</ParagraphWrapper>
			)}
		</>
	);
};