import ReactSwipe from 'react-swipe';
import React, {ReactNode, useState} from "react";
import './Slider.css';

interface SliderProps {
  slides: ReactNode[]
  startSlide?: number
}
export const Slider = ({slides, startSlide}: SliderProps ) => {
  const [swipeSlideIndex, setSwipeSlideIndex] = useState<number>(startSlide || 1);

  return <div className={'slider'}>
    <div className={'slider__slides'}>
      <ReactSwipe
        swipeOptions={{
          continuous: false,
          startSlide: swipeSlideIndex,
          callback: (index: number) => setSwipeSlideIndex(index)
        }}
      >
        {(slides || []).map((slide) => <div className={'slider__slide'}>{slide}</div>)}
      </ReactSwipe>
    </div>
    <div className="slider__dots">
      {slides.map((_, index) => (
          <div
            className={`slider__dot ${swipeSlideIndex === index ? 'slider__dot_active' : ''}`}
            onClick={() => setSwipeSlideIndex(index)}
          />
      ))}
    </div>
  </div>
}