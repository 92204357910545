import styled from "styled-components";

export const BackDrop = styled.div<{ show: boolean }>`
  z-index: 1006;
  display: ${({ show }) => (show ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(10, 18, 68, 0.5);
`;