import { ICalculatedCreditValues } from '../redux/Credit/creditTypes';

export class CreditCalculator {
	static calculateCredit(
		amount: number | null,
		term: number | null,
		percentRate: number | null,
		nextPercentRate: number | null,
		preferentialTerm: number | null,
	): ICalculatedCreditValues | undefined {
		if (amount && term && percentRate && typeof nextPercentRate === 'number' && preferentialTerm) {
			// Сумма кредита
			const AMT = amount;
			// Срок кредита
			const PROD_DUR = term;
			// Размер льготной ставки (в процентах)
			const PREF_RATE = percentRate / 100;
			// Ставка
			const RATE = nextPercentRate / 100;
			// Льготный период
			const PREF_PERIOD = preferentialTerm;

			// Размер платежа с льготной ставкой
			const PREF_ANN = AMT * (PREF_RATE / 12 * Math.pow(1 + PREF_RATE / 12, PROD_DUR)) / (Math.pow(1 + PREF_RATE / 12, PROD_DUR) - 1);
			// Сумма, уплаченная в льготный период
			const PREF_AMT = PREF_ANN * PREF_PERIOD - AMT * PREF_RATE / 12 * PREF_PERIOD;
			// Размер аннуитета
			const ANN = (AMT - PREF_AMT) * (RATE / 12 * Math.pow(1 + RATE / 12, PROD_DUR - PREF_PERIOD)) / (Math.pow(1 + RATE / 12, PROD_DUR - PREF_PERIOD) - 1);

			return {
				monthlyPayment: Math.round(PREF_ANN).toString(),
				// overpaymentAmount: Math.round(ret).toString(),
				creditAmount: Math.round(amount).toString(),
				percentRate: percentRate.toString(),
				nextPercentRate: nextPercentRate.toString(),
				nextMonthlyPayment: Math.round(ANN).toString(),
			};
		}
	}
}
