import React from 'react';
import styled from 'styled-components';
import { FormatCurrancy } from '../../utils/commonFunctions';
import { Flex } from './Flex';
import { String } from './String';

const StyledTotlalAmount = styled.div<ITotlalAmount>`
	width: ${({ width }) => width || "auto"};
	height: ${({ height }) => height || "auto"};
	border: 2px solid transparent;
	border-radius: 4px;
	font-family: Formular;
	font-size: ${({ fSize }) => fSize || "auto"};
	display: flex;
	align-items: center;
	padding: 0 0 0 10px;
  background: #F2F4F7;
	margin: 0;
`;

interface ITotlalAmount {
	width?: string;
	height?: string;
	fSize?: string;
	data: number | string;
	label?: string;
	padding?: string;
	margin?: string;
}

export const OnlyWatchField: React.FC<ITotlalAmount> = (props) => {
	const { data, label, padding } = props;
	return (
		<Flex direction="column" padding={padding}>
			{label && (
				<String fSize="14px" margin={"0 0 8px"}>
					{label}
				</String>
			)}
			<StyledTotlalAmount {...props}>
				{FormatCurrancy(data.toString())}
			</StyledTotlalAmount>
		</Flex>
	);
};