import React from 'react';
import { ToggleField } from '../../../styles/UIKit/CustomCheckBox/ToggleField';
import { Flex } from '../../../styles/UIKit/Flex';
import { String } from '../../../styles/UIKit/String';
import { StringLink } from '../../../styles/UIKit/StringLink';

interface IFinancialProtection {
	isInsurance: boolean;
	setIsInsurance: (isInsurance: boolean) => void;
}

export const FinancialProtection: React.FC<IFinancialProtection> = (props) => {
	const { isInsurance, setIsInsurance } = props;

	const onChangeInsuranceHandler = () => {
		setIsInsurance(!isInsurance);
	};

	return (
		<Flex direction="column">
			<Flex align="center">
				<ToggleField
					checked={isInsurance}
					formItem={"isInsurance"}
					onChange={onChangeInsuranceHandler}
				/>
				<Flex direction="column" margin="20px">
					<String fSize="24px">Финансовая защита</String>
					<StringLink
						to={
							"https://jira.boos.solutions/projects/FEI/issues/FEI-229?filter=allopenissues"
						}
					>
						Узнать подробнее
					</StringLink>
				</Flex>
			</Flex>
		</Flex>
	);
};