import styled from 'styled-components';
import { Image } from '../../../styles/UIKit/Image';
import { adaptiveParams } from '../../../styles/adaptiveParams';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '../../../routesConfig';
import { LoacalData } from '../../../api/localStorage';
import { useDispatch } from 'react-redux';
import { setCurrentForm } from '../../../redux/Application/appActions';
import logoSvg from '../../../assets/logo.svg';

const SubHeaderStyles = styled.div<ISubHeader>`
	width: 100%;
	min-height: 95px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 100000000000000000000;
	padding: ${({ screenWidth }) =>
		screenWidth > adaptiveParams.burgerMenuWidth ? "0 80px" : "0 30px"};
  background-color: #127F8C;
  border-bottom: 1px solid #fff;
`;

interface ISubHeader {
	screenWidth: number;
}

export const SubHeader: React.FC<ISubHeader> = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const refreshHandler = () => {
		const localData = new LoacalData();
		localData.removeToken("accessToken");
		localData.removeToken("refreshToken");
		dispatch(
			setCurrentForm({
				product: "credits",
				formId: undefined,
				flowId: undefined,
			})
		);
		navigate(`/${ROUTE_PATH.CREDITS}/${ROUTE_PATH.START}`);
	};

	return (
		<>
			<SubHeaderStyles {...props}>
				<Image
					width="150px"
					src={logoSvg}
					cursor={"pointer"}
					onClick={refreshHandler}
				/>
			</SubHeaderStyles>
		</>
	);
};