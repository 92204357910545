import { FormikValues } from 'formik';
import React from 'react';
import styled from 'styled-components';
import useDimensions from '../../hooks/useDimensions';
import { FormatCurrancy } from '../../utils/commonFunctions';
import { adaptiveParams } from '../adaptiveParams';
import { StyledFieldsProps } from './CustomField/CustomFieldStyles';
import { Flex } from './Flex';
import { String } from './String';
import { StyledErrorNotificator } from './StyledErrorNotificator';
import { calcErrorOffset } from '../../utils/calcErrorOffset';

interface ICurrancyField extends StyledFieldsProps {
	onBlurHandler?: (
		formItem: string,
		currentValue: string,
		event: FormikValues
	) => void;
	formik: FormikValues;
	fieldName: string;
	label?: string;
	borderColor?: string;
	errorValue?: string;
	error?: boolean;
}

const FieldWrapper = styled.div<ICurrancyField>`
	position: relative;
  font-family: Formular, serif;
	width: ${(props) => props.width || "auto"};
`;

const Icon = styled.div`
	position: absolute;
  font-family: Formular, serif;
	font-size: 30px;
	right: 5px;
	top: 50%;
	transform: translate(-50%, -50%);
	color: #7e829b;
`;

const StyledCurrancyField = styled.input<ICurrancyField>`
	width: ${(props) => props.width || "auto"};
	height: ${(props) => props.height || "auto"};
	font-weight: 400;
	outline: none;
	font-size: ${(props) => props.fSize || "auto"};
	line-height: 43px;
	border-radius: 4px;
  padding: 0 14px;
  // border: 2px solid transparent;
  border: 2px solid ${({borderColor}) => borderColor || "transparent" };
  font-family: Formular, serif;
  background: #F2F4F7;
  // &:focus {
  //   border-color: #00CAD6;
  // }
`;

export const CurrancyField: React.FC<ICurrancyField> = (props) => {
	const { fieldName, formik, label, borderColor, onBlurHandler, errorValue } = props;
	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		let regexp = /[^0-9]/gi;
		let value = e.target.value;
		value = e.target.value.replace(regexp, "");
		formik.setFieldValue(fieldName, FormatCurrancy(value));
	};

	const {screenWidth} = useDimensions()

	const { mainContentWidth, minimalWrap } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;
	const Top = calcErrorOffset(screenWidth, fieldsWrapConditions);

	return (
		<Flex direction="column" margin="20px 0" padding={fieldsWrapConditions ? "0" : "10px 0"} Position={"relative"}>
			{label && <String fSize={"14px"} margin="0 0 8px">{label}</String>}
			<FieldWrapper {...props}>
				<Icon>₽</Icon>
				<StyledCurrancyField
					{...props}
					type={"tel"}
					id={fieldName}
					name={fieldName}
					onChange={onChangeHandler}
					value={formik.values[fieldName] || ""}
					borderColor={borderColor}
					onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
						formik.handleBlur(e);
						onBlurHandler &&
							onBlurHandler(fieldName, e.currentTarget.value, e);
					}}
					onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
						event.target.setAttribute("autocomplete", "off");
					}}
				/>
			</FieldWrapper>
			{errorValue && (
				<StyledErrorNotificator Top={Top}>{errorValue}</StyledErrorNotificator>
			)}
		</Flex>
	);
};