import { FormikValues } from 'formik';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import useDimensions from '../../../hooks/useDimensions';
import { IDadataAddressTypes, } from '../../../redux/Application/appTypes';
import { adaptiveParams } from '../../adaptiveParams';
import { FieldStyles, StyledFieldsProps, } from '../CustomField/CustomFieldStyles';
import { Flex } from '../Flex';
import { String } from '../String';
import { StyledErrorNotificator } from '../StyledErrorNotificator';
import { HintedFieldWrapper } from './HintedFieldWrapper';
import { calcErrorOffset } from '../../../utils/calcErrorOffset';

interface IAutoCompleteAddresses extends StyledFieldsProps {
	// mask?: Array<RegExp | string>;
	formik: FormikValues;
	formName: string;
	dadataAddressList: IDadataAddressTypes[];
	currentValue: string;
	onChangeHandler: (value: IDadataAddressTypes) => void;
	onMouseDownHandler: (chosenItem: IDadataAddressTypes) => void;
	ResetListHandler: () => void;
	// paramKey: TDadataParamKey;

	onBlurHandler?: (
		formItem: string,
		currentValue: string,
		event: FormikValues
	) => void;
	errorValue?: string;
}

// select options window animation
const optionsWindowAnimation = keyframes`
    0% {opacity: 0;}
    100% {opacity: 1;}
`;

// modal with options to select
const OptionsWindow = styled.ul<{ maxHeight?: string }>`
	border-radius: 4px;
	position: absolute;
	width: 100%;
	margin-top: 3px;
	background: #ffffff;
	overflow-y: scroll;
	max-height: ${({ maxHeight }) => maxHeight || "400px"};
	opacity: 0;
	animation: ${optionsWindowAnimation} 0.4s forwards;
	box-shadow: 0px 0px 5px black;
	z-index: 2;
`;

// Each chosen option
const EachOption = styled.div`
	width: 100%;
	padding: 5px 10px;
	font-size: 20px;
	cursor: pointer;
	transition: background-color 0.1s;
	font-family: Formular;
	&:hover {
		background: #00cad6;
		color: white;
	}
`;

export const AutoCompleteAddresses: React.FC<IAutoCompleteAddresses> = (
	props
) => {
	const {
		formik,
		// mask,
		formName,
		label,
		dadataAddressList,
		currentValue,
		onChangeHandler,
		onMouseDownHandler,
		ResetListHandler,
		errorValue,
		// paramKey,
		onBlurHandler,
	} = props;


	const {screenWidth} = useDimensions()

	const { mainContentWidth, minimalWrap } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;
	const Top = calcErrorOffset(screenWidth, fieldsWrapConditions);

	const onChangeValueHandler = (e: any) => {
		const noDadataItems = {
			regionCode: null,
			block: null,
			city: null,
			flat: null,
			house: null,
			postalCode: null,
			region: null,
			street: null,
			streetTypeFull: null,
			title: e.target.value,
			area: null,
			settlement: null,
		};
		if (e.target.value.charAt(0) !== " " || !e.target.value) {
			formik.setFieldValue(formName, noDadataItems);
			onChangeHandler(noDadataItems);
		}
	};

	return (
		<Flex direction="column" Position={"relative"} padding={fieldsWrapConditions ? "0" : "10px 0"}>
			{label && <String fSize={"14px"} margin="0 0 8px">{label}</String>}
			<HintedFieldWrapper>
				<FieldStyles
					{...props}
					name={formName}
					value={currentValue}
					onChange={onChangeValueHandler}
					onBlurCapture={(e: React.FocusEvent<HTMLInputElement>) => {
						onBlurHandler && onBlurHandler(formName, e.target.value, e);
						ResetListHandler();
						formik.setFieldTouched(formName);
					}}
					onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
						event.target.setAttribute("autocomplete", "new-password");
					}}
				/>
				{dadataAddressList.length > 0 && (
					<OptionsWindow>
						{dadataAddressList.map((option, index) => {
							const choseOptionHandler = () => {
								onMouseDownHandler(option);
							};
							return (
								<EachOption key={index} onMouseDown={choseOptionHandler} >
									{option.title}
								</EachOption>
							);
						})}
					</OptionsWindow>
				)}
			</HintedFieldWrapper>
			{errorValue && dadataAddressList.length < 1 && (
				<StyledErrorNotificator Top={Top}>{errorValue}</StyledErrorNotificator>
			)}
		</Flex>
	);
};