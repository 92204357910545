import { ICreditDeclarations } from '../../../redux/Application/appTypes';
import { adaptiveParams, } from '../../../styles/adaptiveParams';
import { OrderItem } from '../../Orders/OrderItem';
import { OrdersEmpty } from '../../Orders/OrdersEmpty';
import { useRefreshToken } from '../../../hooks/useRefreshToken';


interface ICabinetCards {
	screenWidth: number;
	creditDeclarations: ICreditDeclarations[];
	onCreateNewDeclaration: () => void;
}

export const CabinetCards: React.FC<ICabinetCards> = (props) => {
	const { creditDeclarations, screenWidth, onCreateNewDeclaration } = props;
	const firstFlowId = creditDeclarations?.at(0)?.declarationID;

	useRefreshToken(firstFlowId);

	const { mainContentWidth } = adaptiveParams;

	const isMobileVersion = screenWidth < mainContentWidth

	return <div className="container">
    <div className="my-request-head">
      <h3 className="my-request__title">Мои заявки</h3>
      {creditDeclarations.length > 0 && <a href="javascript:void(0);" className="my-request__link" onClick={onCreateNewDeclaration}>
        Создать новую заявку
      </a>}

    </div>
    {creditDeclarations.length === 0 && <OrdersEmpty onCreateNewDeclaration={onCreateNewDeclaration} />}
    <div className="my-request-body">
      {creditDeclarations.map(
        (item) => <OrderItem item={item} isMobileVersion={isMobileVersion}/>
      )}
    </div>
  </div>;
};