import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCallTouch } from '../../hooks/useCallTouch';
import useDimensions from '../../hooks/useDimensions';
import { useRefreshToken } from '../../hooks/useRefreshToken';
import { setCurrentForm, setOrederCatalog } from '../../redux/Application/appActions';
import { ICreditDeclarations, IDadataAddressTypes, IDadataTypes, IFioDadataList } from '../../redux/Application/appTypes';
import { TProductTypes } from '../../redux/commonTypes';
import { getCurrentForm, onCancelDeclaration } from '../../redux/Credit/credit-reducer';
import { setCalculatedCreditData } from '../../redux/Credit/creditActions';
import { Iorder, IPassportConfig, IPassportState, IUploadedFile, } from '../../redux/Credit/creditTypes';
import { AppRootStateType } from '../../redux/store';
import { ROUTE_PATH } from '../../routesConfig';
import { adaptiveMeasurements } from '../../styles/adaptiveParams';
import { Button } from '../../styles/UIKit/Button';
import { Flex } from '../../styles/UIKit/Flex';
import { Modal } from '../../styles/UIKit/Modal/Modal';
import { String } from '../../styles/UIKit/String';
import { Title } from '../../styles/UIKit/Title';
import { CreditCalculator } from '../../utils/creditCalculator';
import { PassportDataForm } from './PassportDataForm';

export interface IAgreementsData {
	code: string;
	isChecked: boolean;
}

export const PassportContainer = ({ product } : { product: string }) => {

	const [showListModal, setShowListModal] = React.useState(false)

	useCallTouch()

	const { screenWidth } = useDimensions();

	const paramsFlowId = useParams().flowId;
	const flowId = paramsFlowId || localStorage.getItem("flowId") || undefined;

	useRefreshToken(flowId, product);

	const dispatch = useDispatch();

	const passportFormDataState = useSelector<AppRootStateType, IPassportState | undefined>((state) => state.creditReducer.passportFormData?.state);
	const passportFiles = useSelector<AppRootStateType, IUploadedFile[]>((state) => state.creditReducer.passportDocumentsList);
	const order = useSelector<AppRootStateType, Iorder | undefined>((state) => state.creditReducer.passportFormData?.config.order);
	const regionsList = useSelector<AppRootStateType, IDadataTypes[]>((state) => state.appReducer.regionsList);
	const documentIssuanceCodesList = useSelector<AppRootStateType, IDadataTypes[]>((state) => state.appReducer.documentIssuanceCodesList);
	const previousDocumentIssuanceCodesList = useSelector<AppRootStateType, IDadataTypes[]>((state) => state.appReducer.previousDocumentIssuanceCodesList);
	const registrationAddressList = useSelector<AppRootStateType, IDadataAddressTypes[]>((state) => state.appReducer.registrationAddressList);
	const config = useSelector<AppRootStateType, IPassportConfig | undefined>((state) => state.creditReducer.passportFormData?.config);
	const actualAddressList = useSelector<AppRootStateType, IDadataAddressTypes[]>((state) => state.appReducer.actualAddressList);
	const citiesList = useSelector<AppRootStateType, IDadataAddressTypes[]>((state) => state.appReducer.citiesList);
	const mobileScanner = useSelector<AppRootStateType, string | undefined>((state) => state.creditReducer.passportFormData?.config.mobileScanner.scan);
	const phoneNumber = useSelector<AppRootStateType, string | undefined>((state) => state.creditReducer.passportFormData?.config.phoneNumber);
	const lkzList = useSelector<AppRootStateType, ICreditDeclarations[] | null>(state => state.appReducer.lkzList)
	const fioList = useSelector<AppRootStateType, IFioDadataList[]>(state => state.appReducer.fioList)
	const currentPassportValidationErrorMessage = useSelector<AppRootStateType, string | null>((state) => state.appReducer.currentPassportValidationErrorMessage);
	const previousPassportValidationErrorMessage = useSelector<AppRootStateType, string | null>((state) => state.appReducer.previousPassportValidationErrorMessage);

	const onGetFoundDeclaration = () => { // в cancel передаем flowId новой заявки
		// lkzList && dispatch(getCurrentForm(lkzList[0].declarationID, product))
		lkzList && flowId && dispatch(onCancelDeclaration(lkzList[0].declarationID, flowId, product))
		dispatch(setOrederCatalog(null))
		setShowListModal(false)
	}

	const onContinueCurrentDeclaration = () => { // в cancel передает flowId старой заявки (которую получили из метода orders)
		lkzList && flowId && dispatch(onCancelDeclaration(flowId, lkzList[0].declarationID, product))
		dispatch(setOrederCatalog(null))
		setShowListModal(false)
	}

	React.useEffect(() => {
    if (lkzList && lkzList.length > 0) {
			setShowListModal(true)
		}
  }, [lkzList])

	React.useEffect(() => {
		if (order && flowId) {
			const { amount, rate, term, preferentialRate, preferentialTerm } = order;
			const calculator = CreditCalculator.calculateCredit(amount, term, preferentialRate, rate, preferentialTerm);
			dispatch(setCalculatedCreditData(calculator));
		}
	}, [order]);

	React.useEffect(() => {
		if (flowId && !config) {
			dispatch(setCurrentForm({product: product as TProductTypes, formId: ROUTE_PATH.PASSPORT, flowId}));
			dispatch(getCurrentForm(flowId, product));
		}
	}, []);

	React.useEffect(() => {
		return () => {
			dispatch(setOrederCatalog(null));
		}
	}, [])

	const standartFontSizeMeasures = adaptiveMeasurements(screenWidth, "28px", "26px", "24px", "20px");

	const onGetUserFio = () => {
		if (config) {
			switch(true) {
				case config.userFio.length >= 3: return config.userFio.split(" ").slice(1, 3).join(" ")
				default: return config.userFio
			}
		}
	}

	return (
		<>
			{config && passportFormDataState && flowId && phoneNumber && (
				<PassportDataForm
					product={product}
					passportFiles={passportFiles}
					passportFormDataState={passportFormDataState}
					config={config}
					flowId={flowId}
					screenWidth={screenWidth}
					registrationAddressList={registrationAddressList}
					actualAddressList={actualAddressList}
					regionsList={regionsList}
					documentIssuanceCodesList={documentIssuanceCodesList}
					previousDocumentIssuanceCodesList={previousDocumentIssuanceCodesList}
					currentPassportValidationErrorMessage={currentPassportValidationErrorMessage}
					previousPassportValidationErrorMessage={previousPassportValidationErrorMessage}
					citiesList={citiesList}
					mobileScanner={mobileScanner}
					phoneNumber={phoneNumber}
					fioList={fioList}
				/>
			)}
			{config && <Modal show={showListModal}>
				<Flex direction="column" margin="0 0 30px 0" align="center">
					<Title fSize={standartFontSizeMeasures}>{onGetUserFio()}, продолжите заполнение заявки</Title>
					<String margin="20px 0 0 0">Вы можете продолжить заполнение заявки,</String>
					<String margin="0 0 0 0">оформление которой начали ранее</String>
				</Flex>
				<Flex direction='column'>
					<Button mode="primary" onClick={onGetFoundDeclaration}>Продолжить заполнение</Button>
					<Button margin='10px 0 0 0' mode="outlined" onClick={onContinueCurrentDeclaration}>Заполнить новую заявку</Button>
				</Flex>
    	</Modal>}
		</>
	);
};
