import { FormikValues } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { Flex } from './Flex';
import { String } from './String';

const CustomQuantityInputWrapper = styled.div<ICustomQuantityInput>`
	border-radius: 4px;
	width: ${({ width }) => width || "auto"};
	height: 71px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #0a1244;
  background: #F2F4F7;
  border: 2px solid transparent;
	font-family: "Formular";
	font-size: 28px;
	position: relative;
`;

const Plus = styled.div`
	position: absolute;
	top: 21px;
	right: 10px;
	cursor: pointer;
`;

const Minus = styled.div`
	position: absolute;
	top: 17px;
	left: 10px;
	cursor: pointer;
`;

interface ICustomQuantityInput {
	width: string;
	formik: FormikValues;
	formItem: string;
	label?: string;
}

export const CustomQuantityInput: React.FC<ICustomQuantityInput> = (props) => {
	const { formik, formItem, label } = props;
	const plusHandler = () => {
		formik.setFieldValue(formItem, formik.values[formItem] + 1);
	};
	const minusHandler = () => {
		if (formik.values[formItem] > 0) {
			formik.setFieldValue(formItem, formik.values[formItem] - 1);
		}
	};
	return (
		<Flex direction="column">
			{label && <String fSize={"14px"} margin="0 0 8px">{label}</String>}
			<CustomQuantityInputWrapper {...props}>
				<Minus onClick={minusHandler}>-</Minus>
				{formik.values[formItem]}
				<Plus onClick={plusHandler}>+</Plus>
			</CustomQuantityInputWrapper>
		</Flex>
	);
};