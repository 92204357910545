import { FormikValues } from 'formik';
import React from 'react';
import { IAdditionalIncomes, IAdvancedOptions } from '../../../../redux/Credit/creditTypes';
import { CurrancyField } from '../../../../styles/UIKit/CurrancyField';
import { FejoaMappedSelect } from '../../../../styles/UIKit/FejoaSelect/FejoaMappedSelect';
import { FejoaSelect } from '../../../../styles/UIKit/FejoaSelect/FejoaSelect';
import { EditItem } from './EditItem';

interface IEditItemWrapper {
	incomesList: IAdvancedOptions[];
	formik: FormikValues;
	list: IAdditionalIncomes[];
	updateFormCallBackForAdditionalIncomesData: (
		formItem: string,
		currentValue: any[]
	) => void;
	setList: (list: IAdditionalIncomes[]) => void;
	chosenItemData: IAdditionalIncomes | null;
	setChosenItemData: (chosenItemData: IAdditionalIncomes | null) => void;
}

export const EditItemWrapper: React.FC<IEditItemWrapper> = (props) => {
	const {
		formik,
		setList,
		list,
		chosenItemData,
		setChosenItemData,
		updateFormCallBackForAdditionalIncomesData,
		incomesList,
	} = props;

	React.useEffect(() => {
		if (chosenItemData) {
			formik.setFieldValue("occupationIncomesKind", chosenItemData.occupationIncomesKind);
			formik.setFieldValue("occupationAdditionalIncomeValue", chosenItemData.occupationAdditionalIncomeValue);
		}
	}, [chosenItemData]);

	return (
		<EditItem
			formik={formik}
			additionalIncomesData={list}
			setAdditionalIncomesData={setList}
			chosenItemData={chosenItemData}
			setChosenItemData={setChosenItemData}
			updateFormCallBackForAdditionalIncomesData={updateFormCallBackForAdditionalIncomesData}
		>
			<FejoaMappedSelect
				formik={formik}
				formItem={"occupationAdditionalIncomesKind"}
				height={"71px"}
				width={"100%"}
				options={incomesList}
				defaultValue={"Выберите"}
				label={"Вид дополнительного дохода"}
			/>
			<CurrancyField
				formik={formik}
				fieldName={"occupationAdditionalIncomeValue"}
				label={"Размер ежемесячного дохода (руб.)"}
				fSize={"24px"}
				width={"100%"}
				height={"71px"}
			/>
		</EditItem>
	);
};