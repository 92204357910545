import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { Button } from '../../styles/UIKit/Button';
import { Flex } from '../../styles/UIKit/Flex';
import { MainBlockWrapper, SecondaryBlockWrapper, StyledPageContainer, } from '../../pages/CommonPagesStyles';
import { ConfirmCreditParamsMainBlock } from './components/ConfirmCreditParamsMainBlock';
import { ConfirmCreditParamsSecondaryBlock } from './components/ConfirmCreditParamsSecondaryBlock';
import useDimensions from '../../hooks/useDimensions';

export const ConfirmCreditParams = () => {
	const { flowId } = useParams();
	const confirmCreditForm = useFormik({
		initialValues: {
			refinancedLoand: "",
			insuranceCompany: "",
			insuranceRate: "",
			allCreditTermInsurance: "",
			insurancePrime: "",
			insuranceProduct: "",
			defferMonths: "",
		},
		onSubmit: (values) => {},
		enableReinitialize: true,
	});
	const { screenWidth } = useDimensions();
	return (
		<>
			<StyledPageContainer>
				<form onSubmit={confirmCreditForm.handleSubmit}>
					<MainBlockWrapper>
						<ConfirmCreditParamsMainBlock
							confirmCreditForm={confirmCreditForm}
						/>
					</MainBlockWrapper>
				</form>
				<SecondaryBlockWrapper>
					<ConfirmCreditParamsSecondaryBlock />
				</SecondaryBlockWrapper>
			</StyledPageContainer>
			<Flex justify="center">
				<Button
					mode="primary"
					width="157px"
					background="#F0F2F6"
					color="#0A1244"
					margin="0 10px"
				>
					Закрыть
				</Button>
				<Button mode="primary" width="264px" margin="0 10px">
					Сохранить черновик
				</Button>
				<Button mode="primary" width="329px" margin="0 10px">
					Направить на рассмотрение
				</Button>
				<Button
					mode="primary"
					width="331px"
					margin="0 10px"
					background="red"
				>
					Отказаться от предложения
				</Button>
			</Flex>
		</>
	);
};