import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {AppRootStateType} from '../../../redux/store';
import useDimensions from '../../../hooks/useDimensions';
import ReactSwipe from 'react-swipe';
import '../Card.css';
import {CardBannerCardInfo} from "./CardBannerCardInfo";
import {CardBannerInfoReferral} from "./CardBannerInfoReferral";
import {PromoCard} from "./PromoCard/PromoCard";
import {IThemes} from "../../../styles/themes/themes";

export interface CardBannerProps {
  isShowPromo?: boolean;
  product?: "cards" | "credits";
}

export const CardBanner : React.FC<CardBannerProps> = ({isShowPromo = true, product}) => {
  const [searchParams] = useSearchParams();
  const utmGift = useSelector<AppRootStateType, number | undefined>(state => state.creditReducer.initialCreditsData?.config?.utmGift);
  const theme = useSelector<AppRootStateType, IThemes>(state => state.appReducer.theme)
  const [swipeSlideIndex, setSwipeSlideIndex] = useState(0);

  const { screenWidth } = useDimensions();
  const isMobile = screenWidth <= 767;
  const isReferral = !!searchParams.get('referral');

  return (
    <>
      {isShowPromo && <PromoCard isReferral={isReferral} type={theme.name}/>}
      <div
        className="card-info-items-wrap"
        style={{
          'position':'relative',
          margin: product === "credits" && isReferral && !isMobile ? "0 0 -150px" : undefined
        }}
      >
        {isReferral
          ? isMobile
                ? <div className="card-info-items card-info-items_with-rows">
                    <ReactSwipe
                        swipeOptions={{
                            continuous: false,
                            startSlide: swipeSlideIndex,
                            callback: (index: number) => {
                                setSwipeSlideIndex(index);
                            }
                        }}
                    >
                        <CardBannerInfoReferral isMobile={isMobile} product={product}/>
                    </ReactSwipe>
                    {product !== "credits" &&
                    <div className="swipe-dots">
                        <div className={`swipe-dots__dot ${swipeSlideIndex === 0 && 'swipe-dots__dot_active'}`}></div>
                        <div className={`swipe-dots__dot ${swipeSlideIndex === 1 && 'swipe-dots__dot_active'}`}></div>
                    </div>
                    }
                </div>
                : <div className="container card-info-items card-info-items_with-rows">
                    <CardBannerInfoReferral isMobile={isMobile} product={product} />
                </div>
          : isMobile
            ? <div className="card-info-items card-info-items_with-rows">
              <ReactSwipe
                swipeOptions={{
                  continuous: false,
                  startSlide: swipeSlideIndex,
                  callback: (index: number) => {
                    setSwipeSlideIndex(index);
                  }
                }}
              >
                <CardBannerCardInfo isMobile={isMobile}/>
              </ReactSwipe>
              <div className="swipe-dots">
                <div className={`swipe-dots__dot ${swipeSlideIndex === 0 && 'swipe-dots__dot_active'}`}></div>
                <div className={`swipe-dots__dot ${swipeSlideIndex === 1 && 'swipe-dots__dot_active'}`}></div>
              </div>
            </div>
            : <div className="container card-info-items card-info-items_with-rows">
              <CardBannerCardInfo isMobile={isMobile} />
            </div>
        }
      </div>
    </>
  );
}
