import React from 'react';
import styled from 'styled-components';
import { String } from './String';

const StyledCustomSelect = styled.select<TCustomSelect>`
	width: ${({ width }) => width || "100%"};
	height: ${({ height }) => height || "auto"};
	font-weight: 400;
	font-family: "Formular";
	font-size: 28px;
	line-height: 43px;
	padding: 0 20px;
	outline: none;
	border-radius: 4px;
	border: 1px solid
		${(props) => props.borderColor || props.theme.appColors.greyText};
	color: ${(props) => props.color || "black"};
`;

const StyledOptions = styled.option`
	font-family: "Formular";
`;

const SelectItemsWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

type TCustomSelect = {
	width?: string;
	height?: string;
	options: string[];
	borderColor?: string;
	label?: string;
	subLabel?: string;
	defaultValue?: string;
};
export const CustomSelect: React.FC<TCustomSelect> = (props) => {
	const { label, subLabel, options, defaultValue } = props;
	return (
		<SelectItemsWrapper>
			{label && <String fSize={"14px"}>{label}</String>}
			<StyledCustomSelect
				{...props}
				options={props.options}
				// defaultValue={"Выберите"}
			>
				<option selected>{defaultValue ? defaultValue : "Выберите"}</option>
				{options.map((option, index) => (
					<StyledOptions key={index}>{option}</StyledOptions>
				))}
			</StyledCustomSelect>
			{subLabel && <String fSize={"12px"}>{subLabel}</String>}
		</SelectItemsWrapper>
	);
};