import { FormikValues } from 'formik';

export const phoneNumbersMatchRules = [
	{
		fieldName: "phoneNumber",
		label: "Мобильный телефон",
		forbiddenFields: ["occupationPhoneNumber", "contactsPhone"],
	},
	{
		fieldName: "occupationPhoneNumber",
		label: "Телефон работодателя",
		forbiddenFields: ["phoneNumber", "contactsPhone"],
	},
	{
		fieldName: "contactsPhone",
		label: "Телефон для экстренной связи",
		forbiddenFields: ["phoneNumber", "occupationPhoneNumber"],
	},
];

export const checkPhones = (rules: any[], values: FormikValues) => {
	const equalFields: string[] = [];
	for (const key in values) {
		const rule = rules.find((rule) => rule.fieldName === key);
		const currentValue = values[key];
		if (rule) {
			rule.forbiddenFields.forEach((forbiddenField: string) => {
				if (currentValue && currentValue?.replace(/\D/g, '') === values[forbiddenField]?.replace(/\D/g, '')) {
					if (!equalFields.includes(rule.label)) {
						equalFields.push(rule.label);
					}
				}
			});
		}
	}
	return equalFields.length ? equalFields : null;
};

export const equalFieldsNotificator = (incorrectFields: string[] | null) => {
	if (incorrectFields) {
		return `Поля: ${incorrectFields
			.map((tel) => ` "${tel}" `)
			.join(",")} не могут совпадать`;
	}
	return undefined;
};