import styled from 'styled-components';

export const CheckBoxWrapper = styled.div`
	padding: 0 20px 0 0;
`

export const SmsButtonsWrapper = styled.div`
	margin-top: 49px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`