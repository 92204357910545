import { FormikValues } from "formik";
import React, { LegacyRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from 'react-google-recaptcha';
import { getSmsData } from "../../../redux/Auth/auth-reducer";
import { IGenerate, IValidate } from "../../../redux/Auth/authTypes";
import { AppRootStateType } from "../../../redux/store";
import { CustomField } from "../../../styles/UIKit/CustomField/CustomField";
import { TelephoneField } from "../../../styles/UIKit/CustomField/TelephoneField";
import { String } from "../../../styles/UIKit/String";
import { SmsModal } from "../../../styles/UIKit/Modal/SmsModal";
import { Flex } from "../../../styles/UIKit/Flex";
import { adaptiveMeasurements, adaptiveParams } from "../../../styles/adaptiveParams";
import { ContinuationButton } from "./ContinuationButton";
import { setAntifrodTimer } from "../../../redux/Auth/authActions";
import { IConsentFilesData, ICreditInitState } from "../../../redux/Credit/creditTypes";
import { getLoadedFile } from "../../../redux/Credit/credit-reducer";
import { ParagraphWrapper } from "../../../pages/CommonPagesStyles";
import { CustomerCalendar } from "../../../styles/UIKit/DatePicker/CustomerCalendar/CustomerCalendar";
import { dateIntoString } from "../../../utils/commonFunctions";
import { ROUTE_PATH } from "../../../routesConfig";
import { IFioDadataList } from "../../../redux/Application/appTypes";
import { getDadataFio, onValidateParam, getDadataCities, onCheckBlackList } from "../../../redux/Application/app-reducer";
import { IDadataAddressTypes, IDadataTypes } from "../../../redux/Application/appTypes";
import { setCitiesList, setEmailValidationErrorMessage, setFioBlackListErrorMessage, setFioList, setPhoneBlackListErrorMessage, setPhoneValidationErrorMessage } from "../../../redux/Application/appActions";
import { CustomAutoComplete } from "../../../styles/UIKit/dadata/CustomAutoComplete";
import { onSetFieldBorderColor } from "../../../utils/onSetFieldBorderColor";
import { CheckBoxField } from "../../../styles/UIKit/CustomCheckBox/CheckBoxField";
import { CalculatorFormNote } from "../../../components/CalculatorFormNote";
import { FioDadataField } from "../../../styles/UIKit/dadata/FioDadataField";
import { onDetectSafari } from "../../../utils/onDetectSafari";
import { REG_EXPS } from "../../../config";
import { pushDataLayerEvent } from "../../../utils/dataLayer";

interface IUserDataFields {
	smsPopup: boolean;
	setSmsPopup: (smsPopup: boolean) => void;
	product: string;
	updateFormHandler: (value: any) => void;
	// accessToken: string | null;
	screenWidth: number;
	flowId: string;
	creditFormData: FormikValues;
	disabledModeForConfirmationPhone: boolean;
	smsCodeValue: string;
	setSmsCodeValue: (smsCodeValue: string) => void;
	isAgree: boolean;
	setIsAgree: (isAgree: boolean) => void;
	antifrodLife: undefined | number;
	downloadDocumentsData: IConsentFilesData[];
	citiesList: IDadataAddressTypes[];
	setFormDataUpdates: (values: ICreditInitState) => void;
	initialState: ICreditInitState;
	siteKey?: string;
	fioBlackListErrorMessage: string | null;
	phoneBlackListErrorMessage: string | null;
	phoneValidationErrorMessage: string | null;
	emailValidationErrorMessage: string | null;
	fioList: IFioDadataList[];
}

export const UserDataFields: React.FC<IUserDataFields> = (props) => {
	const {
		smsPopup,
		setSmsPopup,
		product,
		updateFormHandler,
		// accessToken,
		screenWidth,
		flowId,
		creditFormData,
		disabledModeForConfirmationPhone,
		siteKey,
		smsCodeValue,
		setSmsCodeValue,
		isAgree,
		setIsAgree,
		antifrodLife,
		downloadDocumentsData,
		citiesList,
		setFormDataUpdates,
		initialState,
		fioBlackListErrorMessage,
		phoneBlackListErrorMessage,
		phoneValidationErrorMessage,
		emailValidationErrorMessage,
		fioList,
	} = props;
	const [token, setToken] = React.useState<string>('');
	const [continuePressed, setContinuePressed] = React.useState<boolean>(false);
	const recaptchaRef: LegacyRef<any> = React.createRef();
	const dispatch = useDispatch();
	const CITY_LABEL = {
		[ROUTE_PATH.CREDITS]: "Город выдачи кредита",
		[ROUTE_PATH.CPZA]: "Город выдачи кредита",
		[ROUTE_PATH.CARDS]: "Город получения кредитной карты",
	}

	const generate = useSelector<AppRootStateType, IGenerate | undefined>(
		(state) => state.authReducer.generate
	);
	const validate = useSelector<AppRootStateType, IValidate | undefined>(
		(state) => state.authReducer.validate
	);

	const { mainContentWidth } = adaptiveParams;

	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;

	const isBackendInvalidations = !!fioBlackListErrorMessage || !!phoneBlackListErrorMessage || !!phoneValidationErrorMessage || !!emailValidationErrorMessage

	const _onDelay = React.useCallback(() => {
		if (creditFormData.values.fio) {
			const timeOutId = setTimeout(() => dispatch(getDadataFio({ title: `${creditFormData.values.fio}` })), 500);
			return () => clearTimeout(timeOutId);
		}
	}, [creditFormData.values.fio])

	const getSmsCode = () => {
		setToken('');
		setContinuePressed(true);
		if (!Object.values(creditFormData.errors).length) {
			validatePassport();
			dispatch(onValidateParam({ paramValue: creditFormData.values.phoneNumber, searchParam: "phone" }));
			dispatch(onValidateParam({ paramValue: creditFormData.values.email, searchParam: "email" }))
		}
		creditFormData.setTouched(creditFormData.errors); // TODO
		if (!Object.values(creditFormData.errors).length) {
			dispatch(setAntifrodTimer(undefined));
			onExecute().then(executedToken => {
				if (executedToken) setToken(executedToken);
				recaptchaRef.current?.reset();
			});
		}
		else {
			setContinuePressed(false);
		}
	};

	React.useEffect(() => {
		if (continuePressed && token) {
			dispatch(getSmsData(
				creditFormData.values.phoneNumber,
				creditFormData.values.fio,
				"generateWithSign",
				flowId,
				token
			));
			setContinuePressed(false);
			setToken('');
			setSmsPopup(true);
		}
	}, [continuePressed, token])

	React.useEffect(() => {
		if (!smsPopup) { // если закрыли модалку
			setToken('');
			recaptchaRef.current.reset();
		}
	}, [smsPopup]);

	// FOR DATE PICKER "birthDate"
	const [birthDateValue, setBirthDateValue] = React.useState<string>(
		initialState.birthDate !== null
			? dateIntoString(initialState.birthDate)
			: ""
	);
	const updateFormCallBack = (
		formItem: string,
		currentValue: string | boolean,
		event: FormikValues
	) => {
		updateFormHandler({
			...creditFormData.values,
			[formItem]: currentValue,
		});
		creditFormData.handleBlur(event);
	};
	// console.log(dounloadDocumentsData);
	const downloadDocPersonalDataConditions = () => {
		// window.open(handlingConditions, "_blank", 'noopener,resizable,scrollbars')
		dispatch(
			getLoadedFile(
				flowId,
				downloadDocumentsData[0].id,
				downloadDocumentsData[0].name,
				"consent",
				onDetectSafari()
			)
		);
	};
	const downloadDocPersonalDataConfirmations = () => { // interactionsConditions
		// window.open(interactionsConditions, "_blank", 'noopener,resizable,scrollbars')
		dispatch(
			getLoadedFile(
				flowId,
				downloadDocumentsData[1].id,
				downloadDocumentsData[1].name,
				"consent",
				onDetectSafari()
			)
		);
	};

	const onBlurPhoneHandler = (formItem: string, currentValue: string, event: FormikValues) => {
		onPhoneFocusAdditionalActions();
		updateFormCallBack(formItem, currentValue, event);
		if (creditFormData.values.phoneNumber) {
			dispatch(onCheckBlackList({ query: creditFormData.values.phoneNumber, searchParam: "phone" }));
			dispatch(onValidateParam({ paramValue: creditFormData.values.phoneNumber, searchParam: "phone" }));
		}
	}

	const onPhoneFocusAdditionalActions = () => {
		dispatch(setPhoneBlackListErrorMessage(null))
		dispatch(setPhoneValidationErrorMessage(null))
	}

	const onBlurEmailHandler = (formItem: string, currentValue: string, event: FormikValues) => {
		updateFormCallBack(formItem, currentValue, event);
		dispatch(onValidateParam({ paramValue: creditFormData.values.email, searchParam: "email" }))
	}

	const onBlurFirstNameHandler = (formItem: string, currentValue: string, event: FormikValues) => {
		updateFormCallBack(formItem, currentValue, event);
	}

	const onBlurSecondNameHandler = (formItem: string, currentValue: string, event: FormikValues) => {
		updateFormCallBack(formItem, currentValue, event);
	}

	const onBlurLastNameHandler = (formItem: string, currentValue: string, event: FormikValues) => {
		updateFormCallBack(formItem, currentValue, event);
	}

	const onEmailFocusHandler = () => {
		dispatch(setEmailValidationErrorMessage(null));
	}

	// *** HANDLERS FOR CITIES DADATA ***
	const issuingCity = creditFormData.values.issuingCity ? creditFormData.values.issuingCity : "";
	const onChangeCityCallback = (value: string) => {
		dispatch(getDadataCities("city", "title", value));
		// creditFormData.setFieldValue("issuingCity", null);
		creditFormData.setFieldValue("region", null);
	}
	const setCitiesListOptionHandler = (chosenItem: IDadataTypes) => {
		creditFormData.setFieldValue("issuingCity", chosenItem.city);
		creditFormData.setFieldValue("region", chosenItem.regionCode);
		setFormDataUpdates({ ...creditFormData.values, issuingCity: chosenItem.city, region: chosenItem.regionCode });
		dispatch(setCitiesList([]));
	};
	const resetCitiesListHandler = () => dispatch(setCitiesList([]));


	// *** HANDLERS FOR FIO DADATA ***
	React.useEffect(_onDelay, [_onDelay])

	const setFioListOptionHandler = (chosenItem: IFioDadataList) => {
		const fio = chosenItem.value;
		const gender = chosenItem.gender ? chosenItem.gender[0] : null;
		creditFormData.setFieldValue("fio", fio);
		creditFormData.setFieldValue("gender", gender );
		setFormDataUpdates({ ...creditFormData.values, fio, gender});
	}
	const onCheckBoxPressedHandler = () => {
		creditFormData.setFieldValue("signConsent", !creditFormData.values.signConsent);
		setFormDataUpdates({ ...creditFormData.values, signConsent: !creditFormData.values.signConsent });
	};
	const resetFioListHandler = () => {
		dispatch(onCheckBlackList({ query: creditFormData.values.fio, searchParam: "fio" }))
		dispatch(setFioList([]));
	};

	const validatePassport = () => {
		if (!creditFormData.values.fio && !creditFormData.values.isFatherName) {
			creditFormData.errors.fio = "Укажите Фамилию Имя Отчество";
		} else if (creditFormData.values.fio && REG_EXPS.forFIO(creditFormData.values.fio.replace(/\s+/g, ""))) {
			creditFormData.errors.fio = "Допускается для ввода только кириллица";
		} else if (creditFormData.values.isFatherName && (checkPatronymic(creditFormData.values.fio) < 2 || checkPatronymic(creditFormData.values.fio) > 4)) {
			creditFormData.errors.fio = "Укажите Фамилию Имя";
		} else if (!creditFormData.values.isFatherName && (checkPatronymic(creditFormData.values.fio) < 3 || checkPatronymic(creditFormData.values.fio) > 4)) {
			creditFormData.errors.fio = "Укажите Фамилию Имя Отчество";
		}
	}

	const checkPatronymic = (fio: string): number => {
		return fio?.split(" ")?.filter((el) => el !== "")?.length || 0;
	};

	const onFioFocusHandler = () => dispatch(setFioBlackListErrorMessage(null))

	const onExecute = async () => {
		// @ts-ignore
		return await recaptchaRef.current.execute();
	};

	const onTokenChange = (token: string | null) => {
		if (token) {
			pushDataLayerEvent(`${product}_form1_recaptcha_success`);
			setToken(token);
		}
	};

	const showCustomFio =
		creditFormData?.values?.fio?.replace(/ /g,'')?.length > 0 &&
		!fioList?.filter((item) => item?.qc === 0)?.length &&
		!creditFormData?.errors?.fio

	const setNewFio = (field: string, fieldIndex: number) => {
		const newFio = creditFormData?.values?.fio
			?.split(" ")
			?.map((item: string, index: number) => index === fieldIndex ? field?.replace(/ /g,'') : item)
			?.join(" ");
		creditFormData.setFieldValue("fio", newFio);
		setFormDataUpdates({ ...creditFormData.values, fio: newFio})
	}

	useEffect(() => {
		const { lastName } = creditFormData?.values || {}
		if (lastName) {
			setNewFio(lastName, 0)
		}
	}, [creditFormData.values.lastName])

	useEffect(() => {
		const { firstName } = creditFormData?.values || {}
		if (firstName) {
			setNewFio(firstName, 1)
		}
	}, [creditFormData.values.firstName])

	useEffect(() => {
		const { secondName } = creditFormData?.values || {}
		if (secondName) {
			setNewFio(secondName, 2)
		}
	}, [creditFormData.values.secondName])

	useEffect(() => {
		if (showCustomFio) {
			const { fio } = creditFormData?.values || {};
			const values = fio?.split(" ");
			const lastName = values?.[0];
			const firstName = values?.[1];
			const secondName = values?.[2];
			creditFormData.setFieldValue("lastName", lastName);
			creditFormData.setFieldValue("firstName", firstName);
			creditFormData.setFieldValue("secondName", secondName);
			setFormDataUpdates({ ...creditFormData.values, lastName });
			setFormDataUpdates({ ...creditFormData.values, firstName });
			setFormDataUpdates({ ...creditFormData.values, secondName });
		} else {
			creditFormData.setFieldValue("lastName", "");
			creditFormData.setFieldValue("firstName", "");
			creditFormData.setFieldValue("secondName", "");
			setFormDataUpdates({ ...creditFormData.values, lastName: ""})
			setFormDataUpdates({ ...creditFormData.values, firstName: ""})
			setFormDataUpdates({ ...creditFormData.values, secondName: ""})
		} 
	}, [showCustomFio])

	return (
		<>
			{!(generate?.success) && <>
				<ParagraphWrapper padding={fieldsWrapConditions ? "10px 0" : "0 10px"}>
					<FioDadataField
						formik={creditFormData}
						formName={"fio"}
						dadataList={fioList}
						label={"ФИО"}
						fSize={adaptiveMeasurements(screenWidth, "28px", "26px", "24px", "20px")}
						height={adaptiveMeasurements(screenWidth, "71px", "71px", "50px", "50px")}
						onFocusAdditionalActions={onFioFocusHandler}
						width={"100%"}
						onMouseDownHandler={setFioListOptionHandler}
						ResetListHandler={resetFioListHandler}
						borderColor={onSetFieldBorderColor(creditFormData, "fio")}
						errorValue={fioBlackListErrorMessage || (creditFormData.touched.fio && creditFormData.errors.fio)}
						placeholder={"Петров Алексей Иванович"}
						placeholderOnlyWithFocus={true}
						disabled={showCustomFio}
					/>

					{showCustomFio &&
						<>
							<div
								style={{ 
									color: "#FF6804",
									marginTop: "32px",
									fontFamily: "Formular",
								}}
							>
								Проверьте, что введенные данные совпадают с паспортными
							</div>
							<div style={{ marginTop: "16px"}}>
								<CustomField
									screenWidth={screenWidth}
									fSize={adaptiveMeasurements(screenWidth, "28px", "26px", "24px", "20px")}
									height={adaptiveMeasurements(screenWidth, "71px", "71px", "50px", "50px")}
									onBlurHandler={onBlurLastNameHandler}
									formItem={"lastName"}
									width={"100%"}
									label={"Фамилия"}
									{...creditFormData.getFieldProps("lastName")}
									borderColor={onSetFieldBorderColor(creditFormData, "lastName")}
								/>
							</div>
							<div style={{ marginTop: "8px"}}>
								<CustomField
									screenWidth={screenWidth}
									fSize={adaptiveMeasurements(screenWidth, "28px", "26px", "24px", "20px")}
									height={adaptiveMeasurements(screenWidth, "71px", "71px", "50px", "50px")}
									onBlurHandler={onBlurFirstNameHandler}
									formItem={"firstName"}
									width={"100%"}
									label={"Имя"}
									{...creditFormData.getFieldProps("firstName")}
									borderColor={onSetFieldBorderColor(creditFormData, "lastName")}
								/>
							</div>
							<div style={{ marginTop: "8px"}}>
								<CustomField
									screenWidth={screenWidth}
									fSize={adaptiveMeasurements(screenWidth, "28px", "26px", "24px", "20px")}
									height={adaptiveMeasurements(screenWidth, "71px", "71px", "50px", "50px")}
									onBlurHandler={onBlurSecondNameHandler}
									formItem={"secondName"}
									width={"100%"}
									label={"Отчество"}
									{...creditFormData.getFieldProps("secondName")}
									borderColor={onSetFieldBorderColor(creditFormData, "lastName")}
								/>
							</div>
						</>
					}

					<Flex align={"center"} margin={"20px 0"}>
						<CheckBoxField
							formItem="isFatherName"
							checked={creditFormData.values.isFatherName}
							onChange={() => creditFormData.setFieldValue("isFatherName", !creditFormData.values.isFatherName)}
						/>
						<String margin="0 20px">Без отчества</String>
					</Flex>
				</ParagraphWrapper>
				<ParagraphWrapper padding={fieldsWrapConditions ? "15px 0" : "15px"}>
					<Flex
						direction={fieldsWrapConditions ? "row" : "column"}
						width={"100%"}
						justify="space-between"
					>
						<CustomerCalendar
							updateFormHandler={setFormDataUpdates}
							width={adaptiveMeasurements(screenWidth, "360px", "360px", "100%", "100%")}
							height={adaptiveMeasurements(screenWidth, "71px", "71px", "50px", "50px")}
							fSize={adaptiveMeasurements(screenWidth, "28px", "26px", "24px", "20px")}
							label={"Дата рождения"}
							formik={creditFormData}
							formItem={"birthDate"}
							value={creditFormData.values.birthDate || null}
							dateValue={birthDateValue}
							setDateValue={setBirthDateValue}
							errorValue={creditFormData.touched.birthDate && creditFormData.errors.birthDate}
							error={creditFormData.touched.birthDate && !!creditFormData.errors.birthDate}
							borderColor={onSetFieldBorderColor(creditFormData, "birthDate")}
						/>
						<CustomAutoComplete
							formik={creditFormData}
							formName={"issuingCity"}
							onBlurHandler={updateFormCallBack}
							width={adaptiveMeasurements(screenWidth, "360px", "360px", "100%", "100%")}
							fSize={adaptiveMeasurements(screenWidth, "28px", "26px", "24px", "20px")}
							height={adaptiveMeasurements(screenWidth, "71px", "71px", "50px", "50px")}
							label={CITY_LABEL[product as keyof typeof CITY_LABEL]}
							dadataList={citiesList}
							currentValue={issuingCity}
							onChangeHandler={onChangeCityCallback}
							onMouseDownHandler={setCitiesListOptionHandler}
							ResetListHandler={resetCitiesListHandler}
							paramKey={"title"}
							error={creditFormData.touched.issuingCity && !!creditFormData.errors.issuingCity}
							borderColor={onSetFieldBorderColor(creditFormData, "issuingCity")}
							// customPattern={/[а-яА-Я ]/g}
							errorValue={creditFormData.touched.issuingCity && creditFormData.errors.issuingCity}
						/>
					</Flex>
				</ParagraphWrapper>
				<ParagraphWrapper padding={fieldsWrapConditions ? "10px 0" : "10px"}>
					<Flex
						direction={fieldsWrapConditions ? "row" : "column"}
						width={"100%"}
						justify="space-between"
					>
						<TelephoneField
							screenWidth={screenWidth}
							width={adaptiveMeasurements(screenWidth, "360px", "360px", "100%", "100%")}
							fSize={adaptiveMeasurements(screenWidth, "28px", "26px", "24px", "20px")}
							height={adaptiveMeasurements(screenWidth, "71px", "71px", "50px", "50px")}
							onBlurHandler={onBlurPhoneHandler}
							formItem={"phoneNumber"}
							type={"tel"}
							{...creditFormData.getFieldProps("phoneNumber")}
							label={"Мобильный телефон"}
							subLabel={"Мы отправим решение по заявке на указанный номер"}
							borderColor={onSetFieldBorderColor(creditFormData, "phoneNumber")}
							errorValue={phoneValidationErrorMessage || phoneBlackListErrorMessage || (creditFormData.touched.phoneNumber && creditFormData.errors.phoneNumber)}
							error={creditFormData.touched.phoneNumber && creditFormData.errors.phoneNumber}
							subLabelColor={"#10C8D2"}
						/>
						<CustomField
							screenWidth={screenWidth}
							fSize={adaptiveMeasurements(screenWidth, "28px", "26px", "24px", "20px")}
							height={adaptiveMeasurements(screenWidth, "71px", "71px", "50px", "50px")}
							onBlurHandler={onBlurEmailHandler}
							onFocusHandler={onEmailFocusHandler}
							formItem={"email"}
							width={adaptiveMeasurements(screenWidth, "360px", "360px", "100%", "100%")}
							label={"Электронная почта"}
							{...creditFormData.getFieldProps("email")}
							subLabel={"Мы отправим решение по заявке на указанный email"}
							error={creditFormData.touched.email && !!creditFormData.errors.email}
							errorValue={emailValidationErrorMessage || (creditFormData.touched.email && creditFormData.errors.email)}
							borderColor={onSetFieldBorderColor(creditFormData, "email")}
							subLabelColor={"#10C8D2"}
						/>
					</Flex>
				</ParagraphWrapper>
				<ParagraphWrapper margin={fieldsWrapConditions ? "10px 0" : "10px"}>
				<Flex padding='8px 0'>
					<Flex
						align={"center"}
						margin={"0 20px 0 0"}
					// padding={fieldsWrapConditions ? "0" : "15px"}
					>
						<CheckBoxField
							formItem="signConsent"
							checked={creditFormData.values.signConsent}
							onChange={onCheckBoxPressedHandler}
						/>
					</Flex>
					<Flex align="center">
						<String>
							Я соглашаюсь
							<a
								// onClick={downloadDocPersonalDataConditions}
								href="https://lkz.zenit.ru/files/handlingConditions.pdf"
								target="_blank"
								style={{
									color: "#10C8D2",
									textDecoration: "none",
									cursor: "pointer",
								}}
								rel="noreferrer"
							//  target="_blank"
							>&nbsp;с условиями обработки и использования персональных данных&nbsp;</a>
							и подтверждаю согласие с
							{/*<span*/}
							{/*	onClick={downloadDocPersonalDataConfirmations}*/}
							{/*	style={{*/}
							{/*		color: "#10C8D2",*/}
							{/*		textDecoration: "none",*/}
							{/*		cursor: "pointer",*/}
							{/*	}}*/}
							{/*//  target="_blank"*/}
							{/*>&nbsp;правилами электронного взаимодействия&nbsp;</span>*/}
							<a
								href="https://lkz.zenit.ru/files/rules_of_electronic_interaction.pdf"
								target="_blank"
								style={{
									color: "#10C8D2",
									textDecoration: "none",
									cursor: "pointer"
								}}
								rel="noreferrer"
							>
								&nbsp;согласие с правилами электронного взаимодействия&nbsp;
							</a>
						</String>
					</Flex>
				</Flex>
			</ParagraphWrapper>
			</>}

			<ReCAPTCHA
				sitekey={siteKey || ''}
				size="invisible"
				ref={recaptchaRef}
				onChange={onTokenChange}
			/>

			{generate?.success && (
				<SmsModal
					screenWidth={screenWidth}
					show={smsPopup}
					setShow={setSmsPopup}
					formik={creditFormData}
					formItem={"smsCode"}
					smsCodeValue={smsCodeValue}
					setSmsCodeValue={setSmsCodeValue}
					disabled={disabledModeForConfirmationPhone}
					repeatCode={getSmsCode}
					smsAuthError={validate?.error}
					attempts={validate?.result?.attempts}
					antifrodLife={antifrodLife}
					phoneNumber={creditFormData.values.phoneNumber}
					downloadDocPersonalDataConditions={downloadDocPersonalDataConditions}
					downloadDocPersonalDataConfirmations={downloadDocPersonalDataConfirmations}
					isBackendInvalidations={isBackendInvalidations}
				/>
			)}

			{!(generate?.success) &&
				<ContinuationButton
					fieldsWrapConditions={fieldsWrapConditions}
					disabledModeForConfirmationPhone={
						!creditFormData.values.signConsent || disabledModeForConfirmationPhone
					}
					loading={continuePressed}
					labelText={"Далее"}
					getSmsCode={() => {
						pushDataLayerEvent(`${product}_form1_button_click`);
						getSmsCode();
					}}
				/>
			}

			{screenWidth < 1190 && product !== 'credits' &&  product !== 'cpza' && <Flex margin="10px">
				<CalculatorFormNote fullWidth={false} product={product} />
			</Flex>}
		</>
	);
};
