import './PresentBlock.css';
import presentBox from '../../../assets/presentBox.svg';
import useDimensions from '../../../hooks/useDimensions';

const BANNER_URL = 'https://share.flocktory.com/exchange/login?ssid=5667&bid=9299&email=xname@flocktory.com';
export const PresentBlock: React.FC = () => {
 const { screenWidth } = useDimensions();
 const isMobile = screenWidth <= 767;
 
  const onClickHandler = () => {
    window.location.href = BANNER_URL
  };

return (
  <>
    {!isMobile && (
      <div className="container">
        <div className="banner">
          <div className="banner-content">
            <p>Вам доступен <span className="highlighted">1 подарок</span> за оформленную заявку </p>
            <button className="banner-button" onClick={onClickHandler}>Получить подарок</button>
          </div>
          <div className="banner-image">
            <img src={presentBox} alt="Подарок" className="gift-icon" />
          </div>
        </div> 
      </div>
    )}
    {isMobile && (
      <div className="banner-mobile">
        <div className="banner-content-mobile">
          <p>Вам доступен <span className="highlighted">1 подарок</span> 
          <span className="mobile-content">за оформленную заявку</span> </p>
          <div className="banner-image-mobile">
            <img src={presentBox} alt="Подарок" className="gift-icon-mobile" />
          </div>
        </div>
        <button className="banner-button-mobile" onClick={onClickHandler}>Получить подарок</button>  
      </div>
    )}
  </>
);
}