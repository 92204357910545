import React from 'react';
import styled from 'styled-components';

type Props = {
	display?: string;
	color?: string;
	padding?: string;
	margin?: string;
	fSize?: string;
	fontWeight?: boolean;
	maxWidth?: string;
	fontFamily?: string;
	onClickHandler?: () => void;
	whiteSpace?: string;
    lineHeight?: string;
	textAlign?: string;
	position?: string;
	top?: string;
	left?: string;
};

const StyledString = styled.span<Props>`
	color: ${(props) => props.color || props.theme.appColors.greyText};
	padding: ${(props) => props.padding || "0"};
    display: ${(props) => props.display || "inline"};
	margin: ${(props) => props.margin || "0"};
	font-size: ${(props) => props.fSize || "16px"};
    line-height: ${(props) => props.lineHeight || "1.25rem"};;
	font-weight: ${(props) => (props.fontWeight ? "bold" : "normal")};
	max-width: ${(props) => props.maxWidth || "auto"};
	font-family: ${(props) => props.fontFamily || "Formular"};
	cursor: ${(props) => (props.onClickHandler ? "pointer" : "auto")};
	white-space: ${({ whiteSpace }) => whiteSpace || "normal"};
	text-align: ${({ textAlign }) => textAlign || "start"}; 
	position: ${(props) => props.position || "static"};
	top: ${(props) => props.top || "0"};
	left: ${(props) => props.left || "0"};
`;

export const String: React.FC<Props> = (props) => {
	return <StyledString {...props} onClick={props.onClickHandler} />;
};
