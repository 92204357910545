export const adaptiveParams = {
	burgerMenuWidth: 1000,
	standartFormWidthFirstValue: 1248,
	mainContentWidth: 750, // левая часть формы, со всеми полями
	minimalWrap: 500, // Для не стандартной стилизации конфигуратора
};

export const adaptiveMeasurements = (
	screenWidth: number,
	large: string,
	medium: string,
	small: string,
	mobile: string
): string => {
	const { minimalWrap, mainContentWidth, standartFormWidthFirstValue } =
		adaptiveParams;
	return screenWidth > standartFormWidthFirstValue
		? large
		: screenWidth <= standartFormWidthFirstValue &&
		  screenWidth > mainContentWidth
		? medium
		: screenWidth <= mainContentWidth && screenWidth >= minimalWrap
		? small
		: mobile;
};


