import styled from 'styled-components';
import { String } from '../../../styles/UIKit/String';
import { FormatCurrancy } from '../../../utils/commonFunctions';
import { CheckBoxWrapper } from '../../../pages/SubmitCredit/SubmitCreditStyles';

interface IData {
	id: string;
	isSomeThing: boolean;
	type: string;
	amount: number;
	issueDate: Date;
	remains: number;
}
const currentCredits: IData[] = [
	{
		id: "1",
		isSomeThing: false,
		type: "Потребительский кредит",
		amount: 87940,
		issueDate: new Date("2004-12-17T03:24:00"),
		remains: 504,
	},
	{
		id: "2",
		isSomeThing: false,
		type: "Потребительский кредит",
		amount: 43940,
		issueDate: new Date("2004-12-17T03:24:00"),
		remains: 304,
	},
	{
		id: "3",
		isSomeThing: false,
		type: "Потребительский кредит",
		amount: 87315,
		issueDate: new Date("2004-12-17T03:24:00"),
		remains: 405,
	},
	{
		id: "4",
		isSomeThing: false,
		type: "Потребительский кредит",
		amount: 18734,
		issueDate: new Date("2004-12-17T03:24:00"),
		remains: 342,
	},
];

const TableWrapper = styled.table`
	// border: 1px solid red;
	width: 100%;
	font-size: 20px;
	font-family: "Open Sans";
`;

// const TableRow = styled.tr`
//     border: 1px solid red;
//     margin: 50px 0;
// `;

export const CurrentCredits = () => {
	return (
		<TableWrapper cellSpacing="15px">
			<tr>
				<td />
				<td>
					<String>Тип кредита</String>
				</td>
				<td>
					<String>Сумма выдачи</String>
				</td>
				<td>
					<String>Дата выдачи</String>
				</td>
				<td>
					<String>Остаток долга</String>
				</td>
			</tr>
			{currentCredits.map(
				({ id, type, amount, isSomeThing, issueDate, remains }) => {
					return (
						<tr key={id}>
							<td>
								{/* <ClientOptionsWrapper>
									<CheckBoxWrapper>
										<CustomCheckBox
											checked={isSomeThing}
											setIsChecked={() => {}}
										/>
									</CheckBoxWrapper>
								</ClientOptionsWrapper> */}
							</td>
							<td>{type}</td>
							<td align="right">{FormatCurrancy(amount)} ₽</td>
							<td>{issueDate.toLocaleDateString("ru-RU")}</td>
							<td align="right">
								<String color="red" fSize="20px">
									- {FormatCurrancy(remains)} ₽
								</String>
							</td>
						</tr>
					);
				}
			)}
		</TableWrapper>
	);
};