import MaskedInput from 'react-text-mask';
import styled from 'styled-components';

export type StyledFieldsProps = {
	maxWidth?: string;
	width?: string;
	height?: string;
	label?: string;
	subLabel?: string;
	borderColor?: string;
	color?: string;
	fSize?: string;
	error?: boolean;
	required?: boolean;
	placeholder?: string;
	autocomplete?: string;
	disabled?: boolean;
	customPattern?: RegExp;
	backgroundColor?: string;
	placeholderOnlyWithFocus?: boolean;
};

export const FieldStyles = styled.input<StyledFieldsProps>`
	&::placeholder {
		color: ${ (props) => props.placeholderOnlyWithFocus ? "transparent" : undefined };
	}
	&:focus::placeholder {
    color: ${ (props) => props.placeholderOnlyWithFocus ? "#757575" : undefined };
	}
	background: ${(props) => props.backgroundColor || "#F2F4F7"};
	width: ${(props) => props.width || "auto"};
	// max-width: ${(props) => props.maxWidth || "auto"};
	height: ${(props) => props.height || "auto"};
	font-weight: 400;
	outline: none;
	font-size: ${({ fSize }) => fSize || "28px"};
	line-height: 43px;
	border-radius: 4px;
	font-family: "Formular";
	padding: 0 14px;
	border: 2px solid ${ (props) => props.borderColor || "transparent" };
	color: ${(props) => props.color || "#0A1244"};
`;

export const MaskedFieldStyles = styled(MaskedInput)<StyledFieldsProps>`
	width: ${(props) => props.width || "auto"};
	// max-width: ${(props) => props.maxWidth || "auto"};
	height: ${(props) => props.height || "auto"};
	font-weight: 400;
	outline: none;
	font-size: ${({ fSize }) => fSize || "28px"};
	line-height: 43px;
	border-radius: 4px;
	padding: 0 14px;
  border: 2px solid ${({borderColor}) => borderColor || "transparent" };
  font-family: Formular, serif;
  background: ${(props) => props.backgroundColor || "#F2F4F7"};
  // &:focus {
  //   border-color: #00CAD6;
  // }
`;
