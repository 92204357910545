import { FormikValues } from 'formik';
import { REG_EXPS } from '../../config';
import { checkPatronymic } from '../../utils/commonFunctions';
import { getYearsByDate } from '../../utils/datesHandler';

export const passportValidator = (values: FormikValues, minAllowedAge: number, maxAllowedAge: number, currentFioLength: number, product: string) => {
	type FormikErrorType = {
		isChangedFIO?: boolean;
		previousFio?: string;
		currentPassportSeria?: string;
		currentPassportNumber?: string;
		currentPassportDepartmentCode?: string;
		receiptDate?: string;
		birthDate?: string;
		previousPassportSeria?: string;
		previousPassportNumber?: string;
		previousPassportDepartmentCode?: string;
		previousPassportReceiptDate?: string;
		previousPassportIssuedBy?: string;
		issuedBy?: string;
		birthPlace?: string;
		registrationAddress?: string;
		actualAddress?: string;
		appartmentNumber?: string;
		gender?: string;
		purpose?: string;
	};

	const isCredit = product === "credits"

	const errors: FormikErrorType = {};
	if (values.isChangedFIO && !values.previousFio) {
		errors.previousFio = "Укажите Фамилию Имя Отчество";
	} else if (values.isChangedFIO && values.previousFio && REG_EXPS.forFIO(values.previousFio.replace(/\s+/g, ""))) {
		errors.previousFio = "Допускается для ввода только кириллица";
	} else if (values.isChangedFIO && checkPatronymic(values.previousFio) !== currentFioLength) {
		if (currentFioLength === 2) {
			errors.previousFio = "Укажите Фамилию Имя"
		} else if (currentFioLength > 2) {
			errors.previousFio = "Укажите Фамилию Имя Отчество";
		}
	}
	if (!values.birthDate) {
		errors.birthDate = "Дата должна быть в формате ДД.ММ.ГГГГ. Например, 25.06.1995";
	} else if (REG_EXPS.isValidAge(values.birthDate, minAllowedAge, maxAllowedAge + 0.07671232876)) {
		errors.birthDate = `По условиям кредитования возраст заёмщика от ${minAllowedAge} до ${maxAllowedAge} лет`;
	}
	if (!values.currentPassportSeria) {
		errors.currentPassportSeria = "Серия паспорта должна состоять из 4 цифр";
	} else if (values.currentPassportSeria && values.currentPassportSeria?.replace(" ", "")?.length < 4) {
		errors.currentPassportSeria = "Серия паспорта должна состоять из 4 цифр";
	}
	if (!values.currentPassportNumber) {
		errors.currentPassportNumber = "Номер паспорта должен состоять из 6 цифр";
	} else if (values.currentPassportNumber && values.currentPassportNumber?.length < 6) {
		errors.currentPassportNumber = "Номер паспорта должен состоять из 6 цифр";
	}
	if (!values.receiptDate) {
		errors.receiptDate = "Дата должна быть в формате ДД.ММ.ГГГГ. Например, 25.06.1995";
	} else if (values.receiptDate && getYearsByDate(values.receiptDate) < 0) {
		errors.receiptDate = "Некорректное значение";
	} else if (values.receiptDate && values.birthDate && (values.receiptDate.getTime() - values?.birthDate.getTime()) < (441763200 * 1000)) {
		errors.receiptDate = "Некорректное значение"; // 14 лет
	}
	const isSomePreviousPassportDataFilled = values.previousPassportSeria || values.previousPassportNumber || values.previousPassportDepartmentCode || values.previousPassportReceiptDate || values.previousPassportIssuedBy;
	const isPreviousPassportDataRequired = getYearsByDate(values.receiptDate) < 2 || isSomePreviousPassportDataFilled;
	if (isPreviousPassportDataRequired && !values.previousPassportSeria) {
		errors.previousPassportSeria = "Серия паспорта должна состоять из 4 цифр";
	} else if (values.previousPassportSeria && values.previousPassportSeria?.replace(" ", "")?.length < 4) {
		errors.previousPassportSeria = "Серия паспорта должна состоять из 4 цифр";
	}
	if (isPreviousPassportDataRequired && !values.previousPassportNumber) {
		errors.previousPassportNumber = "Номер паспорта должен состоять из 6 цифр";
	} else if (values.previousPassportNumber && values.previousPassportNumber.length < 6) {
		errors.previousPassportNumber = "Номер паспорта должен состоять из 6 цифр";
	}
	if (isPreviousPassportDataRequired && !values.previousPassportReceiptDate) {
		errors.previousPassportReceiptDate = "Дата должна быть в формате ДД.ММ.ГГГГ. Например, 25.06.1995";
	} else if (values.receiptDate && getYearsByDate(values.previousPassportReceiptDate) < 0) {
		errors.previousPassportReceiptDate = "Некорректное значение";
	} else if (values.previousPassportReceiptDate && values.birthDate && (values.previousPassportReceiptDate.getTime() - values?.birthDate.getTime()) < (441763200 * 1000)) {
		errors.previousPassportReceiptDate = "Некорректное значение"; // 14 лет
	}
	if (!values.issuedBy) {
		errors.issuedBy = "Выберите вариант из списка";
	}
	if (!values.currentPassportDepartmentCode) {
		errors.currentPassportDepartmentCode = "Выберите вариант из списка";
	}
	if (isPreviousPassportDataRequired && !values.previousPassportIssuedBy) {
		errors.previousPassportIssuedBy = "Выберите вариант из списка";
	} 
	if (isPreviousPassportDataRequired && !values.previousPassportDepartmentCode) {
		errors.previousPassportDepartmentCode = "Выберите вариант из списка";
	} 
	if (!values.birthPlace?.title) {
		errors.birthPlace = "Укажите место рождения";
	} 
	if (values.registrationAddress?.title && REG_EXPS.isNoLatinic(values.registrationAddress?.title.replace(/\s+/g, ""))) {
		errors.registrationAddress = "Допускается для ввода только кириллица";
	} else if (!values.registrationAddress?.title) {
		errors.registrationAddress = "Выберите вариант из списка";
	} else if (values.registrationAddress && !values.registrationAddress.house) {
		errors.registrationAddress = "Выберите вариант из списка включая дом";
	}
	if (values.actualAddress?.title && REG_EXPS.isNoLatinic(values.actualAddress?.title.replace(/\s+/g, ""))) {
		errors.actualAddress = "Допускается для ввода только Кириллица";
	} else if (!values.actualAddress?.title) {
		errors.actualAddress = "Выберите вариант из списка";
	} else if (values.actualAddress && !values.actualAddress.house) {
		errors.actualAddress = "Выберите вариант из списка включая дом";
	}
	if (!values.gender || values.gender === "U") {
		errors.gender = "Укажите пол";
	}
	if (isCredit && !values.purpose) {
		errors.purpose = "Выберите вариант из списка";
	}
	return errors;
};
