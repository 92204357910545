import styled from 'styled-components';

const StyledConfiguratorNotificatior = styled.div<IConfiguratorNotificatior>`
	background: ${({ theme }) => theme.appColors.commonTheme || "#FFFFFF"};
	margin: 50px 0 0 0;
	color: #FFFFFF;
	font-family: Formular;
	display: flex;
	justify-content: center;
	padding: 30px;
	border-radius: 4px;
`;

interface IConfiguratorNotificatior {}

export const ConfiguratorNotificatior: React.FC<IConfiguratorNotificatior> = (
	props
) => {
	return (
		<StyledConfiguratorNotificatior>
			Выберите подходящие условия и получите кредит прямо сейчас без
			предоставления дополнительных документов
		</StyledConfiguratorNotificatior>
	);
};