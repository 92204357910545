import React from "react";
import {IThemeNames, THEME_NAMES} from "../../../../styles/themes/themes";
import {PromoCardDefault} from "./PromoCardDefault";
import {PromoCardNewYear} from "./PromoCardNewYear/PromoCardNewYear";


type PromoProps = {
  isReferral: boolean
  type?: IThemeNames
}


export const PromoCard = ({ isReferral, type = THEME_NAMES.default }: PromoProps) => {

  if(type === THEME_NAMES.newYear && !isReferral) return <PromoCardNewYear/>
  return <PromoCardDefault isReferral={isReferral}/>
}