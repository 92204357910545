import { FormikValues } from 'formik';
import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { adaptiveParams } from '../../../styles/adaptiveParams';
import { Button } from '../../../styles/UIKit/Button';
import { CustomerCalendar } from '../../../styles/UIKit/DatePicker/CustomerCalendar/CustomerCalendar';
import { FejoaSelect } from '../../../styles/UIKit/FejoaSelect/FejoaSelect';
import { Flex } from '../../../styles/UIKit/Flex';
import { StyledInfoNotificator } from '../../../styles/UIKit/InfoNotificator';
import { CloseFeature, Modal } from '../../../styles/UIKit/Modal/Modal';
import { Title } from '../../../styles/UIKit/Title';
import { dateIntoString } from '../../../utils/commonFunctions';

interface AgreeModal {
	screenWidth: number;
	isAgree: boolean;
	setIsAgree: (isAgree: boolean) => void;
	formik: FormikValues;
	config: any;
}
export const AgreeModal: React.FC<AgreeModal> = (props) => {
	const { isAgree, setIsAgree, screenWidth, formik, config } = props;
	const { minimalWrap } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > minimalWrap;
	const [arriveDate, setArriveDate] = React.useState<string>(
		formik.values.arrivalDate
			? dateIntoString(formik.values.arrivalDate as Date)
			: ""
	);
	return (
		<Modal show={isAgree}>
			<CloseFeature show={isAgree}>
				<IoMdClose
					size={36}
					onClick={() => {
						setIsAgree(false);
					}}
				/>
			</CloseFeature>
			<Flex>
				<Title
					fSize={fieldsWrapConditions ? "28px" : "20px"}
					margin={"0"}
				>
					Иван Сергеевич, спасибо за выбор банка!
				</Title>
			</Flex>
			<StyledInfoNotificator
				width={"100%"}
				// height={"100px"}
				background={"#F0F2F6"}
				color={"#0A1244"}
				fSize={"16px"}
				padding={"30px"}
				margin={"10px 0"}
			>
				Параметры основного кредита, дополнительные услуги
			</StyledInfoNotificator>
			<FejoaSelect
				height={"71px"}
				width={"100%"}
				label={"Способ получения кредита"}
				formik={formik}
				updateFormCallBack={() => {}}
				formItem={"wayToGetMoney"}
				borderColor={"blue"}
				options={config.wayToGetMoneyOptions}
			/>
			<FejoaSelect
				height={"71px"}
				width={"100%"}
				label={"Выберите офис"}
				formik={formik}
				updateFormCallBack={() => {}}
				formItem={"officeAddress"}
				borderColor={"blue"}
				options={config.officeAddressList}
			/>
			<CustomerCalendar
				updateFormHandler={() => {}}
				width={"100%"}
				label={"Выберите время"}
				formik={formik}
				formItem={"arrivalDate"}
				value={formik.values.arrivalDate}
				minDate={new Date()}
				dateValue={arriveDate}
				fSize={"28px"}
				height={"71px"}
				setDateValue={setArriveDate}
				error={
					formik.touched.officeAddress &&
					!!formik.errors.officeAddress
				}
			/>
			<Flex margin="140px 0 0 0">
				<Button width="100%" mode={"primary"}>
					Согласен
				</Button>
			</Flex>
		</Modal>
	);
};