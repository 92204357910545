import styled from 'styled-components';
import { adaptiveParams } from '../adaptiveParams';

type TStyledInfoNotificator = {
	background?: string;
	width?: string;
	height?: string;
	color?: string;
	fSize?: string;
	padding?: string;
	margin?: string;
};
export const StyledInfoNotificator = styled.div<TStyledInfoNotificator>`
	width: ${({ width }) => width || "auto"};
	position: relative;
	height: ${({ height }) => height || "auto"};
	background: ${({ background }) => background || "transparent"};
	color: ${({ color }) => color || "transparent"};
	font-size: ${({ fSize }) => fSize || "16px"};
	padding: ${({ padding }) => padding || "0"};
	margin: ${({ margin }) => margin || "0"};
	display: flex;
	fustify-content: center;
	align-items: center;
	@media (max-width: ${adaptiveParams.standartFormWidthFirstValue}px) {
		// max-width: ${adaptiveParams.mainContentWidth}px;
		// margin: 0;
	}
	@media (max-width: ${adaptiveParams.mainContentWidth}px) {
		flex-direction: column;
	}
`;