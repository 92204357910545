import React from 'react';
import styled from 'styled-components';

type Props = {
    width?: string
    background?: string
}

const StyledDevider = styled.div<Props>`
    color: grey;
    height: 1px;
    margin: 0 auto;
    width: ${props => props.width || "100%"};
    background: ${props => props.background || props.theme.appColors.greyText};
`

export const Devider: React.FC<Props> = (props) => {
    return <StyledDevider {...props} />
}