import React from "react";
import creditCardReferralIMG from "../../../assets/card_1000₽.webp";
import {goToTheQuestionnaire} from "../../../utils/goToTheQuestionnaire";
import "./PromoCreditReferral.css";

interface PromoCreditReferralProps {
  isMobile?: boolean;
}

export const PromoCreditReferral = ({ isMobile }: PromoCreditReferralProps) => {

  return (
    <section className="promo-credit-referral">
      <div className="container">
        <div className="promo-credit-referral-inner">
          <div className="promo-credit-referral__info-wrapper">
            <h2 className="promo-credit-referral__title">Друг рекомендует вам кредит Банка ЗЕНИТ</h2>
            <h1 className="promo-credit-referral__text">Оформите заявку на этой странице и получите 1 000 ₽!</h1>
            <a href="#anketa" onClick={goToTheQuestionnaire} className="promo-credit-referral__link">
              {isMobile ? "Оформить" : "Оформить кредит"}
            </a>
          </div>
          <div className="promo-credit-referral__img-wrapper">
            <img className="promo-credit-referral__img" src={creditCardReferralIMG} alt=""/>
          </div>
        </div>
      </div>
    </section>
  )
}