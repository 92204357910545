import styled from 'styled-components';
import {adaptiveParams} from '../../../styles/adaptiveParams';
import {Flex} from '../../../styles/UIKit/Flex';
import brightLogoSvg from '../../../assets/logo.svg';
import darkLogoSvg from '../../../assets/logoDark.svg';
import {useDispatch, useSelector} from 'react-redux';
import {setCurrentForm} from '../../../redux/Application/appActions';
import {ROUTE_PATH} from '../../../routesConfig';
import useDimensions from '../../../hooks/useDimensions';
import {Products} from '../../Products/Products';
import {Button} from '../../../styles/UIKit/Button';
import {CardBanner} from '../../Cards/CardBanner/CardBanner';
import {Burger} from '../../../styles/UIKit/Burger/Burger';
import React from 'react';
import {BurgerNavButtons} from './BurgerNavButtons';
import {HREF_START_PAGE} from '../../../config';
import {setGeneratedSmsData} from '../../../redux/Auth/authActions';
import {CreditRateRange} from "./CreditRateRange";
import {defaultTheme, IThemes} from "../../../styles/themes/themes";
import {Promo} from "../../PromoCredit/Promo";
import {AppRootStateType} from "../../../redux/store";
import {ProductOptions} from "../../ProductOptions/ProductOptions";
import {Slider} from "../../Slider/Slider";

const UpperHeader = styled.div`
  background: #127F8C; 
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 140px;
  min-height: 80px;
  align-items: center;

  @media (max-width: 1000px) {
    padding: 0 40px;
  }

  @media (max-width: ${adaptiveParams.mainContentWidth}px) {
    padding: 0 20px;
  }
`;

const PhoneLink = styled.a`
  font-family: Montserrat;
  color: ${defaultTheme.mainColor1};
  font-size: 13.73px;
  font-weight: 700;
  text-decoration-line: none;
`;

const BugerContentWpapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Href = styled.a<any>`
  margin: 5px 0;
  font-size: 12px;
  font-family: "Formular";
  text-decoration: none;
  color: ${({theme}) => theme.FEI_BLUE_DARK};
  opacity: 1;
  &:hover {
    opacity: 0.3;
    transition: 1s opacity;
  }
`;

export const Banner = (props: any = {}) => {
  const dispatch = useDispatch()

  const getOrderListHandler = () => {
    dispatch(setGeneratedSmsData(undefined));
    dispatch(setCurrentForm({product: undefined, formId: ROUTE_PATH.ORDERS, flowId: undefined}));
  };

  const theme = useSelector<AppRootStateType, IThemes>(state => state.appReducer.theme)

  const {screenWidth} = useDimensions();
  const fieldsWrapConditions: boolean = screenWidth > adaptiveParams.mainContentWidth;
  const isMobile = screenWidth <= 767;

  const isCard = props.product === 'cards';
  const isCpza = props.product === 'cpza';
  const isCredits = props.product === 'credits';
  const isReferral = props.isReferral;


  const [isOpen, setIsOpen] = React.useState(false);

  return <>
    <UpperHeader>
      {!fieldsWrapConditions && (
        <Burger
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          burgerColor={defaultTheme.FEI_BLUE_DARK}
          burgerBackgound={defaultTheme.mainColor1}>
          <BurgerNavButtons isOpen={isOpen} setIsOpen={setIsOpen} currentProduct={props.product}/>
            <BugerContentWpapper>
              <Flex justify='center' width='100%' margin='50px 0 0 0'>
                <Button
                  color={defaultTheme.FEI_BLUE_DARK}
                  mode="outlined"
                  FSize="14px"
                  fontWeight="400"
                  fontFamily="Formular"
                  maxHeight="38"
                  padding="10px 20px"
                  onClick={getOrderListHandler}
                >
                  Личный кабинет
                </Button>
              </Flex>
              <Flex direction='column' align='center'>
                  {HREF_START_PAGE.map(el => {
                    const onHandleClick = () => {
                      setIsOpen(false)
                    }
                    return <Href key={el.id} href={el.href} onClick={onHandleClick}>{el.title}</Href>
                  })}
                </Flex>
              <Flex direction='column' padding='20px' align='center'>
                <img src={darkLogoSvg} alt="Банк Зенит" />
              </Flex>
            </BugerContentWpapper>
        </Burger>
      )}
      <Flex flexGrow={props.hideProducts ? '1' : undefined}>
        <img src={brightLogoSvg} alt="logo"/>
        {fieldsWrapConditions && !props.hideProducts && (
          <Flex margin="0 0 0 50px">
            <Products  currentProduct={props?.product}/>
          </Flex>
        )}
      </Flex>
      <Flex align="center">
        {fieldsWrapConditions && (
          <Flex>
            <Button
              color="#FFFFFF"
              mode="outlined"
              margin="0 30px 0 0"
              FSize="14px"
              fontWeight="400"
              fontFamily="Formular"
              maxHeight="38"
              padding="10px 20px"
              onClick={getOrderListHandler}
            >
              Личный кабинет
            </Button>
          </Flex>
        )}
      </Flex>
      { props.showPhone && (
        <PhoneLink href="tel:8800551-43-87">8 800 551-43-87</PhoneLink>
      )}
      {props.showCreditRateRange && <CreditRateRange message="Полная стоимость кредита - " min="16,872%" max="35,225%"/>}
    </UpperHeader>
    {!props.hideLowerHeader && !isCard && <Promo product={props.product} type={theme.name} isReferral={isReferral} isMobile={isMobile}>
      <>
        {(isCredits || (!isMobile && isCpza)) && (
          <ProductOptions
            product={props.product}
            maxCreditAmount={props?.maxCreditAmount}
            maxCreditTerm={props?.maxCreditTerm}
            rate={props?.rate}
          />
        )}
        {(isMobile && isCpza) && (
          <Slider slides={[
            <ProductOptions
              product={props.product}
              maxCreditAmount={props?.maxCreditAmount}
              maxCreditTerm={props?.maxCreditTerm}
              rate={props?.rate}
              numberOfDisplayed={3}
            />,
            <ProductOptions
              product={props.product}
              maxCreditAmount={props?.maxCreditAmount}
              maxCreditTerm={props?.maxCreditTerm}
              rate={props?.rate}
              startPosition={3}
              numberOfDisplayed={3}
            />
          ]}/>
        )}
      </>
    </Promo>}
    {isCard && !props.hideCardBanner && <CardBanner product="cards" />}
    {isCredits && isReferral && !props.hideCardBanner && <CardBanner isShowPromo={false} product="credits" />}
  </>;
};
