import useDimensions from "../../../hooks/useDimensions";
import HouseAndStarsImg from '../../../assets/house-and-stars.webp';
import HouseAndStarsMobileImg from '../../../assets/house-and-stars-mobile.webp';
import './AdOfNeedMoreAmount.css'

export const MiniAdOfNeedMoreAmount = () => {
  const {screenWidth} = useDimensions()
  const isMobile = screenWidth < 520;

  return (
    <div className={"mini-ad-need-more-amount"}>
      <div className={"container"}>
        <div className={"mini-ad-need-more-amount__inner"}>
          <h3 className={"mini-ad-need-more-amount__title"}>
            Нужна сумма больше?
          </h3>
          <span className={"mini-ad-need-more-amount__text"}>
          Оформите кредит на сумму до 40 млн ₽ !
        </span>
          <a
            className={"mini-ad-need-more-amount__btn"}
            href="https://www.zenit.ru/personal/credits/c-credit-mortgage/"
            target="_blank"
            rel="noreferrer"
          >
            Оформить
          </a>
          <img
            className={"mini-ad-need-more-amount__img"}
            src={isMobile ? HouseAndStarsMobileImg : HouseAndStarsImg}
            alt="Пластмассовый домик в праздничных звездах"
          />
        </div>
      </div>
    </div>
  )
}