import {MiniAdOfNeedMoreAmount} from "./AdOfNeedMoreAmount/MiniAdOfNeedMoreAmount";

export enum MINI_ADS_NAMES {
  needMoreAmount = "need-more-amount"
}

export interface MiniAdsProps {
  type?: MINI_ADS_NAMES
}

export const MiniAds = ({type}: MiniAdsProps) => type === MINI_ADS_NAMES.needMoreAmount
  ? <MiniAdOfNeedMoreAmount />
  : null