import {FormikValues} from 'formik';
import React from 'react';
import {ICompanyDadataList, IDadataAddressTypes} from '../../../../redux/Application/appTypes';
import {
  IAdditionalIncomes,
  IAdvancedOptions,
  IOpfListRef,
  IProfileConfig2,
  IProfileState2,
} from '../../../../redux/Credit/creditTypes';
import {adaptiveParams} from '../../../../styles/adaptiveParams';
import {Button} from '../../../../styles/UIKit/Button';
import {CustomProgressBar} from '../../../../styles/UIKit/CustomProgressBar';
import {Flex} from '../../../../styles/UIKit/Flex';
import {String} from '../../../../styles/UIKit/String';
import {Title} from '../../../../styles/UIKit/Title';
import {ParagraphWrapper} from '../../../CommonPagesStyles';
import {TProfileSubPages} from '../ProfileMainBlock';
import {AddAdditionalIncomes} from './AddAdditionalIncomes';
import {MainContent} from './occupationTypeComponents/MainContent';
import {CloseFeature, Modal} from '../../../../styles/UIKit/Modal/Modal';
import {IoMdClose} from 'react-icons/io';
import {onSetFieldsTouched} from '../../../../utils/commonFunctions';
import {onCheckPartOfFields, workActivityFieldsList} from '../../helper/helper';
import {onSetFieldBorderColor} from '../../../../utils/onSetFieldBorderColor';
import {FejoaMappedSelect} from '../../../../styles/UIKit/FejoaSelect/FejoaMappedSelect';
import {setPhoneValidationErrorMessage} from '../../../../redux/Application/appActions';
import {useDispatch} from 'react-redux';
import {pushDataLayerEvent} from "../../../../utils/dataLayer";
import {STAGES, usePixel} from "../../../../hooks/usePixel";
import {useSearchParams} from "react-router-dom";

interface IWorkActivityFixd {
  formik: FormikValues;
  standartFontSizeMeasures: string;
  standartHeightMeasures: string;
  standartWidthMeasures: string;
  screenWidth: number;
  profileConfig: IProfileConfig2;
  setSubPage: (subPage: TProfileSubPages) => void;
  updateFormCallBack: (
    formItem: string,
    currentValue: string,
    event: FormikValues
  ) => void;
  workPlaceLegalAddressList: IDadataAddressTypes[];
  workPlaceAddressList: IDadataAddressTypes[];
  setFormDataUpdates: (values: IProfileState2) => void;
  additionalIncomesData: IAdditionalIncomes[];
  setAdditionalIncomesData: (
    additionalIncomesData: IAdditionalIncomes[]
  ) => void;
  additionalIncomesRef: IAdvancedOptions[];
  otherIncomesRef: IAdvancedOptions[];
  companyNamesDadataList: ICompanyDadataList[];
  companyInnDadataList: ICompanyDadataList[];
  occupationCompanyOpfRef: IOpfListRef[];
  phoneValidationErrorMessage: string | null;
  product: string;
}

export const WorkActivityFixd: React.FC<IWorkActivityFixd> = (props) => {
  const dispatch = useDispatch();
  const {
    formik,
    standartFontSizeMeasures,
    standartHeightMeasures,
    standartWidthMeasures,
    screenWidth,
    profileConfig,
    setSubPage,
    updateFormCallBack,
    workPlaceLegalAddressList,
    workPlaceAddressList,
    setFormDataUpdates,
    additionalIncomesData,
    setAdditionalIncomesData,
    additionalIncomesRef,
    otherIncomesRef,
    companyNamesDadataList,
    companyInnDadataList,
    occupationCompanyOpfRef,
    phoneValidationErrorMessage,
    product
  } = props;

  const [forceRender, setForceRender] = React.useState<number>(0);
  const {mainContentWidth} = adaptiveParams;
  const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;

  const [confirmIncomesModal, setConfirmIncomesModal] = React.useState<boolean>(false)

  const onModalNotificatorHandler = async () => {
    pushDataLayerEvent(`${product}_form3_button_click`);
    // window.scrollTo(0, 0);
    // @ts-ignore
    if (window.occupationPhoneNumberHandler && (await window.occupationPhoneNumberHandler()).isAllowed === false) {
      return;
    }
    const touchedFields = onSetFieldsTouched(workActivityFieldsList)
    formik.setTouched(touchedFields);

    if (onCheckPartOfFields(formik, workActivityFieldsList).length < 1) {
      switch (true) {
        case formik.values.isGettingSalaryOnCard:
          window.scroll(0, 0);
          return setSubPage("additionalData");
        case !formik.values.isGettingSalaryOnCard && formik.values.isReadyConfirmIncomes:
          window.scroll(0, 0);
          return setSubPage("additionalData");
        case !formik.values.isGettingSalaryOnCard && !formik.values.isReadyConfirmIncomes:
          return setConfirmIncomesModal(true)
        default:
          return
      }
    }
  };

  const onNextPageHandler = () => {
    window.scroll(0, 0);
    setSubPage("additionalData")
    setConfirmIncomesModal(false)
  }
  React.useEffect(() => {
    if (!formik.values.occupationType) {
      formik.setFieldValue(
        "occupationType",
        profileConfig.occupationTypeRef[0].code
      );
    }
  }, [formik.values.occupationType]);

  const onSelectOccupationType = () => {
    // @ts-ignore
    window.occupationPhoneNumberHandler = null;
    // formik.resetForm()
    dispatch(setPhoneValidationErrorMessage(null));
    workActivityFieldsList.forEach(field => {
      formik.setFieldValue([field], null)
      formik.setTouched({[field]: false})
    });
    setForceRender(prev => prev + 1);
  }

  const isNextDisabled = !!phoneValidationErrorMessage;

  React.useEffect(() => {
    const touchedFields = onSetFieldsTouched(workActivityFieldsList)
    formik.setTouched(touchedFields);
  }, []);

  return (<>
    <Flex direction="column" padding={screenWidth > 1190 ? '0' : '0 10px'} margin={"0 0 20px 0"}>
      <Title margin="0 0 10px 0" fSize={fieldsWrapConditions ? "28px" : "20px"} fontName="Formular" fontWeight="900">
        Трудовая деятельность
      </Title>
      <Flex margin='20px 0'>
        <String fSize="14px">Шаг 3 из 4. </String>
        <String fSize="14px" color="black">
          &nbsp;Получите +20% к одобрению, заполнив третий шаг
        </String>
      </Flex>
      <CustomProgressBar
        completed={"90%"}
        width={"100%"}
        margin={"0"}
      />
    </Flex>
    <ParagraphWrapper padding={fieldsWrapConditions ? "0" : "10px"} margin="0 0 10px 0">
      <FejoaMappedSelect
        additionalActions={onSelectOccupationType}
        updateFormCallBack={updateFormCallBack}
        height={standartHeightMeasures}
        width={"100%"}
        fSize={standartFontSizeMeasures}
        label={"Тип деятельности"}
        formik={formik}
        formItem={"occupationType"}
        borderColor={onSetFieldBorderColor(formik, "occupationType")}
        options={profileConfig.occupationTypeRef}
      />
    </ParagraphWrapper>

    <div key={forceRender}>
      <MainContent
        occupationCompanyOpfRef={occupationCompanyOpfRef}
        otherIncomesRef={otherIncomesRef}
        formik={formik}
        fieldsWrapConditions={fieldsWrapConditions}
        standartWidthMeasures={standartWidthMeasures}
        standartHeightMeasures={standartHeightMeasures}
        standartFontSizeMeasures={standartFontSizeMeasures}
        updateFormCallBack={updateFormCallBack}
        workPlaceLegalAddressList={workPlaceLegalAddressList}
        workPlaceAddressList={workPlaceAddressList}
        setFormDataUpdates={setFormDataUpdates}
        profileConfig={profileConfig}
        companyNamesDadataList={companyNamesDadataList}
        companyInnDadataList={companyInnDadataList}
        screenWidth={screenWidth}
        esiaUsage={profileConfig?.esiaUsage}
        phoneValidationErrorMessage={phoneValidationErrorMessage}
      />
      <AddAdditionalIncomes
        standartFontSizeMeasures={standartFontSizeMeasures}
        standartHeightMeasures={standartHeightMeasures}
        standartWidthMeasures={standartWidthMeasures}
        formik={formik}
        additionalIncomesData={additionalIncomesData}
        screenWidth={screenWidth}
        setAdditionalIncomesData={setAdditionalIncomesData}
        additionalIncomesRef={additionalIncomesRef}
        setFormDataUpdates={setFormDataUpdates}
      />
    </div>
    <Modal show={confirmIncomesModal}>
      <CloseFeature show={confirmIncomesModal}>
        <IoMdClose
          size={30}
          onClick={() => setConfirmIncomesModal(false)}
        />
      </CloseFeature>
      <Title fSize={standartFontSizeMeasures}>Рекомендуем подтвердить доход</Title>
      <String>Без подтвержденного дохода сумма кредита будет меньше, а ставка выше</String>
      <Flex margin={"20px 0"} direction={"column"}>
        <Button type="button" mode="primary" onClick={() => setConfirmIncomesModal(false)}
                minWidth={'100px'}>Понятно</Button>
        <Button type="button" mode="outlined" onClick={onNextPageHandler} margin={"10px 0 0 0"} minWidth={'100px'}>Продолжить
          без подтверждения дохода</Button>
      </Flex>
    </Modal>
    <Flex justify={"flex-start"} padding={fieldsWrapConditions ? "0" : "10px"} margin="50px 0 0 0">
      <Button
        mode="primary"
        padding="18px 41px"
        onClick={onModalNotificatorHandler}
        type={"button"}
        disabled={isNextDisabled}
      >
        Далее
      </Button>
    </Flex>
  </>);
};
