import { FormikValues } from "formik";
import React from "react";
import styled, { keyframes } from "styled-components";
import useDimensions from "../../../hooks/useDimensions";
import { IDadataTypes } from "../../../redux/Application/appTypes";
import { adaptiveParams } from "../../adaptiveParams";
import { FieldStyles, StyledFieldsProps } from "../CustomField/CustomFieldStyles";
import { Flex } from "../Flex";
import { String } from "../String";
import { StyledErrorNotificator } from "../StyledErrorNotificator";
import { HintedFieldWrapper } from "./HintedFieldWrapper";
import { calcErrorOffset } from '../../../utils/calcErrorOffset';

interface ISuperPassport extends StyledFieldsProps {
  formik: FormikValues;
	dadataList: IDadataTypes[];
	errorValue?: string;
	codeProp: string;
	issuedProp: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur: () => void;
	onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
	onChoise: (option: IDadataTypes) => void
}

export const SuperPassport: React.FC<ISuperPassport> = (props) => {

  const {
    formik,
    dadataList,
    errorValue,
		codeProp,
		issuedProp,
		onChange,
		onBlur,
		onFocus,
		onChoise,
  } = props

  const onSetValue = () => {
    switch(true) {
      case !!formik.values[codeProp] && !!formik.values[issuedProp]: return `${formik.values[codeProp]}, ${formik.values[issuedProp]}`
      case !!formik.values[codeProp] && !formik.values[issuedProp]: return formik.values[codeProp]
      default: return ""
    }
  }

  const {screenWidth} = useDimensions()

	const { mainContentWidth } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;
	const Top = calcErrorOffset(screenWidth, fieldsWrapConditions);

  return <Flex direction="column" Position="relative" padding={fieldsWrapConditions ? "0" : "10px 0"}>
			    <String fSize={"14px"} margin="0 0 8px">Укажите код подразделения и место выдачи</String>
          <HintedFieldWrapper>
          <FieldStyles
						{...props}
						type={"text"}
						name={issuedProp}
						value={onSetValue()}
						onChange={onChange}
						onBlur={onBlur}
						onFocus={onFocus}
					/>
          {dadataList.length > 0 && (
					<OptionsWindow>
						{/* <Flex padding="0 10px">
							<String fSize="8px" margin="0 20px 0 0">Код подразделения</String>
							<String fSize="8px">Место выдачи</String>
						</Flex> */}
						{dadataList?.map((option, index) => {
							const choseOptionHandler = () => onChoise(option);
							return (
								<EachOption
									key={index}
									onMouseDown={choseOptionHandler}
								>
                  <Flex align="center">
										<CodeWrapper>
                    	<String color="#474747" whiteSpace="nowrap">{option.code}</String>
										</CodeWrapper>
										<Flex padding="0" margin="0">
                  		<String color="#6e6d6d">{option.title}</String>
										</Flex>
                  </Flex>
								</EachOption>
							);
						})}
					</OptionsWindow>
				)}
        </HintedFieldWrapper>
        {errorValue && dadataList?.length < 1 && <StyledErrorNotificator Top={Top}>{errorValue}</StyledErrorNotificator>}
        </Flex>
}

const CodeWrapper = styled.div`
	margin-right: 10px;
	padding: 0 5px;
`;

const EachOption = styled.div`
  width: 100%;
	padding: 5px 10px;
	font-size: 20px;
	cursor: pointer;
	transition: background-color 0.1s;
	font-family: Formular;
	&:hover {
		background: #fafafa;
	}

`
const optionsWindowAnimation = keyframes`
    0% {opacity: 0;}
    100% {opacity: 1;}
`;
const OptionsWindow = styled.div`
  border-radius: 4px;
	position: absolute;
	width: 100%;
	margin-top: 3px;
	background: #ffffff;
	/* overflow-y: scroll; */
	max-height: "400px";
	opacity: 0;
	animation: ${optionsWindowAnimation} 0.4s forwards;
	box-shadow: 0px 0px 5px black;
	z-index: 2;

`
