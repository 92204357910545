import {CloseFeature} from "./Modal";
import {IoMdClose} from "react-icons/io";
import {Title} from "../Title";
import {Flex} from "../Flex";
import {String} from "../String";
import {InputCode} from "../../../components/InputCode/InputCode";
import {Button} from "../Button";
import {AntifrodTimer} from "./components/AntifrodTimer";
import React, {FC, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppRootStateType} from "../../../redux/store";
import {setAntifrodTimer, setGeneratedSmsData, setValidateSmsData} from "../../../redux/Auth/authActions";
import {FormikValues} from "formik";

export interface SMSFormProps {
  show: boolean;
  setShow: (show: boolean) => void;
  setSmsCodeValue: (smsCodeValue: string) => void;
  smsCodeValue: string;
  formik: FormikValues;
  formItem: string;
  disabled?: boolean;
  repeatCode: () => void;
  smsAuthError: null | string | undefined;
  screenWidth: number;
  attempts: number | null | undefined;
  antifrodLife: number | undefined;
  phoneNumber: string | null;
  downloadDocPersonalDataConditions: () => void;
  downloadDocPersonalDataConfirmations: () => void;
  isBackendInvalidations: boolean;
}

export const SmsForm: FC<SMSFormProps> = (props) => {

  const {
    screenWidth,
    show,
    setShow,
    smsCodeValue,
    setSmsCodeValue,
    formik,
    formItem,
    disabled,
    repeatCode,
    smsAuthError,
    attempts,
    antifrodLife,
    phoneNumber,
    downloadDocPersonalDataConditions,
    downloadDocPersonalDataConfirmations,
    isBackendInvalidations,
  } = props;
  const [ render, setRender ] = React.useState(0);
  const [ failed, setFailed ] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isBackendInvalidations) {
      onReturned()
    }
  }, [isBackendInvalidations])

  const onReturned = () => {
    dispatch(setGeneratedSmsData(undefined));
    dispatch(setValidateSmsData(undefined));
    setSmsCodeValue("");
    setShow(false);
  }

  const [isFinish, setIsFinish] = React.useState<boolean>(true);
  const error = useSelector<AppRootStateType, string | undefined | null>(state => state.authReducer.validate?.error)

  const getFinishCallback = () => {
    setIsFinish(false);
    // dispatch(setGeneratedSmsData(undefined));
    dispatch(setAntifrodTimer(undefined));
    dispatch(setValidateSmsData(undefined));
  };

  useEffect(() => {
    if (attempts === 0) {
      setFailed(true);
    }
  }, [attempts]);

  const onGeneratelHandler = (first: boolean) => () => {
    const forceRender = (prev: number) => prev + 1;
    setFailed(false);
    repeatCode();
    setIsFinish(true);
    if (!first) setRender(forceRender);
  };

  const codeLength = 6;

  useEffect(() => {
    if (smsCodeValue?.length < codeLength || failed || disabled) {
      return;
    }
    formik.handleSubmit();
  }, [smsCodeValue]);

  return <>
    <CloseFeature show={show}>
      <IoMdClose
        size={24}
        onClick={onReturned}
      />
    </CloseFeature>
    <Title fSize="28px" margin="0 0 6px">Подтвердите оформление заявки</Title>
    <Flex margin="20px 0">
      <String>
        Код подтверждения отправлен на номер смс
        <br/>
        <String fontWeight color="black">{`${phoneNumber}`}</String>
      </String>
    </Flex>

    <Flex direction="column" margin="10px 0">
      <InputCode key={`${+show}${render}`}
                 onComplete={setSmsCodeValue} length={codeLength}
      />
    </Flex>
    {error && (
      <Flex justify="center" height="40px">
        <String color="red">{error}</String>
      </Flex>
    )}
    <Flex
      direction="column"
      justify="space-between"
    >
      <Button
        mode="primary"
        margin="10px 0"
        color={"#0A1244"}
        background="#F0F2F6"
        type="button"
        onClick={onGeneratelHandler(false)}
        disabled={isFinish}
      >
        Получить код еще раз{" "}
        {antifrodLife && (
          <AntifrodTimer
            antifrodLife={antifrodLife}
            getFinishCallback={getFinishCallback}
          />
        )}
      </Button>
    </Flex>
    <Flex>
      <String fSize={"14px"}>Соглашаюсь с {' '}
        <a
          href="https://lkz.zenit.ru/files/handlingConditions.pdf"
          target="_blank"
          style={{
            color: "#10C8D2",
            textDecoration: "none",
            cursor: "pointer"
          }} rel="noreferrer"
        >
          условиями предоставления услуги
        </a>
      </String>
    </Flex>
    <Flex justify="flex-start" margin="10px 0 0">
      <Button
        mode="primary"
        background="#F0F2F6"
        color="#0A1244"
        padding="18px 41px"
        onClick={onReturned}
        type={"button"}
      >
        Вернуться назад
      </Button>
    </Flex>
  </>
}
