import React from 'react';
import styled, { keyframes } from 'styled-components';
import { IoMdArrowDropdown } from 'react-icons/io';
import { FormikValues } from 'formik';
import { String } from '../String';
import { StyledErrorNotificator } from '../StyledErrorNotificator';
import useDimensions from '../../../hooks/useDimensions';
import { adaptiveParams } from '../../adaptiveParams';

// Types for FejoaSelect
interface IFejoaSelect {
	updateFormCallBack?: (
		formItem: string,
		currentValue: string,
		event: FormikValues
	) => void;
	width: string;
	height: string;
	fSize?: string;
	borderColor?: string;
	options: string[];
	advancedOptions?: any[];
	primaryKey?: string;
	formik: FormikValues;
	formItem: string;
	defaultValue?: string;
	label?: string;
	subLabel?: string;
	invalidMode?: boolean;
	errorValue?: string;
	additionalActions?: () => void;
}

// Each chosen option
const EachOption = styled.div`
	width: 100%;
	padding: 5px 10px;
	font-size: 20px;
	cursor: pointer;
	color: #000;
	transition: background-color 0.1s;
	font-family: Formular;
	&:hover {
		background: #00cad6;
		color: white;
	}
`;

// select options window animation
const optionsWindowAnimation = keyframes`
    0% {opacity: 0;}
    100% {opacity: 1;}
`;

// modal with options to select
const OptionsWindow = styled.ul<{ maxHeight: string }>`
	// border: 1px solid red;
	border-radius: 4px;
	position: absolute;
	top: 100%;
	width: 100%;
	// margin-top: 3px;
	background: #ffffff;
	overflow-y: scroll;
	max-height: ${({ maxHeight }) => maxHeight};
	opacity: 0;
	animation: ${optionsWindowAnimation} 0.4s forwards;
	box-shadow: 0px 0px 5px black;
	z-index: 2;
`;

// The triangle arrow to open select
const SelectTrigger = styled(IoMdArrowDropdown)<any>`
	position: absolute;
	pointer-events: none;
	right: -5px;
	top: 50%;
	transform: translate(-50%, -50%);
	// bottom: 0;
	// margin: auto 0;
	color: #0a1244;
`;

// wrapper for select trigger
const CustomSelectWrapper = styled.div`
	position: relative;
`;

// select component
const CustomSelect = styled.input<IFejoaSelect>`
	// position: relative;
	height: ${({ height }) => height};
	font-size: ${({ fSize }) => fSize || "28px"};
	border-radius: 4px;
  border: 2px solid ${({borderColor}) => borderColor || "transparent" };
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 50px 0 20px;
	outline: none;
	width: 100%;
	color: transparent;
	cursor: pointer;
	text-shadow: 0 0 0 #0a1244;
	font-family: Formular,serif;
  background: #F2F4F7;
  // &:focus {
  //   border-color: #00CAD6;
  // }
`;

// selets container
const StyledFejoaSelectWrapper = styled.div<IFejoaSelect>`
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 10px 0;
	// width: 365px;
	width: ${({ width }) => width};
`;
const Select: React.FC<IFejoaSelect> = (props) => {
	const {
		updateFormCallBack,
		options,
		formik,
		formItem,
		defaultValue,
		label,
		borderColor,
		subLabel,
		advancedOptions,
		primaryKey,
		errorValue,
		additionalActions,
	} = props;
	const target = formik.values[formItem];
	const [showOptions, setShowOptions] = React.useState(false);

	const {screenWidth} = useDimensions()

	const { mainContentWidth, minimalWrap } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;

	return (
		<StyledFejoaSelectWrapper {...props}>
			{label && <String fSize={"14px"} margin="0 0 8px">{label}</String>}
			<CustomSelectWrapper>
				<SelectTrigger size={30} />
				<CustomSelect
					{...props}
					// readonly
					onChange={() => {}}
					onClick={() => {
						setShowOptions(!showOptions);
					}}
					onBlur={() => {
						setShowOptions(false);
						formik.setTouched({...formik.touched, [formItem]: true})
					}}
					type="text"
					readOnly={true} 
					borderColor={borderColor}
					value={
						target
							? target
							: defaultValue
								? defaultValue
								: "Выберите"
					}
				/>
			</CustomSelectWrapper>
			{subLabel && <String fSize={"12px"}>{subLabel}</String>}
			{showOptions && (
				<OptionsWindow maxHeight={showOptions ? "400px" : "0"}>
					{options.map((option, index) => {
						const choseOptionHandler = (e: any) => {
							if (advancedOptions && primaryKey) {
								const foundItem = advancedOptions.find((item) => item.title === option);
								formik.setFieldValue(formItem, foundItem[primaryKey]);
								additionalActions && additionalActions()
							} else {
								formik.setFieldValue(formItem, option);
								additionalActions && additionalActions()
							}
							updateFormCallBack &&
								updateFormCallBack(formItem, option, e);
							setShowOptions(!showOptions);
						};
						return (
							<EachOption
								key={index}
								onMouseDown={choseOptionHandler}
							>
								{option}
							</EachOption>
						);
					})}
				</OptionsWindow>
			)}
			{errorValue && !showOptions && (
				<StyledErrorNotificator>{errorValue}</StyledErrorNotificator>
			)}
		</StyledFejoaSelectWrapper>
	);
};

export const FejoaSelect = React.memo(Select);