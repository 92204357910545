export enum ROUTE_PATH {
	MORTGAGE = "mortgage",
	CREDITS = "credits",
	START = "start",
	DRAFT = "draft",
	CARDS = "cards",
	CPZA = "cpza",
	AUTO = "auto",
	DEPOSIT = "deposit",
	PASSPORT = "passport",
	PROFILE = "profile",
	IN_PROGRESS_SHORT = "inProgressShort",
	IN_PROGRESS_FULL = "inProgressFull",
	ORDERS = "orders",
	DBO = "dbo",
	ERROR = "ERROR",
	CONFIGURATOR = "configurator",
	TEMPORARY_FINAL = "temporaryFinal",
};

export type IRoutsPath = ROUTE_PATH;