import React from "react";
import { GET_IP_DATA_URL } from "../config";
import { getFingerprint } from "../utils/onGetFingerprint";
import CryptoJS from "crypto-js";

export const useFingerPrint = () => {
  const [fingerprint, setFingerprint] = React.useState<{key: string, value: any}[] | null>(null);
  const [showReport, setShowReport] = React.useState(true);

  React.useEffect(() => {
    if (showReport) {
      fetch(GET_IP_DATA_URL)                                // Get the IP data
        .then(res => res.json())
        .then(() => getFingerprint())    										// Get the fingerprint
        .then((fp) => {
          setFingerprint(fp as any);                        // Update the state
          setShowReport(false);
        });
    }
  }, [showReport]);
  if (fingerprint) {
    return {
      fingerprint: CryptoJS.MD5(JSON.stringify(fingerprint)).toString(),
      deviceModel: fingerprint?.find(el => el.key === "platform")?.value,
    }
  }
  return null
}