import { useFormik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useDimensions from '../../hooks/useDimensions';
import { setCurrentForm } from '../../redux/Application/appActions';
import { ROUTE_PATH } from '../../routesConfig';
import { Configurator } from './Configurator';
import mochJSON from '../../api/Credit/mocks/mochConfigurator.json';
import { useRefreshToken } from '../../hooks/useRefreshToken';

export const ConfiguratorContainer = () => {
	const paramsFlowId = useParams().flowId;
	const flowId = paramsFlowId || localStorage.getItem("flowId") || undefined;

	useRefreshToken(flowId)

	const { state, config } = mochJSON.data;

	const { screenHeight, screenWidth } = useDimensions();

	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(
			setCurrentForm({
				product: undefined,
				formId: "configurator",
				flowId,
			})
		);
	}, []);

	const [isInsurance, setIsInsurance] = React.useState(true);

	const configuratorFormData = useFormik({
		initialValues: state,
		validate: () => {},
		onSubmit: () => {
			dispatch(
				setCurrentForm({
					product: undefined,
					formId: ROUTE_PATH.ORDERS,
					flowId: undefined,
				})
			);
		},
		enableReinitialize: true,
	});

	return (
		<form onSubmit={configuratorFormData.handleSubmit}>
			{flowId && (
				<Configurator
					flowId={flowId}
					formik={configuratorFormData}
					currentWidth={screenWidth}
					currentHeight={screenHeight}
					isInsurance={isInsurance}
					setIsInsurance={setIsInsurance}
					config={config}
				/>
			)}
		</form>
	);
};