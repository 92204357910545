import React from "react";
import {Divider} from "../Divider";
import cardInfoHandIMG from "../../../assets/card-info-hand.svg";
import cardInfoTimeNewIMG from "../../../assets/card-info-time-new.svg";
import cardInfoCardIMG from "../../../assets/card-info-card.svg";
import cardInfoWalletAddIMG from "../../../assets/card-info-wallet-add.svg";
import cardInfoPresentIMG from "../../../assets/card-info-present.svg";
import cardInfoThumbIMG from "../../../assets/card-info-thumb.svg";


type CardBannerCardInfoProps = {
  isMobile: boolean
}

export const CardBannerCardInfo = ({isMobile}: CardBannerCardInfoProps) => (
  <>
    <div className="card-info-row">
      <div className="card-info-item">
        <div className="card-info__icon">
          <img src={cardInfoWalletAddIMG} alt=""/>
        </div>
        <div className="card-info-texts">
          <h6 className="card-info__title">120 + 120 дней без %</h6>
          <p className="card-info__subtitle">два льготных периода: на покупки + снятие наличных и переводы</p>
        </div>
      </div>
      {!isMobile && <Divider />}
      <div className="card-info-item">
        <div className="card-info__icon">
          <img src={cardInfoHandIMG} alt=""/>
        </div>
        <div className="card-info-texts">
          <h6 className="card-info__title">до 50% кешбэк</h6>
          <p className="card-info__subtitle">у партнеров + кешбэк до 7% по карте</p>
        </div>
      </div>
      {!isMobile && <Divider />}
      <div className="card-info-item">
        <div className="card-info__icon">
          <img src={cardInfoTimeNewIMG}  className="card-info__icon img"  alt=""/>
        </div>
        <div className="card-info-texts">
          <h6 className="card-info__title">0% на наличные</h6>
          <p className="card-info__subtitle">снятие и переводы</p>
        </div>
      </div>
    </div>
    <div className="card-info-row">
      <div className="card-info-item">
        <div className="card-info__icon">
          <img src={cardInfoCardIMG} alt=""/>
        </div>
        <div className="card-info-texts">
          <h6 className="card-info__title">0 ₽</h6>
          <p className="card-info__subtitle">обслуживание и выпуск без каких-либо условий</p>
        </div>
      </div>
      {!isMobile && <Divider />}
      <div className="card-info-item">
        <div className="card-info__icon">
          <img src={cardInfoPresentIMG} alt=""/>
        </div>
        <div className="card-info-texts">
          <h6 className="card-info__title">Акции и скидки</h6>
          <p className="card-info__subtitle">платежной системы «Мир»</p>
        </div>
      </div>
      {!isMobile && <Divider />}
      <div className="card-info-item">
        <div className="card-info__icon">
          <img src={cardInfoThumbIMG}  className="card-info__icon img"  alt=""/>
        </div>
        <div className="card-info-texts">
          <h6 className="card-info__title">До 2 млн ₽</h6>
          <p className="card-info__subtitle">сумма</p>
        </div>
      </div>
    </div>
  </>
)