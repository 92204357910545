import { ICommonFormData, TLoadingMode } from '../commonTypes';
import {
  ICalculatedCreditValues,
  IConsiderationConfig,
  IConsiderationState,
  ICreditDboState,
  ICreditInitState,
  IPassportConfig,
  IPassportState,
  IProfileConfig2,
  IProfileState2,
  ISubmitCreditConfig,
  ISubmitCreditDboConfig,
  IUploadedFile,
} from './creditTypes';

export const SET_CREDIT_DATA = "credit/SET_CREDIT_DATA";
export const SET_CREDIT_DBO_DATA = "credit/SET_CREDIT_DBO_DATA";
export const SET_PASSPORT_DATA = "credit/SET_PASSPORT_DATA";
export const SET_UPLOADING_FILE_MODE = "credit/SET_UPLOADING_FILE_MODE";
export const SET_CALCULATED_CREDIT_DATA = "credit/SET_CALCULATED_CREDIT_DATA";
export const SET_UPLOADED_PASSPORT_STATE = "credit/SET_UPLOADED_PASSPORT_STATE";
export const SET_CONSIDERATIONS = "credit/SET_CONSIDERATIONS";
export const SET_PROFILE_DATA = "credit/SET_PROFILE_DATA";
export const SET_UPLOADED_FILES = "credit/SET_UPLOADED_FILES";

export const setCreditData = (
	payload: ICommonFormData<ISubmitCreditConfig, ICreditInitState> | undefined
) => {
	return { type: SET_CREDIT_DATA, payload } as const;
};

export const setCreditDboData = (
	payload:
		| ICommonFormData<ISubmitCreditDboConfig, ICreditDboState>
		| undefined
) => {
	return { type: SET_CREDIT_DBO_DATA, payload } as const;
};

export const setPassportData = (
	data: ICommonFormData<IPassportConfig, IPassportState> | undefined
) => {
	return { type: SET_PASSPORT_DATA, data } as const;
};

export const setUploadingFileMode = (mode: TLoadingMode) => {
	return { type: SET_UPLOADING_FILE_MODE, mode } as const;
};

export const setCalculatedCreditData = (
	calculatedCreditValues: ICalculatedCreditValues | undefined
) => {
	return {
		type: SET_CALCULATED_CREDIT_DATA,
		calculatedCreditValues,
	} as const;
};

export const setUploadedPassportData = (payload: IPassportState) => {
	return { type: SET_UPLOADED_PASSPORT_STATE, payload } as const;
};

export const setConsiderations = (
	payload:
		| ICommonFormData<IConsiderationConfig, IConsiderationState>
		| undefined
) => {
	return { type: SET_CONSIDERATIONS, payload } as const;
};

export const setProfileData = (payload: ICommonFormData<IProfileConfig2, IProfileState2> | undefined) => {
	return { type: SET_PROFILE_DATA, payload } as const;
};

export const setUploadedFilesList = (payload: IUploadedFile[]) => {
	return { type: SET_UPLOADED_FILES, payload } as const;
};
