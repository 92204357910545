import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import styled from 'styled-components';
import { adaptiveMeasurements } from '../adaptiveParams';
import { Flex } from './Flex';
import { Title } from './Title';
import {defaultTheme} from "../themes/themes";

const StyledPlusIcon = styled(AiOutlinePlus)<IAddItem>`
	color: ${({ theme }) => theme.appColors.commonTheme};
	cursor: pointer;
	border-radius: 50%;
	&:hover {
		background: ${({ theme }) => theme.appColors.secondaryGrey};
	}
`;

const AddItemWrapper = styled.div<{width?: string, height?: string, fSize?: string}>`
	padding: 20px;
	background: #F2F4F7;
	border-radius: 4px;
	margin: 20px 0;
	color: ${defaultTheme.FEI_PURPLE};
	width: ${({width}) => width || "auto"};
	height: ${({height}) => height || "auto"};
	font-size: ${({fSize}) => fSize || "auto"};
`;

interface IAddItem {
	opstTitle: string;
	setShowModal: (show: boolean) => void;
	standartFontSizeMeasures: string;
	standartHeightMeasures: string;
	standartWidthMeasures: string;
	screenWidth: number;
}

export const AddItem: React.FC<IAddItem> = (props) => {
	const { opstTitle, setShowModal, standartFontSizeMeasures, standartWidthMeasures, screenWidth } = props;
	const AdditionHndler = () => setShowModal(true);

	const titileFontSize = adaptiveMeasurements(screenWidth, "24px", "22px", "20px", "16px");

	return (
		<AddItemWrapper
			width={standartWidthMeasures}
			height={"auto"}
			fSize={standartFontSizeMeasures}
		>
			<Flex justify={"space-between"} align={"center"}>
				<Title fSize={titileFontSize}>{opstTitle}</Title>
				<StyledPlusIcon size={40} {...props} onClick={AdditionHndler} />
			</Flex>
		</AddItemWrapper>
	);
};