import { FormikValues } from 'formik';
import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { IAdditionalIncomes } from '../../../../redux/Credit/creditTypes';
import { Button } from '../../../../styles/UIKit/Button';
import { CloseFeature, Modal } from '../../../../styles/UIKit/Modal/Modal';
import { Title } from '../../../../styles/UIKit/Title';

interface IEditItem {
	formik: FormikValues;
	additionalIncomesData: IAdditionalIncomes[];
	setAdditionalIncomesData: (
		additionalIncomesData: IAdditionalIncomes[]
	) => void;
	chosenItemData: IAdditionalIncomes | null;
	setChosenItemData: (chosenItemData: IAdditionalIncomes | null) => void;
	updateFormCallBackForAdditionalIncomesData: (
		formItem: string,
		currentValue: any[]
	) => void;
}

export const EditItem: React.FC<IEditItem> = (props) => {
	const {
		chosenItemData,
		setChosenItemData,
		formik,
		additionalIncomesData,
		setAdditionalIncomesData,
		updateFormCallBackForAdditionalIncomesData,
	} = props;
	const editItemHandler = () => {
		const chosenItem = additionalIncomesData.find((item: IAdditionalIncomes) => item === chosenItemData);
		if (chosenItem) {
			const editableIncomesDataList = additionalIncomesData.map(
				(elem: IAdditionalIncomes) =>
					elem.id === chosenItem.id
						? {
								...elem,
								occupationAdditionalIncomesKind:
									formik.values.occupationAdditionalIncomesKind,
								occupationAdditionalIncomeValue:
									formik.values
										.occupationAdditionalIncomeValue,
						  }
						: elem
			);
			setAdditionalIncomesData(editableIncomesDataList);
			updateFormCallBackForAdditionalIncomesData(
				"additionalIncomes",
				editableIncomesDataList
			);
		}
		setChosenItemData(null);
	};

	const { occupationAdditionalIncomesKind, occupationAdditionalIncomeValue } = formik.values;

	const disablingConditions = !occupationAdditionalIncomesKind || !occupationAdditionalIncomeValue
		|| occupationAdditionalIncomeValue.replace(/\D/g, "") == 0;

	return (
		<Modal show={chosenItemData !== null} height={"90%"} width={"1000px"}>
			<CloseFeature show={chosenItemData !== null}>
				<IoMdClose
					size={36}
					onClick={() => setChosenItemData(null)}
				/>
			</CloseFeature>
			<Title>Изменить источник дохода</Title>
			<>
				{props.children}
				<Button
					mode="primary"
					width="100%"
					height="71px"
					type="button"
					margin="30px 0 0 0"
					onClick={editItemHandler}
					disabled={disablingConditions}
				>
					Изменить
				</Button>
			</>
		</Modal>
	);
};