import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {getCurrentFormParams, TestPageNamesType} from '../utils/getQueryParams';
import {ROUTE_PATH} from "../routesConfig";

export const useSwitchForm = (
	setIsTestMode: (props: {isTestMode?: boolean, testPageName?: TestPageNamesType}) => void,
	product?: string,
	formId?: string,
	flowId?: string,
) => {
	const navigate = useNavigate();
	const {isTest, testName, ...loc} = getCurrentFormParams();
	const id = formId || loc?.formId;
	const hash = loc?.hash || localStorage.getItem('hash');

	let currentProduct = (product ? `/${product}` : "");
	const currentFormId = id ? `/${id}` : "";
	const currentFlowId = flowId ? `/${flowId}` : "";

	if ((!product && formId !== 'orders') && loc?.product === 'cards') {
		currentProduct = `/cards`;
	}

	useEffect(()=> {
		setIsTestMode({ isTestMode: isTest, testPageName: testName});
	},[isTest])

	useEffect(() => {
		if (formId === ROUTE_PATH.ORDERS) {
			navigate(`/${ROUTE_PATH.ORDERS}`)
		} else {
			if (flowId && flowId !== localStorage.getItem('flowId')) {
				localStorage.setItem('flowId', flowId);
			}
			const selectedFormId = (currentFormId !== '/orders' && currentFormId === '/start') ? '/draft' : (currentFormId ? currentFormId : '/start');
			const selectedProduct = loc.product

			if (!loc.product && loc.formId === "start") {
				navigate("/credits/draft")
			} else {
				const navigateTo = `${selectedProduct ? selectedProduct : ''}${isTest ? `/${testName}` : ""}${(flowId || currentFormId === '/orders') ? selectedFormId : '/start'}${hash ? hash : ''}`
				navigate(navigateTo)
			}

			const scrollerHandler = setInterval(() => {
				if (hash) {
					const target = document.querySelector(hash);
					if (target) {
						target.scrollIntoView({behavior: 'smooth', block: 'start'});
						clearInterval(scrollerHandler);
					}
				}
			}, 100);
			return () => {
				if (scrollerHandler) {
					clearInterval(scrollerHandler);
				}
			}
		}
	}, [currentProduct, currentFormId, currentFlowId]);
};