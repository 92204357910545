import { FormikValues } from 'formik';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ICompanyDadataList, } from '../../../redux/Application/appTypes';
import { FieldStyles, MaskedFieldStyles, StyledFieldsProps, } from '../CustomField/CustomFieldStyles';
import { Flex } from '../Flex';
import { String } from '../String';
import { StyledErrorNotificator } from '../StyledErrorNotificator';
import useDimensions from '../../../hooks/useDimensions';
import { adaptiveParams } from '../../adaptiveParams';
import { calcErrorOffset } from '../../../utils/calcErrorOffset';

interface IDadataCompanyAutoComplete extends StyledFieldsProps {
	formik: FormikValues;
	formName: string;
	dadataList: ICompanyDadataList[];
	currentValue: string;
	onChangeHandler: (value: string) => void;
	onMouseDownHandler: (chosenItem: ICompanyDadataList) => void;
	ResetListHandler: () => void;
	paramKey: "name" | "inn";
	errorValue?: string;
	onBlurHandler?: (
		formItem: string,
		currentValue: string,
		event: FormikValues
	) => void;
	mask?: Array<RegExp | string>;
	type?: string;
}

// select options window animation
const optionsWindowAnimation = keyframes`
    0% {opacity: 0;}
    100% {opacity: 1;}
`;

// modal with options to select
const OptionsWindow = styled.ul<{ maxHeight?: string }>`
	border-radius: 4px;
	position: absolute;
	width: 100%;
	margin-top: 3px;
	background: #ffffff;
	overflow-y: scroll;
	max-height: ${({ maxHeight }) => maxHeight || "400px"};
	opacity: 0;
	animation: ${optionsWindowAnimation} 0.4s forwards;
	box-shadow: 0px 0px 5px black;
	z-index: 2;
`;

// Each chosen option
const EachOption = styled.div`
	width: 100%;
	padding: 5px 10px;
	font-size: 20px;
	cursor: pointer;
	transition: background-color 0.1s;
	font-family: Formular;
	&:hover {
		background: #00cad6;
		color: white;
	}
`;

const DadataCompanyAutoCompleteWprapper = styled.div`
	position: relative;
`;

export const DadataCompanyAutoComplete: React.FC<IDadataCompanyAutoComplete> = (
	props
) => {
	const {
		formik,
		formName,
		label,
		dadataList,
		currentValue,
		onChangeHandler,
		onMouseDownHandler,
		ResetListHandler,
		paramKey,
		onBlurHandler,
		mask,
		errorValue,
		type,
	} = props;
	const {screenWidth} = useDimensions()

	const { mainContentWidth } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;

	const Top = calcErrorOffset(screenWidth, fieldsWrapConditions);

	const onChangeValueHandler = (e: any) => {
		// if (/[^a-zA-Z]/g.test(e.nativeEvent.data) && e.target.value.charAt(0) !== " " || !e.nativeEvent.data) {
		if (e.target.value.charAt(0) !== " " || !e.nativeEvent.data) {
			formik.handleChange(e);
			onChangeHandler(e.target.value);
		}
	};
	const onBlurValueHandler = (e: React.FocusEvent<HTMLInputElement>) => {
		onBlurHandler && onBlurHandler(formName, e.target.value, e);
		ResetListHandler();
	};

	return (
		<Flex direction="column" Position="relative" padding={fieldsWrapConditions ? "0" : "10px 0"}>
			{label && <String fSize={"14px"} margin="0 0 8px">{label}</String>}
			<DadataCompanyAutoCompleteWprapper>
				{mask ? (
					<MaskedFieldStyles
						{...props}
						showMask={false}
						guide={false}
						name={formName}
						value={currentValue}
						onChange={onChangeValueHandler}
						onBlur={onBlurValueHandler}
						onBlurCapture={() => formik.setFieldTouched(formName)}
						onFocus={(event) => {
							event.target.setAttribute("autocomplete", "off");
						}}
						mask={mask}
						type={type || "text"}
					/>
				) : (
					<FieldStyles
						{...props}
						name={formName}
						value={currentValue}
						onChange={onChangeValueHandler}
						onBlur={onBlurValueHandler}
						onBlurCapture={() => formik.setFieldTouched(formName)}
						onFocus={(event) => {
							event.target.setAttribute("autocomplete", "off");
						}}
						type={type || "text"}
					/>
				)}
				{dadataList.length > 0 && (
					<OptionsWindow>
						{dadataList.map((option: ICompanyDadataList, index) => {
							const choseOptionHandler = () => {
								onMouseDownHandler(option);
							};
							return (
								<EachOption
									key={index}
									onMouseDown={choseOptionHandler}
								>
									{option[paramKey]}
								</EachOption>
							);
						})}
					</OptionsWindow>
				)}
			</DadataCompanyAutoCompleteWprapper>
			{errorValue && dadataList.length < 1 && (
				<StyledErrorNotificator Top={Top}>{errorValue}</StyledErrorNotificator>
			)}
		</Flex>
	);
};
