import {ConfettiEffect} from "./ConfettiEffect/ConfettiEffect";

export enum PAGE_EFFECTS {
  confetti = 'confetti'
}

type PageEffectProps = {
  type?: PAGE_EFFECTS
  product?: string
}

export const PageEffect = ({type, product}: PageEffectProps) => {

  if(type === PAGE_EFFECTS.confetti) {
      return <ConfettiEffect product={product}/>
  }
  return <></>
}