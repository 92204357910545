import React from 'react';
import { useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { LoacalData } from '../api/localStorage';
import { updateTokens } from '../redux/Auth/auth-reducer';
import { ROUTE_PATH } from '../routesConfig';

export const useRefreshToken = (flowId?: string, product?: string) => {
	const localData = new LoacalData();
	const dispatch = useDispatch();

	React.useEffect(() => {
		if (flowId) {
			const accessToken: string | undefined = localData.getToken("accessToken");
			const tokenDecoded = accessToken !== undefined && jwtDecode<any>(accessToken);
			const targetTimeToRefresh = ((tokenDecoded?.exp || 0) * 1000) - (new Date().getTime());
			const untilUpdate = targetTimeToRefresh < 0 ? 0 : (targetTimeToRefresh * 0.5);
			let intervalHandle: NodeJS.Timeout | undefined;

			const halfTime = (((tokenDecoded?.exp || 0) - (tokenDecoded?.iat || 0)) * 0.5) || 300;

			const updateToken = () => {	// update token
				const refreshToken = localData.getToken("refreshToken");

				const refreshPayload = {
					token: "string",
					refreshToken,
				};

				dispatch(updateTokens(refreshPayload, flowId, product || ROUTE_PATH.CREDITS ));
			};

			const timeoutHandle = setTimeout(() => { // first check and shedule
				clearTimeout(timeoutHandle);
				updateToken();
				intervalHandle = setInterval(updateToken, halfTime * 1000);
			}, untilUpdate);

			return () => { // cleanup
				if (timeoutHandle) clearTimeout(timeoutHandle);
				if (intervalHandle) clearInterval(intervalHandle);
			};
		}
		// eslint-ignore-next-line
	}, []);
};