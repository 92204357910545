import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import { creditReducer } from './Credit/credit-reducer';
import { appReducer } from './Application/app-reducer';
import { authReducer } from './Auth/auth-reducer';
import { signDocsReducer } from './SignDocs/sign-docs-reducer';
import thunk from 'redux-thunk';

export type AppRootStateType = ReturnType<typeof rootReducer>;

export const rootReducer = combineReducers({
	creditReducer,
	appReducer,
	authReducer,
	signDocsReducer,
});

const IS_DEV = process.env.NODE_ENV === 'development';
// @ts-ignore
const reduxDevtoolsExtension = IS_DEV && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
const middleWare = reduxDevtoolsExtension ? compose(applyMiddleware(thunk), reduxDevtoolsExtension) : applyMiddleware(thunk)
export const store = createStore(rootReducer, middleWare);

// @ts-ignore
window.store = store;