import { FormikValues } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getDadataFio } from '../../../redux/Application/app-reducer';
import { setFioList } from '../../../redux/Application/appActions';
import { IFioDadataList } from '../../../redux/Application/appTypes';
import { IAdvancedOptions, IProfileState2 } from '../../../redux/Credit/creditTypes';
import { CurrancyField } from '../../../styles/UIKit/CurrancyField';
import { FioDadataField } from '../../../styles/UIKit/dadata/FioDadataField';
import { CustomerCalendar } from '../../../styles/UIKit/DatePicker/CustomerCalendar/CustomerCalendar';
import { FejoaMappedSelect } from '../../../styles/UIKit/FejoaSelect/FejoaMappedSelect';
import { Flex } from '../../../styles/UIKit/Flex';
import { Title } from '../../../styles/UIKit/Title';
import { dateIntoString } from '../../../utils/commonFunctions';
import { onSetFieldBorderColor } from '../../../utils/onSetFieldBorderColor';
import { ParagraphWrapper } from '../../CommonPagesStyles';

interface ISpouseData {
	formik: FormikValues;
	fieldsWrapConditions: boolean;
	updateFormCallBack: (formItem: string, currentValue: string, event: FormikValues) => void;
	setFormDataUpdates: (values: IProfileState2) => void;
	standartFontSizeMeasures: string;
	standartHeightMeasures: string;
	standartWidthMeasures: string;
	employmentTypeRef: IAdvancedOptions[];
	screenWidth: number;
	gender: string;
	fioList: IFioDadataList[];
}

export const SpouseData: React.FC<ISpouseData> = (props) => {
	const {
		formik,
		fieldsWrapConditions,
		updateFormCallBack,
		setFormDataUpdates,
		standartFontSizeMeasures,
		standartHeightMeasures,
		standartWidthMeasures,
		employmentTypeRef,
		screenWidth,
		gender,
		fioList,
	} = props;

	const dispatch = useDispatch()

	const _onDelay = React.useCallback(() => {
		if (formik.values.additionalInfoSpouseFio) {
			const timeOutId = setTimeout(() => dispatch(getDadataFio({title: formik.values.additionalInfoSpouseFio})), 500);
    	return () => clearTimeout(timeOutId);
		}
	}, [formik.values.additionalInfoSpouseFio])

	const [additionalInfoSpouseBirthDate, setAdditionalInfoSpouseBirthDate] =
		React.useState<string>(
			formik.values.additionalInfoSpouseBirthDate
				? dateIntoString(
						formik.values.additionalInfoSpouseBirthDate as Date
				  )
				: ""
		);

	const SPOSE_FIO_LABEL: {[key: string]: string} = {
		M: "ФИО супруги",
		F: "ФИО супруга",
	};

	const SPOSE_BIRTH_DATA_LABEL: {[key: string]: string} = {
		M: "Дата рождения супруги",
		F: "Дата рождения супруга",
	};

	const SPOSE_OCUPATION_TYPE_LABEL: {[key: string]: string} = {
		M: "Тип трудовой деятельности супруги",
		F: "Тип трудовой деятельности супруга",
	};

	const SPOSE_INCOMES_LABEL: {[key: string]: string} = {
		M: "Размер ежемесячного дохода супруги (руб.)",
		F: "Размер ежемесячного дохода супруга (руб.)",
	};

	// *** HANDLERS FOR SPOSE_FIO DADATA ***
	React.useEffect(_onDelay, [_onDelay])

	const setFioListOptionHandler = (chosenItem: IFioDadataList) => {
		formik.setFieldValue("additionalInfoSpouseFio", chosenItem.value);
	}
	const resetFioListHandler = () => {
		dispatch(setFioList([]));
	};


	return (
		<ParagraphWrapper padding={fieldsWrapConditions ? "35px 0" : "10px"}>
			<Title>Информация о супруге</Title>
			<FioDadataField
				formik={formik}
				formName={"additionalInfoSpouseFio"}
				dadataList={fioList}
				label={SPOSE_FIO_LABEL[gender] || "ФИО"}
				height={standartHeightMeasures}
				fSize={standartFontSizeMeasures}
				width={"100%"}
				onMouseDownHandler={setFioListOptionHandler}	
				ResetListHandler={resetFioListHandler}
				borderColor={ onSetFieldBorderColor(formik, "additionalInfoSpouseFio") }
				error={ formik.touched.additionalInfoSpouseFio && formik.errors.additionalInfoSpouseFio }
				errorValue={formik.touched.additionalInfoSpouseFio && formik.errors.additionalInfoSpouseFio}
			/>
			<Flex
				width={fieldsWrapConditions ? "750px" : "100%"}
				justify="space-between"
				align={fieldsWrapConditions ? "center" : "none"}
				margin="12px 0"
				direction={fieldsWrapConditions ? "row" : "column"}
			>
				<CustomerCalendar
					width={standartWidthMeasures}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					updateFormHandler={setFormDataUpdates}
					label={SPOSE_BIRTH_DATA_LABEL[gender] || "Дата рождения"}
					formik={formik}
					formItem={"additionalInfoSpouseBirthDate"}
					value={formik.values.additionalInfoSpouseBirthDate}
					dateValue={additionalInfoSpouseBirthDate}
					setDateValue={setAdditionalInfoSpouseBirthDate}
					borderColor={ onSetFieldBorderColor(formik, "additionalInfoSpouseBirthDate") }
					error={ formik.touched.additionalInfoSpouseBirthDate && !!formik.errors.additionalInfoSpouseBirthDate }
					errorValue={ formik.touched.additionalInfoSpouseBirthDate && formik.errors.additionalInfoSpouseBirthDate }
				/>
				<FejoaMappedSelect
					updateFormCallBack={updateFormCallBack}
					height={standartHeightMeasures}
					width={standartWidthMeasures}
					fSize={standartFontSizeMeasures}
					label={SPOSE_OCUPATION_TYPE_LABEL[gender] || "Тип трудовой деятельности"}
					formik={formik}
					options={employmentTypeRef}
					formItem={"additionalInfoSpouseOccupationType"}
					borderColor={ onSetFieldBorderColor(formik, "additionalInfoSpouseOccupationType") }
					errorValue={ formik.touched.additionalInfoSpouseOccupationType && formik.errors.additionalInfoSpouseOccupationType }
				/>
			</Flex>
			<CurrancyField
				formik={formik}
				label={SPOSE_INCOMES_LABEL[gender] || "Размер ежемесячного дохода (руб.)"}
				onBlurHandler={updateFormCallBack}
				fieldName={"additionalInfoSpouseIncomes"}
				height={standartHeightMeasures}
				fSize={standartFontSizeMeasures}
				width={"100%"}
				error={ formik.touched.additionalInfoSpouseIncomes && !!formik.errors.additionalInfoSpouseIncomes }
				borderColor={ onSetFieldBorderColor(formik, "additionalInfoSpouseIncomes") }
				errorValue={ formik.touched.additionalInfoSpouseIncomes && formik.errors.additionalInfoSpouseIncomes }
			/>
		</ParagraphWrapper>
	);
};