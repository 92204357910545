import {useDispatch} from "react-redux";
import {setTheme} from "../redux/Application/appActions";
import {useEffect} from "react";
import {IThemes} from "../styles/themes/themes";

export const useSetThemeOnStart = (theme: IThemes) => {
  const dispatch = useDispatch()
  useEffect(()=> {
    dispatch(setTheme(theme))
  }, [])
}