import { FormikValues } from 'formik';
import React, {useEffect, useState} from 'react';
import { ICheckClientsData } from '../../../redux/commonTypes';
import { Button } from '../../../styles/UIKit/Button';
import { Flex } from '../../../styles/UIKit/Flex';
import { String } from '../../../styles/UIKit/String';
import { ParagraphWrapper } from '../../CommonPagesStyles';
import { CheckBoxWrapper } from '../../SubmitCredit/SubmitCreditStyles';
import { FioChangedField } from './components/FioChangedField';
import { FirstInformativeBlock } from './components/FirstInformativeBlock';
import { CurrentPassportData } from './components/CurrentPassportData';
import { PreviousPassportData } from './components/PreviousPassportData';
import { SecondaryPassportFields } from './components/SecondaryPassportFields';
import { IPassportConfig, IPassportState, IUploadedFile, } from '../../../redux/Credit/creditTypes';
import { IDadataAddressTypes, IDadataTypes, IFioDadataList } from '../../../redux/Application/appTypes';
import { AgreementsModal } from '../../../styles/UIKit/Modal/AgreementsModal';
import { dateIntoString } from '../../../utils/commonFunctions';
import { adaptiveMeasurements, adaptiveParams, } from '../../../styles/adaptiveParams';
import { Gender } from '../../../styles/UIKit/Gender';
import { getYearsByDate } from '../../../utils/datesHandler';
import { onSetFieldBorderColor } from '../../../utils/onSetFieldBorderColor';
import { CheckBoxField } from '../../../styles/UIKit/CustomCheckBox/CheckBoxField';
import { FejoaMappedSelect } from '../../../styles/UIKit/FejoaSelect/FejoaMappedSelect';
import { PublicServicesBlock } from '../../../components/PublicServicesBlock/PublicServicesBlock';
import styled from 'styled-components';
import { pushDataLayerEvent } from "../../../utils/dataLayer";

interface IPassportMainBlockProps {
	product: string;
	passportFiles: IUploadedFile[];
	setFormDataUpdates: (values: IPassportState) => void;
	userPassportData: FormikValues;
	passportFormDataState: IPassportState;
	regionsList: IDadataTypes[];
	documentIssuanceCodesList: IDadataTypes[];
	previousDocumentIssuanceCodesList: IDadataTypes[];
	registrationAddressList: IDadataAddressTypes[];
	actualAddressList: IDadataAddressTypes[];
	citiesList: IDadataAddressTypes[];
	mobileScanner: string | undefined;
	screenWidth: number;
	agreements: string[];
	setAgreements: (agreements: string[]) => void;
	flowId: string;
	currentPassportValidationErrorMessage: string | null;
	previousPassportValidationErrorMessage: string | null;
	config: IPassportConfig;
	onEsia: () => void;
	fioList: IFioDadataList[];
}

export const EsiaWrapper = styled.div<{ product: string }>`
	margin: 20px 0 0 0;
	width: 750px;

	@media (min-width: 1200px) {
		width: 1250px;
	}

	@media (max-width: 750px) {
		width: 100%;
	}
`;

const BlockWrapper = styled.div<{minHeight: string}>`
	min-height: ${(props) => props.minHeight};
`

export const PassportMainBlock: React.FC<IPassportMainBlockProps> = (props) => {
	const {
		passportFiles,
		userPassportData,
		setFormDataUpdates,
		passportFormDataState,
		regionsList,
		documentIssuanceCodesList,
		previousDocumentIssuanceCodesList,
		registrationAddressList,
		actualAddressList,
		citiesList,
		mobileScanner,
		screenWidth,
		agreements,
		config,
		setAgreements,
		product,
		flowId,
		currentPassportValidationErrorMessage,
		previousPassportValidationErrorMessage,
		onEsia,
		fioList,
	} = props;

	const [isShowGenderField, setIsShowGenderField] = useState(true);

	const isCredit = product === "credits"
	const isCpza = product === "cpza"

	const { mainContentWidth } = adaptiveParams;

	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;

	const [clientsConfirmations, setClientsConfirmations] = React.useState<
		ICheckClientsData[]
	>(config.agreements);

	const [showAgreeMents, setShowAgreements] = React.useState<boolean>(false);
	const onAcceptAllHandle = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
		if (config && checked) {
			setAgreements(config.agreements.map((agreemnt) => agreemnt.code));
			setClientsConfirmations(
				clientsConfirmations.map((agreement) => ({
					...agreement,
					isChecked: true,
				}))
			);
		} else {
			setAgreements([]);
			setClientsConfirmations(
				clientsConfirmations.map((agreement) => ({
					...agreement,
					isChecked: false,
				}))
			);
		}
	};

	useEffect(()=> {
		const { gender } = userPassportData.values;
		setIsShowGenderField(gender === null || gender === "U")
	}, [])

	const clientsConfirmationsHandler = (code: string, isChecked: boolean) => {
		if (isChecked) {
			setAgreements([...(agreements || []), code]);
		}
		if (!isChecked) {
			setAgreements(agreements.filter((agreement) => agreement !== code));
		}
		setClientsConfirmations(
			clientsConfirmations.map((agreement) =>
				agreement.code === code
					? { ...agreement, isChecked }
					: agreement
			)
		);
	};

	// FOR DATE PICKER "birthDate"
	const [birthDateValue, setBirthDateValue] = React.useState<string>(
		passportFormDataState.birthDate !== null
			? dateIntoString(passportFormDataState.birthDate)
			: ""
	);

	const disabledModeForConfirmationPhone = clientsConfirmations.some(
		(data) => data.required && !data.isChecked
	);

	const updateFormCallBack = (
		formItem: string,
		currentValue: string,
		event: FormikValues
	) => {
		setFormDataUpdates({
			...userPassportData.values,
			agreements,
			[formItem]: currentValue,
		});
		userPassportData.handleBlur(event);
	};

	const standartFontSizeMeasures = adaptiveMeasurements(screenWidth, "28px", "26px", "24px", "20px");
	const standartHeightMeasures = adaptiveMeasurements(screenWidth, "71px", "68px", "56px", "50px");
	const standartWidthMeasures = adaptiveMeasurements(screenWidth, "360px", "360px", "100%", "100%");

	const proviousPassportErrors = userPassportData.values.previousPassportSeria ||
		userPassportData.values.previousPassportNumber ||
		userPassportData.values.previousPassportReceiptDate ||
		userPassportData.values.previousPassportDepartmentCode ||
		userPassportData.values.previousPassportIssuedBy;

	return (
		<>
		<BlockWrapper minHeight="360px">
			{mobileScanner && (
				<FirstInformativeBlock
					passportFiles={passportFiles}
					mobileScanner={mobileScanner}
					screenWidth={screenWidth}
				/>
			)}
			<ParagraphWrapper margin="0">
			</ParagraphWrapper>
			{isShowGenderField && <>
				<Flex
					padding={fieldsWrapConditions ? "0" : "10px"}
					direction={fieldsWrapConditions ? "row" : "column"}
					width={"100%"}
					justify="space-between"
					margin="12px 0"
				>
					<Gender
						formik={userPassportData}
						width={standartWidthMeasures}
						height={standartHeightMeasures}
						fSize={standartFontSizeMeasures}
						updateFormHandler={setFormDataUpdates}
						borderColor={onSetFieldBorderColor(userPassportData, "gender")}
					/>
				</Flex>
			</>}
			<ParagraphWrapper padding={fieldsWrapConditions ? "0" : "10px"}>
				<FioChangedField
					updateFormCallBack={updateFormCallBack}
					standartFontSizeMeasures={standartFontSizeMeasures}
					standartHeightMeasures={standartHeightMeasures}
					standartWidthMeasures={standartWidthMeasures}
					userPassportData={userPassportData}
					fieldsWrapConditions={fieldsWrapConditions}
					screenWidth={screenWidth}
					fioList={fioList}
				/>
			</ParagraphWrapper>
			{(isCredit || isCpza) && <ParagraphWrapper padding={fieldsWrapConditions ? "0" : "10px"}>
				<FejoaMappedSelect
					formik={userPassportData}
					updateFormCallBack={updateFormCallBack}
					formItem={"purpose"}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					width={"100%"}
					options={config?.loanPurposeRef.filter((item=> item.vis !== false && item.vis !== "false")).sort((a, b) => a.pos && b.pos && +a.pos > +b.pos ? 1 : -1)}
					defaultValue={"Выберите"}
					label={"Цель кредита"}
					borderColor={onSetFieldBorderColor(userPassportData, "purpose")}
					errorValue={userPassportData.touched.purpose && userPassportData.errors.purpose}
				/>
			</ParagraphWrapper>}
			</BlockWrapper>

			{
				config.esiaenable && !config.esiaUsage && <Flex>
					<EsiaWrapper product={product}>
						<PublicServicesBlock onEsia={onEsia} />
					</EsiaWrapper>
				</Flex>
			}

			<CurrentPassportData
				standartFontSizeMeasures={standartFontSizeMeasures}
				standartHeightMeasures={standartHeightMeasures}
				standartWidthMeasures={standartWidthMeasures}
				updateFormCallBack={updateFormCallBack}
				setFormDataUpdates={setFormDataUpdates}
				screenWidth={screenWidth}
				userPassportData={userPassportData}
				documentIssuanceCodesList={documentIssuanceCodesList}
				currentPassportValidationErrorMessage={currentPassportValidationErrorMessage}
				product={product}
			/>
			{((getYearsByDate(userPassportData.values.receiptDate) < 2 || proviousPassportErrors) && (
				<PreviousPassportData
					setFormDataUpdates={setFormDataUpdates}
					standartFontSizeMeasures={standartFontSizeMeasures}
					standartHeightMeasures={standartHeightMeasures}
					standartWidthMeasures={standartWidthMeasures}
					updateFormCallBack={updateFormCallBack}
					userPassportData={userPassportData}
					screenWidth={screenWidth}
					previousDocumentIssuanceCodesList={previousDocumentIssuanceCodesList}
					previousPassportValidationErrorMessage={previousPassportValidationErrorMessage} />
			))}
			<SecondaryPassportFields
				standartFontSizeMeasures={standartFontSizeMeasures}
				standartHeightMeasures={standartHeightMeasures}
				standartWidthMeasures={standartWidthMeasures}
				updateFormCallBack={updateFormCallBack}
				screenWidth={screenWidth}
				userPassportData={userPassportData}
				regionsList={regionsList}
				registrationAddressList={registrationAddressList}
				actualAddressList={actualAddressList}
				citiesList={citiesList}
				setFormDataUpdates={setFormDataUpdates}
			/>
			<ParagraphWrapper
				padding={fieldsWrapConditions ? "10px 0" : "10px"}
			>
				<Flex padding='8px 0'>
					<CheckBoxWrapper>
						<CheckBoxField
							formItem="acceptAll"
							checked={!disabledModeForConfirmationPhone}
							onChange={onAcceptAllHandle}
						/>
					</CheckBoxWrapper>
					<String margin="5px 0">
						Я соглашаюсь{" "}
						<String
							color={"#10C8D2"}
							onClickHandler={() =>
								setShowAgreements(!showAgreeMents)
							}
						>
							с условиями предоставления услуги
						</String>
					</String>
				</Flex>
			</ParagraphWrapper>
			<AgreementsModal
				show={showAgreeMents}
				setShow={setShowAgreements}
				clientsConfirmations={clientsConfirmations}
				clarificationClientsDataHandler={clientsConfirmationsHandler}
				flowId={flowId}
				passportFormFiles={config.passportFormFiles}
			/>
			<Flex padding={fieldsWrapConditions ? "0" : "10px"}>
				<Button
					margin="45px 0"
					padding="18px 41px"
					FSize="16px"
					mode="primary"
					type={"submit"}
					disabled={disabledModeForConfirmationPhone}
					onClick={() => pushDataLayerEvent(`${product}_form2_button_click`)}
				// onClick={generateSmsCode}
				>
					Далее
				</Button>
			</Flex>
		</>
	);
};
