import { Dispatch } from 'redux';
import { AuthApi, InvokeActionTypes } from '../../api/Auth/authApi';
import { LoacalData } from '../../api/localStorage';
import { reformatPhoneNumber } from '../../utils/commonFunctions';
import { setAppError, setAppLoadingMode, setAppSuccess, setCurrentForm, } from '../Application/appActions';
import { postCurrentForm } from '../Credit/credit-reducer';
import { AppRootStateType } from '../store';
import {
  SET_ANTIFRD_LIFE,
  SET_GENERATED_SMS_DATA,
  SET_USER_STATUS,
  SET_VALIDATED_SMS_DATA,
  setAntifrodTimer as setAntifrodLife,
  setGeneratedSmsData,
  setUserStatus,
  setValidateSmsData,
} from './authActions';
import { IAuthInitState, TAuthActions } from './authTypes';
import { ThunkAction } from 'redux-thunk';
import { CreditActionsTypes, IPassportConfig, IPassportState } from '../Credit/creditTypes';
import { AppActionsType } from '../Application/appTypes';
import { TCommonPostCurrentForm } from '../../api/Credit/creditApiTypes';
import { getDeclarationsList } from '../Application/app-reducer';
import { ERRORS_HANDLER, PRODUCTS_CODES } from '../../config';
import { onToasting } from '../../utils/onToasting';
import { AxiosError } from 'axios';
import { ICommonFormData, IFingerPrintData, TProductTypes } from '../commonTypes';
import { ROUTE_PATH } from '../../routesConfig';

const initState: IAuthInitState = {
	userStatus: "guest",
	antifrodLife: undefined,
	generate: undefined,
	validate: undefined,
};

export const authReducer = (
	state: IAuthInitState = initState,
	action: TAuthActions
): IAuthInitState => {
	switch (action.type) {
		case SET_USER_STATUS:
			return { ...state, userStatus: action.status };
		case SET_GENERATED_SMS_DATA:
			return { ...state, generate: action.data };
		case SET_VALIDATED_SMS_DATA:
			return { ...state, validate: action.data };
		case SET_ANTIFRD_LIFE:
			return { ...state, antifrodLife: action.value };
		default:
			return state;
	}
};

const loacalData = new LoacalData();

export const initialLogin =
	(
		phone: string,
		fio: string | null = null,
		code: string,
		codeId: number | undefined,
		flowId: string,
		action: InvokeActionTypes,
		payload: TCommonPostCurrentForm,
		product: string,
		fingerprint: IFingerPrintData | null,
		onSuccess?: Function
	): ThunkAction<
		void,
		AppRootStateType,
		unknown,
		CreditActionsTypes | TAuthActions | AppActionsType
	> =>
	async (dispatch) => {
		dispatch(setAppLoadingMode("loading"));
		const client = new AuthApi();
		try {
			if (codeId) {
				const body = {
					phone: reformatPhoneNumber(phone),
					codeId,
					code,
					fio,
					fingerprint: fingerprint?.fingerprint || null,
					deviceModel: fingerprint?.deviceModel || null,
				};
				const res = await client.invokeAction(body, flowId, action);
				const { token, refreshToken } = res.data.result;
				if (res.data.success && token && refreshToken) {
					loacalData.setToken(token, "accessToken");
					loacalData.setToken(refreshToken, "refreshToken");
					dispatch(setUserStatus("isAuth"));
					dispatch(setValidateSmsData(res.data));
					dispatch(postCurrentForm(flowId, payload, product));
					// dispatch(getDeclarationsList(1, true, PRODUCTS_CODES[product]))
					onSuccess && onSuccess()
				} else {
					loacalData.removeToken("accessToken");
					loacalData.removeToken("refreshToken");
					dispatch(setUserStatus("guest"));
					dispatch(setValidateSmsData(res.data));
					dispatch(setAppLoadingMode("failed"));
				}
			} else {
				dispatch(setAppLoadingMode("failed"));
			}
		} catch (err) {
			dispatch(setAppLoadingMode("failed"));
		}
	};

export const getSmsData =
	(
		phone: string,
		fio: string | null = null,
		action: InvokeActionTypes,
		flowId: string | null,
		token?: string
	) =>
	async (dispatch: Dispatch) => {
		const body = {
			phone: reformatPhoneNumber(phone),
			stamp: new Date(),
			fio,
			token
		};
		const client = new AuthApi();
		try {
			const res = await client.invokeAction(body, flowId, action);
			const eolMs = Date.parse(res.data.result.eol);
			if (res.data.error) {
				dispatch(setAppError(res.data.error));
			} else {
				dispatch(setGeneratedSmsData(res.data));
				dispatch(setAntifrodLife(eolMs));

				dispatch(setAppSuccess("Введите код из СМС..."));
			}
		} catch (err) {
			dispatch(setAppLoadingMode("failed"));
		}
	};

export const validateSmsCode =
	(phone: string, codeId: number, code: string, flowId: string | null, action: InvokeActionTypes, onSuccess?: Function) =>
	async (dispatch: Dispatch) => {
		const body = {
			phone: reformatPhoneNumber(phone),
			codeId,
			code,
		};
		const client = new AuthApi();
		try {
			const { data } = await client.invokeAction(
				body,
				flowId,
				action,
			);
			if (data.success && data.result.token && data.result.refreshToken) {
				const { token, refreshToken } = data.result;
				// dispatch(setUserStatus("isAuth"));
				dispatch(setValidateSmsData(data));
				loacalData.setToken(token, "accessToken");
				loacalData.setToken(refreshToken, "refreshToken");
				onSuccess && onSuccess()
			} else {
				loacalData.removeToken("accessToken");
				loacalData.removeToken("refreshToken");
				dispatch(setUserStatus("guest"));
				dispatch(setValidateSmsData(data));
				dispatch(setAppLoadingMode("failed"));
			}
			// dispatch(setValidateSmsData(data));
		} catch (err) {
			dispatch(setAppLoadingMode("failed"));
		}
	};

export const updateTokens = (payload: any, flowId: string, product: string) => async (dispatch: Dispatch) => {
		const client = new AuthApi();
		const localData = new LoacalData();
		try {
			const { data } = await client.fakeMethodForRefresh(
				payload,
				flowId,
				"refresh"
			);
			localData.setToken(data.result.token, "accessToken");
			localData.setToken(data.result.refreshToken, "refreshToken");
		} catch (err) {
			// dispatch(setCurrentForm({
			// 	product: product as TProductTypes,
			// 	formId: ROUTE_PATH.CREDITS_SUBMIT,
			// 	flowId: undefined,
			// }));
			window.location.replace(window.location.origin)
			const error = err as AxiosError
			localData.removeToken("accessToken");
			localData.removeToken("refreshToken");
			dispatch(setAppError("Что-то пошло не так"));
			dispatch(setAppLoadingMode("failed"));
			error.response && onToasting({type: "error", message: ERRORS_HANDLER[error.response?.status]})
		}
	};

export const onAuthEsia = (flowId: string, productCode: number) => async (dispatch: Dispatch) => {
	dispatch(setAppLoadingMode("loading"));
	const client = new AuthApi();
	try {
		const { data } = await client.invokeAction({productCode}, flowId, "esiaLogin")
		const { success, error, result } = data
		if (!success && !!error) {
			dispatch(setAppError('esiaError'));
			throw new Error(error);
		}
		window.open(result?.redirectUrl,'_self')
		dispatch(setAppLoadingMode("approved"));
	} catch(err) {
		dispatch(setAppLoadingMode("failed"));
		const error = err as AxiosError
		error.response && onToasting({type: "error", message: ERRORS_HANDLER[error.response?.status]})
	}

}

export const onLogaut = () => async (dispatch: Dispatch) => {
	dispatch(setAppLoadingMode("loading"));
	const client = new AuthApi();
	try {
		await client.onLogaut()
		dispatch(setAppLoadingMode("approved"));
	} catch(err) {
		dispatch(setAppLoadingMode("failed"));
		const error = err as AxiosError
		error.response && onToasting({type: "error", message: ERRORS_HANDLER[error.response?.status]})
	}
}

// https://spa.feijoa-develop.boos.solutions/api/sms/subscribe <- Ловим приходящие смс
