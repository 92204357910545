import React from "react";
import './PromoCardNewYear.css';
import CardMirImg from "../../../../../assets/card_mir_120+120.webp";
import {goToTheQuestionnaire} from "../../../../../utils/goToTheQuestionnaire";


export const PromoCardNewYear = () => {

  return (
    <section className={'promo'}>
      <div className="container">
        <div className={'promo__inner'}>
          <div className={'promo__info'}>
            <h2 className={'promo__title'}>
              {'Кредитная карта '}
              <span className="promo__sub-title">с бесплатным обслуживанием навсегда</span>
            </h2>
            <a className={'promo__btn'}
               href="#anketa"
               onClick={goToTheQuestionnaire}
            >
              Оформить карту
            </a>
          </div>
          <div className={'promo__image'}>
            <img src={ CardMirImg } alt="ката мир 120 + 120"/>
          </div>
        </div>
      </div>
    </section>
  )
}

