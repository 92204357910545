import { FormikValues } from 'formik';
import React from 'react';
import { adaptiveParams } from '../../styles/adaptiveParams';
import { Button } from '../../styles/UIKit/Button';
import { Flex } from '../../styles/UIKit/Flex';
import { String } from '../../styles/UIKit/String';
import { Title } from '../../styles/UIKit/Title';
import { AgreeModal } from './components/AgreeModal';
import { ConfiguratorConditions } from './components/ConfiguratorConditions';
import { ConfiguratorNotificatior } from './components/ConfiguratorNotificatior';
import { CreditsList } from './components/CreditsList';
import { FileLoader } from './components/FileLoader';
import { FinancialProtection } from './components/FinancialProtection';
import { IsRepayment } from './components/IsRepayment';
import { Ranges } from './components/Ranges';
import { calculateAmounts } from './helper';
import { Wrapper } from './styles';

interface IConfigurator {
	flowId: string;
	formik: FormikValues;
	currentWidth: number;
	currentHeight: number;
	config: any;
	isInsurance: boolean;
	setIsInsurance: (isInsurance: boolean) => void;
}

export const Configurator: React.FC<IConfigurator> = (props) => {
	const {
		flowId,
		formik,
		currentWidth,
		currentHeight,
		config,
		isInsurance,
		setIsInsurance,
	} = props;

	const {
		minCreditAmount,
		maxCreditAmount,
		minCreditTerm,
		maxCreditTerm,
		order,
		productName,
		isRefinAllowed,
		creditsList,
	} = config;

	const { minimalWrap } = adaptiveParams;
	const fieldsWrapConditions: boolean = currentWidth > minimalWrap;

	React.useEffect(() => {
		if (formik.values.requestedAmaunt === null) {
			formik.setFieldValue("requestedAmaunt", minCreditAmount);
		}

		if (formik.values.requestedTerm === null && config) {
			formik.setFieldValue("requestedTerm", maxCreditTerm / 12);
		}
	}, [formik.values.requestedAmaunt, formik.values.requestedTerm]);

	const [showMyCredits, setShowMyCredits] = React.useState<boolean>(true);

	const { amount, rate } = order;

	const [inletCreditsList, setInletCreditsList] =
		React.useState<any[]>(creditsList);

	const [showFileLoader, setShowFileLoader] = React.useState<boolean>(false);
	const [isAgree, setIsAgree] = React.useState(false);

	const updateFormHandler = () => {};

	// console.log(formik.values.term)

	const onAgreeHamdler = () => {
		setIsAgree(true);
	};

	return (
		<Flex justify="center">
			<Wrapper
				Border="2px solid grey"
				BorderRadius="30px"
				Padding={fieldsWrapConditions ? "50px" : "10px"}
				Margin="0 10px 50px 10px"
			>
				<FileLoader
					show={showFileLoader}
					setShaow={setShowFileLoader}
				/>
				<Title>{productName}</Title>
				{isRefinAllowed && (
					<>
						<Flex align="center" margin="20px 0">
							<IsRepayment formik={formik} />
							<String margin="0 10px">
								Погашение действующих кредитов
							</String>
						</Flex>
						<Title
							onClick={() => setShowMyCredits(!showMyCredits)}
							cursor={"pointer"}
						>
							Погашение действующих кредитов
						</Title>
						{formik.values.isRepayment && showMyCredits && (
							<CreditsList
								screenWidth={currentWidth}
								creditsList={inletCreditsList}
								setInletCreditsList={setInletCreditsList}
							/>
						)}
					</>
				)}

				<Ranges
					onBlurHandler={updateFormHandler}
					screenWidth={currentWidth}
					formik={formik}
					maxCreditAmount={maxCreditAmount}
					minCreditAmount={minCreditAmount}
					maxCreditTerm={maxCreditTerm}
					minCreditTerm={minCreditTerm}
					isRepayment={formik.values.isRepayment}
					chosenCreditsTotalAmount={calculateAmounts(
						inletCreditsList
					)}
				/>
				<ConfiguratorNotificatior />
				{formik.values.requestedTerm && (
					<ConfiguratorConditions
						amount={amount}
						rate={rate}
						term={formik.values.requestedTerm}
					/>
				)}
				<Flex
					direction={fieldsWrapConditions ? "row" : "column"}
					margin="20px 0 0 0"
					justify="space-between"
				>
					<FinancialProtection
						isInsurance={isInsurance}
						setIsInsurance={setIsInsurance}
					/>
					<Flex direction="column" justify="space-between">
						<Button
							mode="primary"
							onClick={() => setShowFileLoader(true)}
							type="button"
							margin="5px 0"
						>
							Справка о доходах
						</Button>
						<Button
							mode="primary"
							margin="5px 0"
							type="button"
							onClick={onAgreeHamdler}
						>
							Продолжить
						</Button>
					</Flex>
				</Flex>
			</Wrapper>
			<AgreeModal
				screenWidth={currentWidth}
				isAgree={isAgree}
				setIsAgree={setIsAgree}
				config={config}
				formik={formik}
			/>
		</Flex>
	);
};