import { MdError } from 'react-icons/md';
import styled from 'styled-components';

const StyledErrorNotificator = styled(MdError)<IErrorNotificator>`
	color: ${({ color }) => color || "black"};
	margin: ${({ margin }) => margin || "0"};
`;

interface IErrorNotificator {
	size: number;
	color?: string;
	margin?: string;
}

export const ErrorNotificator: React.FC<IErrorNotificator> = (props) => {
	return <StyledErrorNotificator {...props} />;
};