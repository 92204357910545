import { FormikValues } from 'formik';
import React, { ChangeEvent } from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import { adaptiveParams } from '../../adaptiveParams';
import { Flex } from '../Flex';
import { String } from '../String';
import { StyledErrorNotificator } from '../StyledErrorNotificator';
import { StyledFieldsProps } from './CustomFieldStyles';
import { calcErrorOffset } from '../../../utils/calcErrorOffset';
import useDimensions from '../../../hooks/useDimensions';

interface ITelephoneField extends StyledFieldsProps {
	screenWidth: number;
	onBlurHandler?: (
		formItem: string,
		currentValue: string,
		event: FormikValues
	) => void;
	onChangeHandle?: (data: {event: ChangeEvent<HTMLInputElement>, value: string}) => void;
	formItem?: string;
	backgroundColor?: string;
	errorValue?: string | undefined;
	onFocusAdditionalActions?: () => void;
	subLabelColor?: string
}

export const StyledMaskedInput = styled(MaskedInput)<ITelephoneField>`
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	font-weight: 400;
	outline: none;
	font-size: ${({ fSize }) => fSize || "28px"};
	line-height: 43px;
	border-radius: 4px;
	font-family: "Formular",serif;
	padding: 0 14px;
  background: ${(props) => props.backgroundColor || "#F2F4F7"};
  border: 2px solid ${({borderColor}) => borderColor || "transparent"};
  // &:focus {
  //   border-color: #00CAD6;
  // }
`;

export const TelephoneField: React.FC<ITelephoneField> = (props) => {
	const {
		label,
		subLabel,
		onBlurHandler,
		onChangeHandle,
		formItem,
		errorValue,
		height,
		onFocusAdditionalActions,
	} = props;
	const [ focused, setFocused ] = React.useState<boolean>(false);
	const { mainContentWidth } = adaptiveParams;
	const { screenWidth } = useDimensions();
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;
	const Top = calcErrorOffset(screenWidth, fieldsWrapConditions, undefined, subLabel);
	// console.log(props.error)
	const borderColor = errorValue ? 'red' : props.borderColor;
	return (
		<Flex
			direction="column"
			padding={fieldsWrapConditions ? "0" : "10px 0"}
			Position={"relative"}
		>
			{label && <String fSize={"14px"} margin="0 0 8px">{label}</String>}
			<StyledMaskedInput
				type={"tel"}
				placeholder={"+7 ___ ___ ____"}
				{...props}
				borderColor={borderColor}
				showMask={false}
				onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
					setFocused(false);
					if (formItem && onBlurHandler) {
						onBlurHandler(formItem, e.currentTarget.value, e);
					}
				}}
				onChange={(e: React.ChangeEvent<HTMLInputElement>)=>{
					if(onChangeHandle) onChangeHandle({event: e, value: e.target.value})
					// @ts-ignore
					return props.onChange(e)
				}}
				onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
					setFocused(true);
					event.target.setAttribute("autocomplete", "off");
					onFocusAdditionalActions && onFocusAdditionalActions()
				}}
				guide={false} // /[0-9\-\*]$/,
				mask={[
					// "+",
					// "7",
					// " ",
					// "(",
					// /[1-9]/,
					// /\d/,
					// /\d/,
					// ")",
					// " ",
					// /\d/,
					// /\d/,
					// /\d/,
					// "-",
					// /\d/,
					// /\d/,
					// /\d/,
					// /\d/,
					"+",
					"7",
					" ",
					// "(",
					/[1-9*]$/,
					/[0-9*]$/,
					/[0-9*]$/,
					// ")",
					" ",
					/[0-9*]$/,
					/[0-9*]$/,
					/[0-9*]$/,
					"-",
					/[0-9*]$/,
					/[0-9*]$/,
					/\d/,
					/\d/,
				]}
			/>
			{ (errorValue && !focused) && (
				<StyledErrorNotificator Top={Top}>{errorValue}</StyledErrorNotificator>
			)}
			{!!subLabel && !errorValue && <String fSize={"12px"} color={props.subLabelColor}>{subLabel}</String>}
		</Flex>
	);
};