import { FormikValues } from 'formik';
import React from 'react';
import { CurrancyField } from '../../../styles/UIKit/CurrancyField';
import { CustomField } from '../../../styles/UIKit/CustomField/CustomField';
import { CustomRangeInput } from '../../../styles/UIKit/CustomRangeInput/CustomRangeInput';
import { Flex } from '../../../styles/UIKit/Flex';
import { String } from '../../../styles/UIKit/String';
import { Title } from '../../../styles/UIKit/Title';
import { CurrentCredits } from './CurrentCredits';
import { bigSumHandler, FormatCurrancy, yaersHandler, } from '../../../utils/commonFunctions';
import { ParagraphWrapper } from '../../../pages/CommonPagesStyles';
import { CustomSelect } from '../../../styles/UIKit/CustomSelect';
import { Devider } from '../../../styles/UIKit/Devider';
import { MonthInput } from '../../../styles/UIKit/CustomField/MonthInput';
import { PercentInput } from '../../../styles/UIKit/CustomField/PercentInput';
import { insuranceProductTypes, insurnceCompanies } from '../tempCabinetData';

interface IConfirmCreditParamsMainBlock {
	confirmCreditForm: FormikValues;
}

export const ConfirmCreditParamsMainBlock: React.FC<
	IConfirmCreditParamsMainBlock
> = (props) => {
	const { confirmCreditForm } = props;
	const { refinancedLoand } = confirmCreditForm.values;
	const [amount, setAmount] = React.useState(0);
	const [term, setTerm] = React.useState(0);
	const [isRefinance, setIsRefinance] = React.useState(false);
	const [isLifeInsurance, setIsLifeInsurance] = React.useState(false);
	const [paymentDefferer, setPaymentDefferer] = React.useState(false);
	return (
		<>
			<ParagraphWrapper>
				<Title>Кредитный продукт</Title>
			</ParagraphWrapper>
			<ParagraphWrapper>
				<Flex width="100%" justify="space-between">
					<CustomRangeInput
						minValue={"10000"}
						maxValue={"5000000"}
						label={"Сумма кредита"}
						specialSymbol={"₽"}
						valueHandler={bigSumHandler}
						currancyHandler={FormatCurrancy}
						callbackValue={() => {}}
						value={amount.toString()}
						setValue={setAmount}
					/>
					<CustomRangeInput
						minValue={"2"}
						maxValue={"10"}
						label={"Срок кредита"}
						specialSymbol={"лет"}
						valueHandler={yaersHandler}
						callbackValue={() => {}}
						value={term.toString()}
						setValue={setTerm}
					/>
				</Flex>
			</ParagraphWrapper>
			<ParagraphWrapper>
				<Title>Дополнительные услуги</Title>
			</ParagraphWrapper>
			{/* <ParagraphWrapper>
				<ClientOptionsWrapper>
					<CheckBoxWrapper>
						<CustomCheckBox
							checked={isRefinance}
							setIsChecked={setIsRefinance}
						/>
					</CheckBoxWrapper>
					<String margin="5px 0">Рефинансирование</String>
				</ClientOptionsWrapper>
			</ParagraphWrapper> */}
			<ParagraphWrapper margin={"30px 0 0 0"}>
				<String>Сумма остатков рефинансируемых кредитов (в руб.)</String>
				<CurrancyField
					width={"365px"}
					height={"71px"}
					fieldName={"refinancedLoand"}
					formik={confirmCreditForm}
				/>
			</ParagraphWrapper>
			<ParagraphWrapper>
				<CurrentCredits />
			</ParagraphWrapper>
			{/* <ParagraphWrapper>
				<ClientOptionsWrapper>
					<CheckBoxWrapper>
						<CustomCheckBox
							checked={isLifeInsurance}
							setIsChecked={setIsLifeInsurance}
						/>
					</CheckBoxWrapper>
					<String margin="5px 0">Страхование жизни</String>
				</ClientOptionsWrapper>
			</ParagraphWrapper> */}
			<ParagraphWrapper>
				<Flex width="100%" justify="space-between">
					<CustomSelect
						options={insurnceCompanies}
						width={"365px"}
						height={"71px"}
						label={"Страхования компания"}
						{...confirmCreditForm.getFieldProps("insuranceCompany")}
						defaultValue={"Согласие"}
					/>
					<div>
						<PercentInput
							fieldName={"insuranceRate"}
							formik={confirmCreditForm}
							label={"Страховой тариф"}
						/>
					</div>
				</Flex>
			</ParagraphWrapper>
			<ParagraphWrapper>
				<Flex width="100%" justify="space-between" align="center">
					<CustomField
						label="Страхование на весь срок кредита"
						{...confirmCreditForm.getFieldProps("allCreditTermInsurance")}
						width={"365px"}
						height={"71px"}
					/>
					<div>
						<CurrancyField
							width={"365px"}
							height={"71px"}
							fieldName={"insurancePrime"}
							formik={confirmCreditForm}
							label={"Страховая премия, в руб."}
						/>
					</div>
				</Flex>
			</ParagraphWrapper>
			<ParagraphWrapper>
				<Devider />
			</ParagraphWrapper>
			<ParagraphWrapper>
				<Title>Страховой продукт</Title>
			</ParagraphWrapper>
			<ParagraphWrapper>
				<CustomSelect
					options={insuranceProductTypes}
					width={"100%"}
					height={"71px"}
					label={"Страховой продукт"}
					{...confirmCreditForm.getFieldProps("insuranceProduct")}
					defaultValue={"Страхование от несчастных случаев и болезней"}
				/>
			</ParagraphWrapper>
			<ParagraphWrapper>
				<String>Описание страховго продукта</String>
				<Title>
					Страхование от несчастных случаев и болезней обеспечит Вам выплату в
					случае неожиданных и серьезных проблем со здоровьем.
				</Title>
			</ParagraphWrapper>
			<ParagraphWrapper>
				<String>Стоимость страхового продукта (в руб.)</String>
				<Title>19 359 ₽</Title>
			</ParagraphWrapper>
			<ParagraphWrapper>
				<String>Количество (шт.)</String>
			</ParagraphWrapper>
			<ParagraphWrapper>
				<Devider />
			</ParagraphWrapper>
			{/* <ParagraphWrapper>
				<ClientOptionsWrapper>
					<CheckBoxWrapper>
						<CustomCheckBox
							checked={paymentDefferer}
							setIsChecked={setPaymentDefferer}
						/>
					</CheckBoxWrapper>
					<String margin="5px 0">Отсрочка платежа</String>
				</ClientOptionsWrapper>
			</ParagraphWrapper> */}
			{paymentDefferer && (
				<ParagraphWrapper>
					<MonthInput formik={confirmCreditForm} fieldName={"defferMonths"} />
				</ParagraphWrapper>
			)}
		</>
	);
};