import { Flex } from '../../../styles/UIKit/Flex';
import { Title } from '../../../styles/UIKit/Title';
import styled from 'styled-components';
import { adaptiveParams } from '../../../styles/adaptiveParams';
import { IUsefulInfoLinks } from '../../../redux/Credit/creditTypes';
import {CollapsableBlock} from "../CollabsableBock/CollabsableBlock";
import {ListOfRequirements} from "./ListOfRequirements/ListOfRequirements";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../../redux/store";
import { TestPageNames } from '../../../utils/getQueryParams';

interface IStyledLink {
	width?: string;
}

const StyledLink = styled.a<IStyledLink>`
	// width: 300px;
	width: ${({ width }) => width || "auto"};
	height: 56px;
	margin: 10px;
	text-decoration: none;
	color: #ffffff;
	font-size: 16px;
	// border: 1px solid grey;
	background: ${({ theme }) => theme.appColors.commonTheme};
	border-radius: 4px;
	display: flex;
  font-family: Formular, sans-serif;
  font-weight: 400;
	justify-content: center;
	align-items: center;
`;

const FooterLink = styled.a`
	color: #59208e;
`;

const ConditionText = styled.div<{width: string, fSize?:string, letterSpacing?: string, lineHeight?: string, padding?:string }>`
	width: ${(props) => props.width};
	padding-bottom: 37px;
	color: #9DA0B3;
	text-align: justify;
	font-family: Formular;
	font-size: ${(props)=> props.fSize || '14.6px'};
	line-height: ${(props) => props.lineHeight || '16.734px'};
	padding: ${(props) => props.padding};
	letter-spacing: ${(props) => props.letterSpacing}};
`

const PromotionRules = styled.a`
	lineHeight: 20px;
	color: #10C8D2;
	textAlign: left;
	margin: 0 auto 40px 10px;
	text-decoration: none;
`

const RequirementsWrapper = styled.div`
	padding: 0 10px;
`

interface IUsefulInfo {
	screenWidth: number;
	usefulInfoLinks: IUsefulInfoLinks[];
	showConditionText: boolean;
	isCredit: boolean;
	isCpza: boolean;
	isCard?: boolean;
	isReferral?: boolean;
}

export const UsefulInfo: React.FC<IUsefulInfo> = (props) => {
	const isTestMode = useSelector<AppRootStateType, boolean>(state => state.appReducer.isTestMode)
	const testPageName = useSelector<AppRootStateType, string | undefined>(state => state.appReducer.testPageName)
	const utmCampaign = useSelector<AppRootStateType, string | null | undefined>(state => state.creditReducer.initialCreditsData?.state.utmCampaign)
	const { screenWidth, usefulInfoLinks } = props;
	const currentBlockDirection: boolean = screenWidth > 1200;
	const getCalculatedWidth = (maxWidth = "1271px") => currentBlockDirection
		? maxWidth
		: screenWidth > adaptiveParams.mainContentWidth
			? `${adaptiveParams.mainContentWidth}px`
			: `95%`;

	const isMobile = screenWidth <= 767;

	const isTestPage2 = testPageName === TestPageNames.TEST2

	return (
		<div className={"container"}>
			<Flex align="center" direction="column" margin="20px 0 0 0">
				<div id="useful_info" />
				{(isTestPage2 || !(props.isCredit && isTestMode)) && (
					<>
						<Title fSize={currentBlockDirection ? "26px" : "20px"} padding={"0 0 0 10px"} alignSelf={isMobile ? "center" : "flex-start"}>Полезная информация</Title>
						<Flex
							direction={currentBlockDirection ? "row" : "column"}
							justify="space-between"
							align={"center"}
							width={ getCalculatedWidth() }
							margin="15px 10px 50px"
						>
							{usefulInfoLinks.map(({title, url}, idx) => {
								return (
									<StyledLink
										key={idx}
										href={url}
										target="_blank"
										rel="noreferrer"
										width={currentBlockDirection ? "300px" : "100%"}
									>{title}</StyledLink>
								)
							})}
						</Flex>
					</>
				)}

				{props.isCredit && props.isReferral && <PromotionRules
					href="https://www.zenit.ru/personal/cards/credit-cards-privilege/Pravila_Akcii_Privedi_druga.pdf"
					target="_blank"
				>
					{"Правила акции >>"}
				</PromotionRules>}

				{!props.isCpza && props.showConditionText && <ConditionText width={getCalculatedWidth("1240px")}>
					{props.isCredit && (!utmCampaign || utmCampaign === "MGM1") ?
						<>
							Потребительский кредит без залога и поручительства. Ставка определяется индивидуально для каждого заемщика. 
							При оформлении страхования жизни от 15,5% до 22,8% годовых. Без страхования – ставка от 23,5% до 30,8% годовых. 
							Полная стоимость кредита (ПСК) от 22,597% до 32,886%. Срок кредита от 3 до 5 лет. Сумма кредита от 100 тыс. до 5 млн руб. 
							Валюта – рубли. Погашение ежемесячно равными платежами, досрочное погашение возможно без комиссии. 
							Подробнее в офисах Банка. Не является публичной офертой. ПАО Банк ЗЕНИТ. Ген. лицензия Банка России №3255 от 16.12.2014
							{" "}
							{(!props.isReferral && props.isCredit && isTestMode) && (
								<FooterLink href="https://www.zenit.ru/personal/tariffs/?tab=credits" target="_blank">
									Полные условия и тарифы по потребительскому кредитованию
								</FooterLink>)
							}

							{props.isReferral && <><br/><br/>Организатор акции – ПАО Банк ЗЕНИТ. Для получения вознаграждения: кредит не должен быть
								погашен полностью или досрочно в течение месяца, следующим за месяцем получения кредита.
								Вознаграждение начисляется на действующую карту Банка ЗЕНИТ
							</>}

							{props.isReferral && <><br/><br/>* 1 бонусный рубль = 1 рубль. Вы можете компенсировать бонусами покупки
								по карте и конвертировать их в рубли на свой счет
							</>}
						</> :
						<>
							{ !props.isReferral && <>
								Кредитная карта Привилегий (Тарифный план 1). Валюта счета – рубли. Два льготных периода: 120 дней на покупки и 120 дней на 
								снятие наличных и переводы, действуют с 1-го числа месяца, в котором совершена первая соответствующая операция. 
								Льготный период возобновляется на следующий день после полного погашения задолженности. ПСК: 29,573% - 39,491%. 
								Процентная ставка 29,9 – 39,9%. Минимальный платеж – 2% от суммы задолженности, минимум 300 руб. 
								Неустойка при неоплате минимального платежа – 0,1% ежедневно на сумму просроченной задолженности. 
								Кредитный лимит: от 25 000 до 2 млн. руб. Подробные условия – на zenit.ru. ПАО Банк ЗЕНИТ. 
								Ген. лицензия Банка России №3255 от 16.12.2014.
							</>}

							{props.isReferral && <>
								Кредитная карта Привилегий (Тарифный план 1). Валюта счета – рубли. Два льготных периода: 120 дней на покупки и 120 дней на 
								снятие наличных и переводы, действуют с 1-го числа месяца, в котором совершена первая соответствующая операция. 
								Льготный период возобновляется на следующий день после полного погашения задолженности. ПСК: 29,573% - 39,491%. 
								Процентная ставка 29,9 – 39,9%. Минимальный платеж – 2% от суммы задолженности, минимум 300 руб. 
								Неустойка при неоплате минимального платежа – 0,1% ежедневно на сумму просроченной задолженности. 
								Кредитный лимит: от 25 000 до 2 млн. руб. Подробные условия – на zenit.ru. ПАО Банк ЗЕНИТ. 
								Ген. лицензия Банка России №3255 от 16.12.2014.
							</>}
						</>
						}
				</ConditionText>}

				{props.isCard && props.showConditionText && props.isReferral && <ConditionText width={getCalculatedWidth("1240px")}>
					Организатор Акции – ПАО Банк ЗЕНИТ. Друг допускается к участию в Акции в случае, если оформил Карту
					впервые в период проведения Акции и до даты начала Акции не являлся держателем действующей Карты.
					Другу необходимо оформить заявку на Карту по реферальной ссылке, полученной от Участника Акции,
					получить Карту и совершить с ее использованием безналичные операции в сумме не менее 5000 (пять тысяч)
					рублей в течение месяца, следующего за месяцем оформления карты. Безналичная операция должна быть
					осуществлена за счет лимита овердрафта участвующей в Акции Карты и отражена по СКС в расчетном периоде.
				</ConditionText>}
			</Flex>

			{props.isCpza && <>
				<RequirementsWrapper>
					<CollapsableBlock title="Требования к заемщику">
						<ListOfRequirements
							requirements={[
								'Является владельцем ПТС',
								'Гражданство или постоянная регистрация РФ',
								'Место проживания совпадает с местом работы',
								'Возраст – от 21 до 70 лет включительно',
								'Стаж работы на последнем месте (для Индивидуальных предпринимателей, Владельцев / совладельцев бизнеса, Самозанятых) – не менее 12 месяцев',
								'Доход – должен быть достаточен для обслуживания запрашиваемой суммы кредита',
							]}
						/>
					</CollapsableBlock>

					<CollapsableBlock title="Требования к автомобилю">
						<ListOfRequirements
							requirements={[
								'Автомобиль находится в собственности у клиента (залог собственности третьих лиц не допускается)',
								'Автомобиль в исправном состоянии',
								'Автомобиль должен находиться на регистрационном учете в ГИБДД',
								'По транспортному средству отсутствуют ограничения в виде арестов и запретов на регистрационные действия',
								'Транспортное средство не находится в розыске',
								'Транспортное средство не находится в залоге ПАО Банк ЗЕНИТ',
							]}
						/>
					</CollapsableBlock>
				</RequirementsWrapper>

				{props.showConditionText && <ConditionText
					width={getCalculatedWidth("1240px")}
					fSize="14px"
					letterSpacing="-0.02em"
					lineHeight="120%"
					padding="30px 10px"
				>
					Потребительский кредит под залог авто. Ставка определяется индивидуально для каждого заемщика. 
					При оформлении страхования жизни от 17,0% до 22,8% годовых. Без страхования – ставка от 25,0% до 30,8% годовых. 
					Срок кредита от 3 до 7 лет. Сумма кредита от 300 тыс. до 5 млн руб. Валюта – рубли. 
					Полная стоимость кредита  от 23,206% до 32,886%. Погашение ежемесячно равными платежами, досрочное погашение возможно без комиссии. 
					Обеспечение – залог автомобиля, находящегося в собственности у Заемщика и соответствующего требованиям Банка. 
					Необходимые документы: паспорт гражданина РФ, СТС, паспорт транспортного средства (ПТС)/ выписка из электронного ПТС. 
					Дополнительно Банк может запросить иные документы. Подробнее в офисах Банка. Не является публичной офертой. 
					ПАО Банк ЗЕНИТ. Ген. лицензия Банка России № 3255 от 16.12.2014.
				</ConditionText>}
			</>}
		</div>
	);
};
