import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDocumentList } from '../../../../redux/Credit/credit-reducer';
import { IUploadedFile } from '../../../../redux/Credit/creditTypes';
import { adaptiveParams } from '../../../../styles/adaptiveParams';
import { CustomProgressBar } from '../../../../styles/UIKit/CustomProgressBar';
import { String } from '../../../../styles/UIKit/String';
import { Title } from '../../../../styles/UIKit/Title';
import { ParagraphWrapper } from '../../../CommonPagesStyles';

interface IFirstInformativeBlock {
	mobileScanner: string;
	screenWidth: number;
	passportFiles: IUploadedFile[];
}

export const FirstInformativeBlock: React.FC<IFirstInformativeBlock> = (
	props
) => {
	const { screenWidth } = props;

	const { mainContentWidth } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;

	const dispatch = useDispatch();
	// const [showPassportPhoto, setShowPassportPhoto] = React.useState(false);
	const paramsFlowId = useParams().flowId;
	const flowId = paramsFlowId || localStorage.getItem("flowId") || undefined;

	// const allowedFormats = [
	// 	"jpg",
	// 	"jpeg",
	// 	"tiff",
	// 	"png",
	// 	"pdf",
	// 	"txt",
	// 	"doc",
	// 	"docx",
	// ];

	React.useEffect(() => {
		flowId && dispatch(getDocumentList(flowId));
	}, []);

	return (
		<ParagraphWrapper padding={fieldsWrapConditions ? "0" : "10px"}>
				<Title  margin="0 0 15px" fontName="Formular" fontWeight="900">Паспортные данные</Title>
				{/* <Flex direction="column" align={"center"} margin="0 0 45px">
						<GosUslugyBlock2 screenWidth={screenWidth} />
				</Flex> */}
			{/* <ParagraphWrapper>
				<Title>Паспортные данные</Title>
			</ParagraphWrapper> */}
			<ParagraphWrapper margin="0">
				<String fSize="14px">Шаг 2 из 4. </String>
				<String fSize="14px" color="black">
					Получите +30% к одобрению, заполнив второй шаг
				</String>
			</ParagraphWrapper>
			<CustomProgressBar completed={"70%"} width={"100%"} />
			{/* {flowId && (
				<MakePhoto
					screenWidth={screenWidth}
					modalHeader={"Фото страниц паспорта Клиента"}
					flowId={flowId}
					setShowPassportPhoto={setShowPassportPhoto}
					files={passportFiles}
					docType={"PassportRF"}
				/>
			)} */}
			{/* <Modal show={showPassportPhoto}>
				<CloseFeature show={showPassportPhoto}>
					<IoMdClose
						size={30}
						onClick={() => {
							setShowPassportPhoto(false);
						}}
					/>
				</CloseFeature>
				<FileLoaderBlock
					allowedFormats={allowedFormats}
					docType={"PassportRF"}
					files={passportFiles}
					blockTitle={"Фото страниц паспорта Клиента"}
					qrLink={mobileScanner}
					flowId={flowId}
					screenWidth={screenWidth}
				/>
			</Modal> */}
		</ParagraphWrapper>
	);
};