import styled from 'styled-components';
import { adaptiveParams } from '../adaptiveParams';
import { Flex } from './Flex';
import { Image } from './Image';
import { String } from './String';
import { Title } from './Title';

interface IConsiderationWrapper {
	screenWidth: number;
	imgSrc: string;
	title: string;
	description?: string;
	description2?: string;
	margin?: string;
	background?: string;
	color?: string;
}

const ConsiderationWrapper = styled.div<IConsiderationWrapper>`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 10px 40px rgba(0, 0, 0, 0.2);
	// border: 1px solid red;
	width: calc(100% - 20px);
	margin: ${({ margin }) => margin || "0 auto"};
	background: ${({ background }) => background || "#FFFFFF"};
	color: ${({ color }) => color || "#0A1244"};
	padding: 20px;
`;

export const ConsiderationFrame: React.FC<IConsiderationWrapper> = (props) => {
	const { screenWidth, imgSrc, title, description, description2, color } =
		props;
	const { standartFormWidthFirstValue, mainContentWidth } = adaptiveParams;

	const isMainContentWidth: boolean = mainContentWidth > screenWidth;
	const isStandartFormWidthFirstValue: boolean = standartFormWidthFirstValue > screenWidth;
	return (
		<ConsiderationWrapper {...props}>
			<Flex direction={isMainContentWidth ? "column" : "row"} align={"center"}>
				<Image width="90px" src={imgSrc} margin={"0 0 0 20px"} />
				<Flex direction="column" margin="20px 25px">
					<Title color="#0A1244" fSize="28px" margin="0 0 9px">{title}</Title>
					{description && (
						<String color="#0A1244" fSize="14px">{description}</String>
					)}
					{description2 && (
						<String color="#0A1244" fSize="14px">{description2}</String>
					)}
				</Flex>
			</Flex>
		</ConsiderationWrapper>
	);
};