import './ListOfRequirements.css'

interface ListOfRequirementsProps {
  requirements: string[]
}
export const ListOfRequirements = ({ requirements }: ListOfRequirementsProps) => {
  console.log()

  return <ul className={'list-of-requirements'}>
    {requirements.map((item) => (<li>{item}</li>))}
  </ul>
}