export const fioParser = (fio: string | null) => {
	if (fio) {
		const parsedFio = fio.split(" ");
		const middleName = parsedFio[3] ? parsedFio[2] + " " + parsedFio[3] : parsedFio[2] || null
		// console.log("==> ", middleName)
		return {
			lastName: parsedFio[0] || null,
			firstName: parsedFio[1] || null,
			middleName,
		};
	}
	return {
		lastName: null,
		firstName: null,
		middleName: null,
	};
};
