import blockIMG from '../../assets/block-img.png';
import React from 'react';
import './Card.css';
interface AdvantagesProps {
  rate?: number | string;
  creditcardMaxAmount?: number | string;
  isReferral: boolean;
}

export const formatMaxAmount = (amount: number | string, useIcon: boolean = true) => {
    if (typeof amount === 'string') {
        amount = parseInt(amount);
    }
    const currencyName = useIcon ? '₽' : 'руб.';

    if (amount >= 1000000) {
        return `${(amount / 1000000).toFixed((amount / 1000000) % 1 === 0 ? 0 : 1)} млн ${currencyName}`;
    } else if (amount >= 100000) {
        return `${(amount / 1000).toFixed(0)} тыс ${currencyName}`;
    } else if (amount >= 10000) {
        return `${(amount / 1000).toFixed((amount / 1000) % 1 === 0 ? 0 : 1)} тыс ${currencyName}`;
    } else if (amount >= 1000) {
        return `${(amount / 1000).toFixed((amount / 1000) % 1 === 0 ? 0 : 1)} тыс ${currencyName}`;
    } else {
        return `${amount} ${currencyName}`;
    }
}

export const Advantages : React.FC<AdvantagesProps> = ({ rate= '', creditcardMaxAmount = '', isReferral}) => {
  const maxAmount = formatMaxAmount(creditcardMaxAmount);
  return (
    <div>
      <section className="card-info">
        <div className="container">
          <div className="card-info-wrapper">
            <div className="card-info-main">
              <h4 className="card-info-main__title">Преимущества</h4>
              <div className="card-info-blocks">
                <div className="card-info-block">
                  <h6 className="card-info-block__title">120 + 120 дней без %</h6>
                  <p className="card-info-block__text">
                    беспроцентный период до 120 дней на покупки + 120 дней
                    дополнительно на снятие наличных
                  </p>
                  <div className="card-info-block__img">
                    <img src={blockIMG} alt=""/>
                  </div>
                </div>
                <div className="card-info-block">
                  <h6 className="card-info-block__title">До 7% кешбэк</h6>
                  <p className="card-info-block__text">
                    в кафе и ресторанах, супермаркетах, транспорте
                  </p>
                </div>
                <div className="card-info-block">
                  <h6 className="card-info-block__title">Акции и скидки</h6>
                  <p className="card-info-block__text">от платежной системы «Мир»</p>
                </div>
                <div className="card-info-block">
                  <h6 className="card-info-block__title">Бесплатно навсегда</h6>
                  <p className="card-info-block__text">выпуск и обслуживание</p>
                </div>
                {isReferral
                  ? <div className="card-info-block">
                    <h6 className="card-info-block__title">
                      До 2 млн ₽ кредитный лимит
                    </h6>
                    <p className="card-info-block__text">
                      для лимита до 100 000 ₽ нужен только паспорт
                    </p>
                  </div>
                  : <div className="card-info-block">
                    <h6 className="card-info-block__title">
                      30% кешбэк
                    </h6>
                    <p className="card-info-block__text">
                      полис ипотечного страхования<br/>
                      <a href="https://privetmir.ru/promo/strahovanie/vygodnoe-strakhovanie-ipoteki/" target="_blank">Подробнее об акции</a>
                    </p>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
