import React from 'react';
import styled from 'styled-components';

type Props = {};

const StyledRangedMeasurement = styled.div`
	width: auto;
    font-size: 20px;
    font-family: 'Formular';
    color: ${(props) => props.theme.appColors.greyText};
    padding: 0 10px;
`;

export const RangedMeasurement: React.FC<Props> = (props) => {
	return <StyledRangedMeasurement {...props} />
};