import useDimensions from '../../hooks/useDimensions';
import { ConsiderationFrame } from '../../styles/UIKit/ConsiderationFrame';
import rejectIMG from '../../assets/thumb-up-dynamic-color-reject.svg';
import React from 'react';
import { setCurrentForm } from '../../redux/Application/appActions';
import { useDispatch } from 'react-redux';
import { Flex } from '../../styles/UIKit/Flex';
import { adaptiveParams } from '../../styles/adaptiveParams';

export const Regection = () => {
	const { screenWidth } = useDimensions();
	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(
			setCurrentForm({
				product: "reject",
				formId: undefined,
				flowId: undefined,
			})
		);
	}, []);
	const { standartFormWidthFirstValue, mainContentWidth } = adaptiveParams;

	const isMainContentWidth: boolean = mainContentWidth > screenWidth;
	const isStandartFormWidthFirstValue: boolean =
		standartFormWidthFirstValue > screenWidth;
	return (
		<Flex justify="center" margin="0">
			<ConsiderationFrame
				screenWidth={screenWidth}
				imgSrc={rejectIMG}
				title={"Отказ."}
				description={"К сожалению, вам отказано по заявке на кредит"}
			/>
		</Flex>
	);
};