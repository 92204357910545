import Vector from '../../assets/orders/Vector.svg';
import Coin from '../../assets/orders/coin.svg';
import Time from '../../assets/orders/time.svg';
import Success from '../../assets/orders/success.svg';
import Waiting from '../../assets/orders/waiting.svg';
import Rejected from '../../assets/orders/rejected.svg';
import Draft from '../../assets/orders/draft.svg';
import { ICreditDeclarations } from '../../redux/Application/appTypes';

import './Orders.css';
import { Button } from '../../styles/UIKit/Button';
import { ACTION_NAME } from './helper';
import { Flex } from '../../styles/UIKit/Flex';
import { useDispatch } from 'react-redux';
import { getCurrentForm } from '../../redux/Credit/credit-reducer';
import { setOrederCatalog } from '../../redux/Application/appActions';

type IOrderItem = {
  item: ICreditDeclarations;
  isMobileVersion: boolean;
};


export const OrderItem : React.FC<IOrderItem> = (props: IOrderItem) => {

  const dispatch = useDispatch()

  const ORDER_PRODUCT_MAPPER: {[key: string]: string} = {
		"Потребительский кредит": "credits",
		"Кредитная карта": "cards",
	}

  const iconBasedOnUrl = () => {
    switch (props.item.status) {
      case 'Одобрено. Требуется подтверждение параметров кредита':
        return Success;
      case 'Заявка создана':
        return Waiting;
      default:
        return Draft;
    }
  };

  const onHandleDate = (str: string): string => {
    const addZero = (n: number): string => n < 10 ? `0${n}` : `${n}`;
    const dateTime = str.split(" ");
    const date = dateTime[0].split("-").reverse().join("-");
    const time = dateTime[1];
    const localDate = new Date(`${date}T${time}.000Z`)
    const h = localDate.getHours();
    const m = localDate.getMinutes();
    const s = localDate.getSeconds();
    const localtime = `${addZero(h)}:${addZero(m)}:${addZero(s)}`
    return `${date} ${localtime}`
  }

  // С бека приходит отформатированная дата
  const date = onHandleDate(props.item.created)
    .split(" ")[0]
    .split("-")
    .reverse()
    .join(".")

  return (
    <div className='my-request__actions-wrapper'>
    <div className="my-request-card">
      <div className="my-request-card__child">
        <img src={Vector} alt=""  className="my-request-card__icon"/>

        <p className="my-request-card__text">Категория</p>
        <h6 className="my-request-card__title">{props.item.target}</h6>
      </div>
      <div className="my-request-card__child">
        <img src={Coin} alt=""  className="my-request-card__icon"/>

        <p className="my-request-card__text">Сумма</p>
        <h6 className="my-request-card__title price">{props.item.amount} ₽</h6>
      </div>
      <div className="my-request-card__child">
        <img src={Time} className="my-request-card__icon" alt=""/>
        <p className="my-request-card__text">Дата</p>
        <h6 className="my-request-card__title">{date}</h6>
      </div>
      <div className="my-request-card__child">
        <img src={iconBasedOnUrl()}  className="my-request-card__icon"/>

        <p className="my-request-card__text">Статус</p>
        <h6 className="my-request-card__title">{props.item.status}</h6>
      </div>
    </div>
    {props.item.actions.length > 0 && props.item.actions.map((action, idx) => {
      const onHandleAction = () => {
        const onGetCurrentForm = () => {
          dispatch(getCurrentForm(props.item.declarationID, ORDER_PRODUCT_MAPPER[props.item.target]));
          dispatch(setOrederCatalog([]));
        }
        switch (action) {
          case "FILL_SHORT": return onGetCurrentForm();
          default: return;
        }

      }
      return (
        <Button
          key={idx}
          mode="primary"
          FSize={props.isMobileVersion ? "14px" : "16px"}
          color="#0A1244"
          background="#F0F2F6"
          onClick={onHandleAction}
        >
          {ACTION_NAME[action]}
        </Button>
      )
    })
    }
    </div>
  );
}