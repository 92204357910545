import {formatMaxAmount} from "../Cards/Advantages";
import WalletOkIMG from "../../assets/wallet-ok.svg";
import AnalogClockIMG from "../../assets/analog-clock.svg";
import TickCircleIMG from "../../assets/tick-circle.svg";
import TaskSquareIMG from "../../assets/task-square.svg";
import ClipboardTickIMG from "../../assets/clipboard-tick.svg";
import AwardIMG from "../../assets/award.svg";
import './ProductOptions.css';


interface ProductOptionsProps {
  maxCreditAmount: number
  maxCreditTerm: number
  product: string
  rate: number
  numberOfDisplayed?: number
  startPosition?: number
}


const getOptionsList = (product: string, maxCreditAmount: number, maxCreditTerm: number, rate: number) => {

  // Cpza
  if (product === 'cpza') {
    return [
      {
        id: '0',
        icon: WalletOkIMG,
        textHeader: `до ${formatMaxAmount(maxCreditAmount, true)}`,
        text: 'сумма кредита',
        width: '257px',
      },
      {
        id: '1',
        icon: AnalogClockIMG,
        textHeader: `До 7 лет`,
        text: 'срок с возможностью выбрать наиболее комфортный ежемесячный платеж',
      },
      {
        id: '2',
        icon: TickCircleIMG,
        textHeader: 'За 1 визит',
        text: 'в офис при оформлении онлайн',
      },
      {
        id: '0',
        icon: TaskSquareIMG,
        textHeader: `По паспорту и СТС`,
        text: 'оформление заявки',
        width: '257px',
      },
      {
        id: '1',
        icon: ClipboardTickIMG,
        textHeader: `ПТС остается у вас`,
        text: 'пользуйтесь автомобилем в залоге как обычно',
      },
      {
        id: '2',
        icon: AwardIMG,
        textHeader: 'На любые цели',
        text: 'деньги наличными',
      },
    ];
  }

  return [
    {
      id: '0',
      icon: WalletOkIMG,
      textHeader: `до ${formatMaxAmount(maxCreditAmount, true)}`,
      text: 'сумма кредита',
      width: '257px',
    },
    {
      id: '1',
      icon: AnalogClockIMG,
      textHeader: `До ${maxCreditTerm / 12} лет`,
      text: 'срок кредита',
    },
    {
      id: '2',
      icon: TickCircleIMG,
      textHeader: 'За 1 визит',
      text: 'в офис при оформлении онлайн',
    },
  ];
}

export const ProductOptions = ({product, maxCreditAmount, maxCreditTerm, rate, numberOfDisplayed, startPosition}: ProductOptionsProps) => {
  const optionsList = getOptionsList(product, maxCreditAmount, maxCreditTerm, rate).filter((option, index) => {
    if(startPosition === undefined && numberOfDisplayed === undefined) return true
    const startIndex = startPosition || 0;
    const finishIndex = startIndex + (numberOfDisplayed || 0);
    return index >= startIndex && index < finishIndex;
  });


  return <div className={"product-options"}>
    <div className={'container'}>
      <div className={"product-options__list"}>
        {optionsList.map((option, index) => (
          <div className={"product-options__item"}>
            {!!(index !% 3) && <div className={"product-options__dividing-line"}/>}
            <img className={"product-options__icon"}
                 src={option.icon}
                 alt={`${option.text} ${option.textHeader}`}
            />
            <div className={"product-options__content-block"}>
            <span className={"product-options__text-header"}>
              {option.textHeader}
            </span>
              <span className={"product-options__text"}>
              {option.text}
            </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
}