export class LoacalData {
	setToken(token: string, key: string) {
		localStorage.setItem(key, token);
	}
	getToken(key: string): string {
		const currentToken = localStorage.getItem(key) as string;
		return currentToken;
	}
	removeToken(key: string) {
		localStorage.removeItem(key);
	}

	setFlowId(flowId: string) {
		localStorage.setItem("flowId", flowId);
	}
	getFlowId() {
		return localStorage.getItem("flowId");
	}
	removeFlowId() {
		localStorage.removeItem("flowId");
	}
}
