import { ICommonFormData } from '../../../redux/commonTypes';
import { IProfileConfig2, IProfileState2, } from '../../../redux/Credit/creditTypes';

const profileConfig2: IProfileConfig2 = {
	esiaUsage: false,
	otherIncomesRef: [],
	errorMessage: null,
	gender: "M",
	employmentTypeRef: [],
	contactsRelationRef: [],
	occupationCompanyOpfRef: [],
	thresholdAmount: 1000000,
	occupationCategoryRef: [
		{ code: "Special", title: "Неруководящая должность" },
		{ code: "Head", title: "Руководитель среднего звена" },
		{ code: "Top", title: "Топ-менеджер" },
	],
	realEstateTypeOptions: ["Частный дом", "Квартира", "Другое"],
	realEstateGettingWayOptions: ["Покупка", "Наследство", "Другое"],
	realEstatePledgedDocTypeOptions: ["Договор", "Квитанция"],
	maritalStatusOptions: [],
	maritalStatusRef: [],
	vehicleTypeOptions: [
		"Мотоцикл",
		"Автомобиль",
		"Коммерческий транспорт",
		"Иное",
	],
	vehicleGettingWayOptions: ["Покупка", "Наследство", "Иное"],
	vehicleLabelOptions: ["Toyota", "TAZ", "Иное"],
	vehiclePledgedDocTypeOptions: ["Договор", "Квитанция", "Иное"],
	occupationDocConfirmationIncomesRef: [],
	occupationTypeRef: [
		{ code: "01", title: "Индивидуальный предприниматель" },
		{ code: "02", title: "Работа по найму" },
		{ code: "03", title: "Собственный бизнес" },
		{ code: "04", title: "Прочий доход" },
	],
	additionalIncomesRef: [
		{
			code: "Investment",
			title: "Доход по вкладам и иным финансовым активам",
		},
		{
			code: "Investment",
			title: "Доход по вкладам и иным финансовым активам",
		},
		{
			code: "Special",
			title: "Ежемесячные денежные выплаты для специальных категорий граждан",
		},
		{ code: "Allowance", title: "Алименты и денежные пособия" },
		{ code: "SelfEmployment", title: "Самозанятый" },
		{ code: "GPH", title: "Работа по договору ГПХ" },
		{ code: "Freelance", title: "Неофициально (Фриланс)" },
		{
			code: "Rent",
			title: "Доход от сдачи недвижимого имущества внаем/аренду",
		},
		{ code: "Pension", title: "Пенсия" },
	],
	additionalInfoEducation: [
		{
			code: "Degree",
			title: "Ученая степень",
		},
		{
			code: "MBA",
			title: "MBA",
		},
		{
			code: "Middle",
			title: "Среднее",
		},
		{
			code: "HalfHigher",
			title: "Незаконченное высшее",
		},
		{
			code: "Higher",
			title: "Высшее",
		},
		{
			code: "SecondHigher",
			title: "Высшее",
		},
		{
			code: "HalfMiddle",
			title: "Неполное среднее",
		},
		{
			code: "SpecialMiddle",
			title: "Среднее специальное",
		},
	],
	phoneNumber: "+7 (999) 777-7777",
	order: {
		amount: 20000,
		term: 3,
		rate: 15.5,
		preferentialRate: 4,
		preferentialTerm: 1,
	},
	mobileScanner: {
		scan: "https://spa.feijoa-develop.boos.solutions/scan-it/XkySUP7VtCgmq2bXnqrfBn7Fx2X36fRG7Rb5HgJ5tdXAoWNBDjWPQsxGu5Aqnub3JoDK8yHKf4h2awBYSHgbYazJ1w7nTkprdrNFgCpCDFR1JEKwGPCMJLe5UTuorRgRCgH",
	},
};

const profileState2: IProfileState2 = {
	occupationAdditionalIncomesKind: "",
	occupationCompanyOgrn: "",
	occupationCompanyKpp: "",
	occupationType: "",
	occupationOtherIncomes: "",
	occupationCategory: "",
	occupationPost: "",
	occupationPhoneNumber: "",
	occupationCompanyName: "",
	occupationCompanyAddress: null,
	occupationCompanyLegalAddress: null,
	occupationCompanyIsActualMutchLegal: false,
	// occupationCompanyLegalForm: "",
	occupationCompanyInn: "",
	occupationStartWorkDate: null,
	occupationMonthlyIncomesValue: "",
	isGettingSalaryOnCard: false,
	isAdditionalIncomes: false,
	isReadyToProveIncomes: false,
	occupationDocConfirmationIncomes: "",
	occupationIncomesKind: "",
	occupationAdditionalIncomeValue: "",

	occupationOwnBusinessShare: "",
	ipSeria: "",
	ipNumber: "",
	ipIssueDate: null,

	additionalInfoEducation: "",
	additionalInfoMaritalStatus: "",
	additionalInfoMinorChildrenQuantity: 0,
	additionalInfoOtherDependents: 0,

	additionalInfoSpouseFio: "",
	additionalInfoSpouseBirthDate: null,
	additionalInfoSpouseOccupationType: "",
	additionalInfoSpouseIncomes: "",

	contactsPhone: "",

	additionalIncomes: [],

	questionnaire: [
		{
			id: "1",
			answer: false,
			title: "Имеете ли Вы в настоящее время просроченные финансовые обязательства?",
		},
		{
			id: "2",
			answer: false,
			title: "Участвуете ли Вы в настоящее время в судебном процессе или имеете неисполненные решения суда?",
		},
		{
			id: "3",
			answer: true,
			title: "Являетесь ли Вы налоговым резидентом только в РФ?",
		},
		{
			id: "4",
			answer: false,
			title: "Являетесь ли Вы банкротом, или подано ли в отношении Вас заявление о признании банкротом?",
		},
		{
			id: "5",
			answer: false,
			title: "Существуют ли решения суда об ограничениях, связанных с арестом имущества или нахождением Вас под следствием?",
		},
	],
};

export const mochedProfileData2: {
	data: ICommonFormData<IProfileConfig2, IProfileState2>;
} = {
	data: {
		formId: "profile",
		config: profileConfig2,
		state: profileState2,
		errors: [],
		flowId: "74oDD4tK8towxhqcUdpHMQmCP5m6BSXhQqGVAFtijdu3Q63WC",
	},
};
