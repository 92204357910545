import React from "react";
import { useDispatch } from "react-redux";
import { CabinetCards } from "../components/Cabinet/components/CabinetCards";
import useDimensions from "../hooks/useDimensions";
import { setCurrentForm, setOrederCatalog } from "../redux/Application/appActions";
import { ICreditDeclarations } from "../redux/Application/appTypes";
import { setGeneratedSmsData, setValidateSmsData } from "../redux/Auth/authActions";
import { Button } from "../styles/UIKit/Button";
import { Flex } from "../styles/UIKit/Flex";

export const TestCabinetContainer = () => {
	const dispatch = useDispatch();
	const declarations: ICreditDeclarations[] = [
    {
      actions: ["FILL_SHORT"],
      amount: 500000,
      created: "16-12-2022 14:19:32",
      declarationID: "25qgateTngULuidnE5vKcpxNXd7VwJiKzauDCCFseCQi9X4Cy6",
      // formId: "order",
      status: "Заявка создана",
      target: "Потребительский кредит",
    },
    {
      actions: ["FILL_SHORT"],
      amount: 100000,
      created: "16-12-2020 14:19:32",
      declarationID: "25qgateTngULuidnE5vKcpxNXd7VwJiKzauDCCFseCQi9X4Cy6",
      // formId: "order",
      status: "Заявка создана",
      target: "Потребительский кредит",
    },
    {
      actions: ["FILL_SHORT"],
      amount: 200000,
      created: "16-12-2015 14:19:32",
      declarationID: "25qgateTngULuidnE5vKcpxNXd7VwJiKzauDCCFseCQi9X4Cy6",
      // formId: "order",
      status: "Заявка создана",
      target: "Потребительский кредит",
    },
    {
      actions: ["FILL_SHORT"],
      amount: 300000,
      created: "16-12-2012 14:19:32",
      declarationID: "25qgateTngULuidnE5vKcpxNXd7VwJiKzauDCCFseCQi9X4Cy6",
      // formId: "order",
      status: "Заявка создана",
      target: "Потребительский кредит",
    },
  ]

  React.useEffect(() => {
		dispatch(
			setCurrentForm({
				product: undefined,
				formId: "test_orders",
				flowId: undefined,
			})
		);
	}, [dispatch, declarations]);

	const { screenWidth } = useDimensions();

  const onCreateNewDeclaration = () => {
		dispatch(setGeneratedSmsData(undefined));
		dispatch(setValidateSmsData(undefined))
		dispatch(setOrederCatalog([]));
		dispatch(
			setCurrentForm({
				product: "credits",
				formId: "start",
				flowId: undefined,
			})
		);
	}

  return (
    <Flex justify="center" padding="120px 0 0">
			{declarations && declarations.length ? (
				// <Cabinet creditDeclarations={declarations} />
				<CabinetCards
					creditDeclarations={declarations}
					screenWidth={screenWidth}
					onCreateNewDeclaration={onCreateNewDeclaration}
				/>
			) : (
				// <Title>Нет кредитов</Title>
				// <LoadingMode />
				<>
					<Flex direction="column" align="center">
						<h1>У вас не заявок</h1>
						<Flex margin="20px 0 0 0">
							<Button mode="primary" onClick={onCreateNewDeclaration}>Создать заявку</Button>
						</Flex>
					</Flex>
				</>
			)}
		</Flex>
  )

}