import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { LoacalData } from "../../api/localStorage";
import { PRODUCTS_CODES } from "../../config";
import { ROUTE_PATH } from "../../routesConfig";
import { getDeclarationsList } from "../Application/app-reducer";
import { setCurrentForm, setErrorForm } from "../Application/appActions";
import { TProductTypes } from "../commonTypes";
import {
	setConsiderations,
	setCreditData,
	setPassportData,
	setProfileData,
} from "./creditActions";
import { redefineInitialData } from "./utils/redefineInitialData";

const localData = new LoacalData();

const createDate = (str: string | null): Date | null => {
	if (!str) {
		return null;
	}
	if (str) {
		return new Date(str.split("T")[0]);
	}
	return null;
};
export const getCurrentFormHandler = <K extends AxiosResponse>(
	res: K,
	dispatch: Dispatch,
	product: string
) => {
	switch (res.data.formId) {
		case "temporaryFinal": {
			// dispatch(setErrorForm(res.data));
			dispatch(
				setCurrentForm({
					flowId: undefined,
					formId: undefined,
					product: product as TProductTypes,
				})
			);
			return;
		}
		case ROUTE_PATH.ERROR: {
			dispatch(setErrorForm(res.data));
			dispatch(
				setCurrentForm({
					flowId: res.data.flowId,
					formId: res.data.formId,
					product: product as TProductTypes,
				})
			);
			return;
		}
		case ROUTE_PATH.START: {
			const { data } = res;
			const superRes = {
				...data,
				state: {
					...data.state,
					birthDate: createDate(data.state.birthDate),
				},
			};
			redefineInitialData(superRes,product)
			dispatch(
				setCurrentForm({
					product: product as TProductTypes,
					formId: res.data.formId,
					flowId: res.data.flowId,
				})
			);
			dispatch(setCreditData(superRes));
			localData.removeToken("accessToken")
			localData.removeToken("refreshToken")
			return;
		}
		case ROUTE_PATH.PASSPORT: {
			const { data } = res;
			const superRes = {
				...data,
				state: {
					...data.state,
					birthDate: createDate(data.state.birthDate),
					receiptDate: createDate(data.state.receiptDate),
					previousPassportReceiptDate: createDate(data.state.previousPassportReceiptDate),
				},
			};
			dispatch(
				setCurrentForm({
					product: product as TProductTypes,
					formId: res.data.formId,
					flowId: res.data.flowId,
				})
			);
			return dispatch(setPassportData(superRes));
		}
		case ROUTE_PATH.IN_PROGRESS_SHORT: {
			return dispatch(setConsiderations(res.data));
		}
		case ROUTE_PATH.IN_PROGRESS_FULL: {
			return dispatch(setConsiderations(res.data));
		}
		case ROUTE_PATH.PROFILE: {
			const { data } = res;
			const superRes = {
				...data,
				state: {
					...data.state,
					occupationStartWorkDate: createDate(data.state.occupationStartWorkDate),
					additionalInfoSpouseBirthDate: createDate(data.state.additionalInfoSpouseBirthDate),
				},
			};
			dispatch(
				setCurrentForm({
					product: product as TProductTypes,
					formId: res.data.formId,
					flowId: res.data.flowId,
				})
			);
			dispatch(setProfileData(superRes));
			return;
		}
		default:
			return res;
	}
};

export const postCurrentFormHandler = <K extends AxiosResponse>(
	res: K,
	dispatch: Dispatch,
	product: string
) => {
	switch (res.data.formId) {
		case "temporaryFinal": {
			// dispatch(setErrorForm(res.data));
			dispatch(
				setCurrentForm({
					flowId: undefined,
					formId: undefined,
					product: product as TProductTypes,
				})
			);
			return;
		}
		case ROUTE_PATH.ERROR: {
			dispatch(setErrorForm(res.data));
			dispatch(
				setCurrentForm({
					flowId: res.data.flowId,
					formId: res.data.formId,
					product: product as TProductTypes,
				})
			);
			return;
		}
		case ROUTE_PATH.START: {
			const { data } = res
			const superRes = {
				...data,
				state: {
					...data.state,
					birthDate: createDate(data.state.birthDate),
				},
			};
			dispatch(
				setCurrentForm({
					product: product as TProductTypes,
					formId: res.data.formId,
					flowId: res.data.flowId,
				})
			);
			dispatch(setCreditData(superRes));
			localData.removeToken("accessToken")
			localData.removeToken("refreshToken")
			return;
		}
		case ROUTE_PATH.PASSPORT: {
			const { data } = res
			const superRes = {
				...data,
				state: {
					...data.state,
					birthDate: createDate(data.state.birthDate),
					receiptDate: createDate(data.state.receiptDate),
					previousPassportReceiptDate: createDate(data.state.previousPassportReceiptDate),
				},
			};
			dispatch(
				setCurrentForm({
					product: product as TProductTypes,
					formId: res.data.formId,
					flowId: res.data.flowId,
				})
			);
			dispatch(setCreditData(undefined));
			dispatch(setPassportData(superRes));
			return;
		}
		case ROUTE_PATH.IN_PROGRESS_SHORT: {
			dispatch(
				setCurrentForm({
					product: undefined,
					formId: res.data.formId,
					flowId: res.data.flowId,
				})
			);
			dispatch(setConsiderations(res.data));
			return;
		}
		case ROUTE_PATH.IN_PROGRESS_FULL: {
			dispatch(
				setCurrentForm({
					product: product as TProductTypes,
					formId: res.data.formId,
					flowId: res.data.flowId,
				})
			);
			dispatch(setConsiderations(res.data));
			dispatch(setProfileData(undefined));
			return;
		}
		case ROUTE_PATH.PROFILE: {
			const { data } = res;
			const superRes = {
				...data,
				state: {
					...data.state,
					occupationStartWorkDate: createDate(data.state.occupationStartWorkDate),
				},
			};
			dispatch(
				setCurrentForm({
					product: product as TProductTypes,
					formId: res.data.formId,
					flowId: res.data.flowId,
				})
			);
			dispatch(setPassportData(undefined));
			dispatch(setProfileData(superRes));
			return;
		}
		default:
			return res;
	}
};