import { IUsefulInfoLinks } from "../redux/Credit/creditTypes";
import { ROUTE_PATH } from "../routesConfig";

const CPZA_LINKS = [
  {
    "code": "cpza",
    "title": "Тарифы и документы",
    "url": "https://www.zenit.ru/personal/tariffs/?tab=credits"
  },
  {
    "code": "cpza",
    "title": "Подробные условия",
    "url": "https://www.zenit.ru/personal/credits/c-credit-standard/#requirements"
  },
  {
    "code": "cpza",
    "title": "Погашение",
    "url": "https://www.zenit.ru/personal/credits/c-credit-standard/#repayment"
  },
  {
    "code": "cpza",
    "title": "Бонусы",
    "url": "https://www.zenit.ru/personal/cards/credit-cards-privilege/#cashback"
  },
]

const CARD_LINKS = [
  {
    "url": "https://www.zenit.ru/personal/credits/c-credit-standard/#requirements",
    "code": "CreditRequirements",
    "title": "Требования к заемщику"
  },
  {
    "url": "https://www.zenit.ru/personal/cards/credit-cards-privilege/Pravila_Akcii_Privedi_druga.pdf",
    "code": "CardsReferralConditions",
    "title": "Подробные условия акции"
  },
  {
    "url": "https://www.zenit.ru/personal/credits/c-credit-standard/#requirements",
    "code": "CreditDocuments",
    "title": "Необходимые документы"
  },
  {
    "url": "https://www.zenit.ru/personal/cards/credit-cards-privilege/#conditions",
    "code": "CardsConditions",
    "title": "Подробные условия"
  },
  {
    "url": "https://www.zenit.ru/personal/credits/c-credit-standard/#repayment",
    "code": "CardsReferralPromotionRules",
    "title": "Погашение"
  },
  {
    "url": "https://www.zenit.ru/personal/cards/credit-cards-privilege/#cashback",
    "code": "CardsReferralBonuses",
    "title": "Бонусы"
  },
  {
    "url": "https://www.zenit.ru/personal/cards/credit-cards-privilege/#cashback",
    "code": "CardsBonuses",
    "title": "Бонусы"
  },
  {
    "url": "https://www.zenit.ru/personal/credits/c-credit-standard/#repayment",
    "code": "CardsPromotionRules",
    "title": "Погашение"
  },
  {
    "url": "https://www.zenit.ru/personal/tariffs/?tab=cards",
    "code": "CardsReferralTariffs",
    "title": "Тарифы и документы"
  },
  {
    "url": "https://www.zenit.ru/personal/credits/c-credit-standard/#repayment",
    "code": "CreditRepayment",
    "title": "Погашение"
  },
  {
    "url": "https://www.zenit.ru/personal/tariffs/?tab=cards",
    "code": "CardsTariffs",
    "title": "Тарифы и документы"
  },
  {
    "url": "https://www.zenit.ru/personal/tariffs/?tab=credits",
    "code": "CreditTariffs",
    "title": "Тарифы и документы"
  }
]

const REFERRAL_CREDITS_LINKS =[
  {
    "url": "https://www.zenit.ru/personal/credits/c-credit-standard/#requirements",
    "code": "CreditRequirements",
    "title": "Требования к заемщику"
  },
  {
    "url": "https://www.zenit.ru/personal/credits/c-credit-standard/#requirements",
    "code": "CreditDocuments",
    "title": "Необходимые документы"
  },
  {
    "url": "https://www.zenit.ru/personal/credits/c-credit-standard/#repayment",
    "code": "CardsPromotionRules",
    "title": "Погашение"
  },
  {
    "url": "https://www.zenit.ru/personal/tariffs/?tab=cards",
    "code": "CardsTariffs",
    "title": "Тарифы и документы"
  },
]

export const onSeparateLinksByProduct = (arr: IUsefulInfoLinks[], product: string, isReferral: boolean) => {
  switch(product) {
    case ROUTE_PATH.CPZA: return CPZA_LINKS
    case ROUTE_PATH.CREDITS: return isReferral ? REFERRAL_CREDITS_LINKS : arr.filter(el => el.code.includes("Credit"))
    case ROUTE_PATH.CARDS: return CARD_LINKS.filter(el => isReferral ?
      el.code.includes("CardsReferral"):
      el.code.includes("Cards") && !el.code.includes("Referral")
    )
    default: return arr
  }
}