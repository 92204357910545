import { FormikValues } from 'formik';
import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { IAdditionalIncomes, IAdvancedOptions, IProfileState2, } from '../../../../redux/Credit/creditTypes';
import { adaptiveParams } from '../../../../styles/adaptiveParams';
import { AddItem } from '../../../../styles/UIKit/AddItem';
import { AnimatedBlock } from '../../../../styles/UIKit/AnimatedBlock';
import { CheckBoxField } from '../../../../styles/UIKit/CustomCheckBox/CheckBoxField';
import { Flex } from '../../../../styles/UIKit/Flex';
import { CloseFeature, Modal } from '../../../../styles/UIKit/Modal/Modal';
import { String } from '../../../../styles/UIKit/String';
import { Title } from '../../../../styles/UIKit/Title';
import { ParagraphWrapper } from '../../../CommonPagesStyles';
import { AdditionalIncomes } from '../AdditionalIncomes';
import { EditItemWrapper } from '../EditItem/EditItemWrapper';
import { ItemsList } from '../ItemsList';

interface IAddAdditionalIncomes {
	formik: FormikValues;
	screenWidth: number;
	setFormDataUpdates: (values: IProfileState2) => void;
	additionalIncomesData: IAdditionalIncomes[];
	setAdditionalIncomesData: (additionalIncomesData: IAdditionalIncomes[]) => void;
	additionalIncomesRef: IAdvancedOptions[];
	standartFontSizeMeasures: string;
	standartHeightMeasures: string;
	standartWidthMeasures: string;
}

export const AddAdditionalIncomes: React.FC<IAddAdditionalIncomes> = (
	props
) => {
	const {
		formik,
		screenWidth,
		setFormDataUpdates,
		additionalIncomesData,
		setAdditionalIncomesData,
		additionalIncomesRef,
		standartFontSizeMeasures,
		standartHeightMeasures,
		standartWidthMeasures,
	} = props;
	const { mainContentWidth } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;

	const [showWorkActivity, setShowWorkActivity] = React.useState<boolean>(false);
	const [chosenItemData, setChosenItemData] = React.useState<IAdditionalIncomes | null>(null);

	const updateFormCallBackForAdditionalIncomesData = (
		formItem: string,
		currentValue: any[]
	) => {
		setFormDataUpdates({
			...formik.values,
			[formItem]: currentValue,
		});
	};

	return (
		<ParagraphWrapper padding={fieldsWrapConditions ? "0" : "10px"}>
			<Flex align={"center"} margin={"20px 0 20px 0"}>
				<CheckBoxField 
					formItem="isAdditionalIncomes" 
					checked={formik.values.isAdditionalIncomes} 
					onChange={() => formik.setFieldValue("isAdditionalIncomes", !formik.values.isAdditionalIncomes)} 
				/>
				<String margin="0 20px">Есть регулярный дополнительный доход</String>
			</Flex>
			{formik.values.isAdditionalIncomes && (
				<>
					<AnimatedBlock>
						<ItemsList
							mappingList={additionalIncomesRef}
							// incomesList={additionalIncomesRef}
							list={additionalIncomesData}
							updateFormCallBackForAdditionalIncomesData={updateFormCallBackForAdditionalIncomesData}
							setList={setAdditionalIncomesData}
							formik={formik}
							renderBy={"occupationAdditionalIncomesKind"}
							editItemById={setChosenItemData}
						/>
					</AnimatedBlock>
					<AnimatedBlock>
						<AddItem
							opstTitle="Добавить источники дохода"
							setShowModal={setShowWorkActivity}
							standartFontSizeMeasures={standartFontSizeMeasures}
							standartHeightMeasures={standartHeightMeasures}
							standartWidthMeasures={"100%"}
							screenWidth={screenWidth}
						/>
					</AnimatedBlock>
					<EditItemWrapper
						incomesList={additionalIncomesRef}
						formik={formik}
						updateFormCallBackForAdditionalIncomesData={updateFormCallBackForAdditionalIncomesData}
						list={additionalIncomesData}
						setList={setAdditionalIncomesData}
						chosenItemData={chosenItemData}
						setChosenItemData={setChosenItemData}
					/>
					<Modal
						show={showWorkActivity}
						width={"1000px"}
					>
						<CloseFeature show={showWorkActivity}>
							<IoMdClose size={30} onClick={() => setShowWorkActivity(false)} />
						</CloseFeature>
						<Title fSize={fieldsWrapConditions ? "30px" : "24px"}>Добавить источник дохода</Title>
						<AdditionalIncomes
							incomesList={additionalIncomesRef}
							updateFormCallBackForAdditionalIncomesData={updateFormCallBackForAdditionalIncomesData}
							formik={formik}
							standartFontSizeMeasures={standartFontSizeMeasures}
							standartHeightMeasures={standartHeightMeasures}
							additionalIncomesData={additionalIncomesData}
							setAdditionalIncomesData={setAdditionalIncomesData}
							setShow={setShowWorkActivity}
						/>
					</Modal>
				</>
			)}
		</ParagraphWrapper>
	);
};