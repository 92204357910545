import React from 'react';
import styled, { keyframes } from 'styled-components';

interface IAnimatedBlock {
	seconds?: string;
	padding?: string;
	width?: string;
	margin?: string;
	background?: string;
	borderRadius?: string;
}

const optionsWindowAnimation = keyframes`
    0% {opacity: 0;}
    100% {opacity: 1;}
`;

export const StyledAnimatedBlock = styled.div<IAnimatedBlock>`
	padding: ${({ padding }) => padding || "0"};
	width: ${({ width }) => width || "auto"};
	margin: ${({ margin }) => margin || "0"};
	background: ${({ background }) => background || "transparent"};
	border-radius: ${({ borderRadius }) => borderRadius || "0"};
	animation: ${optionsWindowAnimation} ${({ seconds }) => seconds || "0.8s"}
		// forwards;
`;

export const AnimatedBlock: React.FC<IAnimatedBlock> = (props) => {
	return <StyledAnimatedBlock {...props} />;
};