import { FormikValues } from 'formik';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {OCUPATION_TYPES_MAPPER, PRODUCTS_CODES} from '../../../../../config';
import { getDadataAddress, getDadataCompany, onValidateParam } from '../../../../../redux/Application/app-reducer';
import {
  setCompanyInnDadata,
  setCompanyNameDadata,
  setPhoneValidationErrorMessage,
  setWorkPlaceAddressList,
  setWorkPlaceLegalAddressList
} from '../../../../../redux/Application/appActions';
import {ICompanyDadataList, ICurrentRoutesParams, IDadataAddressTypes} from '../../../../../redux/Application/appTypes';
import { IProfileConfig2, IProfileState2, } from '../../../../../redux/Credit/creditTypes';
import { AnimatedBlock } from '../../../../../styles/UIKit/AnimatedBlock';
import { CurrancyField } from '../../../../../styles/UIKit/CurrancyField';
import { CheckBoxField } from '../../../../../styles/UIKit/CustomCheckBox/CheckBoxField';
import { CustomMaskedField } from '../../../../../styles/UIKit/CustomField/CustomMaskedField';
import { TelephoneField } from '../../../../../styles/UIKit/CustomField/TelephoneField';
import { AutoCompleteAddresses } from '../../../../../styles/UIKit/dadata/AutoCompleteAddresses';
import { DadataCompanyAutoComplete } from '../../../../../styles/UIKit/dadata/DadataCompanyAutoComplete';
import { FejoaMappedSelect } from '../../../../../styles/UIKit/FejoaSelect/FejoaMappedSelect';
import { Flex } from '../../../../../styles/UIKit/Flex';
import { String } from '../../../../../styles/UIKit/String';
import { onSetFieldBorderColor } from '../../../../../utils/onSetFieldBorderColor';
import { ParagraphWrapper } from '../../../../CommonPagesStyles';
import { PublicServicesBlock } from '../../../../../components/PublicServicesBlock/PublicServicesBlock';
import { EsiaWrapper } from '../../../../PassportDataForm/PassportMainBlock/PassportMainBlock';
import { onAuthEsia } from '../../../../../redux/Auth/auth-reducer';
import { AppRootStateType } from '../../../../../redux/store';

interface IndividualHolder {
	esiaUsage?: boolean;
	formik: FormikValues;
	fieldsWrapConditions: boolean;
	standartWidthMeasures: string;
	standartHeightMeasures: string;
	standartFontSizeMeasures: string;
	profileConfig: IProfileConfig2;
	companyInnDadataList: ICompanyDadataList[];
	workPlaceLegalAddressList: IDadataAddressTypes[];
	workPlaceAddressList: IDadataAddressTypes[];
	companyNamesDadataList: ICompanyDadataList[];
	updateFormCallBack: (
		formItem: string,
		currentValue: string,
		event: FormikValues
	) => void;
	setFormDataUpdates: (values: IProfileState2) => void;
	phoneValidationErrorMessage: string | null;
}

export const IndividualHolder: React.FC<IndividualHolder> = (props) => {
	const {
		esiaUsage,
		formik,
		fieldsWrapConditions,
		standartWidthMeasures,
		standartHeightMeasures,
		standartFontSizeMeasures,
		updateFormCallBack,
		setFormDataUpdates,
		profileConfig,
		companyInnDadataList,
		workPlaceLegalAddressList,
		workPlaceAddressList,
		companyNamesDadataList,
		phoneValidationErrorMessage,
	} = props;

	const { flowId, product } = useSelector<AppRootStateType, ICurrentRoutesParams>((state) => state.appReducer.currentForm);
	const dispatch = useDispatch()
	const [defaultOccupationDocConfirmationIncomesValue, setdefaultOccupationDocConfirmationIncomesValue] = React.useState("Выберите");

	React.useEffect(() => formik.setFieldValue("occupationCompanyCodeOKOPF", "50102"), [formik.values.occupationCompanyCodeOKOPF, formik.values.occupationType])

	// *** INDIVIDUAL HOLDER INN HANDLER ***
	const currentCompanyInn = formik.values.occupationCompanyInn ? formik.values.occupationCompanyInn : "";
	const onChangeCompanyInnHandler = (value: string) => dispatch(getDadataCompany("inn", value, "ИП"));
	const setCompanyInnHandler = (chosenItem: ICompanyDadataList) => {
		formik.setFieldValue("occupationCompanyInn", chosenItem.inn);
		dispatch(setCompanyInnDadata([]));
	};
	const resetCompanyInnDataHandler = () => dispatch(setCompanyInnDadata([]));


	// *** HANDLERS FOR INDIVIDUAL HOLDER LEGAL ADDRESS LIST ***
	const workPlaceLegalAddress = formik.values.occupationCompanyLegalAddress ? formik.values.occupationCompanyLegalAddress.title : "";
	const onChangeWorkPlaceLegalAddressCallback = (value: IDadataAddressTypes) =>
		dispatch(getDadataAddress("address", "title", value.title, "legal_work_place"));
	const setWorkPlaceLegalAddressListOptionHandler = (chosenItem: IDadataAddressTypes) => {
		formik.setFieldValue("occupationCompanyLegalAddress", chosenItem);
		setFormDataUpdates({ ...formik.values, occupationCompanyLegalAddress: chosenItem});
		dispatch(setWorkPlaceLegalAddressList([]));
	};
	const workPlaceLegalAddressListHandler = () => dispatch(setWorkPlaceLegalAddressList([]));


	// *** HANDLERS FOR INDIVIDUAL HOLDER ACTUAL ADDRESS LIST ***
	const workPlaceAddress = formik.values.occupationCompanyAddress ? formik.values.occupationCompanyAddress.title : "";
	const onChangeWorkPlaceAddressCallback = (value: IDadataAddressTypes) =>
		dispatch(getDadataAddress("address", "title", value.title, "work_place"));
	const setWorkPlaceAddressListOptionHandler = (chosenItem: IDadataAddressTypes) => {
		formik.setFieldValue("occupationCompanyAddress", chosenItem);
		setFormDataUpdates({ ...formik.values, occupationCompanyAddress: chosenItem,});
		dispatch(setWorkPlaceAddressList([]));
	};
	const workPlaceActualAddressListHandler = () => dispatch(setWorkPlaceAddressList([]));


	// ***  INDIVIDUAL HOLDER COMPANY NAME HANDLER ***
	const currentCompanyName = formik.values.occupationCompanyName ? formik.values.occupationCompanyName : "";
	const onChangeCompanyNameHandler = (value: string) => dispatch(getDadataCompany("name", value, "ИП"));
	const setCompanyNameHandler = (chosenItem: ICompanyDadataList) => {
		formik.setFieldValue("occupationCompanyName", chosenItem.name);
		formik.setFieldValue("occupationCompanyInn", chosenItem.inn);
		formik.setFieldValue("occupationCompanyLegalAddress", chosenItem.address);
		formik.setFieldValue("occupationCompanyOgrn", chosenItem.ogrn);
		formik.setFieldValue("occupationCompanyBranchType", chosenItem.branchType);
		formik.setFieldValue("occupationCompanyCodeOkved", chosenItem.okved);
		formik.setFieldValue("occupationCompanyStatus", chosenItem.status);
		// formik.setFieldValue("occupationCompanyCodeOKOPF", chosenItem.okopf);
		formik.setFieldValue("occupationCompanyOrgType", chosenItem.type);
		formik.setFieldValue("occupationCompanyKpp", chosenItem.kpp);
		dispatch(setCompanyNameDadata([]));
	};
	const resetCompanyNameDataHandler = () => dispatch(setCompanyNameDadata([]));

	const onBlurPhoneHandler = (formItem: string, currentValue: string, event: FormikValues) => {
		let promise;
		if (formItem) updateFormCallBack(formItem, currentValue, event);
		if (formik.values.occupationPhoneNumber) {
			dispatch(setPhoneValidationErrorMessage(null));
			promise = dispatch(onValidateParam({paramValue: formik.values.occupationPhoneNumber, searchParam: "phone"}));
		} else {
			promise = dispatch(setPhoneValidationErrorMessage(null));
		}
		return promise;
	}

	// *** Подстановка значений документа подтверждения дохода при нажатии чекбокса ***
	const onOccupationDocConfirmationIncomesUpdateHandler = () => {
		formik.setFieldValue("isReadyConfirmIncomes", !formik.values.isReadyConfirmIncomes);
		if(formik.values.occupationType === "PrivateEntrepreneur")
		{
			const defaultValue = profileConfig.occupationDocConfirmationIncomesRef.find((x) => x.code==='StatementPFR' && x.parentCode==='PrivateEntrepreneur')
			defaultValue && setdefaultOccupationDocConfirmationIncomesValue(defaultValue.title);
			formik.setFieldValue("occupationDocConfirmationIncomes",defaultValue?.code)
		}
    };

	// @ts-ignore
	window.occupationPhoneNumberHandler = onBlurPhoneHandler;

	const onEsia = () => flowId && product && dispatch(onAuthEsia(flowId, PRODUCTS_CODES[product]));

	return (
		<ParagraphWrapper padding={fieldsWrapConditions ? "0" : "10px"}>
			<DadataCompanyAutoComplete
				formik={formik}
				formName={"occupationCompanyName"}
				onBlurHandler={updateFormCallBack}
				width={"100%"}
				height={standartHeightMeasures}
				fSize={standartFontSizeMeasures}
				label={"Полное наименование ИП"}
				dadataList={companyNamesDadataList}
				currentValue={currentCompanyName}
				onChangeHandler={onChangeCompanyNameHandler}
				onMouseDownHandler={setCompanyNameHandler}
				ResetListHandler={resetCompanyNameDataHandler}
				paramKey={"name"}
				error={ formik.touched.occupationCompanyName && !!formik.errors.occupationCompanyName }
				borderColor={ onSetFieldBorderColor(formik, "occupationCompanyName") }
				errorValue={ formik.touched.occupationCompanyName && formik.errors.occupationCompanyName }
			/>
			<Flex
				direction={fieldsWrapConditions ? "row" : "column"}
				width={fieldsWrapConditions ? "750px" : "100%"}
				justify="space-between"
				align={fieldsWrapConditions ? "center" : "none"}
				margin="12px 0"
			>
				<DadataCompanyAutoComplete
					formik={formik}
					formName={"occupationCompanyInn"}
					onBlurHandler={updateFormCallBack}
					width={standartWidthMeasures}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					label={"ИНН"}
					dadataList={companyInnDadataList}
					currentValue={currentCompanyInn}
					onChangeHandler={onChangeCompanyInnHandler}
					onMouseDownHandler={setCompanyInnHandler}
					ResetListHandler={resetCompanyInnDataHandler}
					paramKey={"inn"}
					error={ formik.touched.occupationCompanyInn && !!formik.errors.occupationCompanyInn }
					borderColor={ onSetFieldBorderColor(formik, "occupationCompanyInn") }
					mask={ [ /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/ ]}
					errorValue={ formik.touched.occupationCompanyInn && formik.errors.occupationCompanyInn }
					type={"tel"}
				/>
				<CustomMaskedField
					width={standartWidthMeasures}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					onBlurHandler={updateFormCallBack}
					formItem={"occupationCompanyOgrn"}
					{...formik.getFieldProps("occupationCompanyOgrn")}
					label={"ОГРНИП"}
					error={formik.touched.occupationCompanyOgrn && !!formik.errors.occupationCompanyOgrn}
					borderColor={ onSetFieldBorderColor(formik, "occupationCompanyOgrn") }
					mask={[ /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/ ]}
					errorValue={ formik.touched.occupationCompanyOgrn && formik.errors.occupationCompanyOgrn }
				/>
			</Flex>
			<AutoCompleteAddresses
				formik={formik}
				onBlurHandler={updateFormCallBack}
				formName={"occupationCompanyLegalAddress"}
				width={"100%"}
				height={standartHeightMeasures}
				fSize={standartFontSizeMeasures}
				label={"Юридический адрес"}
				dadataAddressList={workPlaceLegalAddressList}
				currentValue={workPlaceLegalAddress}
				onChangeHandler={onChangeWorkPlaceLegalAddressCallback}
				onMouseDownHandler={setWorkPlaceLegalAddressListOptionHandler}
				ResetListHandler={workPlaceLegalAddressListHandler}
				error={ formik.touched.occupationCompanyLegalAddress && !!formik.errors.occupationCompanyLegalAddress }
				borderColor={ onSetFieldBorderColor(formik, "occupationCompanyLegalAddress") }
				errorValue={ formik.touched.occupationCompanyLegalAddress && formik.errors.occupationCompanyLegalAddress }
			/>
			<Flex
				align={"center"}
				margin={"20px 0"}
				padding={fieldsWrapConditions ? "0" : "10px"}
			>
				<CheckBoxField
					formItem="occupationCompanyIsActualMutchLegal" 
					checked={formik.values.occupationCompanyIsActualMutchLegal} 
					onChange={() => formik.setFieldValue("occupationCompanyIsActualMutchLegal", !formik.values.occupationCompanyIsActualMutchLegal)} 
				/>
				<String margin="0 20px">
					Фактический адрес совпадает с юридическим
				</String>
			</Flex>
			{!formik.values.occupationCompanyIsActualMutchLegal && (
				<AnimatedBlock>
					<AutoCompleteAddresses
						formik={formik}
						onBlurHandler={updateFormCallBack}
						formName={"occupationCompanyAddress"}
						width={"100%"}
						height={standartHeightMeasures}
						fSize={standartFontSizeMeasures}
						label={"Фактический адрес"}
						dadataAddressList={workPlaceAddressList}
						currentValue={workPlaceAddress}
						onChangeHandler={onChangeWorkPlaceAddressCallback}
						onMouseDownHandler={setWorkPlaceAddressListOptionHandler}
						ResetListHandler={workPlaceActualAddressListHandler}
						error={ formik.touched.occupationCompanyAddress && !!formik.errors.occupationCompanyAddress }
						borderColor={ onSetFieldBorderColor(formik, "occupationCompanyAddress") }
						errorValue={ formik.touched.occupationCompanyAddress && formik.errors.occupationCompanyAddress }
					/>
				</AnimatedBlock>
			)}
			<Flex
				direction={fieldsWrapConditions ? "row" : "column"}
				width={fieldsWrapConditions ? "750px" : "100%"}
				justify="space-between"
				align={fieldsWrapConditions ? "center" : "none"}
				margin="12px 0"
			>
				<CurrancyField
					formik={formik}
					onBlurHandler={updateFormCallBack}
					fieldName={"occupationMonthlyIncomesValue"}
					width={standartWidthMeasures}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					label={"Размер ежемесячного дохода (руб.)"}
					error={ formik.touched.occupationMonthlyIncomesValue && !!formik.errors.occupationMonthlyIncomesValue }
					borderColor={ onSetFieldBorderColor(formik, "occupationMonthlyIncomesValue") }
					errorValue={ formik.touched.occupationMonthlyIncomesValue && formik.errors.occupationMonthlyIncomesValue }
				/>
				<TelephoneField
					label="Рабочий телефон (Необязательно)"
					width={standartWidthMeasures}
					height={standartHeightMeasures}
					fSize={standartFontSizeMeasures}
					onBlurHandler={onBlurPhoneHandler}
					formItem={"occupationPhoneNumber"}
					{...formik.getFieldProps("occupationPhoneNumber")}
					error={ formik.touched.occupationPhoneNumber && !!formik.errors.occupationPhoneNumber }
					borderColor={ onSetFieldBorderColor(formik, "occupationPhoneNumber") }
					errorValue={ phoneValidationErrorMessage || (formik.touched.occupationPhoneNumber && formik.errors.occupationPhoneNumber) }
				/>
			</Flex>
			<Flex align={"center"} margin={"20px 0"}>
				<CheckBoxField 
					formItem="isReadyConfirmIncomes" 
					checked={formik.values.isReadyConfirmIncomes} 
					onChange={() => onOccupationDocConfirmationIncomesUpdateHandler()} 
				/>
				<String margin="0 20px">
					Готов подтвердить доход
				</String>
			</Flex>
			{formik.values.isReadyConfirmIncomes && (
				<AnimatedBlock>
					<FejoaMappedSelect
						formik={formik}
						updateFormCallBack={updateFormCallBack}
						formItem={"occupationDocConfirmationIncomes"}
						height={standartHeightMeasures}
						width={"100%"}
						fSize={standartFontSizeMeasures}
						options={
							profileConfig.occupationDocConfirmationIncomesRef
								.filter((el) => el.parentCode && el.parentCode === OCUPATION_TYPES_MAPPER.PrivateEntrepreneur)
								.sort((a, b) => a.title < b.title ? -1 : 1)
						}
						defaultValue={"Выберите"}
						label={"Документ для подтверждения дохода"}
						borderColor={ onSetFieldBorderColor(formik, "occupationDocConfirmationIncomes") }
						errorValue={ formik.touched.occupationDocConfirmationIncomes && formik.errors.occupationDocConfirmationIncomes }
					/>
					{
						!esiaUsage && product && formik.values.occupationDocConfirmationIncomes === 'StatementPFR' && <AnimatedBlock>
							<Flex>
								<EsiaWrapper product={product}>
									<PublicServicesBlock onEsia={onEsia} isIncomeTexts={true} />
								</EsiaWrapper>
							</Flex>
						</AnimatedBlock>
					}
				</AnimatedBlock>
			)}
		</ParagraphWrapper>
	);
};