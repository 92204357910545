import React, {ReactElement} from "react";
import moneyPhone from "../../../assets/money-phone.webp";
import {goToTheQuestionnaire} from "../../../utils/goToTheQuestionnaire";
import './PromoCreditDefault.css';

interface PromoCreditDefaultProps {
  children?: ReactElement
}

export const PromoCreditDefault = (props: PromoCreditDefaultProps) => {
  const { children } = props;

  return (
    <div className={"promo-credit-default"}>
      <div className={"promo-credit-default_inner container"}>
        <div className={"promo-credit-default__info-block"}>
          <div className={"promo-credit-default__info"}>
            <div className={"promo-credit-default__title"}>
              Кредит наличными
            </div>
            <div className={"promo-credit-default__sub-title"}>
              по выгодной ставке!
            </div>
            <a className={"promo-credit-default__btn promo__btn"}
               href="#anketa"
               onClick={goToTheQuestionnaire}
            >
              Оформить
            </a>
          </div>
          <div className={"promo-credit-default__img"}>
            <img src={moneyPhone} alt="big percent"/>
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}