import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate}  from 'react-router-dom';
import { LoacalData } from "../../api/localStorage";
import { useCallTouch } from "../../hooks/useCallTouch";
import useDimensions from "../../hooks/useDimensions";
import { getDeclarationsList } from "../../redux/Application/app-reducer";
import { setCurrentForm, setOrederCatalog } from "../../redux/Application/appActions";
import { ICreditDeclarations } from "../../redux/Application/appTypes";
import { setGeneratedSmsData, setValidateSmsData } from "../../redux/Auth/authActions";
import { AppRootStateType } from "../../redux/store";
import { ROUTE_PATH } from "../../routesConfig";
import { Button } from "../../styles/UIKit/Button";
import { CheckIsAuth } from "../../styles/UIKit/CheckIsAuth";
import { Flex } from "../../styles/UIKit/Flex";
import { CabinetCards } from "./components/CabinetCards";
import { OrdersEmpty } from '../Orders/OrdersEmpty';

export const CabinetContainer = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const declarations = useSelector<AppRootStateType, ICreditDeclarations[] | null>((state) => state.appReducer.lkzList);

	const localData = new LoacalData();

	const accessToken = localData.getToken("accessToken");

	const { screenWidth } = useDimensions();

	React.useEffect(() => {
		if (accessToken && !declarations) {
			dispatch(getDeclarationsList())
		}
		dispatch(
			setCurrentForm({
				product: undefined,
				formId: "orders",
				flowId: undefined,
			})
		);
	}, [dispatch, declarations]);

	// React.useEffect(() => {
	// 	return () => {
	// 		dispatch(setOrederCatalog([]));
	// 	};
	// }, []);

	React.useEffect(() => {
		return () => {
			// localData.removeToken("accessToken");
			// localData.removeToken("refreshToken");
			dispatch(setOrederCatalog(null));
		}
	}, [])

	const onCreateNewDeclaration = () => {
		dispatch(setGeneratedSmsData(undefined));
		dispatch(setValidateSmsData(undefined))
		// localData.removeToken("accessToken");
		// localData.removeToken("refreshToken");
		// dispatch(setOrederCatalog(null));
		dispatch(
			setCurrentForm({
				product: ROUTE_PATH.CREDITS,
				formId: undefined,
				flowId: undefined,
			})
		);
		navigate(`/${ROUTE_PATH.CREDITS}/${ROUTE_PATH.START}`)
	}

	useCallTouch()

	return (
		<Flex justify="center" align="center" direction="column" padding={screenWidth > 575 ? '120px 30px' : '120px 0'}>
			{!accessToken && <CheckIsAuth header="Авторизация" />}
			{(declarations) && (
				<CabinetCards
					creditDeclarations={declarations}
					screenWidth={screenWidth}
					onCreateNewDeclaration={onCreateNewDeclaration}
				/>
			)}
		</Flex>
	);
}