import { IoMdClose } from 'react-icons/io';
import styled from 'styled-components';
import { Devider } from '../../../styles/UIKit/Devider';
import { FileButton } from '../../../styles/UIKit/file_loader/FileButton';
import { CloseFeature, Modal } from '../../../styles/UIKit/Modal/Modal';
import { Title } from '../../../styles/UIKit/Title';

interface IFileLoader {
	show: boolean;
	setShaow: (show: boolean) => void;
}

const StyledParagraph = styled.p`
  padding: 30px;
`;

export const FileLoader: React.FC<IFileLoader> = (props) => {
	const { show, setShaow } = props;
	return (
		<Modal show={show}>
			<CloseFeature show={show}>
				<IoMdClose size={30} onClick={() => setShaow(false)} />
			</CloseFeature>
			<Title margin="0 0 20px 0">Нужна справка о доходах</Title>
			<Devider />
			<StyledParagraph>
				Информационный текст Информационный текст Информационный текст
				Информационный текст Информационный текст Информационный текст
			</StyledParagraph>
            {/* <input type="file" /> */}
			<FileButton buttonTitle="Добавить Файл" handleFile={() => {} } />
		</Modal>
	);
};