import React from "react";
import {Divider} from "../Divider";
import awardIMG from "../../../assets/award.svg";
import cardReceiveIMG from "../../../assets/card-receive.svg";
import emptyWalletTickIMG from "../../../assets/empty-wallet-tick.svg";
import percentageCircleIMG from "../../../assets/percentage-circle.svg";
import tickCircleIMG from "../../../assets/tick-circle.svg";
import walletAddIMG from "../../../assets/wallet-add.svg";
import AnalogClockIMG from "../../../assets/analog-clock.svg";

type BannerInfo = {
  icon: string;
  title: string;
  subtitle: string;
  isDivided?: boolean;
}

type InfoDictionary = {
  cards: Array<BannerInfo[]>,
  credits: Array<BannerInfo[]>,
}

type CardBannerInfoReferralProps = {
  utmGift?: number;
  isMobile?: boolean;
  product?: "cards" | "credits";
}

export const CardBannerInfoReferral = ({utmGift, isMobile, product = "cards"}: CardBannerInfoReferralProps) => {
  const dictionary: InfoDictionary = {
    cards: [
      [
        {
          icon: emptyWalletTickIMG,
          title: "120 + 120 дней без %",
          subtitle: "два льготных периода: на покупки + снятие наличных и переводы",
        },
        {
          icon: cardReceiveIMG,
          title: "до 50% кешбэк",
          subtitle: "у партнеров + кешбэк до 7% по карте",
          isDivided: true,
        },
        {
          icon: percentageCircleIMG,
          title: "0% на наличные",
          subtitle: "снятие и переводы",
          isDivided: true,
        },
      ],
      [
        {
          icon: tickCircleIMG,
          title: "0 ₽",
          subtitle: "обслуживание и выпуск без каких-либо условий",
        },
        {
          icon: awardIMG,
          title: "500 ₽",
          subtitle: "в подарок на ваш бонусный счет",
          isDivided: true,
        },
        {
          icon: walletAddIMG,
          title: "До 2 млн ₽",
          subtitle: "сумма лимита",
          isDivided: true,
        },
      ]
    ],
    credits: [
      [
        {
          icon: emptyWalletTickIMG,
          title: "До 5 млн ₽",
          subtitle: "сумма кредита",
        },
        {
          icon: AnalogClockIMG,
          title: "До 5 лет",
          subtitle: "срок кредита",
          isDivided: true,
        },
        {
          icon: tickCircleIMG,
          title: "За 1 визит",
          subtitle: "в офис при оформлении онлайн",
          isDivided: true,
        },
      ],
    ]
  }

  return (
    <>
      {(dictionary[product] || []).map((row) => (
        <div className="card-info-row">
          {(row.map((item) =>
            <>
              {item.isDivided && !isMobile && <Divider/>}
              <div className="card-info-item">
                <div className="card-info__icon">
                  <img src={item.icon} alt=""/>
                </div>
                <div className="card-info-texts">
                  <h6 className="card-info__title">{item.title}</h6>
                  <p className="card-info__subtitle">{item.subtitle}</p>
                </div>
              </div>
            </>
          ))}
        </div>
      ))}
    </>
  )
}