import React from 'react';
import { adaptiveParams } from '../../../styles/adaptiveParams';
import { MonthlyPayment } from '../../../styles/UIKit/MonthlyPayment/MonthlyPayment';

export const ProfileSecondaryBlock: React.FC<{ screenWidth: number, product: string, flowId: string, userPhone: string }> = ({
	screenWidth,
	product,
	flowId,
	userPhone,
}) => {
	const fieldsWrapConditions: boolean = screenWidth > adaptiveParams.mainContentWidth;
	return (
		<>
			{fieldsWrapConditions && <MonthlyPayment
				product={product}
				screenWidth={screenWidth}
				flowId={flowId}
				userPhone={userPhone}
			/>}
		</>
	);
};