import styled from 'styled-components';
import { adaptiveParams } from '../../../styles/adaptiveParams';
import { Flex } from '../../../styles/UIKit/Flex';
import { FormatCurrancy } from '../../../utils/commonFunctions';

const Elem = styled.div<IElem>`
	// border: 1px solid green;
	// background: red;
	font-family: Formular;
	width: 100%;
	display: flex;
	justify-content: ${({ justify }) => justify || "start"};
	align-items: center;
	margin: ${({ margin }) => margin || "0"};
	padding: ${({ padding }) => padding || "0"};
`;

interface ICreditsList {
	creditsList: any[];
	setInletCreditsList: (inletCreditsList: any[]) => void;
	screenWidth: number;
}

interface IElem {
	justify: "start" | "end" | "center";
	margin?: string;
	padding?: string;
}

export const CreditsList: React.FC<ICreditsList> = (props) => {
	const { creditsList, setInletCreditsList, screenWidth } = props;

	const { minimalWrap } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > minimalWrap;

	return (
		<Flex
			direction="column"
			maxWidth={"700px"}
			margin={"0 auto"}
			backGround="#f0f0f0"
			// Border="1px solid grey"
			BorderRadius={"20px"}
		>
			{fieldsWrapConditions && <Flex padding={"20px 0"} justify="space-between">
				<Elem justify="center">Дата договора</Elem>
				<Elem justify="center">Тип кредита</Elem>
				<Elem justify="center">Сумма к погашению</Elem>
				<Elem justify="center">Выбор</Elem>
			</Flex>}
			{creditsList.map((item, i) => {
				const checkboxHandler = (id: string, checked: boolean) => {
					setInletCreditsList(
						creditsList.map((el) =>
							el.id === id ? { ...el, isChecked: checked } : el
						)
					);
				};
				return (
					<Flex
						justify="space-between"
						direction={fieldsWrapConditions ? "row" : "column"}
						key={item.id}
						padding={"20px 0"}
						backGround="#dbdbdb"
					>
						<Elem justify="center">
							{new Date(item.date).toLocaleDateString("ru-RU")}
						</Elem>
						<Elem justify="center">{item.title}</Elem>
						<Elem justify="center">
							{FormatCurrancy(Math.ceil(item.amount))} ₽
						</Elem>
						<Elem justify="center">
							{/* <CheckBoxArray
								id={item.id}
								checked={item.isChecked}
								callback={checkboxHandler}
							/> */}
						</Elem>
					</Flex>
				);
			})}
		</Flex>
	);
};