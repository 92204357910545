import styled from 'styled-components';
import { Flex } from '../../../styles/UIKit/Flex';
import { String } from '../../../styles/UIKit/String';
import { Title } from '../../../styles/UIKit/Title';
import { FormatCurrancy } from '../../../utils/commonFunctions';

const CreditParamsBlockWpapper = styled.div`
    width: 440px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 40px;
`;

const data = [
    {id: "1", title: "Итоговая сумма кредита", amount: 1058304},
    {id: "2", title: "Ставка кредитования", amount: 5.5},
    {id: "3", title: "Ежемесячный платеж", amount: 44096},
    {id: "4", title: "Ежемесячный платёж на период отсрочки", amount: 44096},
    {id: "5", title: "Ежемесячный платёж после отсрочки", amount: 44096},
    {id: "6", title: "Стоимость страхования жизни", amount: 7587},
    {id: "7", title: "Стоимость прочих услуг", amount: 1579},
]

export const CreditParamsBlock = () => {
    return <CreditParamsBlockWpapper>
        <Title>Параметры кредита</Title>
        {data.map(({id, title, amount}) => {
            return (
                <Flex width="100%" justify="space-between" margin="9px 0" key={id}>
                    <String maxWidth="220px">{title}</String>
                    <String>{FormatCurrancy(amount)} ₽</String>
                </Flex>
            )
        })}
    </CreditParamsBlockWpapper>
}