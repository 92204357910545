import React from 'react';
import {useFormik} from 'formik';
import {ICreditInitState, ISubmitCreditConfig,} from '../../redux/Credit/creditTypes';
import {MonthlyPayment} from '../../styles/UIKit/MonthlyPayment/MonthlyPayment';
import {Title} from '../../styles/UIKit/Title';
import {MainBlockWrapper, SecondaryBlockWrapper, StyledPageContainer,} from '../CommonPagesStyles';
import {FirstInformativeBlock} from './components/FirstInformativeBlock';
import {Ranges} from './components/Ranges';
import {UserDataFields} from './components/UserDataFields';
import {useDispatch, useSelector} from 'react-redux';
import {creditValidator} from './creditValidator';
import {initialLogin} from '../../redux/Auth/auth-reducer';
import {TCommonPostCurrentForm} from '../../api/Credit/creditApiTypes';
import {adaptiveParams} from '../../styles/adaptiveParams';
import useFormSaver from '../../hooks/useFormSaver';
import {Flex} from '../../styles/UIKit/Flex';
import {fioParser} from '../helper';
import {useProductSetter} from '../../hooks/useProductSetter';
import {IDadataAddressTypes, IFioDadataList} from '../../redux/Application/appTypes';
import {Modal} from '../../styles/UIKit/Modal/Modal';
import {String} from '../../styles/UIKit/String';
import {ROUTE_PATH} from '../../routesConfig';
import {IReferalQuery} from './SubmitCreditContainer';
import {Button} from '../../styles/UIKit/Button';
import {PublicServicesBlock} from '../../components/PublicServicesBlock/PublicServicesBlock';
import {IFingerPrintData} from '../../redux/commonTypes';
import {useFingerPrint} from '../../hooks/useFingerPrint';
import {setFioList} from '../../redux/Application/appActions';
import {getInitialCreditData} from "../../redux/Credit/credit-reducer";
import {buildFieldsObject} from "../../utils/getQueryParams";
import {useSearchParams} from "react-router-dom";
import {useValidationScroll} from '../../hooks/useValidationScroll';
import {pushDataLayerEvent} from "../../utils/dataLayer";
import {defaultTheme} from "../../styles/themes/themes";
import styled from "styled-components";
import {AppRootStateType} from "../../redux/store";
import {IGenerate} from "../../redux/Auth/authTypes";

interface ISubmitCredit {
    esiaData: Object | null
    queryData?: IReferalQuery | null;
    screenWidth: number;
    flowId: string;
    codeId: number | undefined;
    formId: string;
    config: ISubmitCreditConfig;
    initialCreditState: ICreditInitState;
    smsCodeValue: string;
    setSmsCodeValue: (smsCodeValue: string) => void;
    isAgree: boolean;
    setIsAgree: (isAgree: boolean) => void;
    antifrodLife: number | undefined;
    product: string;
    citiesList: IDadataAddressTypes[];
    fioBlackListErrorMessage: string | null;
    phoneBlackListErrorMessage: string | null;
    phoneValidationErrorMessage: string | null;
    emailValidationErrorMessage: string | null;
    fioList: IFioDadataList[];
    onEsia: () => void;
    isCard: boolean;
    hidePublicServices?: boolean;
    isReferral?: boolean;
}

const PublicServicesBlockTitle = styled.h2<{textAlign?: string}>`
  text-align: ${(props => props.textAlign || 'center')};
  font-family: Formular;
  font-size: 23px;
  margin: 31px 0 27px;
`

export const SubmitCredit: React.FC<ISubmitCredit> = (props) => {
    const {
        queryData,
        screenWidth,
        flowId,
        formId,
        config,
        codeId,
        initialCreditState,
        smsCodeValue,
        setSmsCodeValue,
        isAgree,
        setIsAgree,
        antifrodLife,
        product,
        citiesList,
        esiaData,
        fioBlackListErrorMessage,
        phoneBlackListErrorMessage,
        phoneValidationErrorMessage,
        emailValidationErrorMessage,
        fioList,
        onEsia,
        isCard,
        isReferral,
    } = props;


    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const {mainContentWidth} = adaptiveParams;
    const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;
    const isMobile = screenWidth <= 767;
    const generate = useSelector<AppRootStateType, IGenerate | undefined>((state) => state.authReducer.generate);

    const initialState = {
        ...initialCreditState,
        isFatherName: initialCreditState.isFatherName
            ? initialCreditState.isFatherName
            : false,
    };

    const fingerprint: IFingerPrintData | null = useFingerPrint()
    const [smsPopup, setSmsPopup] = React.useState(false);
    const creditFormData = useFormik<ICreditInitState>({
        initialValues: queryData ? {...initialState, ...queryData} : initialState,
        validate: (values) => creditValidator(values, config.minAllowedAge, config.maxAllowedAge),
        onSubmit: (values) => {
            if (formId !== "start") {
                window.scrollTo(0, 0);
            }
            if (values.phoneNumber && flowId && formId && values.term) {
                const state = {
                    ...values,
                    lastName: fioParser(values.fio).lastName,
                    firstName: fioParser(values.fio).firstName,
                    middleName: fioParser(values.fio).middleName,
                    esiaData,
                    rate: product === 'cards' ? config.creditcardRate : config.rate,
                };
                const payload: TCommonPostCurrentForm = {formId, state};
                const onSuccess = () => {
                    pushDataLayerEvent(`${product}_sms1_verification_success`);
                    setSmsPopup(false);
                    setSmsCodeValue("");
                }
                dispatch(initialLogin(
                    values.phoneNumber,
                    values.fio,
                    smsCodeValue,
                    codeId,
                    flowId,
                    "validateWithSign",
                    payload,
                    product,
                    fingerprint,
                    onSuccess
                ));
                dispatch(setFioList([]));
            }
        },
        enableReinitialize: true,
    });
    useValidationScroll(creditFormData);

    useProductSetter(creditFormData, product)

    const setFormDataUpdates = useFormSaver<ICreditInitState>(
        creditFormData.values,
        ROUTE_PATH.START,
        flowId
    );

    const AMOUNT_DEFAULT_VALUES = {
        [ROUTE_PATH.CREDITS]: config.creditDefaultAmount || config.minCreditAmount,
        [ROUTE_PATH.CPZA]: config.creditDefaultAmount || config.minCreditAmount,
        [ROUTE_PATH.CARDS]: config.creditcardDefaultAmount || config.creditcardMinAmount,
    }

    const getDefaultTermValue = ({product, isReferral}: {product: string, isReferral?: boolean}) => {
            if(!isReferral && product === ROUTE_PATH.CREDITS) return 60;
            if(!isReferral && product === ROUTE_PATH.CPZA) return 84;

            if(product === ROUTE_PATH.CREDITS) return config.creditDefaultTerm || config.minCreditTerm;
            if(product === ROUTE_PATH.CPZA) return config.creditDefaultTerm;
            if(product === ROUTE_PATH.CARDS) return config.creditDefaultTerm;
    }


    React.useEffect(() => {
        if (creditFormData.values.amount === null) {
            creditFormData.setFieldValue("amount", AMOUNT_DEFAULT_VALUES[product as keyof typeof AMOUNT_DEFAULT_VALUES]);
        }
        if (creditFormData.values.term === null && config) {
            creditFormData.setFieldValue("term", getDefaultTermValue({product, isReferral}));
        }
    }, [creditFormData.values.amount, creditFormData.values.term]);

    const disabledModeForConfirmationPhone = !!creditFormData.errors.signConsent ||
        !!fioBlackListErrorMessage ||
        !!phoneBlackListErrorMessage ||
        !!phoneValidationErrorMessage ||
        !!emailValidationErrorMessage;

    const onUnavailable = () => {
        dispatch(getInitialCreditData("credit", product, {
            ...creditFormData.values,
            issuingCity: null,
            isCityAvailableForLoanIssuing: true,
        }));
    };

    React.useEffect(() => {
        setSearchParams(buildFieldsObject(creditFormData.values));
    }, [creditFormData.values]);

    return (
        <StyledPageContainer padding="90px 0 0 0">
            <form onSubmit={creditFormData.handleSubmit}>
                <MainBlockWrapper>
                    {!(generate?.success) &&
                        <>
                            <FirstInformativeBlock screenWidth={screenWidth} product={product} isCard={isCard}/>
                            <Ranges
                                screenWidth={screenWidth}
                                setFormDataUpdates={setFormDataUpdates}
                                creditFormData={creditFormData}
                                config={config}
                                currentRate={config.preferentialRate}
                                nextRate={product === 'cards' ? config.creditcardRate : config.rate}
                                preferentialTerm={config.preferentialTerm}
                                product={product}
                            />
                      </>
                    }
                    {screenWidth <= 1190 && <>
                        <br/><br/><br/>
                        <MonthlyPayment
                            product={product}
                            screenWidth={screenWidth}
                            flowId={flowId}
                        />
                        {config.esiaenable && !props.hidePublicServices && <PublicServicesBlock onEsia={onEsia}/>}
                    </>}
                    <div id="contacts_info"/>

                    { isMobile && !isReferral && isCard && <>
                        {<div className={"container"}>
                            <PublicServicesBlockTitle textAlign={screenWidth < 520 ? 'left' : undefined}>
                                Заполните онлайн-заявку и получите кредитную карту
                            </PublicServicesBlockTitle>
                        </div>}

                        {config && config.esiaenable && (
                            <PublicServicesBlock
                                onEsia={onEsia}
                                isCard={isCard}
                                textAlignLeft={product === 'cards' && screenWidth < 750}
                            />
                        )}
                    </>}
                    {!(generate?.success) &&
                    <Title
                        margin={fieldsWrapConditions ? "36px 0 16px" : "36px 10px 16px"}
                        fSize={fieldsWrapConditions ? "24px" : "24px"}
                        color={"#0A1244"}
                    >
                        Контактная информация
                    </Title>
                    }
                    <UserDataFields
                        initialState={initialState}
                        setFormDataUpdates={setFormDataUpdates}
                        antifrodLife={antifrodLife}
                        updateFormHandler={setFormDataUpdates}
                        screenWidth={screenWidth}
                        flowId={flowId}
                        creditFormData={creditFormData}
                        disabledModeForConfirmationPhone={disabledModeForConfirmationPhone}
                        smsCodeValue={smsCodeValue}
                        setSmsCodeValue={setSmsCodeValue}
                        isAgree={isAgree}
                        setIsAgree={setIsAgree}
                        downloadDocumentsData={config.consentFiles}
                        product={product}
                        citiesList={citiesList}
                        siteKey={config.siteKey}
                        smsPopup={smsPopup}
                        setSmsPopup={setSmsPopup}
                        fioBlackListErrorMessage={fioBlackListErrorMessage}
                        phoneBlackListErrorMessage={phoneBlackListErrorMessage}
                        phoneValidationErrorMessage={phoneValidationErrorMessage}
                        emailValidationErrorMessage={emailValidationErrorMessage}
                        fioList={fioList}
                    />
                    <Modal show={!creditFormData.values.isCityAvailableForLoanIssuing}>
                        <Title align="center" margin={"0 0 20px 0"}>В вашем городе оформление кредита невозможно</Title>
                        <Flex justify="center" margin="10px 0">
                            <String fSize="20px">Выберите город доступный для выдачи</String>
                        </Flex>
                        <Flex justify="center" margin="10px 0 0 0">
                            <String fSize="20px">
                                <a href="https://www.zenit.ru/offices/list/" target={"_blank"}
                                   style={{color: defaultTheme.appColors.commonTheme}} rel="noreferrer">
                                    Адреса доступных отделений
                                </a>
                            </String>
                        </Flex>
                        <Flex justify="center" margin="20px 0 0 0">
                            <Button type="button" mode="primary" width="100%" onClick={onUnavailable}>OK</Button>
                        </Flex>
                    </Modal>
                </MainBlockWrapper>
            </form>
            {
                screenWidth > 1190 && <SecondaryBlockWrapper>
                    <MonthlyPayment
                        product={product}
                        screenWidth={screenWidth}
                        flowId={flowId}
                    />
                </SecondaryBlockWrapper>
            }
        </StyledPageContainer>
    );
};
