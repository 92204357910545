import { FormikValues } from 'formik';
import { IoMdClose } from 'react-icons/io';
import { Button } from '../Button';
import { CloseFeature, Modal } from './Modal';
import { Title } from '../Title';
import { Flex } from '../Flex';
import { setAntifrodTimer, setGeneratedSmsData, setValidateSmsData, } from '../../../redux/Auth/authActions';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { String } from '../String';
import { AntifrodTimer } from './components/AntifrodTimer';
import { AppRootStateType } from '../../../redux/store';
import { InputCode } from '../../../components/InputCode/InputCode';
import styled from "styled-components";
import {SmsForm, SMSFormProps} from "./SmsForm";



const SmsFormWrapper = styled.div`
	padding: 80px 10px;
`

export const SmsModal: React.FC<SMSFormProps> = (props) => {

	const {
		screenWidth,
		show,
		formItem: formItem,
	} = props;

	const isWrapped = screenWidth > 450

	return (
		<SmsFormWrapper>
			<SmsForm {...props}/>
		</SmsFormWrapper>
	)
};
