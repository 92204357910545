import styled from 'styled-components';

export const StyledErrorNotificator = styled.div<{height?: string, Top?: string}>`
	position: absolute;
	color: red;
	top: ${({Top}) => Top || "100px"};;
	padding: 8px;
	font-family: Formular;
	font-size: 16px;
	line-height: 1.35;
	background: #ffffff;
	box-shadow: 0 3px 8px 2px rgba(0,0,0,0.1);
	z-index: 1;
`;