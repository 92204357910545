export const getQueryParams = () => {
  let finalQuery: string = ""
  const query = window.location.search.slice(1)
  if (query) {
    finalQuery = query
  }
  return finalQuery
}

export enum TestPageNames {
  TEST = "test",
  TEST1 = "1",
  TEST2 = "2",
  TEST3 = "3",
  TEST4 = "4",
  TEST5 = "5",
  TEST6 = "6"
}

export type TestPageNamesType = keyof typeof TestPageNames;

export const getCurrentFormParams = () => {
  const result: {
    formId?: string;
    product?: string;
    hash?: string;
    flowId?: string;
    isTest?: boolean;
    testName?: TestPageNamesType;
  } = {};
  const { hash, pathname }  = window.location;
  const [ first, second, third, fourth] = pathname.split('/').filter(Boolean);

  result.isTest = [TestPageNames.TEST, TestPageNames.TEST1, TestPageNames.TEST2, TestPageNames.TEST3, 
    TestPageNames.TEST4, TestPageNames.TEST5, TestPageNames.TEST6].includes(second as TestPageNames);
  if(result.isTest){
    const formId = third || first;
    result.testName = second as TestPageNamesType;
    if (formId && formId !== 'cards' && formId !== 'credits') result.formId = formId;
    if (!!third || first === 'cards' || first === 'credits') result.product = first;
    if (!!fourth) result.flowId = fourth;
  } else {
    const formId = second || first;
    if (formId && formId !== 'cards' && formId !== 'credits') result.formId = formId;
    if (!!second || first === 'cards' || first === 'credits') result.product = first;
    if (!!third) result.flowId = third;
  }
  if (!!hash) result.hash = hash;

  if (['start', 'draft'].includes('result.formId')) {
      if (hash) localStorage.setItem('hash', hash)
      else localStorage.removeItem('hash');
  }
  return result;
}

const QUERY_MAPPER = {
  "lead_id": "leadID",
  "utm_content": "utmContent",
  "utm_campaign": "utmCampaign",
  "utm_medium": "utmMedium",
  "utm_source": "sourceAdv",
  "click_id": "clickID",
  "utm_id": "utmID",
  "utmterm": "utmTerm",
  "referral": "referral",
  "questionnaire": "questionnaire",
}

export const onParseQuery = (queryString: string) => {
  let result = {}
  const queryPropsList = queryString.split("&")
  queryPropsList.forEach((param) => {
    const parsedParam = param.split("=")
    const key = QUERY_MAPPER[parsedParam[0] as keyof typeof QUERY_MAPPER]
    const value = parsedParam[1]
    result = {...result, [key ? key : parsedParam[0]]: value}
  })
  return result
}

export const buildFieldsObject = (formState: { [key: string]: any }) => {
  const utmFields: { [key: string]: any } = {};
  Object.entries(QUERY_MAPPER).forEach(([queryKey, dataKey]) => {
    if (dataKey in formState && formState[dataKey]) {
      utmFields[queryKey] = formState[dataKey];
    }
  });
  return utmFields;
};
