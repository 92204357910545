import { FormikValues } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { getDadataFio } from "../../../../redux/Application/app-reducer";
import { setFioList } from "../../../../redux/Application/appActions";
import { IFioDadataList } from "../../../../redux/Application/appTypes";
import { AnimatedBlock } from "../../../../styles/UIKit/AnimatedBlock";
import { CheckBoxField } from "../../../../styles/UIKit/CustomCheckBox/CheckBoxField";
import { CustomField } from "../../../../styles/UIKit/CustomField/CustomField";
import { CustomField2 } from "../../../../styles/UIKit/CustomField/CustomField2";
import { FioDadataField } from "../../../../styles/UIKit/dadata/FioDadataField";
import { Flex } from "../../../../styles/UIKit/Flex";
import { String } from "../../../../styles/UIKit/String";
import { onSetFieldBorderColor } from "../../../../utils/onSetFieldBorderColor";

interface IFioChangedField {
	userPassportData: FormikValues;
	standartFontSizeMeasures: string;
	updateFormCallBack: (formItem: string, currentValue: string, event: FormikValues) => void;
	standartHeightMeasures: string;
	standartWidthMeasures: string;
	fieldsWrapConditions: boolean;
	screenWidth: number;
	fioList: IFioDadataList[];
}
export const FioChangedField: React.FC<IFioChangedField> = (props) => {
	const {
		userPassportData,
		updateFormCallBack,
		standartFontSizeMeasures,
		standartHeightMeasures,
		standartWidthMeasures,
		fieldsWrapConditions,
		screenWidth,
		fioList,
	} = props;

	const dispatch = useDispatch()

	const _onDelay = React.useCallback(() => {
		if (userPassportData.values.previousFio) {
			const timeOutId = setTimeout(() => dispatch(getDadataFio({title: userPassportData.values.previousFio})), 500);
    	return () => clearTimeout(timeOutId);
		}
	}, [userPassportData.values.previousFio])
	
	// *** HANDLERS FOR FIO DADATA ***
	React.useEffect(_onDelay, [_onDelay])
	
	const setFioListOptionHandler = (chosenItem: IFioDadataList) => {
		userPassportData.setFieldValue("previousFio", chosenItem.value);
	}
	const resetFioListHandler = () => {
		dispatch(setFioList([]));
	};


	return (
		<>
			<Flex
				align={"center"}
				margin={"0 0 20px 0"}
				padding={"10px 0 0 0"}
			>
				<CheckBoxField 
					formItem="isChangedFIO" 
					checked={userPassportData.values.isChangedFIO} 
					onChange={() => userPassportData.setFieldValue("isChangedFIO", !userPassportData.values.isChangedFIO)} 
				/>
				<String margin="0 20px">Меняли ли вы ФИО?</String>
			</Flex>
			{userPassportData.values.isChangedFIO && (
				<AnimatedBlock>
					<FioDadataField
						formik={userPassportData}
						formName={"previousFio"}
						dadataList={fioList}
						label={"Данные о предыдущих ФИО"}
						fSize={standartFontSizeMeasures}
						width={"100%"}
						height={standartHeightMeasures}
						onMouseDownHandler={setFioListOptionHandler}	
						ResetListHandler={resetFioListHandler}
						borderColor={ onSetFieldBorderColor(userPassportData, "previousFio") }
						error={ userPassportData.touched.previousFio && userPassportData.errors.previousFio }
						errorValue={userPassportData.touched.previousFio && userPassportData.errors.previousFio}
					/>
				</AnimatedBlock>
			)}
		</>
	);
};