import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCallTouch } from '../../hooks/useCallTouch';
import useDimensions from '../../hooks/useDimensions';
import { useRefreshToken } from '../../hooks/useRefreshToken';
import { setCurrentForm } from '../../redux/Application/appActions';
import { ICompanyDadataList, IDadataAddressTypes, IFioDadataList, } from '../../redux/Application/appTypes';
import { TProductTypes } from '../../redux/commonTypes';
import { getCurrentForm } from '../../redux/Credit/credit-reducer';
import { setCalculatedCreditData } from '../../redux/Credit/creditActions';
import { Iorder, IProfileConfig2, IProfileState2, } from '../../redux/Credit/creditTypes';
import { AppRootStateType } from '../../redux/store';
import { ROUTE_PATH } from '../../routesConfig';
import { adaptiveMeasurements } from '../../styles/adaptiveParams';
import { CreditCalculator } from '../../utils/creditCalculator';
import { Profile } from './Profile';

export const ProfileContainer = ({ product } : { product: string }) => {
	const { screenWidth } = useDimensions();

	const dispatch = useDispatch();
	const paramsFlowId = useParams().flowId;
	const flowId = paramsFlowId || localStorage.getItem("flowId") || undefined;

	useCallTouch()

	useRefreshToken(flowId, product);

	React.useEffect(() => {
		dispatch(
			setCurrentForm({
				product: product as TProductTypes,
				formId: ROUTE_PATH.PROFILE,
				flowId,
			})
		);
		flowId && dispatch(getCurrentForm(flowId, product));
	}, []);

	const profileState = useSelector<AppRootStateType, IProfileState2 | undefined>((state) => state.creditReducer.profile?.state);
	const profileConfig = useSelector<AppRootStateType, IProfileConfig2 | undefined>((state) => state.creditReducer.profile?.config);
	const order = useSelector<AppRootStateType, Iorder | undefined>((state) => state.creditReducer.profile?.config.order);
	const workPlaceAddressList = useSelector<AppRootStateType, IDadataAddressTypes[]>((state) => state.appReducer.workPlaceAddressList);
	const companyNamesDadataList = useSelector<AppRootStateType, ICompanyDadataList[]>((state) => state.appReducer.companyNamesDadataList);
	const companyInnDadataList = useSelector<AppRootStateType, ICompanyDadataList[]>((state) => state.appReducer.companyInnDadataList);
	const workPlaceLegalAddressList = useSelector<AppRootStateType, IDadataAddressTypes[]>((state) => state.appReducer.workPlaceLegalAddressList);
	const fioList = useSelector<AppRootStateType, IFioDadataList[]>(state => state.appReducer.fioList);
	const phoneValidationErrorMessage = useSelector<AppRootStateType, string | null>(state => state.appReducer.phoneValidationErrorMessage)

	const standartFontSizeMeasures = adaptiveMeasurements(screenWidth,"28px","26px","24px","20px");
	const standartHeightMeasures = adaptiveMeasurements(screenWidth,"71px","68px","56px","50px");
	const standartWidthMeasures = adaptiveMeasurements(screenWidth,"360px","360px","100%","100%");

	React.useEffect(() => {
		if (order && flowId) {
			const { amount, rate, term, preferentialRate, preferentialTerm } = order;
			const calculator = CreditCalculator.calculateCredit(amount, term, preferentialRate, rate, preferentialTerm);
			dispatch(setCalculatedCreditData(calculator));
		}
	}, [order]);
	return (

		<>
			{flowId && profileState && profileConfig && order ? (
				<Profile
					employmentTypeRef={profileConfig.employmentTypeRef}
					contactsRelationRef={profileConfig.contactsRelationRef}
					occupationCompanyOpfRef={profileConfig.occupationCompanyOpfRef}
					product={product}
					companyNamesDadataList={companyNamesDadataList}
					companyInnDadataList={companyInnDadataList}
					standartFontSizeMeasures={standartFontSizeMeasures}
					standartHeightMeasures={standartHeightMeasures}
					standartWidthMeasures={standartWidthMeasures}
					screenWidth={screenWidth}
					profileConfig={profileConfig}
					flowId={flowId}
					creditAmount={profileConfig.order.amount}
					realEstateTypeOptions={profileConfig.realEstateTypeOptions}
					realEstateGettingWayOptions={profileConfig.realEstateGettingWayOptions}
					realEstatePledgedDocTypeOptions={profileConfig.realEstatePledgedDocTypeOptions}
					vehicleTypeOptions={profileConfig.vehicleTypeOptions}
					vehicleGettingWayOptions={profileConfig.vehicleGettingWayOptions}
					vehicleLabelOptions={profileConfig.vehicleLabelOptions}
					vehiclePledgedDocTypeOptions={profileConfig.vehiclePledgedDocTypeOptions}
					maritalStatusRef={profileConfig.maritalStatusRef}
					scanItLink={profileConfig.mobileScanner.scan}
					state={profileState}
					workPlaceAddressList={workPlaceAddressList}
					workPlaceLegalAddressList={workPlaceLegalAddressList}
					fioList={fioList}
					phoneValidationErrorMessage={phoneValidationErrorMessage}
				/>
			) : (
				<h1>Loading</h1>
			)}
		</>
	);
};
