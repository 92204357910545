import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTE_PATH } from "../routesConfig";
import { PassportContainer } from "./PassportDataForm/PassportContainer";
import { ProfileContainer } from "./Profile/ProfileContainer";
import { SubmitCreditContainer } from "./SubmitCredit/SubmitCreditContainer";
import { getCurrentProductName } from "../utils/getCurrentProductName";
import { ReferalLanding } from "../components/ReferalLanding/ReferalLanding";
import { Error } from "./Error/Error";
import { Consideration } from "../components/Consideration/Consideration";


export const CPZARoutes = () => {
	const currentProductName: string = getCurrentProductName()
	return (
		<Routes>
			<Route path={"/"} element={<Navigate to={`${ROUTE_PATH.START}`} replace />} />
			<Route path={`${ROUTE_PATH.START}`} element={<ReferalLanding product={currentProductName} />} />
			<Route path={`${ROUTE_PATH.START}/:flowId`} element={<SubmitCreditContainer product={currentProductName} />} />
			<Route path={`${ROUTE_PATH.DRAFT}/`} element={<SubmitCreditContainer product={currentProductName} />} />
			<Route path={`${ROUTE_PATH.DRAFT}/:flowId`} element={<SubmitCreditContainer product={currentProductName} />} />
			<Route path={`${ROUTE_PATH.PASSPORT}`} element={<PassportContainer product={currentProductName} />} />
			<Route path={`${ROUTE_PATH.PASSPORT}/:flowId`} element={<PassportContainer product={currentProductName} />} />
			<Route path={`${ROUTE_PATH.PROFILE}`} element={<ProfileContainer product={currentProductName} />} />
			<Route path={`${ROUTE_PATH.PROFILE}/:flowId`} element={<ProfileContainer product={currentProductName} />} />
			<Route path={`${ROUTE_PATH.IN_PROGRESS_FULL}`} element={<Consideration product={currentProductName} />} />
			<Route path={`${ROUTE_PATH.IN_PROGRESS_FULL}/:flowId`} element={<Consideration product={currentProductName} />} />
			<Route path={`${ROUTE_PATH.ERROR}`} element={<Error product={currentProductName} />} />
			<Route path={`${ROUTE_PATH.ERROR}/:flowId`} element={<Error product={currentProductName} />} />
		</Routes>
	);
};
