import './ConfettiEffect.css'
import Confetti1Img from '../../../assets/new-year-effect/confetti-1.webp';
import Confetti2Img from '../../../assets/new-year-effect/confetti-2.webp';
import Confetti3Img from '../../../assets/new-year-effect/confetti-3.webp';
import Confetti4Img from '../../../assets/new-year-effect/confetti-4.webp';
import ScatteringOfConfettiInTopLeftImg from '../../../assets/new-year-effect/scattering_of_confetti_in_top_left.webp';
import ScatteringOfConfettiInDownLeftImg from '../../../assets/new-year-effect/scattering_of_confetti_in_down_left.webp';
import ScatteringOfConfettiInTopRightImg from '../../../assets/new-year-effect/scattering_of_confetti_in_top_right.webp';
import ScatteringOfConfettiInDownRightImg from '../../../assets/new-year-effect/scattering_of_confetti_in_down_right.webp';

export const ConfettiEffect = ({product}: {product?: string}) => {

  return <div className={`confetti-effect confetti-effect_${product}`}>
    <img className={"effect-img Confetti1Img"} src={Confetti1Img} alt="конфетти"/>
    <img className={"effect-img Confetti2Img"} src={Confetti2Img} alt="конфетти"/>
    <img className={"effect-img Confetti3Img"} src={Confetti3Img} alt="конфетти"/>
    <img className={"effect-img Confetti4Img"} src={Confetti4Img} alt="конфетти"/>
    <img className={"effect-img ScatteringOfConfettiInTopLeftImg"} src={ScatteringOfConfettiInTopLeftImg} alt="конфетти"/>
    <img className={"effect-img ScatteringOfConfettiInDownLeftImg"} src={ScatteringOfConfettiInDownLeftImg} alt="конфетти"/>
    <img className={"effect-img ScatteringOfConfettiInTopRightImg"} src={ScatteringOfConfettiInTopRightImg} alt="конфетти"/>
    <img className={"effect-img ScatteringOfConfettiInDownRightImg"} src={ScatteringOfConfettiInDownRightImg} alt="конфетти"/>
    <img className={"effect-img ScatteringOfConfettiAdditionally1Img"} src={ScatteringOfConfettiInTopRightImg} alt="конфетти"/>
    <img className={"effect-img ScatteringOfConfettiAdditionally2Img"} src={ScatteringOfConfettiInDownRightImg} alt="конфетти"/>
  </div>
}