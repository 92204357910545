import "./PublicServicesProposal.css"

export const PublicServicesProposal = () => {
  return (
    <div className="public-service-proposal">
      <div className="public-service-proposal__title">Получите +70% к одобрению</div>
      <div className="public-service-proposal__text">
        Заполните заявку через Госуслуги, это значительно повысит шансы на одобрение кредита
      </div>
    </div>
  )
}