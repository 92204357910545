import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { Banner } from "../../components/Header/Banner/Banner";
import useDimensions from "../../hooks/useDimensions";
import {
	setCurrentForm,
	setIsShowFooterMenu,
} from "../../redux/Application/appActions";
import {
	IDadataAddressTypes,
	IFioDadataList,
} from "../../redux/Application/appTypes";
import { TProductTypes } from "../../redux/commonTypes";
import { getCurrentForm } from "../../redux/Credit/credit-reducer";
import {
	ICreditInitState,
	ISubmitCreditConfig,
	TFormId,
} from "../../redux/Credit/creditTypes";
import { AppRootStateType } from "../../redux/store";
import { ROUTE_PATH } from "../../routesConfig";
import { adaptiveParams } from "../../styles/adaptiveParams";
import { Flex } from "../../styles/UIKit/Flex";
import { onParseQuery, TestPageNames } from "../../utils/getQueryParams";
import { AdvantagesCards } from "./components/CreditAdvantages/AdvantagesCards";
import { ProgressModel } from "./components/ProgressModel/ProgressModel";
import { UsefulInfo } from "./components/UsefulInfo";
import { SubmitCredit } from "./SubmitCredit";
import { LoacalData } from "../../api/localStorage";
import styled from "styled-components";
import { onAuthEsia } from "../../redux/Auth/auth-reducer";
import { PRODUCTS_CODES } from "../../config";
// import { Advantages } from '../../components/Cards/Advantages';
import { MarathonComponent } from "../../components/Cards/marathon/Marathon";
import { PublicServicesBlock } from "../../components/PublicServicesBlock/PublicServicesBlock";
import { onSeparateLinksByProduct } from "../../utils/onSeparateLinksByProduct";
import { HowToGetBonus } from "../../components/HowToGetBonus/HowToGetBonus";
import {
	/* PAGE_EFFECTS, */ PageEffect,
} from "../../components/PageEffect/PageEffect";
import { Disclaimer } from "./Disclaimer/Disclaimer";
import { MINI_ADS_NAMES, MiniAds } from "../../components/MiniAds/MiniAds";
// import { IThemes, THEME_NAMES } from '../../styles/themes/themes';
import { STAGES, usePixel } from "../../hooks/usePixel";
import { CompleteIn2Minutes } from "../../components/CompleteIn2Minutes";
import { Indent } from "../../components/Indent";
import { Reviews } from "../../components/Reviews/Reviews";
import { IGenerate } from "../../redux/Auth/authTypes";

export interface IReferalQuery {
	clickID: string | null;
	utmCampaign: string | null;
	utmContent: string | null;
	utmID: string | null;
	utmMedium: string | null;
	sourceAdv: string | null;
	utmTerm: string | null;
	leadID: string | null;
}

// const ImageMacbook = styled.img`
//   width: ${(props) => props.width};
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   @media (max-width: 1200px) {
//     display: none;
//   }
// `;

const BlockContainer = styled.div`
	margin: 66px auto 22px;
	max-width: 1271px;
	padding: 0 15px;

	@media (max-width: 1191px) {
		text-align: center;
		text-wrap: balance;
	}

	@media (max-width: 1191px) {
		margin: 14px auto 22px;
	}

	@media (max-width: 760px) {
		text-wrap: unset;
	}
`;

const BlockTitle = styled.h2`
	font-size: 27.816px;
	font-family: Roboto;

	@media (max-width: 760px) {
		text-align: left;
	}
`;

// const PublicServicesBlockTitle = styled.h2<{ textAlign?: string }>`
//   text-align: ${(props => props.textAlign || 'center')};
//   font-family: Formular;
//   font-size: 23px;
//   margin: 31px 0 27px;
// `

export const SubmitCreditContainer = ({ product }: { product: string }) => {
	const dispatch = useDispatch();
	const { screenWidth } = useDimensions();

	const config = useSelector<
		AppRootStateType,
		ISubmitCreditConfig | undefined
	>((state) => state.creditReducer.initialCreditsData?.config);
	const codeId = useSelector<AppRootStateType, number | undefined>(
		(state) => state.authReducer.generate?.result?.codeId
	);
	const initialCreditState = useSelector<
		AppRootStateType,
		ICreditInitState | undefined
	>((state) => state.creditReducer.initialCreditsData?.state);
	const antifrodLife = useSelector<AppRootStateType, number | undefined>(
		(state) => state.authReducer.antifrodLife
	);
	const citiesList = useSelector<AppRootStateType, IDadataAddressTypes[]>(
		(state) => state.appReducer.citiesList
	);
	const formId = useSelector<AppRootStateType, TFormId | undefined>(
		(state) => state.creditReducer.initialCreditsData?.formId
	);
	const fioBlackListErrorMessage = useSelector<
		AppRootStateType,
		string | null
	>((state) => state.appReducer.fioBlackListErrorMessage);
	const phoneBlackListErrorMessage = useSelector<
		AppRootStateType,
		string | null
	>((state) => state.appReducer.phoneBlackListErrorMessage);
	const startDataQuery = useSelector<AppRootStateType, string | null>(
		(state) => state.appReducer.startDataQuery
	);
	const esiaData = useSelector<AppRootStateType, Object | null>(
		(state) => state.appReducer.esiaData
	);
	const fioList = useSelector<AppRootStateType, IFioDadataList[]>(
		(state) => state.appReducer.fioList
	);
	const phoneValidationErrorMessage = useSelector<
		AppRootStateType,
		string | null
	>((state) => state.appReducer.phoneValidationErrorMessage);
	const emailValidationErrorMessage = useSelector<
		AppRootStateType,
		string | null
	>((state) => state.appReducer.emailValidationErrorMessage);
	const isShowFooterMenu = useSelector<AppRootStateType, boolean>(
		(state) => state.appReducer.isShowFooterMenu
	);
	// const theme = useSelector<AppRootStateType, IThemes>((state) => state.appReducer.theme);
	const generate = useSelector<AppRootStateType, IGenerate | undefined>(
		(state) => state.authReducer.generate
	);
	const testPageName = useSelector<AppRootStateType, string | undefined>(
		(state) => state.appReducer.testPageName
	);

	const [smsCodeValue, setSmsCodeValue] = useState<string>("");
	const [isAgree, setIsAgree] = useState<boolean>(false);
	const [queryData, setQueryData] = useState<IReferalQuery | null>();

	const [searchParams] = useSearchParams();
	const isReferral = !!searchParams.get("referral");
	const isQuestionnaire = !!searchParams.get("questionnaire");

	const paramsFlowId = useParams().flowId;
	const flowId = paramsFlowId || localStorage.getItem("flowId") || undefined;

	const isTestPage2 = testPageName === TestPageNames.TEST2;
	const isTestPage1 = testPageName === TestPageNames.TEST1;

	const isCard = product === "cards";
	const isCredits = product === "credits";
	const isCpza = product === "cpza";
	const isMobile = screenWidth <= 767;

	const WRAP_CONDITIONS: { [key: string]: boolean } = {
		[ROUTE_PATH.CREDITS]: true,
		[ROUTE_PATH.CPZA]: true,
		[ROUTE_PATH.CARDS]: screenWidth > adaptiveParams.mainContentWidth,
	};
	const localData = new LoacalData();

	const onEsia = () =>
		flowId && dispatch(onAuthEsia(flowId, PRODUCTS_CODES[product]));

	if (isQuestionnaire && isShowFooterMenu) {
		dispatch(setIsShowFooterMenu(false));
	}

	if (!isQuestionnaire && !isShowFooterMenu) {
		dispatch(setIsShowFooterMenu(true));
	}

	usePixel({ stage: STAGES.start, product, searchParams });

	// Нужно для того, чтобы на стартовой форме сбрасывался CallTouch
	useEffect(() => {
		// @ts-ignore
		window.CalltouchDataObject && window.location.reload();
	}, []);

	useEffect(() => {
		localData.removeToken("accessToken");
		localData.removeToken("refreshToken");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => {
		if (!initialCreditState) {
			dispatch(
				setCurrentForm({
					flowId,
					formId: ROUTE_PATH.START,
					product: product as TProductTypes,
				})
			);
			flowId && dispatch(getCurrentForm(flowId, product));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, flowId, config]);

	useEffect(() => {
		startDataQuery &&
			setQueryData(onParseQuery(startDataQuery) as IReferalQuery);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{!generate?.success && (
				<>
					<Banner
						product={product}
						maxCreditAmount={config?.maxCreditAmount}
						maxCreditTerm={config?.maxCreditTerm}
						rate={config?.preferentialRate}
						hideProducts={isQuestionnaire}
						// showPhone={isQuestionnaire}
						// showCreditRateRange={!isReferral && isCredits}
						hideCardBanner={isQuestionnaire}
						hideLowerHeader={isQuestionnaire}
						isReferral={isReferral}
					/>
					{!isQuestionnaire &&
						!isCard &&
						WRAP_CONDITIONS[product] && (
							<ProgressModel
								product={product}
								config={config}
								isReferral={isReferral}
							/>
						)}
					{(isQuestionnaire ||
						(isCard && isReferral && screenWidth < 768)) && (
						<BlockContainer>
							{isCard && isReferral && screenWidth < 768 && (
								<Indent padding="35px 0 0" />
							)}
							<BlockTitle>
								{product === ROUTE_PATH.CARDS &&
									"Заполните онлайн-заявку и получите кредитную карту"}
								{product === ROUTE_PATH.CREDITS &&
									"Заполните онлайн-заявку и получите кредит"}
							</BlockTitle>
						</BlockContainer>
					)}
					{isCard && isReferral && (
						<CompleteIn2Minutes
							screenWidth={screenWidth}
							setShift={false}
							alignLeft
							noLimitWidth
						/>
					)}
					{isCard && isReferral && screenWidth < 768 && (
						<Indent padding="40px 0 0" />
					)}
					{(isQuestionnaire ||
						isCredits ||
						isCpza ||
						(!isMobile && isCard) ||
						(isReferral && isCard && isMobile)) &&
						config &&
						config.esiaenable && (
							<PublicServicesBlock
								onEsia={onEsia}
								isCard={isCard}
								noBackground={isQuestionnaire}
								textAlignLeft={
									isQuestionnaire && screenWidth < 760
								}
								bottomIndent={
									isQuestionnaire && screenWidth < 1190
								}
								hideCompleteIn2Minutes={
									isReferral && isCard && isMobile
								}
							/>
						)}
				</>
			)}

			{initialCreditState && formId && flowId && config && (
				<SubmitCredit
					esiaData={esiaData}
					citiesList={citiesList}
					antifrodLife={antifrodLife}
					initialCreditState={initialCreditState}
					screenWidth={screenWidth}
					codeId={codeId}
					formId={formId}
					config={config}
					smsCodeValue={smsCodeValue}
					setSmsCodeValue={setSmsCodeValue}
					flowId={flowId}
					isAgree={isAgree}
					setIsAgree={setIsAgree}
					product={product}
					queryData={queryData}
					fioList={fioList}
					fioBlackListErrorMessage={fioBlackListErrorMessage}
					phoneBlackListErrorMessage={phoneBlackListErrorMessage}
					phoneValidationErrorMessage={phoneValidationErrorMessage}
					emailValidationErrorMessage={emailValidationErrorMessage}
					onEsia={onEsia}
					isCard={isCard}
					isReferral={isReferral}
					hidePublicServices={isQuestionnaire || isMobile}
				/>
			)}
			{!generate?.success && (
				<>
					{(isCard || isCredits) &&
						isReferral &&
						!isQuestionnaire && (
							<Flex
								direction="column"
								align="center"
								width="100%"
							>
								<HowToGetBonus
									isMobile={screenWidth <= 750}
									config={config}
									product={product}
								/>
							</Flex>
						)}
					{(isCredits || isCpza) && !isQuestionnaire && (
						<>
							<AdvantagesCards
								rate={config?.preferentialRate}
								screenWidth={screenWidth}
								isMobile={screenWidth <= 750}
								product={product}
							/>
							{((isCredits && !isReferral && !isTestPage1) ||
								isTestPage2) && (
								<MiniAds type={MINI_ADS_NAMES.needMoreAmount} />
							)}
						</>
					)}

					{isCard && !isQuestionnaire && (
						<MarathonComponent
							screenWidth={screenWidth}
							isReferral={isReferral}
						/>
					)}

					{isCard && isReferral && !isQuestionnaire && <Reviews />}

					{/*{isCard && isReferral && !isQuestionnaire &&*/}
					{/*<Advantages rate={config?.creditcardRate} creditcardMaxAmount={config?.creditcardMaxAmount} isReferral={isReferral}/>}*/}

					{isCard &&
						screenWidth < 991 &&
						!isReferral &&
						!isQuestionnaire && <Disclaimer />}

					{!isQuestionnaire && (
						<Flex
							direction="column"
							backGround={isMobile ? "#FFF" : "#f3f4f8"}
							staticBackground={isMobile ? "#FFF" : "#f3f4f8"}
						>
							{config && (
								<UsefulInfo
									isCredit={isCredits}
									isCpza={isCpza}
									screenWidth={screenWidth}
									usefulInfoLinks={onSeparateLinksByProduct(
										config.usefulInfoLinks,
										product,
										isReferral
									)}
									showConditionText={
										isCpza ||
										isCredits ||
										(isCard && screenWidth > 991) ||
										(isCard && isReferral)
									}
									isReferral={isReferral}
									isCard={isCard}
								/>
							)}
						</Flex>
					)}
					<PageEffect
						// type={PAGE_EFFECTS.confetti}
						product={product}
					/>
				</>
			)}
		</>
	);
};
