import React from 'react';
import styled from 'styled-components';
import {defaultTheme} from "../themes/themes";

interface ITitle {
	Border?: string;
	color?: string;
	fSize?: string;
	padding?: string;
	margin?: string;
	fontWeight?: string;
  lineHeight?: string;
	cursor?: string;
	onClick?: (e: any) => void;
	textTransform?: string;
	fontName?: string;
  noChangeOnHover?: boolean;
  align?: string;
	alignSelf?: string;
}

const StyledTitle = styled.div<ITitle>`
	font-family: ${(props) => props.fontName || "Formular"};
	font-style: normal;
	text-transform: ${(props) => props.textTransform || "unset"};
	font-weight: ${(props) => props.fontWeight || "900"};
	padding: ${(props) => props.padding || "0"};
	margin: ${(props) => props.margin || "0"};
	font-size: ${(props) => props.fSize || "28px"};
	line-height: ${(props) => props.lineHeight || "1.25"};;
	color: ${(props) => props.color || "#0A1244"};
	cursor: ${(props) => props.cursor};
	border: ${({ Border }) => Border || "none"};
	text-align: ${(props) => props.align || "left"};
	align-self: ${props => props.alignSelf};

	&:hover {
    color: ${(props) => props.noChangeOnHover ? 'auto' : props.onClick ? defaultTheme.appColors.commonTheme : props.color};
		cursor: ${(props) => props.onClick ? "pointer" : "auto"};
	} 
`;

export const Title: React.FC<ITitle> = (props) => {
	return <StyledTitle {...props} />;
};