import { FormikValues } from 'formik';
import { ToggleField } from '../../../styles/UIKit/CustomCheckBox/ToggleField';

interface IIsRepayment {
	formik: FormikValues;
}

export const IsRepayment: React.FC<IIsRepayment> = (props) => {
	const { formik } = props;
	return (
		<>
			<ToggleField
				checked={formik.values.isRepayment}
				formItem={"isRepayment"}
				onChange={() => formik.setFieldValue("isRepayment", !formik.values.isRepayment)}
			/>
		</>
	);
};