import { FC } from "react";
import { useSelector } from "react-redux";
import { NavLink, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { ROUTE_PATH } from '../../routesConfig';
import { CustomNavLink } from '../Header/SubHeader/CustomNavLink';
import { AppRootStateType } from "../../redux/store";
import { TestPageNames } from '../../utils/getQueryParams';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled(NavLink)`
  position: relative;
  height: 80px;
  display: inline-flex;
  margin: 0 25px;
  align-items: center;
  font-family: Formular, sans-serif;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  
  &.active::after {
    display: block;
    position: absolute;
    content: ".";
    bottom: 0;
    left: 0;
    height: 0;
    width: 100%;
    border-radius: 2px 2px 0 0;
    background: white;
    border: 1px solid white;
  }
`

export interface ProductsProps {
  currentProduct: string;
}

export const Products: FC<ProductsProps> = ({currentProduct}) => {
  const [searchParams] = useSearchParams();
  const params = searchParams.toString();
  const linkSuffix = params ? ('?' + params) : '';
  const isTestMode = useSelector<AppRootStateType, boolean>(state => state.appReducer.isTestMode)
  const testPageName = useSelector<AppRootStateType, string | undefined>(state => state.appReducer.testPageName)

  const isTestPage2 = testPageName === TestPageNames.TEST2
  
  const showLink = isTestPage2 || !isTestMode

  const subLink = isTestPage2 ? `${TestPageNames.TEST2}/`: ""

  return (
     <Container>
         {showLink &&
            <CustomNavLink
              as={Button}
              to={`/${ROUTE_PATH.CREDITS}/${subLink}${ROUTE_PATH.START}${linkSuffix}`}
            >
              Кредиты
            </CustomNavLink>
         }
         {(showLink || currentProduct === ROUTE_PATH.CPZA) &&
            <CustomNavLink
              as={Button}
              to={`/${ROUTE_PATH.CPZA}/${ROUTE_PATH.START}${linkSuffix}`}
            >
              Кредит под залог авто
            </CustomNavLink>
         }
         {(showLink || currentProduct === ROUTE_PATH.CARDS) &&
            <CustomNavLink
              as={Button}
              to={`/${ROUTE_PATH.CARDS}/${ROUTE_PATH.START}${linkSuffix}`}
            >
              Карты
            </CustomNavLink>
         }
     </Container>
  )
}