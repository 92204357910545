import { FormikValues } from 'formik';
import React from 'react';
import { adaptiveParams } from '../../adaptiveParams';
import { HintedFieldWrapper } from '../dadata/HintedFieldWrapper';
import { Flex } from '../Flex';
import { String } from '../String';
import { StyledErrorNotificator } from '../StyledErrorNotificator';
import { FieldStyles, StyledFieldsProps } from './CustomFieldStyles';
import { calcErrorOffset } from '../../../utils/calcErrorOffset';

interface ICustomField extends StyledFieldsProps {
	screenWidth: number;
	onBlurHandler?: (
		formItem: string,
		currentValue: string,
		event: FormikValues
	) => void;
	formItem?: string;
	errorValue?: string;
	onFocusHandler?: () => void;
	subLabelColor?: string;
}

export const CustomField: React.FC<ICustomField> = (props) => {
	const { label, subLabel, screenWidth, onBlurHandler, formItem, errorValue, onFocusHandler } = props;
	const { mainContentWidth } = adaptiveParams;
	const fieldsWrapConditions: boolean = screenWidth > mainContentWidth;
	const Top = calcErrorOffset(screenWidth, fieldsWrapConditions, undefined, subLabel);
	const borderColor = errorValue ? 'red' : (props?.borderColor || 'transparent');
	return (
		<Flex
			direction="column"
			padding={fieldsWrapConditions ? "0" : "10px 0"}
			Position="relative"
		>
			{label && <String fSize={"14px"} margin="0 0 8px">{label}</String>}
			<HintedFieldWrapper>
			<FieldStyles
				{...props}
				borderColor={borderColor}
				onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
					event.target.setAttribute("autocomplete", "off");
					onFocusHandler && onFocusHandler()
				}}
				onBlur={(formikEvent: React.FocusEvent<HTMLInputElement>) => {
					if (formItem && onBlurHandler) {
						onBlurHandler(
							formItem,
							formikEvent.currentTarget.value,
							formikEvent
						);
					}
				}}
			/>
			</HintedFieldWrapper>
			{!!errorValue && <StyledErrorNotificator Top={Top}>{errorValue}</StyledErrorNotificator>}
			{!!subLabel && !errorValue && <String fSize={"12px"} color={props.subLabelColor}>{subLabel}</String>}
		</Flex>
	);
};