import React from 'react';
import './AdvantagesCards.css';
import PurposeIMG from '../../../../assets/purpose.webp';

interface AdvantagesCardProps {
	  screenWidth: number;
    rate?: number | string;
    isMobile?: boolean;
    product:string
}

interface Advantage {
  title: string,
  text: string,
  img?: string,
  alt?: string
  isBig?: boolean
}

const DICTIONARY_OF_ADVANTAGES: {[key:string]: Advantage[]} = {
  credits: [
    {
      title: 'На любые цели',
      text:'не откладывайте планы и мечты на потом',
      img: PurposeIMG,
      alt:'объемная галочка выполнено',
      isBig: true
    },
    {
      title: 'Без скрытых комиссий',
      text:'и платежей',
    },
    {
      title: 'Без залога и поручителей',
      text:'простые условия получения',
    },
    {
      title: 'Досрочное погашение',
      text:'без каких-либо доплат',
    },
    {
      title: 'Удобное погашение',
      text:'в приложении, на сайте, в банкоматах, отделениях или через банки-партнеры',
    },
  ],
  cpza: [
    {
      title: 'Меньше ежемесячный платеж',
      text:'в сравнении с обычным потребительским кредитом за счет возможности оформления на более длительный срок',
      img: PurposeIMG,
      alt:'объемная галочка выполнено',
      isBig: true
    },
    {
      title: 'Без скрытых комиссий',
      text:'и платежей',
    },
    {
      title: 'Можно пользоваться автомобилем',
      text: 'как обычно, он остается у заемщика',
    },
    {
      title: 'Досрочное погашение',
      text:'без каких-либо доплат',
    },
    {
      title: 'Удобное погашение',
      text:'в приложении, на сайте, в банкоматах, отделениях или через банки-партнеры',
    },
  ]
}

export const AdvantagesCards: React.FC<AdvantagesCardProps> = ({ screenWidth, rate = '4', isMobile= false, product }) => {
  const advantages: Advantage[] | [] = DICTIONARY_OF_ADVANTAGES[product] || [];

  return (
    <div className={"credit-advantages"}>
      <div className={"container"}>
        <h2 className={"credit-advantages__title"}>
          Преимущества
        </h2>
        <div className={"credit-advantages__list"}>
          {advantages.map((item) => (
            <div className={`credit-advantages__item${item.isBig ? ' credit-advantages__item_big' : ''}`}>
              <h3 className={"credit-advantages__item-title"}>
                {item.title}
              </h3>
              <span className={"credit-advantages__item-text"}>
                {item.text}
              </span>
              {item.alt && <img className={"credit-advantages__image"} src={item.img} alt={item.alt} />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
